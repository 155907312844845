import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MotdButtonComponent } from './motd-button/motd-button.component';
import { MotdDialogComponent } from './motd-dialog/motd-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [MotdButtonComponent, MotdDialogComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatDialogModule],
  exports: [MotdButtonComponent]
})
export class FlexMotdModule {}
