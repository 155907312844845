import { Action, Selector, State, StateContext } from '@ngxs/store';
import { GridPointLoadedEvent } from '../grid-point.actions';
import { FormStatus, NgxsFormState } from '../../../../common/ngxs-form-state';
import { Capacity } from '../../../../../core/domain/capacity';
import { GridPoint } from '../../../../../api-private/grid-point/grid-point';
import { Injectable } from '@angular/core';

export const DEFAULT_PULSE_WEIGHT = 1;
export const DEFAULT_PULSE_PEAK = Capacity.kW(0);

export class PulseMeasurementConfiguration {
  pulseDeviceId?: string;
  pulseConsumptionChannelName?: string;
  pulseProductionChannelName?: string;
  pulseConsumptionWeight?: number;
  pulseProductionWeight?: number;
  pulseConsumptionPeak?: Capacity;
  pulseProductionPeak?: Capacity;
  pulseEnabled: boolean = true;
  pulseConsumptionDataAvailableForLastHour: boolean = false;
  pulseProductionDataAvailableForLastHour: boolean = false;

  static fromGridPoint(gridPoint: GridPoint): PulseMeasurementConfiguration {
    if (!gridPoint) return new PulseMeasurementConfiguration();

    const {
      pulseConsumptionChannelName,
      pulseProductionChannelName,
      pulseConsumptionWeight,
      pulseProductionWeight,
      pulseDeviceId,
      pulseConsumptionDataAvailableForLastHour,
      pulseProductionDataAvailableForLastHour
    } = gridPoint;

    const pulseEnabled = !(
      pulseConsumptionChannelName === null &&
      pulseProductionChannelName === null &&
      pulseConsumptionWeight === null &&
      pulseProductionWeight === null
    );

    return {
      pulseEnabled,
      pulseProductionWeight: pulseProductionWeight || DEFAULT_PULSE_WEIGHT,
      pulseProductionChannelName,
      pulseConsumptionChannelName,
      pulseConsumptionWeight: pulseConsumptionWeight || DEFAULT_PULSE_WEIGHT,
      pulseDeviceId,
      pulseConsumptionDataAvailableForLastHour,
      pulseProductionDataAvailableForLastHour,
      pulseProductionPeak: gridPoint.pulseProductionPeak || DEFAULT_PULSE_PEAK,
      pulseConsumptionPeak: gridPoint.pulseConsumptionPeak || DEFAULT_PULSE_PEAK
    };
  }
}

export class PulseMeasurementConfigurationStateModel implements NgxsFormState<any> {
  dirty: boolean | null;
  model: PulseMeasurementConfiguration;
  status: FormStatus | null;
}

@State<PulseMeasurementConfigurationStateModel>({
  name: 'pulseMeasurementConfiguration',
  defaults: new PulseMeasurementConfigurationStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class PulseMeasurementConfigurationState {
  @Selector()
  public static getModel(state: PulseMeasurementConfigurationStateModel): any {
    return state.model;
  }

  @Action(GridPointLoadedEvent)
  setGridPoint({ setState, getState }: StateContext<PulseMeasurementConfigurationStateModel>, { payload }: GridPointLoadedEvent): void {
    setState({
      ...getState(),
      model: PulseMeasurementConfiguration.fromGridPoint(payload)
    });
  }
}
