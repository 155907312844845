import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';
import { Capacity } from '../domain/capacity';

@Pipe({
  name: 'capacityNumber'
})
export class CapacityNumberPipe implements PipeTransform {
  transform(value: any, targetUnit?: 'TW' | 'GW' | 'MW' | 'kW', digitsInfo?: string): any {
    if (!value?.unit) {
      value = {
        value: 0,
        unit: targetUnit
      };
    }

    return Capacity.format(value, targetUnit, false, digitsInfo);
  }
}

@Pipe({
  name: 'capacity'
})
export class CapacityPipe implements PipeTransform {
  transform(value: any, targetUnit?: 'TW' | 'GW' | 'MW' | 'kW', digitsInfo?: string): any {
    if (!value?.unit) {
      value = {
        value: 0,
        unit: targetUnit
      };
    }

    return Capacity.format(value, targetUnit, true, digitsInfo);
  }
}
