import { OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ConstructorType, MixinBase } from './constructor-type.mixin';

export interface OnDestroyProvider {
  onDestroy$: Subject<any>;
}

/**
 * Can be used to provide an OnDestroyProvider to constructors that require it.
 * E.g. when it is expected to live forever in providedIn: 'root' services.
 */
export const SINGLETON = new (class Foo extends OnDestroyMixin(MixinBase) {
  ngOnDestroy(): void {
    this.onDestroy$.next();
    // Do not complete, this will allow it to emit multiple times in tests.
  }
})();

export interface IDestroyable {
  destroy(): void;
}

export abstract class Destroyable implements IDestroyable {
  protected constructor(onDestroyProvider?: OnDestroyProvider) {
    if (onDestroyProvider) {
      onDestroyProvider.onDestroy$.subscribe(() => this.destroy());
    }
  }

  destroy(): void {}
}

// eslint-disable-next-line
export function OnDestroyMixin<TBase extends ConstructorType>(Base: TBase) {
  return class OnDestroyBase extends Base implements OnDestroy, OnDestroyProvider {
    _onDestroy$: Subject<void>;

    // This is a getter to ensure that it can be used in decorators.
    // If it was a property, it would only get initialized when the constructor is called, which is after decorators fire.
    // Getters and methods are created earlier.
    get onDestroy$(): Subject<void> {
      if (!this._onDestroy$) {
        this._onDestroy$ = new Subject();
      }

      return this._onDestroy$;
    }

    ngOnDestroy(): void {
      this.onDestroy$.next();
      this.onDestroy$.complete();
    }
  };
}

// eslint-disable-next-line
export function DestroyableMixin<TBase extends ConstructorType>(Base: TBase) {
  return class DestroyableBase extends Base implements IDestroyable {
    registerOnDestroyProvider(onDestroyProvider: OnDestroyProvider): this {
      onDestroyProvider.onDestroy$.subscribe(() => this.destroy());
      return this;
    }

    destroy(): void {
      console.warn('Destroy method not implemented when DestroyableMixin is used. This method should be overridden.');
    }
  };
}
