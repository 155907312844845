import { D3GraphSubmodule } from '../d3-graph-submodule';
import { BaseType, ScaleTime, Selection } from 'd3';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ScaleLinear } from 'd3-scale';
import { takeUntil } from 'rxjs/operators';
import { last } from 'lodash-es';
import { MixinBase } from '../../../../core/common/constructor-type.mixin';
import { DynamicDataHelper, SubjectProvider } from '../../common';
import { OnDestroyMixin } from '../../../../core/common/on-destroy.mixin';

export interface HorizontalLineDatum {
  x1: number;
  x2: number;

  y: number;

  /**
   * Color of the line
   */
  color: string;
}

export interface GapIndicatorLineHelperDatum extends HorizontalLineDatum {
  xGaps: number[]; // Center positions of the 'heartbeat'

  xIntensity: number; // Width of the 'heartbeat' in px
  yIntensity: number; // Height of the 'heartbeat' in px
}

type SupportedXScale = ScaleTime<number, number>;
type SupportedYScale = ScaleLinear<any, any>;

export class D3GraphScaleLineHorizontalLines extends OnDestroyMixin(MixinBase) implements D3GraphSubmodule {
  private yScaleProvider = new SubjectProvider<SupportedYScale>(this);
  private xScaleProvider = new SubjectProvider<SupportedXScale>(this);
  private gapsProvider = new SubjectProvider<any[]>(this, new BehaviorSubject([]));
  private horizontalLinesHelper: HorizontalLinesHelper;
  private horizontalLinesWithGapIndicatorHelper: HorizontalLineWithGapIndicatorHelper;
  private numberOfTicks: number;
  private baselineYPositionsProvider = new SubjectProvider<number[]>(this);

  private colorDefaultLine = '#f6f8fa';
  private colorBaseline = '#a3b0ba';

  constructor() {
    super();
  }

  attach(mainSvg: Selection<BaseType, any, any, any>): () => any {
    const node = mainSvg.append('g').attr('class', 'horizontal-lines');

    this.horizontalLinesHelper = new HorizontalLinesHelper(node);
    this.horizontalLinesWithGapIndicatorHelper = new HorizontalLineWithGapIndicatorHelper(node);

    const subscription = combineLatest([
      this.yScaleProvider.value$,
      this.xScaleProvider.value$,
      this.baselineYPositionsProvider.value$,
      this.gapsProvider.value$
    ])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([yScale, xScale, baselineYPositions, gaps]) => {
        const scaleTicks = this.numberOfTicks ? yScale.ticks(this.numberOfTicks) : yScale.ticks();

        this.horizontalLinesHelper.setData(
          scaleTicks
            .filter((scaleTick) => !baselineYPositions.includes(scaleTick))
            .map(yScale)
            .map((yPosition) => {
              return {
                y: yPosition,
                x1: xScale.range()[0],
                x2: xScale.range()[1],
                color: this.colorDefaultLine
              };
            })
        );

        this.horizontalLinesWithGapIndicatorHelper.setData(
          baselineYPositions.map((baselineYPosition) => {
            return {
              y: baselineYPosition,
              x1: xScale.range()[0],
              x2: xScale.range()[1],
              color: this.colorBaseline,
              yIntensity: 20,
              xIntensity: 20,
              xGaps: gaps.map(xScale)
            };
          })
        );
      });

    return () => subscription.unsubscribe;
  }

  setXScale$(xScale$: Observable<SupportedXScale>): void {
    this.xScaleProvider.follow(xScale$);
  }

  setYScale$(scale$: Observable<SupportedYScale>): void {
    this.yScaleProvider.follow(scale$);
  }

  /**
   * Create 'heartbeats' in the horizontal line centered on the given positions of the domain
   */
  setGaps$(gaps$: Observable<any[]>): void {
    this.gapsProvider.follow(gaps$);
  }

  setNumberOfTicks(numberOfTicks: number): void {
    this.numberOfTicks = numberOfTicks;
  }

  setBaselinePositions$(baselineYAxisScalePositions$: Observable<number[]>): void {
    this.baselineYPositionsProvider.follow(baselineYAxisScalePositions$);
  }
}

/**
 * Draw horizontal lines for the provided y values
 */
export class HorizontalLinesHelper extends DynamicDataHelper<HorizontalLineDatum> {
  protected nodeName = 'line';
  protected class = 'horizontal-line';

  protected setDynamic(selectAllWithData: Selection<BaseType, HorizontalLineDatum, BaseType, any>): void {
    selectAllWithData
      .attr('x1', (datum) => Math.max(datum.x1, 1))
      .attr('x2', (datum) => Math.max(datum.x2, 1))
      .attr('y1', (datum) => Math.max(datum.y, 1))
      .attr('y2', (datum) => Math.max(datum.y, 1))
      .attr('stroke', (datum) => datum.color);
  }

  protected setStatic(selectAllWithData: Selection<BaseType, HorizontalLineDatum, BaseType, any>): void {
    selectAllWithData.attr('shape-rendering', 'crispEdges');
  }
}

export class HorizontalLineWithGapIndicatorHelper extends DynamicDataHelper<GapIndicatorLineHelperDatum> {
  protected nodeName = 'path';
  protected class = 'horizontal-line-with-gap-indicator';

  protected setDynamic(selectAllWithData: Selection<BaseType, GapIndicatorLineHelperDatum, BaseType, any>): void {
    selectAllWithData.attr('d', (datum) => this.getFullPath(datum)).attr('stroke', (datum) => datum.color);
  }

  protected setStatic(selectAllWithData: Selection<BaseType, GapIndicatorLineHelperDatum, BaseType, any>): void {
    selectAllWithData.attr('shape-rendering', 'crispEdges');
  }

  getFullPath(datum: GapIndicatorLineHelperDatum): string {
    // Start at the start point defined by x1 and y
    let line = `m ${datum.x1} ${datum.y}`;

    datum.xGaps.forEach((gap) => {
      const start = gap - datum.xIntensity / 2;
      // Add a horizontal line to the start of the next heartbeat gap, then add the heartbeat
      line += ` H${start} ${this.getHeartBeatPath(datum.xIntensity, datum.yIntensity)}`;
    });

    // Add the last horizontal line to X2
    line += ` H ${datum.x2}`;

    return line;
  }

  getHeartBeatPath(width: number, height: number): string {
    const xStep = Math.round(width / 4);
    const yStep = Math.round(height / 2);

    return `l ${xStep} -${yStep} ` + `l ${xStep * 2} ${height} ` + `l ${xStep} -${yStep}`;
  }
}
