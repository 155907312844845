import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { timer } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { MixinBase } from '../../../core/common/constructor-type.mixin';

import { OnDestroyMixin } from '../../../core/common/on-destroy.mixin';
import { SubjectProvider } from '../../../d3-graph/d3-graph/common';
import { isObject } from 'lodash-es';
/**
 * Simple save button that blocks click actions and shows a spinner when pending
 */
@Component({
  selector: 'ph-flex-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.scss']
})
export class SaveButtonComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  clickDebounceProvider = new SubjectProvider<MouseEvent>(this);

  /**
   * Set cdkFocusInitial on button if true. Can be useful when used in dialogs to allow for immediate confirmation
   */
  @Input() shouldGetInitialFocus: boolean = false;

  /**
   * Disable button without changing color
   */
  @Input() disabled: boolean = false;

  /**
   * Pending state, when true, show spinner
   * Is not affected by clicking the button, should be managed by the parent component
   */
  @Input() pending: boolean = false;

  /**
   * Error input, used for the tooltip text if truthy but not True. When non-null button will be shown as color="warn"
   */
  @Input() error: string | boolean | null = null;

  /**
   * Success input, used for the tooltip text if truthy but not True. When non-null button will be shown as color="success", if no error is present
   */
  @Input() success: string | boolean | null = null;

  /**
   * Debounce time in ms for click action
   */
  @Input() debounceTime: number = 100;

  @Input() minDisabledTimeAfterClick: number = 2000;

  /**
   * Label is used when ng-content is not provided
   */
  @Input() label: string = 'Save';

  /**
   * Label to be shown when spinner is visible (button is 'pending')
   * Default is NULL, since we only show a spinner most of the time.
   */
  @Input() pendingLabel?: string = null;

  recentlyClicked = false;

  /**
   * Non-error color attribute for button
   */
  @Input() color: string = 'primary';

  @Input() type: 'flat' | 'stroked' = 'flat';

  @Output() clickChanged = new EventEmitter<MouseEvent>();

  constructor(private cdr: ChangeDetectorRef) {
    super();
  }

  get _isDisabled(): boolean {
    return this.disabled || this.pending || this.recentlyClicked;
  }

  get _color(): string {
    if (this.error) {
      return 'warn';
    }
    if (this.success) {
      return 'success';
    }
    return this.color || undefined;
  }

  get _tooltip(): string {
    if (this.error) {
      return this.mapSuccessOrErrorInput(this.error);
    }
    if (this.success) {
      return this.mapSuccessOrErrorInput(this.success);
    }
    return '';
  }

  private mapSuccessOrErrorInput(input: any): string {
    if (input === true) {
      return '';
    }
    if (isObject(input)) {
      return JSON.stringify(input);
    }
    return input;
  }

  ngOnInit(): void {
    this.clickDebounceProvider.value$.pipe(debounce(() => timer(this.debounceTime))).subscribe((event) => {
      this.recentlyClicked = true;
      this.clickChanged.emit(event);

      setTimeout(() => {
        this.recentlyClicked = false;
        this.cdr.detectChanges();
      }, this.minDisabledTimeAfterClick);
    });
  }
}
