<ng-container [ngTemplateOutlet]="selected ? selectedMenuItem : normalMenuItem"></ng-container>

<ng-template #selectedMenuItem>
  <button mat-raised-button disableRipple class="selected">
    <ng-container [ngTemplateOutlet]="contentOutlet"></ng-container>
  </button>
</ng-template>

<ng-template #normalMenuItem>
  <button mat-flat-button color="primary" disableRipple>
    <ng-container [ngTemplateOutlet]="contentOutlet"></ng-container>
  </button>
</ng-template>

<ng-template #contentOutlet>
  <ng-content></ng-content>
</ng-template>
