import { Observable } from 'rxjs';
import { first, timeout } from 'rxjs/operators';

const DEBUG = true;
const DEBUG_TIMEOUT = 1500;

/**
 * Throw an error when a specific observable does not return a value within the specified timeout, where this is expected.
 * Used for e.g. graphs where some Subject/ObservableProviders are expected/required to emit a value for a downstream value to be determined.
 */
export function expectObservableValue(observable: Observable<any>, ...args: any): void {
  if (!DEBUG) {
    return;
  }

  observable.pipe(first(), timeout(DEBUG_TIMEOUT)).subscribe({
    error(err: any): void {
      if (err?.name === 'TimeoutError') {
        console.error(...args);
      }
    }
  });
}
