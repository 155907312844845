import { Selection } from 'd3';
import { BaseType } from 'd3-selection';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { D3GraphSubmodule } from '../submodules/d3-graph-submodule';
import { MixinBase } from '../../../core/common/constructor-type.mixin';
import { SubjectProvider } from '../common';
import { DestroyableMixin, OnDestroyMixin, OnDestroyProvider } from '../../../core/common/on-destroy.mixin';

export class SubmoduleAttachHelper extends OnDestroyMixin(DestroyableMixin(MixinBase)) {
  protected mainSvg: Selection<BaseType, any, any, any>;
  protected lastUnsubscribeProvider = new SubjectProvider(this, new BehaviorSubject(undefined));
  isDisabled$: Observable<boolean> = this.lastUnsubscribeProvider.value$.pipe(map((value) => !value));
  protected containerNode: Selection<BaseType, any, any, any>;
  protected isShownProvider = new SubjectProvider<boolean>(this, new BehaviorSubject(true));

  constructor(private onDestroyProvider: OnDestroyProvider, protected subModule: D3GraphSubmodule) {
    super();

    this.registerOnDestroyProvider(onDestroyProvider);
  }

  get isDisabled(): boolean {
    return !this.lastUnsubscribeProvider.value;
  }

  toggle(): void {
    this.isShownProvider.next(this.isDisabled);

    if (this.isDisabled) {
      this.attach(this.mainSvg);
    } else {
      this.detach();
    }
  }

  /**
   * Same as attach, but can also just register mainSvg
   */
  register(mainSvg: Selection<BaseType, any, any, any>, attach: boolean): void {
    if (!attach) {
      this.mainSvg = mainSvg;
      return;
    }
    this.attach(mainSvg);
  }

  attach(mainSvg: Selection<BaseType, any, any, any>): void {
    this.mainSvg = mainSvg;
    if (!this.isShownProvider.value) {
      return;
    }
    const containerNode = this.getOrCreateContainerNode();
    if (this.mainSvg) {
      // Detach previously attached submodule, if it exists
      this.detach();
      this.lastUnsubscribeProvider.next(this.subModule.attach(containerNode));
    }
  }

  detach(): void {
    if (this.lastUnsubscribeProvider.value) {
      this.lastUnsubscribeProvider.value();
      this.lastUnsubscribeProvider.next(null);
    }
  }

  protected getOrCreateContainerNode(): Selection<BaseType, any, any, any> {
    if (!this.containerNode) {
      this.containerNode = this.mainSvg.append('g');
    }
    return this.containerNode;
  }
}
