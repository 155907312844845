import { AfterContentInit, Component, ContentChildren, EventEmitter, HostBinding, Input, Output, QueryList } from '@angular/core';
import { Observable } from 'rxjs';
import { get, has } from 'lodash-es';
import { SidenavMenuItemContentDef } from './sidenav-menu-item-content-def.directive';
import { OnDestroyMixin } from '../../core/common/on-destroy.mixin';
import { AppInfo, AppInfoService } from '../../core/app-info/app-info.service';
import { takeUntil } from 'rxjs/operators';
import { MixinBase } from '../../core/common/constructor-type.mixin';

@Component({
  selector: 'ph-flex-sidenav-menu',
  templateUrl: './sidenav-menu.component.html',
  styleUrls: ['./sidenav-menu.component.scss']
})
export class SidenavMenuComponent extends OnDestroyMixin(MixinBase) implements AfterContentInit {
  @Input() logoUrl: string;
  @Input() menuItems: string[] = [];
  @Input() requiredAuthorities: { [key: string]: string[] }[] = [];
  @Output() logoClicked = new EventEmitter<MouseEvent>();
  @HostBinding('class.sidebar-theme') themeClass = true;
  appInfo$: Observable<AppInfo>;

  sidenavMenuItemDirective: SidenavMenuItemContentDef;
  @ContentChildren(SidenavMenuItemContentDef) sidenavMenuItemDirectives: QueryList<SidenavMenuItemContentDef>;

  constructor(appInfoService: AppInfoService) {
    super();
    this.appInfo$ = appInfoService.getAppInfo();
  }

  ngAfterContentInit(): void {
    this.sidenavMenuItemDirective = this.sidenavMenuItemDirectives.first;
    this.sidenavMenuItemDirectives.changes.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.sidenavMenuItemDirective = this.sidenavMenuItemDirectives.first;
    });
  }

  isSelected(menuItem: string): boolean {
    return menuItem === this.menuItems[1];
  }

  getRequiredAuthorities(menuItemName: string): ReadonlyArray<string> {
    if (has(this.requiredAuthorities, menuItemName)) {
      return get(this.requiredAuthorities, menuItemName) as any;
    }
    return ['no-one'];
  }

  clickToClipBoard(text: string): void {
    const inputElement = document.createElement('input');
    inputElement.value = text;
    inputElement.setAttribute('readonly', '');
    inputElement.style.left = '-9999px';
    inputElement.style.position = 'fixed';

    document.body.appendChild(inputElement);

    inputElement.select();
    document.execCommand('copy');
    document.body.removeChild(inputElement);
  }
}
