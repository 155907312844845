import { Pipe, PipeTransform } from '@angular/core';
import { Parser } from 'expr-eval';

@Pipe({
  name: 'percentageToFactor'
})
export class PercentageToFactorPipe implements PipeTransform {
  static calculator = Parser.parse('percentage / 100');

  transform(value: any): any {
    return value && PercentageToFactorPipe.calculator.evaluate({ percentage: value });
  }
}
