import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Customer } from './customer';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RestClientService } from '../rest-client/rest-client.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class CustomerService extends RestClientService<Customer> {
  constructor() {
    super('/api/v1/customers');
  }

  getById(id: string): Observable<Customer> {
    const url = `${this.endpoint}/${id}`;
    return this.http.get<Customer>(url).pipe(tap(() => this.log(`fetched id=${id}`)));
  }

  add(value: Customer): Observable<Customer> {
    return this.http.post<Customer>(this.endpoint, value, httpOptions).pipe(tap((added: Customer) => this.log(`added id=${added.id}`)));
  }

  update(value: Customer): Observable<Customer> {
    const url = `${this.endpoint}/${value.id}`;
    return this.http.put<Customer>(url, value, httpOptions).pipe(tap(() => this.log(`updated id=${value.id}`)));
  }
}
