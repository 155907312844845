import { JsonProperty, JsonObject } from 'typescript-json-serializer';

import { Parser } from 'expr-eval';
import { formatNumber } from '@angular/common';
import { AppInjector } from '../common/app-injector';
import { LOCALE_ID } from '@angular/core';

export type CapacityUnit = 'TW' | 'GW' | 'MW' | 'kW';

@JsonObject()
export class Capacity {
  static kWFactor = 3;
  static MWFactor = 6;
  static GWFactor = 9;
  static TWFactor = 12;

  static kWDefaultDigitsInfo = '0.0-0';
  static MWDefaultDigitsInfo = '0.0-3';
  static GWDefaultDigitsInfo = '0.0-3';
  static TWDefaultDigitsInfo = '0.0-3';
  static FallbackDefaultDigitsInfo = '0.0-3';

  static converter = Parser.parse('inValue * 10 ^ (inFactor - outFactor)');

  @JsonProperty()
  value: number;

  @JsonProperty()
  unit: CapacityUnit;

  /**
   * Return the raw capacity value.
   * Only use for display and raw edit functionality! In all other cases, convert to the desired unit using the asX functions.
   */
  static value(capacity: Capacity): number {
    return capacity.value;
  }

  static kW(value: number): Capacity {
    return {
      value,
      unit: 'kW'
    } as any;
  }

  static MW(value: number): Capacity {
    return {
      value,
      unit: 'MW'
    } as any;
  }

  static TW(value: number): Capacity {
    return {
      value,
      unit: 'TW'
    } as any;
  }

  static isZero(capacity: Capacity): boolean {
    return capacity?.value === 0;
  }

  static isAboveZero(capacity: Capacity): boolean {
    return capacity?.value > 0;
  }

  static factor(capacity: Capacity): number {
    switch (capacity.unit) {
      case 'kW':
        return Capacity.kWFactor;
      case 'MW':
        return Capacity.MWFactor;
      case 'GW':
        return Capacity.GWFactor;
      case 'TW':
        return Capacity.TWFactor;
      default:
        return 0;
    }
  }

  static asKW(capacity: Capacity): number | null {
    if (!capacity) {
      return null;
    }

    if (capacity.unit === 'kW') {
      return capacity.value;
    }
    return Capacity.converter.evaluate({
      inValue: capacity.value,
      inFactor: Capacity.factor(capacity),
      outFactor: Capacity.kWFactor
    });
  }

  static asMW(capacity: Capacity): number | null {
    if (!capacity) {
      return null;
    }

    if (capacity.unit === 'MW') {
      return capacity.value;
    }
    return Capacity.converter.evaluate({
      inValue: capacity.value,
      inFactor: Capacity.factor(capacity),
      outFactor: Capacity.MWFactor
    });
  }

  static asGW(capacity: Capacity): number | null {
    if (!capacity) {
      return null;
    }

    if (capacity.unit === 'GW') {
      return capacity.value;
    }
    return Capacity.converter.evaluate({
      inValue: capacity.value,
      inFactor: Capacity.factor(capacity),
      outFactor: Capacity.GWFactor
    });
  }

  static asTW(capacity: Capacity): number | null {
    if (!capacity) {
      return null;
    }

    if (capacity.unit === 'TW') {
      return capacity.value;
    }
    return Capacity.converter.evaluate({
      inValue: capacity.value,
      inFactor: Capacity.factor(capacity),
      outFactor: Capacity.TWFactor
    });
  }

  /**
   * Returns true if capacity is >= 1GW, regardless of unit
   */
  static isGW(capacity: Capacity): boolean {
    switch (capacity.unit) {
      case 'GW':
        return capacity.value >= 1;
      case 'MW':
        return capacity.value >= 1000;
      case 'kW':
        return capacity.value >= 1000_000;
    }
    return false;
  }

  static format(capacity: Capacity, targetUnit: CapacityUnit = capacity?.unit, addUnit: boolean = true, digitsInfo?: string): string {
    if (!capacity) {
      return '';
    }

    let numberValue;

    switch (targetUnit) {
      case 'kW':
        numberValue = Capacity.asKW(capacity);
        digitsInfo = digitsInfo ?? Capacity.kWDefaultDigitsInfo;
        break;
      case 'MW':
        numberValue = Capacity.asMW(capacity);
        digitsInfo = digitsInfo ?? Capacity.MWDefaultDigitsInfo;
        break;
      case 'GW':
        numberValue = Capacity.asGW(capacity);
        digitsInfo = digitsInfo ?? Capacity.GWDefaultDigitsInfo;
        break;
      case 'TW':
        numberValue = Capacity.asTW(capacity);
        digitsInfo = digitsInfo ?? Capacity.TWDefaultDigitsInfo;
        break;
      default:
        numberValue = capacity.value;
        digitsInfo = digitsInfo ?? Capacity.FallbackDefaultDigitsInfo;
    }

    return `${formatNumber(numberValue, AppInjector.get(LOCALE_ID), digitsInfo)}` + (addUnit ? ` ${targetUnit}` : '');
  }

  static invert(capacity: Capacity): Capacity {
    if (!capacity) {
      return capacity;
    }

    return {
      value: -capacity.value,
      unit: capacity.unit
    };
  }
}
