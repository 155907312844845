import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridPointDatasourceDirective } from './grid-point/grid-point-datasource.directive';
import { CustomerDatasourceDirective } from './customer/customer-datasource.directive';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConversationInterceptorService } from './conversation-interceptor.service';
import { AnalogInputDatasourceDirective } from './analog-input/analog-input-datasource.directive';
import { ControlDatasourceDirective } from './control/control-datasource.directive';
import { DataSourceFilterByGridPointIdDirective } from './grid-point/grid-point-id-data-source-filter.directive';
import { DataSourceFilterByCustomerIdDirective } from './customer/customer-id-data-source-filter.directive';
import { DataSourceFilterByIdDirective } from './grid-point/id-data-source-filter.directive';
import { GridPointPipe } from './grid-point/grid-point.pipe';

@NgModule({
  declarations: [
    GridPointDatasourceDirective,
    CustomerDatasourceDirective,
    AnalogInputDatasourceDirective,
    ControlDatasourceDirective,
    DataSourceFilterByGridPointIdDirective,
    DataSourceFilterByCustomerIdDirective,
    DataSourceFilterByIdDirective,
    GridPointPipe
  ],
  imports: [CommonModule],
  exports: [
    GridPointDatasourceDirective,
    CustomerDatasourceDirective,
    AnalogInputDatasourceDirective,
    ControlDatasourceDirective,
    DataSourceFilterByGridPointIdDirective,
    DataSourceFilterByCustomerIdDirective,
    DataSourceFilterByIdDirective,
    GridPointPipe
  ]
})
export class FlexApiPrivateModule {
  static forRoot(): ModuleWithProviders<FlexApiPrivateModule> {
    return {
      ngModule: FlexApiPrivateModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ConversationInterceptorService,
          multi: true
        }
      ]
    };
  }
}
