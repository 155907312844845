import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Selection } from 'd3';
import { BaseType, EnterElement } from 'd3-selection';
import { DestroyableMixin, OnDestroyMixin, OnDestroyProvider } from '../../../core/common/on-destroy.mixin';
import { MixinBase } from '../../../core/common/constructor-type.mixin';
import { D3GraphSubmodule } from './d3-graph-submodule';
import { AxisPosition, SubjectProvider } from '../common';

/**
 * Reusable D3 component that draws an axis including legend labels (e.g. x axis)
 */
export abstract class D3GraphAxisSubModule<TConfig extends { position: AxisPosition }, TXScale, TYScale>
  extends OnDestroyMixin(DestroyableMixin(MixinBase))
  implements D3GraphSubmodule
{
  /**
   * Translated label string
   */
  protected abstract label: string;

  /**
   * Class to add on the group
   */
  protected abstract className: string;

  protected configProvider: SubjectProvider<TConfig> = new SubjectProvider<TConfig>(
    this,
    new BehaviorSubject({ position: 'left' } as TConfig)
  );
  protected xScaleProvider = new SubjectProvider<TXScale>(this);
  yScaleProvider = new SubjectProvider<TYScale>(this);

  constructor(onDestroyProvider: OnDestroyProvider) {
    super();
    this.registerOnDestroyProvider(onDestroyProvider);
  }

  destroy(): void {
    this.ngOnDestroy();
  }

  setConfig(config: Partial<TConfig>): void {
    this.configProvider.next({
      ...this.configProvider.value,
      ...config
    });
  }

  setYScale$(yScale$: Observable<TYScale>): void {
    this.yScaleProvider.follow(yScale$);
  }

  setXScale$(xScale$: Observable<TXScale>): void {
    this.xScaleProvider.follow(xScale$);
  }

  /**
   * Calls internalAttach to attach the axis to the mainSVG.
   * Creates a container g node with the class set by this.class
   * Returns an unattach method that, when called, removes the node from the DOM and unsubscribes the internal subscription.
   */
  attach(mainSvg: Selection<BaseType, any, any, any>): () => void {
    const node = mainSvg.append('g').attr('class', this.className);

    const subscription = this.internalAttach(node);

    return () => {
      subscription.unsubscribe();
      node.remove();
    };
  }

  protected abstract internalAttach(mainSvg: Selection<EnterElement, any, BaseType, any>): Subscription;
}
