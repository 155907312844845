import { AbstractControl, UntypedFormGroup, ValidationErrors, Validator, ValidatorFn, Validators } from '@angular/forms';
import { isFinite, isFunction, isNumber } from 'lodash-es';
import moment, { isMoment, Moment } from 'moment';
import { arrayWithDefault } from './array-with-default';
import { PhoneNumberUtil } from 'google-libphonenumber';

function assert(condition: any, msg?: string): asserts condition {
  console.assert(condition, msg);
}

// @dynamic
export class CustomValidators {
  /**
   * Do not require the control to have a value, but if it has a value it should also exist in the provided array of values
   * Generally used to ensure the selected value exists in the current selectable dataset for drop downs and the like
   * @param input Array or function that returns an array to check the control value against
   */
  static inCollection(input: any[] | (() => any[])): ValidatorFn {
    return (control: AbstractControl) => {
      let inputArray = isFunction(input) ? input() : input;
      inputArray = arrayWithDefault(inputArray);
      return !control.value || inputArray.includes(control.value) ? {} : { 'not-included-in-collection': true };
    };
  }

  static conditionalRequired(input: boolean | (() => boolean)): ValidatorFn {
    return (control: AbstractControl) => {
      const inputBoolean = isFunction(input) ? input() : input;
      return inputBoolean ? Validators.required(control) : null;
    };
  }

  static atLeastOneRequired: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    let result = false;
    Object.keys(control.controls).forEach((key) => {
      result = result || Validators.required(control.controls[key]) == null;
    });
    return !result ? { atLeastOneRequired: true } : null;
  };

  static atLeastOneGreaterThanZero: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    let result = false;
    Object.keys(control.controls).forEach((key) => {
      result = result || (control.controls[key]?.value || 0) > 0;
    });
    return !result ? { atLeastOneGreaterThanZero: true } : null;
  };

  static minFn(input: number | (() => number)): ValidatorFn {
    return (control: AbstractControl) => {
      const inputNumber = isFunction(input) ? input() : input;
      return isNumber(inputNumber) ? Validators.min(inputNumber)(control) : null;
    };
  }

  /**
   * Define 0 as valid but otherwise use the provided value as if the Validators.min validator was present
   */
  static minOrZero(input: number): ValidatorFn {
    const validator = Validators.min(input);
    return (control: AbstractControl) => (control.value === 0 ? null : validator(control));
  }

  static maxFn(input: number | (() => number)): ValidatorFn {
    return (control: AbstractControl) => {
      const inputNumber = isFunction(input) ? input() : input;
      const validator = Validators.max(inputNumber);

      return isNumber(inputNumber) ? validator(control) : null;
    };
  }

  static momentMin(input: Moment | (() => Moment)): ValidatorFn {
    return (control: AbstractControl) => {
      const inputMoment = isFunction(input) ? input() : input;
      return inputMoment ? new MomentNotBeforeValidator(inputMoment).validate(control) : null;
    };
  }

  static numberOfDecimals(minDecimals: number, maxDecimals: number): ValidatorFn {
    const validator = new NumberOfDecimalsValidator(minDecimals, maxDecimals);
    return (control: AbstractControl) => validator.validate(control);
  }

  static minExclusive(min: number): ValidatorFn {
    const validator = new MinExclusiveValidator(min);
    return (control: AbstractControl) => validator.validate(control);
  }

  static conditionalMinExclusive(shouldValidateFn: () => boolean, min: number): ValidatorFn {
    const validator = new MinExclusiveValidator(min);
    return (control: AbstractControl) => (shouldValidateFn() ? validator.validate(control) : null);
  }

  static multipleOf(multiple: number): ValidatorFn {
    const validator = new MultipleOfValidator(multiple);
    return (control: AbstractControl) => validator.validate(control);
  }

  static timeValidator(): ValidatorFn {
    const validator = new TimeValidator();
    return (control: AbstractControl) => validator.validate(control);
  }

  static phoneNumberValidator(): ValidatorFn {
    const validator = new PhoneNumberValidator();
    return (control: AbstractControl) => validator.validate(control);
  }
}

class NumberOfDecimalsValidator implements Validator {
  constructor(private minDecimalCount: number, private maxDecimalCount: number) {
    assert(minDecimalCount >= 0, 'minDecimalCount should be equal or greater than 0');
    assert(minDecimalCount <= maxDecimalCount, 'maxDecimalCount should be equal or greater than minDecimalCount');
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value === null || control.value.length === 0 || Number.isNaN(control.value)) {
      return null;
    }

    const decimalPlaces = this.decimalPlaces(control.value);
    const isMinValid = decimalPlaces >= this.minDecimalCount;
    const isMaxValid = decimalPlaces <= this.maxDecimalCount;
    const isValid = isMaxValid && isMinValid;

    let errors: any = isValid
      ? null
      : {
          invalidNumberOfDecimals: true
        };

    if (!isMaxValid) {
      errors = {
        ...errors,
        maxNumberOfDecimals: {
          max: this.maxDecimalCount,
          min: this.minDecimalCount
        }
      };
    }

    if (!isMinValid) {
      errors = {
        ...errors,
        minNumberOfDecimals: {
          min: this.minDecimalCount,
          max: this.maxDecimalCount
        }
      };
    }

    return errors;
  }

  /**
   * From https://stackoverflow.com/questions/10454518/javascript-how-to-retrieve-the-number-of-decimals-of-a-string-number
   * Slightly modified to allow for , separator
   */
  private decimalPlaces(num: string | number): number {
    const match = ('' + num).match(/(?:[\.,](\d+))?(?:[eE]([+-]?\d+))?$/);
    if (!match) {
      return 0;
    }
    return Math.max(
      0,
      // Number of digits right of decimal point.
      (match[1] ? match[1].length : 0) -
        // Adjust for scientific notation.
        (match[2] ? +match[2] : 0)
    );
  }
}

class MinExclusiveValidator implements Validator {
  constructor(private min: number) {
    assert(isNumber(min), 'min should be a number');
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value === null || Number.isNaN(control.value)) {
      return null;
    }
    // Support simple number parsing, ideally we would have a number already from app-number-input for example.
    const numberValue = isNumber(control.value) ? control.value : parseFloat(control.value);

    return numberValue > this.min
      ? null
      : {
          minExclusive: {
            min: this.min
          }
        };
  }
}

class MomentNotBeforeValidator implements Validator {
  constructor(private minMoment: Moment) {
    assert(isMoment(minMoment), 'min should be a Moment');
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value === null || !isMoment(control.value)) {
      return null;
    }
    return this.minMoment.isSameOrBefore(control.value) ? null : { min: true };
  }
}

class TimeValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return !control.value || moment(control.value, 'HH:mm', true).isValid() ? null : { invalidTime: true };
  }
}

class MultipleOfValidator implements Validator {
  constructor(private multiple: number) {
    assert(isNumber(multiple), 'multiple should be a number');
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!isFinite(control.value)) {
      return null;
    }
    // Support simple number parsing, ideally we would have a number already from app-number-input for example.
    const numberValue = isNumber(control.value) ? control.value : parseFloat(control.value);

    return numberValue % this.multiple === 0 ? null : { invalidMultipleOf: { multiple: this.multiple } };
  }
}

class PhoneNumberValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const phoneNumberUtil = PhoneNumberUtil.getInstance();
      try {
        const parsedPhoneNumber = phoneNumberUtil.parseAndKeepRawInput(control.value, 'nl');
        if (phoneNumberUtil.isValidNumber(parsedPhoneNumber)) {
          return null;
        }
      } catch (e: any) {
        console.debug('Got error in PhoneNumberValidator', e);
        // Ignore, if parsing failed, it should return the error
      }
    }
    return { invalidPhoneNumberFormat: true };
  }
}
