import { animationFrameScheduler, interval, OperatorFunction } from 'rxjs';
import { map, pairwise, startWith, switchMap, takeWhile } from 'rxjs/operators';
// @ts-ignore Apparently this causes an error, not sure why, since the function is still present, although it is marked @internal
import { pipeFromArray } from 'rxjs/internal/util/pipe';
import { clone, isEqual } from 'lodash-es';

// eslint-disable-next-line @typescript-eslint/no-shadow
type ElementType<T extends ReadonlyArray<unknown>> = T extends ReadonlyArray<infer ElementType> ? ElementType : never;

/**
 * Used for incremental rendering. Return type is any[], which works but could be better.
 */
export function asBatch<T, R extends any[]>({
  batchSize,
  batchDelayMs,
  equalFn = isEqual
}: {
  batchSize: number;
  batchDelayMs: number;
  // @ts-ignore TODO fix typing
  equalFn?: (a: ElementType<T>, b: ElementType<T>) => boolean;
}): OperatorFunction<T, R> {
  return pipeFromArray([
    startWith(null),
    pairwise(),
    // @ts-ignore
    switchMap(([previousValue, value]) => {
      // @ts-ignore
      const baseObservable = interval(batchDelayMs, animationFrameScheduler).pipe(
        map((currentIndex) => currentIndex + 2),
        startWith(1)
      );

      let lastResult;

      return baseObservable.pipe(
        takeWhile(() => !isEqual(value, lastResult)),
        // @ts-ignore
        map(() => {
          let currentCount = 0;
          do {
            lastResult = getNextCurrent(lastResult || previousValue || [], value, equalFn);
            currentCount++;
          } while (lastResult !== value && currentCount < batchSize);

          return lastResult;
        })
      );
    })
  ]);
}

/**
 * Determine the next operation to move the current array towards the target array:
 *
 * Iterate through new array
 * Find previous location of item
 * Check if the item currently there has a position in the new array
 * Check that position in the new array to see if anything needs to be moved there, repeat until the item is not present in the target array
 */
export function getNextCurrent<T>(current: T[], target: T[], isEqualFn: typeof isEqual = isEqual): T[] {
  const firstMismatchedIndex = target.findIndex((targetItem, targetIndex) => !isEqualFn(current[targetIndex], target[targetIndex]));

  if (firstMismatchedIndex === -1) {
    // No new found TODO create test to check if this works
    return target;
  }

  const result = moveToTarget(current, target, firstMismatchedIndex, isEqualFn);

  // Return array with items from the target array, even if they are equal.
  return result.map((item) => {
    return target.find((targetItem) => isEqualFn(targetItem, item)) || item;
  });
}

function moveToTarget<T>(current: T[], target: T[], targetIndex: number, isEqualFn: typeof isEqual): T[] {
  let workingCurrent = clone(current);

  const targetItem = target[targetIndex];

  // Remove current target from the working array
  const removedValues = workingCurrent.splice(targetIndex, 1, null);

  // Get the index of the target in the current array
  const currentTargetItemIndex = getItemIndex(workingCurrent, targetItem, isEqualFn);
  const removedItemIndex = removedValues.length === 0 ? null : getItemIndex(target, removedValues[0], isEqualFn);

  if (currentTargetItemIndex !== null) {
    // Remove item from working array
    workingCurrent = moveToTarget(workingCurrent, target, currentTargetItemIndex, isEqualFn);
  }

  // Re-add the removed item if the removed item will later have to be added to get to the target array
  if (removedValues.length > 0 && removedItemIndex !== null) {
    workingCurrent.splice(removedItemIndex, 1, removedValues[0]);
  }

  // Add item to working array
  if (targetItem !== undefined) {
    workingCurrent.splice(targetIndex, 1, targetItem);
  } else {
    workingCurrent.splice(targetIndex, 1);
  }
  return workingCurrent;
}

function getItemIndex<T>(current: T[], targetItem: T, isEqualFn: typeof isEqual): number | null {
  const currentTargetItemIndex = current.findIndex((currentItem) => isEqual(currentItem, targetItem));
  if (currentTargetItemIndex === -1) {
    return null;
  }
  return currentTargetItemIndex;
}
