import { HttpClient } from '@angular/common/http';
import { isObservable, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { RestEntity } from './rest-entity';
import { MessageService } from '../../core/messages/message.service';
import { AppInjector, getFromInjector } from '../../core/common/app-injector';

function wrapWithLazy<TSource, TFn>(source: Observable<TSource>, key: string): any {
  return (...args) => {
    return source.pipe(
      switchMap((resolvedSource) => {
        const result = resolvedSource[key].apply(resolvedSource, args);
        if (isObservable(result)) {
          return result;
        } else {
          return of(result);
        }
      })
    );
  };
}

export class RestClientService<RequestType extends RestEntity, ResponseType extends RestEntity = RequestType> {
  private messageService_: MessageService;

  constructor(protected endpoint: string) {}

  /**
   * This contraption is responsible for managing the fact that http is resolved using the global App Injector.
   * The problem here is that HttpClient isn't a dependency of whatever service extends RestClientService, so it may be that HttpClient is not initialized yet.
   * Fortunately all return types of HttpClient return an observable, so we can do some switchMapping to get HttpClient and then call the appropriate method.
   */
  get http(): HttpClient {
    return {
      delete: wrapWithLazy(getFromInjector(HttpClient), 'delete'),
      get: wrapWithLazy(getFromInjector(HttpClient), 'get'),
      request: wrapWithLazy(getFromInjector(HttpClient), 'request'),
      jsonp: wrapWithLazy(getFromInjector(HttpClient), 'jsonp'),
      head: wrapWithLazy(getFromInjector(HttpClient), 'head'),
      options: wrapWithLazy(getFromInjector(HttpClient), 'options'),
      patch: wrapWithLazy(getFromInjector(HttpClient), 'patch'),
      post: wrapWithLazy(getFromInjector(HttpClient), 'post'),
      put: wrapWithLazy(getFromInjector(HttpClient), 'put')
    } as HttpClient;
  }

  protected get messageService(): MessageService {
    if (!this.messageService_) {
      this.messageService_ = AppInjector.get(MessageService);
    }
    return this.messageService_;
  }

  getAll(): Observable<ResponseType[]> {
    return this.http.get<ResponseType[]>(this.endpoint).pipe(tap(() => this.log('fetched all')));
  }

  getById(id: string): Observable<ResponseType> {
    const url = `${this.endpoint}/${id}`;
    return this.http.get<ResponseType>(url).pipe(tap(() => this.log(`fetched id=${id}`)));
  }

  add(value: RequestType): Observable<ResponseType> {
    return this.http.post<ResponseType>(this.endpoint, value).pipe(tap((added: ResponseType) => this.log(`added id=${added.id}`)));
  }

  update(value: RequestType): Observable<ResponseType> {
    const url = `${this.endpoint}/${value.id}`;
    return this.http.put<ResponseType>(url, value).pipe(tap(() => this.log(`updated id=${value.id}`)));
  }

  delete(value: RequestType): Observable<any> {
    const url = `${this.endpoint}/${value.id}`;
    return this.http.delete(url).pipe(tap(() => this.log(`deleted id=${value.id}`)));
  }

  /** Log a message with the MessageService */
  protected log(message: string): void {
    // this.messageService.debug(`RestClientService: ${message}`);
  }
}
