import { UntypedFormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class FnErrorMatcher implements ErrorStateMatcher {
  constructor(private fn: () => boolean) {}

  isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control?.invalid && (control?.touched || this.fn());
  }
}
