import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { MessageService } from '../messages/message.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  constructor(private messageService: MessageService, private httpClient: HttpClient) {}

  private static parseFilenameFromContentDisposition(contentDisposition: string): string | null {
    if (!contentDisposition) return null;

    const matches = /filename="(.*?)"/g.exec(contentDisposition);
    return matches && matches.length > 1 ? matches[1] : null;
  }

  download(response: Observable<HttpResponse<Blob>>): Observable<HttpResponse<Blob>> {
    return response.pipe(
      tap(
        (res) => {
          const url = window.URL.createObjectURL(res.body);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = DownloadService.parseFilenameFromContentDisposition(res.headers.get('Content-Disposition'));
          a.click();
          setTimeout(() => {
            window.URL.revokeObjectURL(url);
            a.remove(); // remove the element
          }, 100); // Edge does not download anything without timeout
        },
        (error) => {
          this.messageService.error('download error:' + JSON.stringify(error));
        },
        () => {
          this.messageService.debug('Completed file download.');
        }
      )
    );
  }

  downloadExcel(uri: string, options: any = {}): Observable<HttpResponse<Blob>> {
    const headers = new HttpHeaders({ Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    return this.download(
      this.httpClient.get<Blob>(uri, {
        headers,
        observe: 'response',
        responseType: 'blob' as 'json',
        ...options
      }) as Observable<HttpResponse<Blob>>
    );
  }
}
