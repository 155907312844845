import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[phFlexModalDialogTrigger]'
})
export class FlexModalDialogTriggerDirective {
  @Output()
  clicked: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor() {}

  @HostListener('click', ['$event'])
  clickEvent(event: MouseEvent): any {
    event.preventDefault();
    event.stopPropagation();
    this.clicked.emit(event);
  }
}
