import { Directive, Inject, Injectable, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { get, isArray } from 'lodash-es';
import { AUTOCOMPLETE_DATA_SOURCE_FILTER, DataSourceFilter } from '../../material/autocomplete/autocomplete.component';

@Injectable()
export class DataSourceFilterByCustomerId implements DataSourceFilter<any> {
  customerIdSubject = new BehaviorSubject<string | string[]>(null);

  getFilter(values: any[]): Observable<boolean[]> {
    return this.customerIdSubject.asObservable().pipe(
      map((customerId) => {
        if (customerId) {
          const customerIds = isArray(customerId) ? customerId : [customerId];

          return values.map((value) => customerIds.includes(get(value, 'customerId')));
        } else {
          return values.map(() => true);
        }
      })
    );
  }
}

@Directive({
  selector: '[phFlexDataSourceFilterByCustomerId]',
  providers: [
    {
      provide: AUTOCOMPLETE_DATA_SOURCE_FILTER,
      useFactory: (a) => a,
      multi: true,
      deps: [DataSourceFilterByCustomerId]
    },
    {
      provide: DataSourceFilterByCustomerId,
      useClass: DataSourceFilterByCustomerId
    }
  ]
})
export class DataSourceFilterByCustomerIdDirective implements OnChanges, OnDestroy {
  @Input('phFlexDataSourceFilterByCustomerId') customerId: string | string[];

  constructor(@Inject(DataSourceFilterByCustomerId) private dataSourceFilter: DataSourceFilterByCustomerId) {}

  ngOnDestroy(): void {
    this.dataSourceFilter.customerIdSubject.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.dataSourceFilter.customerIdSubject.next(this.customerId);
  }
}
