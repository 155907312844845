import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { endOfDay, endOfMonth, endOfYear, format, startOfDay, startOfMonth, startOfYear } from 'date-fns';
import { isEmpty, isNil, omit, pickBy } from 'lodash-es';
import moment from 'moment';
import { NumberRange, RangeMap } from 'range-ts';
import { Observable } from 'rxjs';
import { map, retry } from 'rxjs/operators';
import { Direction } from '../../core/common/direction';
import { PaginatedResponse } from '../../core/common/paginated-response';
import { TimeSlot } from '../../core/date-time/time-slot';
import { normalizeToDate } from '../../core/date-time/utils';
import { Capacity } from '../../core/domain/capacity';
import { DownloadService } from '../../core/download/download.service';
import { getRetryConfig } from '../../core/common/rxjs-utils';

// Compensate for not having strongly typed reactive forms
// https://github.com/angular/angular/pull/43834
export interface FormControlsFilters {
  customerId: string;
  controlId: string;
  gridPointId: string;
  dealReference: string;
  createdPeriod: string;
  dealPeriod: string;
  dealStatus: IntradayDealStatus;
}

interface RequestFilterParams {
  customerId: string;
  controlId: string;
  gridPointId: string;
  dealReference: string;
  createdPeriod: { startDate: string; endDate: string };
  dealPeriod: { startDate: string; endDate: string };
  dealStatus: IntradayDealStatus;
}

enum Period {
  YEAR = 1,
  MONTH = 2,
  DAY = 3
}

export interface PriceHistoryJson {
  slotPeriod: TimeSlot;
  dayAheadPrice: number;
  intraDayBidPrices: {
    validPeriod: TimeSlot;
    price: number;
  }[];
  intraDayAskPrices: {
    validPeriod: TimeSlot;
    price: number;
  }[];
}

@Injectable({
  providedIn: 'root'
})
export class IntradayService {
  uri = '/api/v1/intraday';

  constructor(
    private http: HttpClient,
    private downloadService: DownloadService
  ) {}

  static parseFilters(filters: Partial<FormControlsFilters>): string | null {
    const parsed: Partial<RequestFilterParams> = omit({ ...filters }, ['createdPeriod', 'dealPeriod']);

    if (filters.createdPeriod) {
      parsed.createdPeriod = IntradayService.normalizeDateForBackend(filters.createdPeriod);
    }
    if (filters.dealPeriod) {
      parsed.dealPeriod = IntradayService.normalizeDateForBackend(filters.dealPeriod);
    }

    return isEmpty(parsed) ? null : JSON.stringify(parsed);
  }

  private static normalizeDateForBackend(period: string): { startDate: string; endDate: string } {
    const splitPeriod = period.split('-');

    switch (splitPeriod.length) {
      case Period.YEAR:
        return {
          startDate: format(startOfYear(normalizeToDate(period)), 'yyyy-MM-dd').toString(),
          endDate: format(endOfYear(normalizeToDate(period)), 'yyyy-MM-dd').toString()
        };
      case Period.MONTH:
        return {
          startDate: format(startOfMonth(normalizeToDate(period)), 'yyyy-MM-dd').toString(),
          endDate: format(endOfMonth(normalizeToDate(period)), 'yyyy-MM-dd').toString()
        };
      case Period.DAY:
        return {
          startDate: format(startOfDay(normalizeToDate(period)), 'yyyy-MM-dd').toString(),
          endDate: format(endOfDay(normalizeToDate(period)), 'yyyy-MM-dd').toString()
        };
    }
  }

  getLatest(): Observable<IntraDayJson> {
    return this.http.get<IntraDayJson>(`${this.uri}/latest`).pipe(
      retry(
        getRetryConfig({
          excludedStatusCodes: []
        })
      )
    );
  }

  sendDeals(deals: IntradayDeal[]): Observable<any> {
    return this.http.post(this.uri, { deals });
  }

  getDealsForCurrentDay(): Observable<PaginatedResponse<PostPendingIntradayDeal>> {
    return this.http
      .get<PostPendingIntraDayDealOverview>(`${this.uri}/deals`, {
        params: {
          sort: 'created desc',
          filter: JSON.stringify({
            created: startOfDay(new Date()).toISOString()
          }),
          pageSize: 999999 // Arbitrary high page size to make sure we get all deals for today
        }
      })
      .pipe(
        retry(
          getRetryConfig({
            excludedStatusCodes: []
          })
        ),
        map((result) => result.deals)
      );
  }

  getDealableControlIds(): Observable<DealableControlIdsPerDutchDay[]> {
    return this.http.get<DealableControlIdsPerDutchDay[]>(`${this.uri}/dealableControls`).pipe(
      retry(
        getRetryConfig({
          excludedStatusCodes: []
        })
      )
    );
  }

  getFilteredDeals(
    pageIndex?: number,
    pageSize?: number,
    sort?: string,
    filter?: Partial<FormControlsFilters>
  ): Observable<PostPendingIntraDayDealOverview> {
    const definedFilters = pickBy(filter, (value) => value !== '' && !isNil(value));
    const parsedFilters = IntradayService.parseFilters(definedFilters);

    return this.http.get<PostPendingIntraDayDealOverview>(`${this.uri}/deals`, {
      params: pickBy(
        {
          page: pageIndex,
          pageSize,
          sort,
          filter: parsedFilters
        },
        (a) => !!a || a === 0
      ) as any
    });
  }

  getDeals(
    pageIndex?: number,
    pageSize?: number,
    searchTerm?: string,
    sort?: string,
    searchCategory?: string,
    customerId?: string,
    dealStatus?: IntradayDealStatus,
    direction?: Direction,
    currentDay?: boolean
  ): Observable<PostPendingIntraDayDealOverview> {
    const filter: any = {};

    if (dealStatus) {
      filter.dealStatus = dealStatus;
    }

    if (direction) {
      filter.dealDirection = direction;
    }

    if (searchTerm && !isEmpty(searchTerm) && searchCategory) {
      filter[searchCategory] = searchTerm;
    }

    if (customerId) {
      filter.customerId = customerId;
    }

    if (currentDay) {
      filter.created = moment().startOf('day').toISOString();
    }

    return this.http.get<PostPendingIntraDayDealOverview>(`${this.uri}/deals`, {
      params: pickBy(
        {
          page: pageIndex,
          pageSize,
          sort,
          filter: isEmpty(filter) ? null : JSON.stringify(filter)
        },
        (a) => !!a || a === 0
      ) as any
    });
  }

  getDealStatuses(dealIds: string[]): Observable<IntraDayDealStatusUpdate[]> {
    return this.http.get<IntraDayDealStatusUpdate[]>(`${this.uri}/dealStatus`, {
      params: {
        dealIds: dealIds.join(',')
      }
    });
  }

  getControls(): Observable<IntradayControl[]> {
    return this.http.get<IntradayControl[]>(`${this.uri}/controls`).pipe(
      retry(
        getRetryConfig({
          excludedStatusCodes: []
        })
      )
    );
  }

  downloadData(sort?: string, filter?: any): Observable<HttpResponse<Blob>> {
    const definedFilters = pickBy(filter, (value) => value !== '' && !isNil(value));
    const parsedFilters = IntradayService.parseFilters(definedFilters);

    const headers = new HttpHeaders({ Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    return this.downloadService.download(
      this.http.get<Blob>(`${this.uri}/deals`, {
        headers,
        params: pickBy(
          {
            sort,
            filter: parsedFilters
          },
          (a) => !!a
        ) as any,
        observe: 'response',
        responseType: 'blob' as 'json'
      })
    );
  }

  getPriceHistory(timeSlot: TimeSlot): Observable<PriceHistoryJson> {
    let params = new HttpParams();
    params = params.set('startDateTime', timeSlot.startDateTime);
    params = params.set('toDateTime', timeSlot.toDateTime);

    return this.http.get<PriceHistoryJson>(`${this.uri}/latest/history`, { params });
  }
}

export interface IntraDayJson {
  slots: IntraDaySlot[];
  positions?: IntraDayPosition[];
  priceWindowId: string;
  receivedDateTime: string;
  priceWindowTo: string;
  priceWindowStart: string;
  mandatoryTradingDirections?: MandatoryTradeDirection[];
  idconsDealingEnabled?: boolean;
  remLimitOrderDealingEnabled?: boolean;
}

/**
 * Matches IntraDayPricesReceivedSseEvent payload
 */
export interface IntraDayPricesReceivedData {
  slots: IntraDaySlot[];
  priceWindowId: string;
  priceWindowStart: string;
  priceWindowTo: string;
  receivedDateTime: string;
}

export interface MandatoryTradeDirection {
  id: string;
  period: TimeSlot;
  direction: Direction;
  gridPointId: string;
  customerId: string;
}

export interface IntraDayPosition {
  period: TimeSlot;
  customerId: string;
  controlId: string;
  position: Capacity;
}

export class IntraDayGridPointDealDirectionClaimApprovedSseEventPayload {
  id: string;
  gridPointId: string;
  dealId: string;
  dealPeriod: TimeSlot;
  dealDirection: Direction;
  dealSubType: IntradayOrderType;
  customerId: string;
}

export interface IntradayPositionWithMinMax extends IntraDayPosition {
  positionMin: Capacity;
  positionMax: Capacity;
}

/**
 * Helper to determine position for controls over multiple time slots
 */
export class IntraDayPositionHelper {
  private positionsForControlId = new Map<string, RangeMap<number>>();
  private customerIdsForControlId = new Map<string, string[]>();

  private zeroCapacityNumberRange: NumberRange;

  constructor(zeroCapacityTimeSlot?: TimeSlot) {
    if (zeroCapacityTimeSlot) {
      this.zeroCapacityNumberRange = TimeSlot.toNumberRange(zeroCapacityTimeSlot);
    }
  }

  add(position: IntraDayPosition): void {
    this.updateCustomerIdsForControlId(position.controlId, position.customerId);
    const positionRange = TimeSlot.toNumberRange(position.period);
    const positionKw = Capacity.asKW(position.position);

    const rangeMap = this.getOrCreateRangeMap(position.controlId);

    const subRangeMap = rangeMap.subRangeMap(positionRange);

    rangeMap.putCoalescing(positionRange, positionKw);
    [...subRangeMap.asMapOfRanges().entries()].forEach(([key, value]) => {
      rangeMap.putCoalescing(key, value + positionKw);
    });
  }

  /**
   * Return a list of IntraDayPositions with one IntraDayPositionWithMinMax for every known controlIds covering the entire known period for that controlId
   */
  getPositions(): IntradayPositionWithMinMax[] {
    return [...this.positionsForControlId.entries()].map(([controlId, rangeMap]) => {
      const positionMinKw = Math.min(...rangeMap.asMapOfRanges().values());
      const positionMaxKw = Math.max(...rangeMap.asMapOfRanges().values());
      return {
        controlId,
        customerId: this.customerIdsForControlId.get(controlId)[0],
        position: Capacity.kW(positionMinKw),
        positionMin: Capacity.kW(positionMinKw),
        positionMax: Capacity.kW(positionMaxKw),
        period: TimeSlot.fromNumberRangeWithTime(rangeMap.span())
      };
    });
  }

  /**
   * Returns a reference to a rangeMap stored in positionsForControlId matching the controlId
   */
  private getOrCreateRangeMap(controlId: string): RangeMap<number> {
    if (this.positionsForControlId.has(controlId)) {
      return this.positionsForControlId.get(controlId);
    }
    const rangeMap = new RangeMap<number>();
    if (this.zeroCapacityNumberRange) {
      rangeMap.putCoalescing(this.zeroCapacityNumberRange, 0);
    }

    this.positionsForControlId.set(controlId, rangeMap);
    return rangeMap;
  }

  private updateCustomerIdsForControlId(controlId: string, customerId: string): void {
    if (this.customerIdsForControlId.has(controlId)) {
      this.customerIdsForControlId.get(controlId).push(customerId);
    } else {
      this.customerIdsForControlId.set(controlId, [customerId]);
    }
  }
}

export interface IntraDayControlDeals {
  [controlId: string]: number;
}

export abstract class IntraDaySlot {
  bidPriceId: string;
  askPriceId: string;
  period: TimeSlot;

  askPrice: number;
  availableAskCapacity: Capacity;

  availableBidCapacity: Capacity;
  bidPrice: number;

  dayAheadPrice: number;

  static canBeDealt(slot: IntraDaySlot, direction: Direction): boolean {
    switch (direction) {
      case Direction.CONSUMPTION:
        return !(slot.askPrice === 0 && Capacity.isZero(slot.availableAskCapacity));
      case Direction.PRODUCTION:
        return !(slot.bidPrice === 0 && Capacity.isZero(slot.availableBidCapacity));
      default:
        return !(
          slot.askPrice === 0 &&
          Capacity.isZero(slot.availableAskCapacity) &&
          slot.bidPrice === 0 &&
          Capacity.isZero(slot.availableBidCapacity)
        );
    }
  }
}

export interface IntraDaySlotWithFlags extends IntraDaySlot {
  askDealVolumeTooLow: boolean;
  askDealVolumeTooHigh: boolean;
  askDealsInvalid: boolean;

  bidDealVolumeTooLow: boolean;
  bidDealVolumeTooHigh: boolean;
  bidDealsInvalid: boolean;

  askPriceDelta: IntraDayPriceDelta | null;
  askPriceDeltaAmount: number;
  bidPriceDelta: IntraDayPriceDelta | null;
  bidPriceDeltaAmount: number;
}

export enum IntraDayPriceDelta {
  HIGHER = 'HIGHER',
  LOWER = 'LOWER',
  EQUAL = 'EQUAL'
}

export function getIntraDayPriceDeltaFromPrices(a: number, b: number): IntraDayPriceDelta {
  if (a === b) {
    return IntraDayPriceDelta.EQUAL;
  } else if (b > a) {
    return IntraDayPriceDelta.HIGHER;
  } else if (b < a) {
    return IntraDayPriceDelta.LOWER;
  }
  return null;
}

export abstract class IntradayDeal {
  id: string; // UUID
  dealPeriod: TimeSlot;

  controlId: string;

  dealCapacity: Capacity;
  dealDirection: Direction;

  dealPrice: number; // Price per kWh/MWh?
  priceId: string; // UUID

  /**
   * Check if the provided IntraDayDeal has the same controlId, direction and period
   * Used to check for replacements when updating existing deals
   */
  static overlaps(a: IntradayDeal, b: IntradayDeal): boolean {
    return a.controlId === b.controlId && TimeSlot.isEqual(a.dealPeriod, b.dealPeriod) && a.dealDirection === b.dealDirection;
  }
}

export enum IntradayDealStatus {
  PENDING = 'PENDING',
  INVALID = 'INVALID',
  AWAITING_APPROVAL = 'AWAITING_APPROVAL',
  APPROVED = 'APPROVED',
  AWAITING_CONFIRMATION = 'AWAITING_CONFIRMATION',
  REJECTED = 'REJECTED',
  FAILED = 'FAILED',
  CONFIRMED = 'CONFIRMED'
}

export abstract class IntraDayDealWithStatus extends IntradayDeal {
  dealStatus: IntradayDealStatus;
  lastUpdated: string;
}

export enum IntradayOrderType {
  REGULAR = 'REGULAR',
  IDCONS = 'IDCONS'
}

export abstract class PostPendingIntraDayDealOverview {
  deals: PaginatedResponse<PostPendingIntradayDeal>;
  unpagedContainsMandatoryDeals: boolean;
}

/**
 * IntraDayDeal result from the backend
 */
export abstract class PostPendingIntradayDeal {
  controlId: string;
  customerId: string;

  id: string;
  dealPrice: number;
  dealDirection: Direction;
  dealCapacity: Capacity;
  dealPeriod: TimeSlot;
  dealStatus: IntradayDealStatus;
  dealAmount: number;

  orderType: IntradayOrderType;

  gridPointEan: string;

  priceId: string;
  pricePeriod: TimeSlot;
  priceCapacity: Capacity;

  priceWindowId: string;
  priceWindowPeriod: TimeSlot;
  priceWindowReceived: string;

  createdByUserName: string;
  created: string;
  lastUpdated: string;
  reason: string;

  dealReference: string;
  mandatoryDelivery: boolean;

  static getDealDisplayStatus(deal: PostPendingIntradayDeal): IntraDayDealDisplayStatus | null {
    switch (deal.dealStatus) {
      case IntradayDealStatus.CONFIRMED:
        return IntraDayDealDisplayStatus.CONFIRMED;
      case IntradayDealStatus.FAILED:
        return IntraDayDealDisplayStatus.FAILED;
      default:
        // all others are PENDING
        return IntraDayDealDisplayStatus.PENDING;
    }
  }
}

export interface IntraDaySummary {
  /**
   * Deal volume in kW
   */
  volume: number;

  /**
   * Number of dealt deals comprising the mentioned volume
   */
  numberOfDeals: number;

  /**
   * In €/MW
   */
  averagePrice: number;
}

export interface IntraDayRecentSummary extends IntraDaySummary {
  pending: number;
  confirmed: number;
  failed: number;
}

export enum IntradayControlDirection {
  CONSUMPTION = 'CONSUMPTION',
  PRODUCTION = 'PRODUCTION'
}

export interface IntradayControl {
  controlId: string;
  customerId: string;
  gridPointId: string;

  customerLegalName: string;

  controlDirection: IntradayControlDirection;
  controlDescription: string;
  namePlatePower: Capacity;

  gridPointDescription: string;
  gridPointEan: string;
}

export interface IntraDayDealStatusUpdate {
  id: string;
  dealStatus: IntradayDealStatus;
}

export interface IntraDayCapacityClaimedEvent {
  controlId: string;
  period: TimeSlot;
  capacity: Capacity;

  direction: Direction;
}

export enum IntraDayDealDisplayStatus {
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  CONFIRMED = 'CONFIRMED'
}

export abstract class DealableControlIdsPerDutchDay {
  dutchDay: string;
  controlIds: string[];
}

export type RecentDeal = PostPendingIntradayDeal | IntraDayDealWithStatus;
