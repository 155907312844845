import { AppInsightsService } from './app-insights.service';
import { isNumber } from 'lodash-es';
import { exhaustMap, merge, of, timer } from 'rxjs';
import { debounceTime, take } from 'rxjs/operators';
import { AppInjector } from '../core/common/app-injector';
import { MixinBase } from '../core/common/constructor-type.mixin';
import { SubjectProvider } from '../d3-graph/d3-graph/common';
import { DestroyableMixin, OnDestroyMixin, OnDestroyProvider } from '../core/common/on-destroy.mixin';

export enum AzureMetric {
  // Add new metrics here to facilitate centralized metrics management

  TOKEN_WATCHDOG_FIRED = '[TokenWatchdogService] Time skip detected',
  AUTH_INTERCEPTOR_TIMED_OUT = '[CustomOAuthInterceptor] Request timed out due to no token being available',
  REQUEST_TIMEOUT_TOKEN_SIGNALING = '[TokenService] Token service tokensAvailable$ re-emit due to a token not being available to complete a request'
}

export abstract class MetricsProvider extends OnDestroyMixin(DestroyableMixin(MixinBase)) {
  static DEFAULT_DEBOUNCE_TIME_MS = 10_000;
  static DEFAULT_MAX_TIME_MS = 60_000;

  private metricsProvider: AppInsightsService | null = AppInjector?.get(AppInsightsService, null);

  protected newMetricSubjectProvider = new SubjectProvider<void>(this);

  delay$ = merge(
    merge(of(null), this.newMetricSubjectProvider.value$).pipe(
      debounceTime(MetricsProvider.DEFAULT_DEBOUNCE_TIME_MS) // Wait 1000 ms after each emitted metric to buffer multiple together
    ),
    // Or, emit after 60 seconds if data has been continuous
    timer(MetricsProvider.DEFAULT_MAX_TIME_MS)
  ).pipe(take(1));

  protected sum = 0;
  protected count = 0;

  constructor(onDestroyProvider: OnDestroyProvider, private metric: AzureMetric) {
    super();

    this.registerOnDestroyProvider(onDestroyProvider);

    this.newMetricSubjectProvider.value$.pipe(exhaustMap(() => this.delay$)).subscribe(() => this.trackMetric());
  }

  destroy(): void {
    this.ngOnDestroy();
  }

  private trackMetric(): void {
    if (this.count === 0) {
      return;
    }

    if (this.metricsProvider?.appInsights) {
      this.metricsProvider.appInsights.trackMetric({
        name: this.metric,
        average: this.sum / this.count
      });
    } else {
      // No metricsProvider, log to console instead
      console.warn('Tracking for: ', this.metric, this.sum / this.count);
    }

    // Reset
    this.count = 0;
    this.sum = 0;
  }
}

/**
 * Exposes a counter metric (see https://prometheus.io/docs/concepts/metric_types/ for an explanation of metric types)
 */
export class CounterProvider extends MetricsProvider {
  increment(): void {
    this.sum += 1;
    this.count = 1;
    this.newMetricSubjectProvider.next();
  }
}

/**
 * Exposes a counter metric (see https://prometheus.io/docs/concepts/metric_types/ for an explanation of metric types)
 */
export class GaugeProvider extends MetricsProvider {
  logValue(value: number): void {
    if (isNumber(value)) {
      this.sum += value;
      this.count += 1;
      this.newMetricSubjectProvider.next();
    }
  }
}
