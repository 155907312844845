import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormErrorMessagesComponent } from './form-error-messages/form-error-messages.component';
import { MatFormFieldModule } from '@angular/material/form-field';

@NgModule({
  declarations: [FormErrorMessagesComponent],
  imports: [CommonModule, MatFormFieldModule],
  exports: [FormErrorMessagesComponent]
})
export class FlexErrorMessagesModule {}
