import { DynamicDataHelper } from 'flex-app-shared';
import { BaseType, Selection } from 'd3';

interface RectDatum {
  x: number;
  y: number;
  width: number;
  height: number;
}

export class RectHelper extends DynamicDataHelper<RectDatum> {
  protected nodeName = 'rect';
  protected class = 'vertical-line';

  protected setDynamic(selectAllWithData: Selection<BaseType, RectDatum, BaseType, any>): void {
    selectAllWithData
      .attr('x', (d) => d.x)
      .attr('y', (d) => d.y)
      .attr('width', (d) => d.width)
      .attr('height', (d) => d.height);
  }

  protected setStatic(selectAllWithData: Selection<BaseType, RectDatum, BaseType, any>): void {
    selectAllWithData.attr('shape-rendering', 'crispEdges').attr('fill', '#FFFFFFA6'); // White with .85 opacity
  }
}
