import { OAuthModuleConfig, OAuthResourceServerErrorHandler } from 'angular-oauth2-oidc';
import { Inject, Injectable, Optional } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, filter, mergeMap, take, tap, timeout } from 'rxjs/operators';
import { REQUIRE_AUTHENTICATION } from './auth.service';
import { TokenService } from './token.service';
import { AzureMetric, CounterProvider } from '../../app-insights/metrics-provider';
import { SINGLETON } from '../../core/common/on-destroy.mixin';

@Injectable()
export class CustomOAuthInterceptor implements HttpInterceptor {
  static GLOBAL_REQUEST_TOKEN_TIMEOUT_MS = 5000;
  private requestTimedOutDueToNoTokenMetricsProvider = new CounterProvider(SINGLETON, AzureMetric.AUTH_INTERCEPTOR_TIMED_OUT);

  constructor(
    private tokenService: TokenService,
    private errorHandler: OAuthResourceServerErrorHandler,
    @Optional() private moduleConfig: OAuthModuleConfig,
    @Optional() @Inject(REQUIRE_AUTHENTICATION) private requireAuthentication: boolean
  ) {}

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const url = req.url.toLowerCase();

    if (!this.requireAuthentication) {
      return next.handle(req);
    }

    if (!this.moduleConfig) {
      return next.handle(req);
    }
    if (!this.moduleConfig.resourceServer) {
      return next.handle(req);
    }
    if (this.moduleConfig.resourceServer.allowedUrls && !this.checkUrl(url)) {
      return next.handle(req);
    }

    return this.tokenService.validIdToken$.pipe(
      filter((a) => !!a),
      timeout(CustomOAuthInterceptor.GLOBAL_REQUEST_TOKEN_TIMEOUT_MS),
      take(1),
      mergeMap((token) => {
        const header = 'Bearer ' + token.originalToken;
        const headers = req.headers.set('Authorization', header);

        // eslint-disable-next-line no-param-reassign
        req = req.clone({ headers });

        return next.handle(req).pipe(catchError((err) => this.handleError(err)));
      }),
      tap({
        error: () => this.requestTimedOutDueToNoTokenMetricsProvider.increment()
      })
    );
  }

  private handleError(err: HttpResponse<any>): Observable<any> {
    return this.errorHandler.handleError(err);
  }

  private checkUrl(url: string): boolean {
    if (this.moduleConfig.resourceServer.customUrlValidation) {
      return this.moduleConfig.resourceServer.customUrlValidation(url);
    }

    if (this.moduleConfig.resourceServer.allowedUrls) {
      return !!this.moduleConfig.resourceServer.allowedUrls.find((u) => url.startsWith(u));
    }

    return true;
  }
}
