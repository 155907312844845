import { AfterViewInit, Component, HostBinding, OnInit } from '@angular/core';
import { MessageService } from './message.service';
import { ActivatedRoute, ResolveEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { merge, of } from 'rxjs';
import { getRouterConfigUrl } from '../common/find-parameter-value';

@Component({
  selector: 'ph-flex-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent {
  private isDashboardAfterResolveEnd$ = this.router.events.pipe(
    filter((e) => e instanceof ResolveEnd),
    map((e: ResolveEnd) => e.toString().includes('dashboard'))
  );

  isDashboard$ = merge(of(getRouterConfigUrl(this.activatedRoute.snapshot)?.includes('dashboard')), this.isDashboardAfterResolveEnd$);
  constructor(public messageService: MessageService, private activatedRoute: ActivatedRoute, private router: Router) {}
}
