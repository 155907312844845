import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { REQUIRE_AUTHENTICATION } from './authentication/auth.service';
import { CustomOAuthInterceptor } from './authentication/auth-interceptor.service';
import { AuthShowDirective } from './authorization/auth-show.directive';
import { LoginRequiredComponent } from './authentication/login-required/login-required.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { StorageFactory } from '../core/storage-store/storage-factory';
import { MatIconModule } from '@angular/material/icon';
import { FlexCoreModule } from '../core/core.module';

@NgModule({
  declarations: [AuthShowDirective, LoginRequiredComponent],
  imports: [CommonModule, MatButtonModule, MatBottomSheetModule, MatIconModule, FlexCoreModule],
  exports: [AuthShowDirective]
})
export class FlexAuthModule {
  static forRoot(): ModuleWithProviders<FlexAuthModule> {
    return {
      ngModule: FlexAuthModule,
      providers: [
        ...OAuthModule.forRoot({
          resourceServer: {
            allowedUrls: ['/api'],
            sendAccessToken: false
          }
        }).providers,
        {
          provide: OAuthStorage,
          useFactory: StorageFactory.sessionStorageFactory
        },
        {
          provide: REQUIRE_AUTHENTICATION,
          useValue: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: CustomOAuthInterceptor,
          multi: true
        }
      ]
    };
  }
}
