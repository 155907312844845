import { Observable, ObservableInput, RetryConfig, throwError, timer } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';

/**
 * https://www.learnrxjs.io/operators/error_handling/retrywhen.html
 * @deprecated Use getDelayFn with retry instead.
 */
export const genericRetryStrategy =
  ({
    maxRetryAttempts = 3,
    scalingDuration = 1000,
    excludedStatusCodes = ['5xx'],
    maxScalingDurationMultiplier = 3
  }: {
    maxRetryAttempts?: number;
    scalingDuration?: number;
    excludedStatusCodes?: (number | string)[];
    maxScalingDurationMultiplier?: number;
  } = {}) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        // if maximum number of retries have been met
        // or response is a status code we don't wish to retry, throw error
        if (retryAttempt > maxRetryAttempts || excludedStatusCodes.find((e) => compareWithNumberString(error.status, e))) {
          return throwError(error);
        }
        const scalingDurationFactor = Math.min(retryAttempt, maxScalingDurationMultiplier);
        console.log(`Attempt ${retryAttempt}: retrying in ${scalingDurationFactor * scalingDuration}ms`);
        // retry after 1s, 2s, etc...
        return timer(scalingDurationFactor * scalingDuration);
      }),
      finalize(() => console.log('We are done!'))
    );
  };

function compareWithNumberString(value: number, mask: string | number): boolean {
  const min = parseInt(mask.toString().replace(/x/g, '0'), 10);
  const max = parseInt(mask.toString().replace(/x/g, '9'), 10);

  return value >= min && value <= max;
}

/**
 * Configurable retry behavior for http requests
 *
 * Usage:
 *
 * .pipe(
 *  retry(getRetryConfig())
 * )
 */
export function getRetryConfig({
  maxRetryAttempts = 3,
  scalingDuration = 1000,
  excludedStatusCodes = ['5xx'],
  maxScalingDurationMultiplier = 3
}: {
  maxRetryAttempts?: number;
  scalingDuration?: number;
  excludedStatusCodes?: (number | string)[];
  maxScalingDurationMultiplier?: number;
} = {}): RetryConfig {
  return {
    count: maxRetryAttempts,
    delay: getDelayFn({
      scalingDuration,
      excludedStatusCodes,
      maxScalingDurationMultiplier
    })
  };
}

/**
 * Usage:
 *
 * .pipe(
 *  retry({
 *    count: 3,
 *    delay: getDelayFn()
 *  })
 * )
 */
export function getDelayFn({
  scalingDuration = 1000,
  excludedStatusCodes = ['5xx'],
  maxScalingDurationMultiplier = 3
}: {
  maxRetryAttempts?: number;
  scalingDuration?: number;
  excludedStatusCodes?: (number | string)[];
  maxScalingDurationMultiplier?: number;
} = {}): (error: any, retryCount: number) => ObservableInput<any> {
  return (error, retryAttempt) => {
    // if maximum number of retries have been met
    // or response is a status code we don't wish to retry, throw error
    if (excludedStatusCodes.find((e) => compareWithNumberString(error.status, e))) {
      return throwError(error);
    }
    const scalingDurationFactor = Math.min(retryAttempt, maxScalingDurationMultiplier);
    console.log(`Attempt ${retryAttempt}: retrying in ${scalingDurationFactor * scalingDuration}ms`);
    // retry after 1s, 2s, etc...
    return timer(scalingDurationFactor * scalingDuration);
  };
}
