import { Directive } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MomentDisplayFormatters } from './moment-formatters';
import { DateFnsDisplayFormatters } from './date-fns-formatters';

const momentFormats: MatDateFormats = {
  parse: {
    dateInput: MomentDisplayFormatters.DAY
  },
  display: {
    dateInput: MomentDisplayFormatters.DAY,
    monthYearLabel: MomentDisplayFormatters.DAY_NICE,
    dateA11yLabel: 'LL',
    monthYearA11yLabel: MomentDisplayFormatters.MONTH
  }
};

const dateFnsFormats: MatDateFormats = {
  parse: {
    dateInput: DateFnsDisplayFormatters.DAY
  },
  display: {
    dateInput: DateFnsDisplayFormatters.DAY,
    monthYearLabel: DateFnsDisplayFormatters.DAY_NICE,
    dateA11yLabel: 'PP',
    monthYearA11yLabel: DateFnsDisplayFormatters.MONTH
  }
};

@Directive({
  selector: '[phFlexDateAsDay]',
  providers: [{ provide: MAT_DATE_FORMATS, useFactory: dateFormatFactory, deps: [DateAdapter] }]
})
export class DateAsDayDirective {}

function dateFormatFactory(dateAdapter: DateAdapter<any>): MatDateFormats {
  // TODO enable when 13 is released
  // if (dateAdapter instanceof DateFnsAdapter) {
  //   return dateFnsFormats;
  // }
  return momentFormats;
}
