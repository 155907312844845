import { Inject, Injectable, OnDestroy, Optional } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { first, map, retry, switchMap, takeUntil, tap } from 'rxjs/operators';
import { CONFIG_APPLICATION_TYPE, REQUIRE_AUTHENTICATION } from '../authentication/auth.service';
import { getRetryConfig } from '../../core/common/rxjs-utils';
import { isNil } from 'lodash-es';
import { TokenService } from '../authentication/token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthorityService implements OnDestroy {
  private authoritiesSubject = new ReplaySubject<string[]>(1);
  authorities$ = this.authoritiesSubject.asObservable();

  private landingPageSubject = new ReplaySubject<string>(1);
  landingPage$ = this.landingPageSubject.asObservable();

  private isEmployeeSubject = new ReplaySubject<boolean>(1);
  isEmployee$ = this.isEmployeeSubject.asObservable();

  private readonly ngUnsubscribe = new Subject<void>();

  constructor(
    private http: HttpClient,
    private tokenService: TokenService,
    @Optional() @Inject(REQUIRE_AUTHENTICATION) private requireAuthentication: boolean,
    @Optional() @Inject(CONFIG_APPLICATION_TYPE) private configApplicationType: string
  ) {
    if (!this.configApplicationType) {
      throw new Error('Config application type required, please add path variable to app module');
    }

    if (!this.requireAuthentication) {
      this.landingPageSubject.next('dashboard');
    } else {
      this.tokenService.validIdToken$
        .pipe(
          first(),
          switchMap(() => this.http.get<any>(`/api/v1/security/user-config/${configApplicationType}`)),
          retry(
            getRetryConfig({
              maxRetryAttempts: Number.POSITIVE_INFINITY,
              scalingDuration: 2000,
              excludedStatusCodes: [],
              maxScalingDurationMultiplier: 5
            })
          )
        )
        .subscribe((result) => {
          this.authoritiesSubject.next(result?.authorities || []);
          this.landingPageSubject.next(result?.landingPage);

          if (!isNil(result?.isEmployee)) {
            this.isEmployeeSubject.next(result.isEmployee);
          }
        });
    }
  }

  hasAuthorities(...authorities: ReadonlyArray<string>): Observable<boolean> {
    return this.authorities$.pipe(
      map((list) => authorities.every((v) => list.includes(v))),
      takeUntil(this.ngUnsubscribe)
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
