import { AfterContentInit, Component, ContentChildren, Host, Input, Optional, QueryList, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterLinkActive } from '@angular/router';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnDestroyMixin } from '../../../core/common/on-destroy.mixin';
import { MatRipple } from '@angular/material/core';
import { MixinBase } from '../../../core/common/constructor-type.mixin';

@Component({
  selector: 'ph-flex-sidenav-menu-item',
  templateUrl: './sidenav-menu-item.component.html',
  styleUrls: ['./sidenav-menu-item.component.scss']
})
export class SidenavMenuItemComponent extends OnDestroyMixin(MixinBase) implements AfterContentInit {
  @Input()
  selected: boolean;

  @ContentChildren(RouterLinkActive, { descendants: true })
  routerLinkActive: QueryList<RouterLinkActive>;

  @ViewChild(MatRipple, { static: false })
  ripple: MatRipple;

  subscription: Subscription;

  constructor(private router: Router, @Optional() @Host() private hostRouterLinkActive: RouterLinkActive) {
    super();
    this.update();
    router.events.pipe(takeUntil(this.onDestroy$)).subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationEnd) {
        this.update();
      }
    });
  }

  ngAfterContentInit(): void {
    this.routerLinkActive.changes.subscribe(() => this.update());
  }

  private update(): void {
    setTimeout(() => {
      if (this.hostRouterLinkActive || this.routerLinkActive.length > 0) {
        this.selected = this.routerLinkActive.some((routerLinkActive) => routerLinkActive.isActive) || this.hostRouterLinkActive?.isActive;
      }
    });
  }
}
