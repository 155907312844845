import * as Highcharts from 'highcharts';
import { NgZone } from '@angular/core';
import { first } from 'rxjs/operators';
import { ConstructorType } from './constructor-type.mixin';
import { AppInjector } from './app-injector';

/**
 * Can be used to implement default reflow functionality for charts.
 * Intended for one chart per component, but reflowChart chan be used to reflow additional charts
 */
export function ChartReflowMixin<TBase extends ConstructorType>(Base: TBase = class {} as any) {
  return class ChartReflowBase extends Base {
    public readonly Highcharts: typeof Highcharts = Highcharts;
    ngZone: NgZone;
    afterInitialReflow = false;
    chartAlreadyReflowed = false;

    constructor(...args: any[]) {
      super(...args);
      this.ngZone = AppInjector.get(NgZone);

      this.ngZone.onStable.pipe(first()).subscribe(() => {
        this.afterInitialReflow = true;
        this.reflowChart();
      });
    }

    _chart: Highcharts.Chart = null;
    get chart(): Highcharts.Chart {
      return this._chart;
    }

    set chart(value: Highcharts.Chart) {
      this._chart = value;
      if (this.afterInitialReflow && !this.chartAlreadyReflowed) {
        this.reflowChart();
      }
    }

    reflowChart(chart: Highcharts.Chart = this.chart): void {
      setTimeout(() => {
        // Check if chart exists and if chart has any keys. If not, it is already destroyed and reflow will not work.
        if (chart && Object.keys(chart).length > 0) {
          this.chartAlreadyReflowed = true;
          chart.reflow();
        }
      });
    }
  };
}
