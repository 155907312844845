import { Inject, Injectable, InjectionToken } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { filter, map, retry, shareReplay, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MOTD_STORAGE_KEY, StorageHelper } from '../../core/storage-store/browser-storage';
import { AsyncMessagingService, MessageType } from '../../core/async-messaging/async-messaging.service';
import { getRetryConfig } from '../../core/common/rxjs-utils';

export const MOTD_STORAGE = new InjectionToken<Storage>('Motd storage e.g. localStorage');

export class MotdMessage {
  id: string;
  title: string;
  body: string;
  hasBeenShown?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class MotdService {
  private motdIdStorageHelper = new MotdIdStorageHelper(this.storage);
  private uri = '/api/v1/announcement/public';
  private motdSubject = new ReplaySubject<MotdMessage | null>(1);
  private lastMotd: MotdMessage | null = null;

  motd$ = this.motdSubject.asObservable().pipe(
    map((motdMessage) => this.enhanceMotdMessage(motdMessage)),
    tap((motdMessage) => this.motdIdStorageHelper.store(motdMessage?.id)),
    tap((motdMessage) => (this.lastMotd = motdMessage)),
    shareReplay(1) // Prevent multiple store/enhance calls
  );

  constructor(
    private asyncMessagingService: AsyncMessagingService,
    @Inject(MOTD_STORAGE) private storage: Storage,
    private http: HttpClient
  ) {
    this.refreshAnnouncementMessage();
    this.registerSseSubscription();
  }

  private getAnnouncementMessage(): Observable<MotdMessage> {
    return this.http.get<MotdMessage>(this.uri).pipe(retry(getRetryConfig({ maxRetryAttempts: Number.POSITIVE_INFINITY })));
  }

  private enhanceMotdMessage(motdMessage: MotdMessage): MotdMessage {
    if (!motdMessage) {
      return;
    }
    return {
      ...motdMessage,
      hasBeenShown: motdMessage.id === this.motdIdStorageHelper.fetch()
    };
  }

  private registerSseSubscription(): void {
    this.asyncMessagingService
      .onMessageOfType(MessageType.AnnouncementSseEvent)
      .pipe(
        map((message) => JSON.parse(message.payload)),
        filter((newMotd) => newMotd?.id !== this.lastMotd?.id)
      )
      .subscribe((motdMessage) => this.motdSubject.next(motdMessage));
  }

  private refreshAnnouncementMessage(): void {
    this.getAnnouncementMessage().subscribe((result) => this.motdSubject.next(result));
  }
}

export class MotdIdStorageHelper extends StorageHelper<string> {
  constructor(storage: Storage) {
    super(storage, MOTD_STORAGE_KEY);
  }
}
