import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexModalDialogTriggerDirective } from './dialog-trigger.directive';
import { CommonModule } from '@angular/common';
import { FlexDialogComponent } from './dialog.component';
import { FlexModalDialogResponsiveCloseDirective } from './dialog-close.directive';
import { FlexSaveButtonModule } from '../save-button/save-button.module';
import { FlexCoreModule } from '../../core/core.module';

@NgModule({
  declarations: [FlexModalDialogTriggerDirective, FlexModalDialogResponsiveCloseDirective, FlexDialogComponent],
  imports: [CommonModule, MatDialogModule, MatIconModule, MatButtonModule, FlexSaveButtonModule, FlexCoreModule],
  exports: [FlexDialogComponent, FlexModalDialogTriggerDirective, FlexModalDialogResponsiveCloseDirective]
})
export class FlexDialogModule {}
