import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MAT_DRAWER_DEFAULT_AUTOSIZE } from '@angular/material/sidenav';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { FlexLoadingContextModule } from '../material/loading-context/loading-context.module';
import { MOTD_STORAGE } from '../material/motd/motd.service';
import { PhFlexSvgComponent } from './common/flex-svg';
import { EVENT_SOURCE } from './common/event-source';
import { MoneyPipe } from './common/money';
import { PercentageToFactorPipe } from './common/percentage-to-factor.pipe';
import { UriEncoderInterceptor } from './common/uri-encoder.interceptor';
import { CalendarPeriodListItemDirective } from './date-time/calendar-period-list/calendar-period-list-item.directive';
import { CalendarPeriodListComponent } from './date-time/calendar-period-list/calendar-period-list.component';
import { CalendarPeriodPipe } from './date-time/calendar-period.pipe';
import { TimeSlotDaysPipe } from './date-time/time-slot';
import { DisableLinkDirective } from './disable-link/disable-link.directive';
import { DummyComponent } from './dummy/dummy-component';
import { DynamicOverlay } from './dynamic-overlay/dynamic-overlay';
import { ErrorComponent } from './error/error.component';
import { LocaleClassDirective } from './locale/locale-class.directive';
import { MessagesComponent } from './messages/messages.component';
import { FlexNumberComponent } from './pipe-components/number.component';
import { StorageFactory } from './storage-store/storage-factory';
import { DefaultPipe } from './default-pipe/default.pipe';
import { TooltipComponent } from './tooltip/tooltip.component';
import { TooltipColumnLeftComponent } from './tooltip/tooltip-column-left/tooltip-column-left.component';
import { TooltipColumnRightComponent } from './tooltip/tooltip-column-right/tooltip-column-right.component';
import { TextTruncateComponent } from './text-truncate/text-truncate.component';
import { PRODUCTION_MODE } from './common/production-mode';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { TooltipRowDirective } from './tooltip/tooltip-row.directive';
import { TooltipColumnLeftDirective } from './tooltip/tooltip-column-left.directive';
import { TooltipColumnRightDirective } from './tooltip/tooltip-column-right.directive';
import { TooltipFallbackRowDirective } from './tooltip/tooltip-fallback-row.directive';
import { FlexHeightChangedDirective, FlexResizeObserverDirective, FlexWidthChangedDirective } from './common/resize-observer.directive';
import { CapacityNumberPipe, CapacityPipe } from './common/capacity.pipe';
import { ToolbarTabComponent } from './toolbar-tab/toolbar-tab/toolbar-tab.component';
import { ToolbarTabSelectComponent } from './toolbar-tab/toolbar-tab-select/toolbar-tab-select.component';
import { DummyContainerComponent } from './dummy/dummy-container.component';
import { RouterModule } from '@angular/router';
import { TextCropComponent } from './text-crop/text-crop.component';
import { VerticalCenterComponent } from './vertical-center/vertical-center.component';
import { MAT_SELECT_CONFIG, MatSelectConfig } from '@angular/material/select';

export const CUSTOMER_STORAGE_TOKEN = new InjectionToken('customer storage');
export const READABLE_APP_NAME = new InjectionToken('Readable app name');
export const APP_NAME = new InjectionToken('App name for e.g. app insights');

@NgModule({
  declarations: [
    ErrorComponent,
    DummyComponent,
    MessagesComponent,
    PercentageToFactorPipe,
    DisableLinkDirective,
    FlexNumberComponent,
    LocaleClassDirective,
    MoneyPipe,
    TimeSlotDaysPipe,
    CalendarPeriodPipe,
    CalendarPeriodListComponent,
    CalendarPeriodListItemDirective,
    CapacityPipe,
    CapacityNumberPipe,
    PhFlexSvgComponent,
    DefaultPipe,
    TooltipComponent,
    TooltipColumnLeftComponent,
    TooltipColumnRightComponent,
    TooltipRowDirective,
    TooltipFallbackRowDirective,
    TooltipColumnLeftDirective,
    TooltipColumnRightDirective,
    TextTruncateComponent,
    ToolbarComponent,
    FlexResizeObserverDirective,
    FlexWidthChangedDirective,
    FlexHeightChangedDirective,
    ToolbarTabComponent,
    ToolbarTabSelectComponent,
    DummyContainerComponent,
    TextCropComponent,
    VerticalCenterComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
    OverlayModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    FlexLoadingContextModule,
    RouterModule
  ],
  exports: [
    ErrorComponent,
    DummyComponent,
    MessagesComponent,
    PercentageToFactorPipe,
    DisableLinkDirective,
    FlexNumberComponent,
    LocaleClassDirective,
    MoneyPipe,
    TimeSlotDaysPipe,
    CalendarPeriodPipe,
    CalendarPeriodListComponent,
    CalendarPeriodListItemDirective,
    CapacityPipe,
    CapacityNumberPipe,
    PhFlexSvgComponent,
    DefaultPipe,
    TooltipComponent,
    TooltipRowDirective,
    TooltipFallbackRowDirective,
    TooltipColumnLeftDirective,
    TooltipColumnRightDirective,
    TextTruncateComponent,
    ToolbarComponent,
    FlexResizeObserverDirective,
    FlexWidthChangedDirective,
    FlexHeightChangedDirective,
    ToolbarTabComponent,
    ToolbarTabSelectComponent,
    TextCropComponent,
    VerticalCenterComponent
  ]
})
export class FlexCoreModule {
  static forRoot(readableAppName: string, appName: string): ModuleWithProviders<FlexCoreModule> {
    return {
      ngModule: FlexCoreModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: UriEncoderInterceptor,
          multi: true
        },
        {
          provide: MAT_DRAWER_DEFAULT_AUTOSIZE,
          useValue: true
        },
        {
          provide: EVENT_SOURCE,
          useValue: EventSourcePolyfill
        },
        {
          provide: CUSTOMER_STORAGE_TOKEN,
          useFactory: StorageFactory.sessionStorageFactory
        },
        {
          provide: MOTD_STORAGE,
          useFactory: StorageFactory.localStorageFactoryWithFallback
        },
        {
          provide: PRODUCTION_MODE,
          useValue: true
        },
        {
          provide: READABLE_APP_NAME,
          useValue: readableAppName
        },
        {
          provide: APP_NAME,
          useValue: appName
        },
        {
          provide: MAT_SELECT_CONFIG,
          useValue: {
            hideSingleSelectionIndicator: true
          } as MatSelectConfig
        },
        DynamicOverlay
      ]
    };
  }
}
