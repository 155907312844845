import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { FlexModalDialogTriggerDirective } from './dialog-trigger.directive';
import { map, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { EMPTY, Observable } from 'rxjs';
import { OnDestroyMixin } from '../../core/common/on-destroy.mixin';
import { MixinBase } from '../../core/common/constructor-type.mixin';

import { noop } from 'lodash-es';
import { DialogModalSize, FlexDialogService } from './dialog.service';
import { MatDialogRef } from '@angular/material/dialog';

export type ActionLabel = string;
export type CancelLabel = string;

@Component({
  selector: 'ph-flex-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class FlexDialogComponent extends OnDestroyMixin(MixinBase) implements AfterContentInit {
  @Input()
  dialogTitle: string;
  @Input()
  dialogModalSize: DialogModalSize;
  @Input()
  dialogActionLabel: ActionLabel = 'Ok';
  @Input()
  dialogActionIcon: string;
  @Input()
  dialogCancelLabel: CancelLabel = 'Cancel';
  @Input()
  dialogCancelIcon: string;
  @Input()
  dialogContent: TemplateRef<any>;
  @Input()
  dataFn: () => any = noop;
  @Input()
  autoFocus = true;
  @Input()
  hideDialogCancel = false;
  @Input()
  dialogContentFullWidth: boolean = false;

  /**
   * Re-emit the dialog afterClosed result.
   *
   * Emits true when the dialog is closed when the confirm button is pressed.
   * Emits undefined when the dialog is closed by clicking cancel or outside of the dialog.
   */
  @Output()
  closeAction: EventEmitter<boolean | undefined> = new EventEmitter<boolean | undefined>();

  @Input()
  panelClass: string;

  /**
   * The provided observable is subscribed to when the confirm button is pressed.
   * On completion, the dialog closes and closeAction is called with true.
   * On error, the button becomes red (save button behavior) and the dialog stays open.
   */
  @Input()
  confirmAction$: Observable<any>;

  @ContentChildren(FlexModalDialogTriggerDirective, { descendants: true })
  trigger: QueryList<FlexModalDialogTriggerDirective>;

  dialogRef: MatDialogRef<any>;

  @ViewChild('modalContent')
  modalContent: TemplateRef<any> | any;

  pending: boolean = false;

  error: any;

  constructor(private flexDialogService: FlexDialogService) {
    super();
  }

  ngAfterContentInit(): void {
    this.trigger.changes
      .pipe(
        startWith(this.trigger),
        switchMap((triggers) => (triggers.length > 0 ? triggers.first.clicked : EMPTY)),
        map(() => {
          this.dialogRef = this.flexDialogService.open(this.modalContent, {
            data: this.dataFn(),
            size: this.dialogModalSize,
            autoFocus: this.autoFocus,
            panelClass: this.panelClass
          });

          return this.dialogRef;
        }),
        switchMap((dialogRef) => dialogRef.afterClosed()),
        takeUntil(this.onDestroy$)
      )
      .subscribe((closedResult) => {
        this.closeAction.emit(closedResult);
      });
  }

  handleConfirmButtonClick(): void {
    console.error(`${this.confirmAction$}`);
    this.error = null;

    if (!this.confirmAction$) {
      this.dialogRef.close(true);
      return;
    }

    this.pending = true;
    this.confirmAction$.subscribe({
      next: () => {
        this.dialogRef.close(true);
        this.pending = false;
      },
      error: (error: any) => {
        this.error = error;
        this.pending = false;
      }
    });
  }
}
