<mat-form-field phFlexInputTheme phFlexDateAsMonth [class.hide-notch]="!label" [subscriptSizing]="subscriptSizing">
  <mat-label>{{ label }}</mat-label>
  <input matInput [matDatepicker]="selectedDateDatepicker" [formControl]="control" [min]="min" [max]="max" />
  <mat-datepicker-toggle matSuffix [for]="selectedDateDatepicker">
    <mat-icon matDatepickerToggleIcon svgIcon="ic-new:calendar"></mat-icon>
  </mat-datepicker-toggle>
  <mat-datepicker
    #selectedDateDatepicker
    [startView]="startView"
    [calendarHeaderComponent]="calendarHeaderComponent"
    (monthSelected)="monthSelected($event, selectedDateDatepicker)"
  ></mat-datepicker>
  <ph-flex-form-error-messages
    [control]="control"
    descriptiveName="period"
    i18n-descriptiveName="@@dateRangeFixedPeriod-periodLabel"
  ></ph-flex-form-error-messages>
</mat-form-field>
