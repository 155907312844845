import { PortalOverlayManager } from './portal-overlay-manager';
import { ComponentPortal } from '@angular/cdk/portal';
import { InjectionToken } from '@angular/core';

/**
 * Use component as portal
 */
export class ComponentPortalOverlayManager extends PortalOverlayManager {
  registerComponent(component: any): void {
    this.portal = new ComponentPortal(component);
  }
}

export const COMPONENT_PORTAL_OVERLAY_MANAGER = new InjectionToken<ComponentPortalOverlayManager>(
  'Current portal overlay manager for overlay components'
);
