import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { filter, takeUntil, withLatestFrom } from 'rxjs/operators';
import { OnDestroyMixin } from '../../../core/common/on-destroy.mixin';
import { EntityFacade } from '../../../store/common/entity.state';
import { MixinBase } from '../../../core/common/constructor-type.mixin';

@Component({
  selector: 'ph-flex-entity-save-button',
  templateUrl: './entity-save-button.component.html',
  styleUrls: ['./entity-save-button.component.scss']
})
export class EntitySaveButtonComponent extends OnDestroyMixin(MixinBase) implements OnChanges {
  @Input()
  formValid: boolean; // TODO remove this if we use @ngxs/forms

  @Input()
  disabled = false;
  @Input()
  entityFacade: EntityFacade;
  @Output()
  clickChanged = new EventEmitter<MouseEvent>();

  @Input()
  buttonLabel: string;

  @Input()
  buttonIcon: string = 'ic-old:save';

  @Input()
  color: string;

  @Input()
  shouldGetInitialFocus = false;

  pending$: Observable<boolean>;
  error$: Observable<string>;
  isPending: boolean;
  pendingSubscription: Subscription;
  actionDoneSubscription: Subscription;

  isClickedOnce = false;

  @Output()
  /**
   * true === success, false === error
   */
  actionDone = new EventEmitter<boolean>();

  get _isDisabled(): boolean {
    return this.disabled || this.isPending;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.entityFacade) {
      this.pending$ = this.entityFacade.saveButtonPending$;
      this.error$ = this.entityFacade.saveButtonError$;

      if (this.pendingSubscription) {
        this.pendingSubscription.unsubscribe();
      }

      if (this.actionDoneSubscription) {
        this.actionDoneSubscription.unsubscribe();
      }

      this.pendingSubscription = this.pending$.pipe(takeUntil(this.onDestroy$)).subscribe((pending) => {
        setTimeout(() => {
          this.isPending = pending;
        });
      });

      this.actionDoneSubscription = this.pending$
        .pipe(
          filter(() => this.isClickedOnce),
          withLatestFrom(this.error$),
          takeUntil(this.onDestroy$)
        )
        .subscribe(([pending, error]) => {
          setTimeout(() => {
            if (!pending) {
              this.actionDone.next(!error);
            }
          });
        });
    }
  }

  handleClick(event: MouseEvent): void {
    if (this._isDisabled) {
      event.stopPropagation();
      event.preventDefault();
    } else {
      this.isClickedOnce = true;
      this.clickChanged.emit(event);
    }
  }
}
