import { NgModule } from '@angular/core';
import { NumberInputComponent } from './number-input.component';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [NumberInputComponent],
  imports: [FormsModule, MatInputModule],
  exports: [NumberInputComponent]
})
export class FlexNumberInputModule {}
