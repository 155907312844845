import { Actions, getValue, ofActionDispatched, Store } from '@ngxs/store';
import { distinctUntilChanged, filter, map, startWith, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { cloneDeep, isEqual, toPairs, set, isObject } from 'lodash-es';
import {
  SetFormDirty,
  SetFormDisabled,
  SetFormEnabled,
  SetFormPristine,
  UpdateFormDirty,
  UpdateFormErrors,
  UpdateFormStatus,
  UpdateFormValue
} from '@ngxs/form-plugin';
import defineProperty = Reflect.defineProperty;
import { OnDestroyProvider } from '../common/on-destroy.mixin';
import { AppInjector } from '../common/app-injector';

export class NgXsFormModel<T> {
  model: T;
  dirty: boolean;
  status: 'VALID' | 'INVALID' | 'PENDING' | 'DISABLED';
  errors: any;

  static defaults<T>(value: T = null): NgXsFormModel<T> {
    return {
      model: value,
      dirty: false,
      status: 'VALID',
      errors: null
    };
  }

  /**
   * Update the given NgXsFormModel model with the partial value provided
   */
  static patchModel<T>(source: NgXsFormModel<T>, partialValue: Partial<T>): NgXsFormModel<T> {
    return {
      ...source,
      model: {
        ...source.model,
        ...partialValue
      }
    };
  }
}

export class FlexNgXsFormSyncOptions {
  /**
   * Do not sync falsy form values with state (such as null or '' or []) which would override a state set default.
   * Note that null seems to work better than '' in some cases (if the desired behavior is a state reset)
   */
  initialSyncOnlyTruthyFormValues? = true;

  /**
   * Use subPath to map a child control with the provided path instead of the entire form (group).
   */
  subPath?: string = null;

  /**
   * Should use getRawValue() instead of value when reading form state, or when formGroup.valueChanges emits a value, use the rawValue instead of the emitted event.
   * This means that values of disabled controls are synced with the state, instead of being removed (regular values get cleared when control is disabled).
   */
  useRawValue?: boolean = false;
}

export function mergeFormWithState<T>(form: AbstractControl, state: T): T {
  let stateCopy = cloneDeep(state);

  if (isObject(stateCopy)) {
    recursiveMergeFormWithState(form, stateCopy, '');
  } else if (form.value) {
    stateCopy = form.value;
  }

  return stateCopy;
}

function recursiveMergeFormWithState(form: AbstractControl, target: any, baseKey: string): any {
  if (form instanceof UntypedFormGroup) {
    toPairs(form.controls).map(([key, value]) => recursiveMergeFormWithState(value, target, baseKey === '' ? key : `${baseKey}.${key}`));
  } else if (form instanceof UntypedFormArray) {
    form.controls.forEach((value, index) =>
      recursiveMergeFormWithState(value, target, baseKey === '' ? `${index}` : `${baseKey}[${index}]`)
    );
  } else if (form.value) {
    set(target, baseKey, form.value);
  }
}

export interface ControlNames {
  /**
   * List of child control names. Will only show formGroups (which can have children)
   */
  children: ControlNames[];
  controlNames: string[];
}

export function getControlNames(object: AbstractControl): ControlNames {
  if (object instanceof UntypedFormGroup) {
    return {
      controlNames: Object.keys(object.controls),
      children: Object.values(object.controls)
        .map((child) => getControlNames(child))
        .filter((result) => !!result)
    };
  }
  return null;
}

export function getControlValue(control: AbstractControl | UntypedFormGroup, useRawValue: boolean): any {
  // @ts-ignore
  return control.getRawValue && useRawValue ? control.getRawValue() : control.value;
}

/**
 * To decorate a form group property initialized by e.g. this.fb.group({})
 *
 * Syncs form and store
 * - Form -> store value changes
 * - Store -> form value changes based on direct modifications of state
 * - Store -> form value changes based on UpdateFormValue
 * - Form -> store status changes
 *
 * Does not sync status from state to form, rely on the Set... actions from NgXs instead
 */
export function FlexNgXsFormSync(
  path: string,
  providedOptions?: FlexNgXsFormSyncOptions
): (target: OnDestroyProvider, propertyKey: string) => void {
  const options: FlexNgXsFormSyncOptions = {
    ...new FlexNgXsFormSyncOptions(),
    ...providedOptions
  };

  return (target, propertyKey) => {
    let updating = false;

    const resetSubject = new Subject<void>();

    if (!target.onDestroy$) {
      console.error('FlexNgXsFormSync must be used with onDestroyMixin!');
    }

    target.onDestroy$.subscribe(() => {
      resetSubject.next();
    });

    let formGroup: UntypedFormGroup = target[propertyKey];

    defineProperty(target, propertyKey, {
      get: () => formGroup,
      set: (v) => {
        resetSubject.next();
        formGroup = v;

        formGroup.valueChanges
          .pipe(
            startWith([null]),
            map(() => getControlNames(formGroup)),
            distinctUntilChanged(isEqual),
            takeUntil(resetSubject)
          )
          .subscribe(() => {
            syncFormToState();
            initBindings();
          });
      }
    });

    function syncFormToState(): void {
      const control = options.subPath ? formGroup.get(options.subPath) : formGroup;

      if (!control) {
        return;
      }

      const store = AppInjector.get(Store);
      const formModel: NgXsFormModel<any> = store.selectSnapshot((state) => getValue(state, path));

      if (!formModel) {
        throw new Error(
          'flexNgXsFormSync was unable to find a NgXsFormModel object for the specified path. Please update the (default) state model and make sure the store is present in the store.config.ts.'
        );
      }

      const valueToCommit = !options.initialSyncOnlyTruthyFormValues
        ? getControlValue(control, options.useRawValue)
        : mergeFormWithState(control, formModel.model);

      if (!isEqual(formModel.model, valueToCommit)) {
        store.dispatch(
          new UpdateFormValue({
            path,
            value: valueToCommit
          })
        );
      }

      if (formModel.dirty !== control.dirty) {
        store.dispatch(
          new UpdateFormDirty({
            path,
            dirty: control.dirty
          })
        );
      }

      if (!isEqual(formModel.errors, control.errors)) {
        store.dispatch(
          new UpdateFormErrors({
            path,
            errors: control.errors
          })
        );
      }

      if (formModel.status !== control.status) {
        store.dispatch(
          new UpdateFormStatus({
            path,
            status: control.status
          })
        );
      }
    }

    function initBindings(): void {
      const control = options.subPath ? formGroup.get(options.subPath) : formGroup;

      if (!control) {
        return;
      }

      const store = AppInjector.get(Store);
      const actions = AppInjector.get(Actions);

      // Form -> store value changes
      control.valueChanges
        .pipe(
          map((changes) => getControlValue(control, options.useRawValue)),
          distinctUntilChanged(isEqual),
          filter((changes) => !isEqual(store.selectSnapshot((state) => getValue(state, path))?.model, changes)),
          takeUntil(resetSubject)
        )
        .subscribe((changes) => {
          if (updating) {
            console.debug(`Skipping form -> store value update of ${path} due to updating being true`);
            return;
          }

          console.warn('dispatching form to store -> ', path, changes);
          updating = true;
          store.dispatch(
            new UpdateFormValue({
              path,
              value: changes
            })
          );
          updating = false;
        });

      // Store -> form value changes based on direct modifications of state model
      store
        .select((state) => getValue(state, path)?.model)
        .pipe(
          filter((changes) => !isEqual(getControlValue(control, options.useRawValue), changes)),
          takeUntil(resetSubject)
        )
        .subscribe((changes) => {
          if (updating) {
            console.debug(`Skipping store -> form value update (state modifications) of ${path} due to updating being true`);
            return;
          }

          updating = true;
          if (!isEqual(getControlValue(control, options.useRawValue), changes)) {
            control.patchValue(changes);
          }
          updating = false;
        });

      // Store -> form value changes based on UpdateFormValue
      actions
        .pipe(
          ofActionDispatched(UpdateFormValue),
          // @ts-ignore
          map((actionUpdate) => actionUpdate?.action ?? actionUpdate),
          filter((action) => action?.payload?.path === path),
          takeUntil(resetSubject)
        )
        .subscribe((action) => {
          if (updating) {
            console.debug(`Skipping store -> form value update (UpdateFormValue) of ${path} due to updating being true`);
            return;
          }

          updating = true;
          if (action instanceof UpdateFormValue && !isEqual(getControlValue(control, options.useRawValue), action.payload.value)) {
            control.patchValue(action.payload.value);
          }
          updating = false;
        });

      // Form -> store status changes
      control.statusChanges.pipe(takeUntil(resetSubject)).subscribe(() => {
        if (updating) {
          console.debug(`Skipping Form -> store status update of ${path} due to updating being true`);
          return;
        }
        updating = true;
        const stateForm: NgXsFormModel<any> = store.selectSnapshot((state) => getValue(state, path));

        // Status text
        if (control.status !== stateForm.status) {
          store.dispatch(
            new UpdateFormStatus({
              path,
              status: control.status
            })
          );
        }

        // Dirty
        if (control.dirty !== stateForm.dirty) {
          store.dispatch(
            new UpdateFormDirty({
              path,
              dirty: control.dirty
            })
          );
        }

        // Errors
        if (control.errors !== stateForm.errors) {
          store.dispatch(
            new UpdateFormErrors({
              path,
              errors: control.errors
            })
          );
        }
        updating = false;
      });

      // Do not sync status from state to form, rely on the Set... actions from NgXs instead
      actions
        .pipe(
          ofActionDispatched(SetFormDisabled, SetFormEnabled, SetFormDirty, SetFormPristine),
          filter((action) => action?.payload?.startsWith(path)),
          takeUntil(resetSubject)
        )
        .subscribe((action) => {
          let currentControl = control;
          if (action.payload !== path) {
            const childPath = action.payload.substr(path.length + 1);
            currentControl = control.get(childPath);
          }
          if (!currentControl) {
            return;
          }
          if (action instanceof SetFormDisabled && currentControl.enabled) {
            currentControl.disable();
          }
          if (action instanceof SetFormEnabled && currentControl.disabled) {
            currentControl.enable();
          }
          if (action instanceof SetFormDirty && !currentControl.dirty) {
            currentControl.markAsDirty();
          }
          if (action instanceof SetFormPristine && !currentControl.pristine) {
            currentControl.markAsPristine();
          }
        });
    }
  };
}
