import { Injectable } from '@angular/core';
import { Control } from './control';
import { Observable } from 'rxjs';
import { pickBy, isEmpty } from 'lodash-es';
import { Serialized } from '../../core/types/serialized';
import { PeriodView } from '../../core/domain/period';
import { RestClientService } from '../rest-client/rest-client.service';

@Injectable({
  providedIn: 'root'
})
export class ControlService extends RestClientService<Control> {
  constructor() {
    super('/api/v1/controls');
  }

  disable(controlId: string): Observable<object> {
    return this.http.put(`${this.endpoint}/${controlId}/disable`, null);
  }

  enable(controlId: string): Observable<object> {
    return this.http.put(`${this.endpoint}/${controlId}/enable`, null);
  }

  sendTestSchedule(controlId: string, state: string, period: Serialized<PeriodView>): Observable<object> {
    return this.http.put(`${this.endpoint}/${controlId}/test`, { state, period });
  }

  configureControl(control: Control): Observable<Control> {
    return this.http.put(`${this.endpoint}/${control.id}/configure`, {
      upwardsR3Participation: control.upwardsR3Participation,
      downwardsR3Participation: control.downwardsR3Participation,
      switchCooldownTime: control.switchCooldownTime
    }) as Observable<Control>;
  }

  getFiltered({ customerId, gridPointId }: ImbalanceBiddingFilter): Observable<ReadonlyArray<Control>> {
    const params = pickBy(
      {
        customerId,
        gridPointId
      },
      (value) => value
    );

    return this.http.get<ReadonlyArray<Control>>(this.endpoint, {
      params: isEmpty(params) ? null : params
    });
  }
}

export interface ImbalanceBiddingFilter {
  customerId: string | undefined | null;
  gridPointId: string | undefined | null;
}
