import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DataSource, isDataSource, ListRange } from '@angular/cdk/collections';
import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  Host,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { get, isNil, orderBy, zip } from 'lodash-es';
import { combineLatest, Observable, of, Subject } from 'rxjs';

import { map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ShowOnTouchedErrorMatcher } from '../../core/common/show-on-touched-error-matcher';
import { AutoCompleteDataViewer, FilteredDataSource } from './shared';
import { MatStep } from '@angular/material/stepper';

/**
 * Provide an observable that can emit an array of boolean values for the given values. Length of these arrays should be identical.
 */
export interface DataSourceFilter<T> {
  /**
   * Post filters run after other filters.
   * They can be used to, for example, change the value based on whether the selected value is still available after filtering.
   */
  isPostFilter?: boolean;
  /**
   * Lower is higher priority
   */
  priority?: number;

  getFilter(values: T[], autocomplete: AutocompleteComponent): Observable<boolean[]>;
}

// Even though we expect DataSource, ListRange may be ignored when connecting and disconnecting
export const AUTOCOMPLETE_DATA_SOURCE = new InjectionToken<DataSource<any>>('Autocomplete data source');

// Provide filters like e.g. customerId to filter datasource with
export const AUTOCOMPLETE_DATA_SOURCE_FILTER = new InjectionToken<DataSourceFilter<any>>('Autocomplete data source filter');

@Component({
  selector: 'ph-flex-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AutocompleteComponent
    }
  ]
})
export class AutocompleteComponent
  implements OnInit, OnDestroy, OnChanges, AutoCompleteDataViewer<any>, MatFormFieldControl<string>, ControlValueAccessor, DoCheck
{
  private static nextId = 0;
  stateChanges = new Subject<void>();
  focused = false;
  controlType = 'ph-flex-autocomplete';
  id = `ph-flex-autocomplete-${AutocompleteComponent.nextId++}`;
  describedBy = '';
  /**
   * Stored when inputValue is cleared due to it not being a valid id.
   */
  oldInputValue = '';
  @Input()
  shouldOnlyEmitIds: boolean = false;
  @Input()
  shouldEmitNull: boolean = false;
  @Input()
  shouldAutoSelectIfOnlyOneResult: boolean = false;
  viewChange: Observable<ListRange> = new Subject<ListRange>();
  @Input()
  shouldAutoSelectFirstAvailableResult: boolean = false;
  data$: Observable<any>;
  filteredData$: Observable<any>;

  @Input() placeholder: string;
  @Input() required: boolean = false;
  @Input() disabled: boolean = false;
  @Input() value: string | null;
  @Input() errorStateMatcher: ErrorStateMatcher = new ShowOnTouchedErrorMatcher();
  filterChange = new Subject<string>();
  isInitialized = false;
  lastState;
  private onDestroy$ = new Subject<void>();
  @ViewChild(MatAutocompleteTrigger, { static: true })
  private matAutoCompleteTrigger: MatAutocompleteTrigger;
  private externalDisabledState: boolean = false;

  constructor(
    @Optional() @Self() @Inject(AUTOCOMPLETE_DATA_SOURCE) public dataSource: FilteredDataSource<any>,
    @Optional() @Host() @Inject(AUTOCOMPLETE_DATA_SOURCE_FILTER) public filters: DataSourceFilter<any>[],
    @Optional() @Self() public ngControl: NgControl,
    @Optional() @Host() public form: NgForm,
    @Optional() @Host() public formGroupDirective: FormGroupDirective,
    @Optional() @Host() public formControl: UntypedFormControl,
    @Optional() @Host() public step: MatStep,
    private focusMonitor: FocusMonitor,
    private elementRef: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef
  ) {
    if (!isDataSource(dataSource)) {
      throw new Error(
        'No datasource provided for autocomplete component. Please ensure a decorator that provides AUTOCOMPLETE_DATA_SOURCE is present'
      );
    }

    if (this.step) {
      // Mat stepper detected, listen for interaction and if it happens, call onTouched
      this.step.interactedStream.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.onTouched());
    }

    this.data$ = dataSource.connect(this).pipe(
      tap(() => {
        if (!this.isInitialized) {
          this.isInitialized = true;
          // We need to update the matAutoCompleteTrigger so displayWith is re-evaluated, since the latest property is now populated
          if (this.inputValue) {
            this.matAutoCompleteTrigger.writeValue(this.inputValue);
            this.filterChange.next(this.inputValue);
          }
        } else {
          // Check for stale input value
          if (!this.dataSource.isValidFilterValue(this.inputValue) && !this.dataSource.isValidId(this.inputValue) && this.inputValue) {
            this.oldInputValue = this.inputValue;
            this.inputValue = '';
            this.onChange('');
          } else if (this.oldInputValue && this.dataSource.isValidId(this.oldInputValue)) {
            // Reset input value to new
            this.inputValue = this.oldInputValue;
            this.onChange('');
          }
        }
      }),
      tap(() => {
        // If datasource contains only one element, then select it and set the control to disabled.
        if (this.dataSource.latest.length === 1 && this.shouldAutoSelectIfOnlyOneResult) {
          const id = this.dataSource.identifyWith(this.dataSource.latest[0]);
          if (id !== this.inputValue) {
            this.inputValue = id;
            this.emitValue(id);
            this.filterChange.next(id);
          }

          this.setDisabledState(true, true);
        } else {
          this.setDisabledState(false, true);
        }

        if (this.shouldAutoSelectFirstAvailableResult) {
          // TODO: previously, we toggled the disabledState here. However, that caused some issues when disabling the autocomplete when only one item was available.
          // Removal of the toggling has fixed that. But please note it has only been tested on de FlexOverviewComponent
          if (this.dataSource.latest.length > 0) {
            const currentlyHasNoValue = this.inputValue === '' || this.inputValue === undefined || this.inputValue === null;

            if (!!this.oldInputValue && currentlyHasNoValue) {
              // reset control when autocomplete is programmatically cleared
              this.ngControl?.reset();
            }

            if (currentlyHasNoValue && !this.ngControl?.touched) {
              // Select first available
              const id = this.dataSource.identifyWith(this.dataSource.latest[0]);
              this.inputValue = id;
              this.emitValue(id);
              this.filterChange.next(id);
            }
          } else {
            this.inputValue = '';
            this.onChange('');
          }
        }
      })
    );

    const that = this;

    function filterStage<T>(stageFilters: DataSourceFilter<T>[], currentData: T[]): Observable<T[]> {
      if (!stageFilters.length) {
        return of(currentData);
      }

      return combineLatest(stageFilters.map((filter) => filter.getFilter(currentData, that))).pipe(
        map((filterResults) => zip(...filterResults).map((values) => values.every((value) => !!value))),
        // auditTime(10),
        map((combinedFilterResult) =>
          zip(currentData, combinedFilterResult)
            .filter(([value, filterResult]) => filterResult)
            .map(([value, filterResult]) => value)
        )
      );
    }

    this.filteredData$ = this.data$.pipe(
      switchMap((data) => {
        if (!this.filters) {
          return of(data);
        }

        return filterStage(
          orderBy(
            this.filters.filter((filter) => !filter.isPostFilter),
            ['priority'],
            ['asc']
          ),
          get(this.dataSource, 'latestFilteredWithCurrent') || data
        ).pipe(
          switchMap((currentResult) =>
            filterStage(
              orderBy(
                this.filters.filter((filter) => filter.isPostFilter),
                ['priority'],
                ['asc']
              ),
              currentResult
            )
          )
        );
      })
    );

    focusMonitor
      .monitor(elementRef, true)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((origin) => {
        if (!this.focused && origin) {
          this.onTouched();
        }

        if (this.focused && !origin) {
          this.onBlur();
        }

        this.focused = !!origin;
        this.stateChanges.next();
      });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  _inputValue = '';

  get inputValue(): string {
    return this._inputValue;
  }

  set inputValue(value: string) {
    this._inputValue = value;
    if (!!value) {
      // Value is not empty
      this.oldInputValue = '';
    }
  }

  get errorState(): boolean {
    return this.lastState;
  }

  get empty(): boolean {
    return !this.inputValue;
  }

  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  @Input() displayWith: (id: string) => string = (id: string) => `Id: ${id}`;

  ngOnDestroy(): void {
    this.dataSource.disconnect(this);
    this.stateChanges.complete();
    this.focusMonitor.stopMonitoring(this.elementRef);
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.required) {
      this.required = coerceBooleanProperty(this.required);
    }

    if (changes.disabled) {
      this.disabled = coerceBooleanProperty(this.disabled);
    }

    if (changes.placeholder || changes.required || changes.disabled || changes.value) {
      this.stateChanges.next();
    }
  }

  ngOnInit(): void {
    // FA-4659: disabled
    // const validators = arrayWithDefault(this.ngControl.control.validator);

    // this.ngControl.control.setValidators(
    //   validators.concat([
    //     (control: FormGroup): ValidationErrors | null => {
    //
    //       if (this.dataSource.isInitialized && control.value && !this.dataSource.isValidId(control.value)) {
    //         return { invalidEntity: true };
    //       }
    //     }
    //   ])
    // );

    if (!this.inputValue) {
      // Call filterChange to get an initial list of data
      this.filterChange.next(this.inputValue);
    }
  }

  onChange = (_: any): void => {};

  onTouched = (): void => {};

  setDescribedByIds(ids: ReadonlyArray<string>): void {
    this.describedBy = ids.join(' ');
  }

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      const input = this.elementRef.nativeElement.querySelector('input');
      if (input) {
        input.focus();
        setTimeout(() => {
          this.matAutoCompleteTrigger.openPanel();
        });
      }
    }
  }

  writeValue(val: string | null): void {
    if ((val as any) instanceof AbstractControl) {
      console.warn(
        '[AutocompleteComponent] Got an instance of AbstractControl instead of a value. Did you use [ngModel] where you should use [formControl]?'
      );
    }
    this.inputValue = val;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Implement setDisabledState, but also support internal override of disabled (e.g. when only one value is available, and the shouldAutoSelectIfOnlyOneResult is true)
   * @param isDisabled Value to set disabled to
   * @param internal If true, forces disabled state to be isDisabled. If isDisabled is false, use last non-internal value
   */
  setDisabledState(isDisabled: boolean, internal: boolean = false): void {
    if (!internal) {
      this.externalDisabledState = isDisabled;
    }

    if (internal && !isDisabled) {
      this.disabled = this.externalDisabledState;
    } else {
      this.disabled = isDisabled;
    }
    this.cdr.markForCheck();
  }

  _handleInput(): void {
    this.filterChange.next(this.inputValue);
    this.emitValue(this.inputValue);
  }

  emitValue(value: any): void {
    if (this.shouldEmitNull && (isNil(value) || !value)) {
      return this.onChange(null);
    }
    if (this.shouldOnlyEmitIds && !this.dataSource.isValidId(value)) {
      return;
    }
    this.onChange(value);
  }

  onBlur(): void {
    // Automatically select the result if the input is blurred and the selection is reduced to 1 result
    // But ignore auto selection when an entity that is currently available (ignoring filtering) is already selected
    if (
      this.dataSource.latestFiltered.length === 1 &&
      !this.dataSource.latest.some((data) => this.dataSource.identifyWith(data) === this.inputValue) &&
      !!this.inputValue // InputValue should not be empty string or null (removed all data)
    ) {
      const id = this.dataSource.identifyWith(this.dataSource.latestFiltered[0]);

      this.emitValue(id);
      this.inputValue = id;
      this.filterChange.next(id);
    }
  }

  onOptionSelected($event: MatAutocompleteSelectedEvent): void {
    this.emitValue($event.option.value);
  }

  /**
   * When the input is clicked and already focused, focus monitor will not trigger.
   * We still want to open the autocomplete panel when this happens, so it is triggered here.
   */
  _handleClickInInput(): void {
    if (this.focused && !this.matAutoCompleteTrigger.panelOpen) {
      this.matAutoCompleteTrigger.openPanel();
    }
  }

  updateErrorState(): void {
    const state = this.ngControl && this.errorStateMatcher.isErrorState(this.ngControl.control as UntypedFormControl, null);
    if (this.lastState !== state) {
      this.stateChanges.next();
    }
    this.lastState = state;
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }
}
