import { GridPoint } from '../../../../api-private/grid-point/grid-point';

export class LoadGridPointsByCustomerIdCommand {
  public static readonly type = '[Grid Points] Fetch grid points';

  constructor(public customerId?: string) {}
}

export class LoadGridPointsCommand {
  public static readonly type = '[Grid Points] Load grid points';
}

export class ResetGridPointsCommand {
  public static readonly type = '[Grid Points] Reset grid points';
}

export class GridPointsLoadedEvent {
  public static readonly type = '[Grid Points] Grid points loaded event';

  constructor(public payload: ReadonlyArray<GridPoint>, public customerId?: string) {}
}

export class DownloadGridPointsCommand {
  public static readonly type = '[Grid Points] Download grid points';
}
