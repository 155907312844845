import { Component, DoCheck, ElementRef, HostBinding, Input, OnChanges, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NgControl,
  ValidationErrors,
  Validator
} from '@angular/forms';
import { Subject } from 'rxjs';
import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ShowOnTouchedErrorMatcher } from '../../core/common/show-on-touched-error-matcher';
import { MatFormFieldControl } from '@angular/material/form-field';
import { ErrorStateMatcher } from '@angular/material/core';
import { Expression, Parser } from 'expr-eval';

export class NumberValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return !Number.isNaN(control.value) ? null : { invalidNumber: true };
  }
}

// @dynamic
@Component({
  selector: 'ph-flex-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: NumberInputComponent
    },
    {
      provide: NG_VALIDATORS,
      useClass: NumberValidator,
      multi: true
    }
  ]
})
export class NumberInputComponent implements ControlValueAccessor, MatFormFieldControl<string>, OnInit, OnDestroy, OnChanges, DoCheck {
  static nextId = 0;
  private static decimalValidationExpression = /^-?[0-9]*(([.,])[0-9]+)?$/;
  private static numberValidationExpression = /^-?[0-9]*$/;
  @HostBinding()
  readonly id: string = `number-input-${NumberInputComponent.nextId++}`;
  inputValue = '';
  focused = false;
  readonly autofilled: boolean;
  readonly controlType = 'app-number-input';
  readonly stateChanges = new Subject<void>();
  @Input() errorStateMatcher: ErrorStateMatcher = new ShowOnTouchedErrorMatcher();
  // Simply, if this is true (after conversion) with a view value of '123,45' the model value will be 1.2345
  @Input() isPercentage: boolean = false;

  // TODO Changing this value does not change the component output. Maybe add this later if it is ever relevant?
  @Input() percentageDecimals: number = 0; // Number of decimals allowed for percentage inputs. Factor inputs will always be this value + 2.

  // Use 'view conversion' to re-calculate the view value that is currently displayed.
  // Whatever is the current view/string value will be converted based on the previous isPercentage value
  // From false to true is value/100 and true to false is value*100, so '1,2345' will be converted to '123,45'
  lastState;

  private percentageToFactorCalculator = Parser.parse('value / 100');
  private factorToPercentageCalculator = Parser.parse('value * 100');

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe((origin) => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  @Input()
  get value(): string | null {
    return this.inputValue;
  }

  set value(val: string | null) {
    this.inputValue = val;
    this.stateChanges.next();
  }

  get empty(): boolean {
    return this.inputValue === null || this.inputValue.length === 0;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty;
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match
  private _disabled = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  private _placeholder: string = '';

  @Input()
  get placeholder(): string {
    return this._placeholder;
  }

  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private _required = false;

  @Input()
  get required(): boolean {
    return this._required;
  }

  set required(req: boolean) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  get errorState(): boolean {
    return this.lastState;
  }

  validate(val: string): boolean {
    return (
      NumberInputComponent.decimalValidationExpression.test(val.trim()) || NumberInputComponent.numberValidationExpression.test(val.trim())
    );
  }

  ngDoCheck(): void {
    if (this.ngControl) {
      this.updateErrorState();
    }
  }

  updateErrorState(): void {
    const state = this.ngControl && this.errorStateMatcher.isErrorState(this.ngControl.control as UntypedFormControl, null);
    if (this.lastState !== state) {
      this.stateChanges.next();
    }
    this.lastState = state;
  }

  ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    if (changes.isPercentage) {
      function getState(value: any): boolean {
        return value === '' || value === 'true' || value === true;
      }

      const oldState = getState(changes.isPercentage.previousValue);
      const newState = getState(changes.isPercentage.currentValue);

      if (!oldState && newState) {
        // Convert value to '0,10' to '10'
        this.value =
          this.value &&
          this.factorToPercentageCalculator
            .evaluate({ value: parseFloat(this.value.replace(',', '.')) })
            .toFixed(this.percentageDecimals)
            .replace('.', ',');
      } else if (oldState && !newState) {
        // Convert value from '10' to '0,10'
        this.value =
          this.value &&
          this.percentageToFactorCalculator
            .evaluate({ value: parseFloat(this.value.replace(',', '.')) })
            .toFixed(this.percentageDecimals + 2)
            .replace('.', ',');
      }
      this.isPercentage = newState;
    }
  }

  ngOnInit(): void {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef.nativeElement);
  }

  onChange = (_: any) => {};

  onTouched = () => {};

  onContainerClick(event: MouseEvent): void {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this._elementRef.nativeElement.querySelector('input').focus();
    }
  }

  setDescribedByIds(ids: ReadonlyArray<string>): void {}

  handleInput(): void {
    if (this.validate(this.inputValue)) {
      const val = this.inputValue.replace(',', '.');
      if (val.length === 0) {
        this.onChange(null);
      } else {
        if (this.isPercentage) {
          const factor = this.percentageToFactorCalculator.evaluate({ value: Number.parseFloat(val) }).toFixed(this.percentageDecimals + 2);
          const numberFactor = Number.parseFloat(factor);
          const calculatedPercentage = this.factorToPercentageCalculator.evaluate({ value: numberFactor }).toFixed(this.percentageDecimals);

          if (calculatedPercentage !== val) {
            // If toFixed removed input then the number had more precision than desired, so we should output NaN
            this.onChange(Number.NaN);
          } else {
            this.onChange(
              Number.parseFloat(
                this.percentageToFactorCalculator.evaluate({ value: Number.parseFloat(val) }).toFixed(this.percentageDecimals + 2)
              )
            );
          }
        } else {
          this.onChange(Number.parseFloat(val));
        }
      }
    } else {
      this.onChange(Number.NaN);
    }
  }

  writeValue(val: string | number | null): void {
    this.value = val && val.toString().replace('.', ',');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
