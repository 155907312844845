import { JsonObject } from 'typescript-json-serializer';

export enum InputKind {
  ANALOG = 'ANALOG'
}

export enum AnalogInputType {
  HEAT_BUFFER = 'HEAT_BUFFER',
  STATE_OF_CHARGE = 'STATE_OF_CHARGE',
  SOLAR_IRRADIANCE = 'SOLAR_IRRADIANCE'
}

@JsonObject()
export class AnalogInputCalibration {
  @JsonObject()
  signalType: string;
  @JsonObject()
  lboundMeasurement: number;
  @JsonObject()
  uboundMeasurement: number;
  @JsonObject()
  uboundCalibration: number;
}

@JsonObject()
export class AnalogInput {
  @JsonObject()
  id: string;
  @JsonObject()
  kind: InputKind;
  @JsonObject()
  customerId: string;
  @JsonObject()
  customerName: string | null = null;
  @JsonObject()
  gridPointId: string | null = null;
  @JsonObject()
  description: string;
  @JsonObject()
  type: AnalogInputType;
  @JsonObject()
  calibration: AnalogInputCalibration | null = null;
  @JsonObject()
  providerId: string;
  @JsonObject()
  providerProperties: Map<string, string>;
}
