import { ActivationStart, Router } from '@angular/router';
import { getFromInjector } from './app-injector';
import { concat, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, retry, switchMap } from 'rxjs/operators';
import { findParameterValue } from './find-parameter-value';
import { isEqual } from 'lodash-es';
import { getRetryConfig } from './rxjs-utils';

const router$ = getFromInjector(Router);

/**
 * Takes an array of router params (e.g. ['customerId', 'gridPointId']) and a function that returns an observable with the type provided.
 * Can be used to implement a router param based data source for LazyLoadedDataSource
 */
export function routerParamDataSourceFactory<T>(params: string[], fn: (resolvedParams: string[]) => Observable<T>): Observable<T> {
  return concat(
    router$.pipe(map((router) => router.routerState.snapshot)),
    router$.pipe(
      switchMap((router) => router.events),
      filter((event) => event instanceof ActivationStart),
      map((routerEvent: ActivationStart) => routerEvent.snapshot)
    )
  ).pipe(
    map((snapshot) => params.map((param) => findParameterValue(snapshot, param))),
    distinctUntilChanged(isEqual),
    switchMap((snapshot) => updateDataWithRetry(snapshot))
  ) as Observable<T>;

  function updateDataWithRetry(resolvedParams: string[]): Observable<T> {
    return fn(resolvedParams).pipe(retry(getRetryConfig()));
  }
}
