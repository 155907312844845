<ng-container phFlexDark>
  <div *ngIf="showFilterInputsBreakpointMatches$ | async">
    <ph-flex-filter-wrapper [formComponent]="formComponent" [formGroup]="filterForm"></ph-flex-filter-wrapper>
  </div>
</ng-container>

<ng-template #myDialogContent>
  <ph-flex-filter-wrapper [formComponent]="formComponent" [formGroup]="filterForm" [inDialog]="true"></ph-flex-filter-wrapper>
</ng-template>

<div class="filter-buttons button-group">
  <div *ngIf="(hasFilters$ | async) && !(showFilterInputsBreakpointMatches$ | async)">
    <ph-flex-dialog
      [dialogContent]="myDialogContent"
      [autoFocus]="false"
      dialogActionLabel="Close"
      dialogModalSize="xs"
      dialogTitle="Filters"
      hideDialogCancel="true"
      i18n-dialogActionLabel="@@filters-labelActionClose"
      phFlexDialogResponsiveClose
    >
      <button
        class="ph-flex-mat-icon-button-on-dark ph-flex-mat-icon-button-toolbar ph-flex-mat-icon-button-medium"
        mat-icon-button
        phFlexModalDialogTrigger
        type="button"
      >
        <ph-flex-vertical-center [alignCenter]="true">
          <mat-icon svgIcon="ic-new:filter"></mat-icon>
        </ph-flex-vertical-center>
      </button>
    </ph-flex-dialog>
  </div>
  <ng-content select="[ph-flex-filters-additional-buttons]"></ng-content>
  <ph-flex-dialog
    (closeAction)="changeFilters($event)"
    [dialogContent]="filterDialogContent"
    dialogModalSize="xs"
    dialogTitle="Filter settings"
    i18n-dialogTitle="@@filters-titleFilterSettings"
    dialogActionLabel="Confirm"
    i18n-dialogActionLabel="@@filters-labelAction"
  >
    <button
      mat-icon-button
      phFlexModalDialogTrigger
      class="ph-flex-mat-icon-button-on-dark ph-flex-mat-icon-button-toolbar ph-flex-mat-icon-button-medium"
      type="button"
      *ngIf="!hideFilterSettingsButton"
    >
      <ph-flex-vertical-center [alignCenter]="true">
        <mat-icon svgIcon="ic-new:settings"></mat-icon>
      </ph-flex-vertical-center>
    </button>
  </ph-flex-dialog>
</div>

<ng-template #filterDialogContent>
  <ph-flex-filter-dialog
    (selectedFiltersChanged)="selectedFiltersUpdated($event)"
    [filters]="filters$ | async"
    [optionsTemplate]="dialogOptionsTemplate"
    [selectedFilters]="selectedFilters$ | async"
    [maxSelectedFilters]="allowedNumberOfFilters"
  ></ph-flex-filter-dialog>
  <div class="filter-dialog-content-footer">
    <ng-content select="[ph-flex-filters-dialog-footer]"></ng-content>
  </div>
</ng-template>
