import { get, set } from 'lodash-es';
import moment from 'moment';

export enum DateFormat {
  LocalDate = 'YYYY-MM-DD'
}

export function formatMomentAsLocalDate(obj: any, properties: ReadonlyArray<string>): void {
  properties.forEach((property) => {
    const value = get(obj, property);
    set(obj, property, moment(value).format(DateFormat.LocalDate));
  });
}
