import { isObservable, Observable } from 'rxjs';
import { StateContext, Store } from '@ngxs/store';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { get, isArray, isNumber, isUndefined, keys, pick } from 'lodash-es';

export class EntityStateModel<T> {
  model: T;
  /**
   * Has an error occurred during the process of saving data to the backend?
   * When true, expect frontend to be ahead of backend.
   */
  sendError = null;
  /**
   *
   * Has an error occurred while fetching data?
   * When true, expect frontend to be behind backend
   */
  receiveError = null;
  // Has data been initialized?
  isInitialized = true;
  // Data update in progress, user input should be prevented
  isBusySending = false;
  busySendingCount = 0;
  busyReceivingCount = 0;
  sendErrors = [];
  receiveErrors = [];
  // Data update in progress, user input should be prevented
  isBusyReceiving = false;

  constructor(previousState?: EntityStateModel<T>) {
    // This constructor is called with previousState when it is reset
    // to ensure the properties contained in this class are retained.
    if (previousState) {
      const definedKeys = keys(new EntityStateModel());
      return pick(previousState, definedKeys) as EntityStateModel<T>;
    }
  }
}

export interface EntityFacade {
  saveButtonPending$: Observable<boolean>;
  saveButtonError$: Observable<string>;
}

export interface EntityStateOpts<T> {
  actionFactoryMethod?: (result: T) => any | any[];
  errorActionFactoryMethod?: (error: { message: string }) => any | any[];
}

export function FetchEntity(): (target: any, propertyKey: string, descriptor: PropertyDescriptor) => any {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor): any => {
    return createDecorator(target, propertyKey, descriptor, ({ getState, setState }) => {
      const onInit: () => void = () => {
        validateState(getState());
        setState({
          ...getState(),
          isInitialized: false,
          busyReceivingCount: getState().busyReceivingCount + 1
        });
        updateBooleans(setState, getState);
      };

      const onNext: () => void = () => {
        validateState(getState());
        setState({
          ...getState(),
          isInitialized: true,
          busyReceivingCount: safeDecrementUntilZero(getState().busyReceivingCount)
        });
        updateBooleans(setState, getState);
      };

      const onError: (err: { message?: string }) => void = (err) => {
        validateState(getState());
        setState({
          ...getState(),
          isInitialized: true,
          busyReceivingCount: safeDecrementUntilZero(getState().busyReceivingCount),
          sendError: err && err.message,
          receiveErrors: getState().receiveErrors.concat([err.message])
        });
        updateBooleans(setState, getState);
      };

      return {
        onError,
        onNext,
        onInit
      };
    });
  };
}

export function validateState(state: EntityStateModel<any>): void {
  if (
    isUndefined(state.busySendingCount) ||
    isUndefined(state.busyReceivingCount) ||
    !isArray(state.sendErrors) ||
    !isArray(state.receiveErrors)
  ) {
    throw new Error('State is not initialized properly for use with entity state');
  }
}

export function SaveEntity(): (target: any, propertyKey: string, descriptor: PropertyDescriptor) => any {
  return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
    return createDecorator(target, propertyKey, descriptor, ({ getState, setState }) => {
      const onInit = () => {
        validateState(getState());
        setState({
          ...getState(),
          busySendingCount: getState().busySendingCount + 1
        });
        updateBooleans(setState, getState);
      };

      const onNext = () => {
        validateState(getState());
        setState({
          ...getState(),
          busySendingCount: safeDecrementUntilZero(getState().busySendingCount)
        });
        updateBooleans(setState, getState);
      };

      const onError = (err) => {
        validateState(getState());

        setState({
          ...getState(),
          busySendingCount: safeDecrementUntilZero(getState().busySendingCount),
          sendError: err.message,
          sendErrors: getState().sendErrors.concat([err.message])
        });
        updateBooleans(setState, getState);
      };

      return {
        onError,
        onNext,
        onInit
      };
    });
  };
}

function createDecorator(
  target: any,
  propertyKey: string,
  descriptor: PropertyDescriptor,
  cb: (ctx: StateContext<any>) => { onInit; onNext; onError }
): any {
  const originalFn = get(target, propertyKey);

  descriptor.value = function (...args: any): Observable<any> {
    const ctx = args[0];
    const { onInit, onNext, onError } = cb(ctx);
    // eslint-disable-next-line no-invalid-this
    const result = originalFn.apply(this, args);

    const observable: Observable<any> = new Observable((observer) => {
      onInit();
      // Ensure we only send onNext once
      // TODO maybe rename onNext to onComplete or onStarted
      let isDone = false;
      let inner;
      if (isObservable(result)) {
        inner = result.pipe(
          tap({
            error: (err) => {
              if (!isDone) {
                isDone = true;
                onError(err);
              }
            },
            next: () => {
              if (!isDone) {
                isDone = true;
                onNext();
              }
            }
          })
        );
      } else {
        inner = new Observable((subscriber) => {
          subscriber.next(result);
          subscriber.complete();
        });
      }

      const subscription = inner.subscribe(
        (next) => {
          observer.next(next);
        },
        (error) => {
          observer.error(error);
        },
        () => observer.complete()
      );
      return () => {
        if (!isDone) {
          isDone = true;
          onNext();
        }
        subscription.unsubscribe();
      };
    });

    return observable;
  };

  return descriptor;
}

/**
 * Default implementation of a store managing a single instance of a backend managed entity
 * TODO Remove this in favor of decorators
 */
export abstract class EntityState<T> {
  constructor(protected store: Store) {
    if (!store) {
      throw new Error('No store was provided when initializing an EntityState.');
    }
  }

  static isBusySending(state: EntityStateModel<any>): boolean {
    return state.isBusySending;
  }

  /**
   * Save behavior to save a single entity
   *
   * State properties modified by this method:
   * isBusySending:
   *  - True after this function is called
   *  - False after the observable has returned a value
   *  - False after the observable has errored
   * sendError:
   *  - Contains error message if an error has occurred
   *
   * @param setState ctx
   * @param getState ctx
   * @param dispatch ctx
   * @param saveObservable Observable that saves the data and completes after it is saved or errors when it failed
   * @param actionFactoryMethod Callback to provide actions to dispatch
   * @param errorActionFactoryMethod Callback to provide actions to dispatch on error
   */
  saveData(
    { setState, getState, dispatch }: StateContext<EntityStateModel<T>>,
    saveObservable: Observable<T>,
    { actionFactoryMethod, errorActionFactoryMethod }: EntityStateOpts<T>
  ): Observable<any> {
    setState({
      ...getState(),
      sendError: null,
      busySendingCount: getState().busySendingCount + 1
    });
    updateBooleans(setState, getState);

    return saveObservable.pipe(
      tap((result) => {
        setState({
          ...getState(),
          busySendingCount: safeDecrementUntilZero(getState().busySendingCount)
        });
        updateBooleans(setState, getState);

        return this.handleActionFactory<T>(actionFactoryMethod, result);
      }),
      catchError((error) => {
        setState({
          ...getState(),
          busySendingCount: safeDecrementUntilZero(getState().busySendingCount),
          sendError: error.message,
          sendErrors: getState().sendErrors.concat([error.message])
        });
        updateBooleans(setState, getState);
        this.handleActionFactory<{ message: string }>(errorActionFactoryMethod, error);

        throw error;
      })
    );
  }

  /**
   * Fetch behavior for states to fetch a single entity
   *
   * State properties modified by this method:
   * isBusyReceiving:
   *  - True after this function is called
   *  - False after the observable has returned a value
   *  - False after the observable has errored
   * initializationError:
   *  - Contains error message if an error has occurred
   *
   * @param getState ctx
   * @param setState ctx
   * @param dispatch ctx
   * @param initObservable Observable that fetches the data to be initialized
   * @param actionFactoryMethod Callback to provide actions to dispatch on scucess
   * @param errorActionFactoryMethod Callback to provide actions to dispatch on error
   */
  fetchData(
    { getState, setState, dispatch }: StateContext<EntityStateModel<T>>,
    initObservable: Observable<T>,
    { actionFactoryMethod, errorActionFactoryMethod }: EntityStateOpts<T>
  ): Observable<any> {
    // Start receiving
    setState({
      ...getState(),
      receiveError: null,
      busyReceivingCount: getState().busyReceivingCount + 1
    });
    updateBooleans(setState, getState);

    return initObservable.pipe(
      switchMap((result) => {
        // Data received
        setState({
          ...getState(),
          busyReceivingCount: safeDecrementUntilZero(getState().busyReceivingCount)
        });
        updateBooleans(setState, getState);

        // Dispatch event to handle storage
        return this.handleActionFactory<T>(actionFactoryMethod, result);
      }),
      catchError((error) => {
        // No longer receiving
        // Register that an initialization error has occurred
        setState({
          ...getState(),
          receiveError: error.message,
          busyReceivingCount: safeDecrementUntilZero(getState().busyReceivingCount),
          receiveErrors: getState().receiveErrors.concat(error.message)
        });
        updateBooleans(setState, getState);
        this.handleActionFactory<{ message: string }>(errorActionFactoryMethod, error);
        throw error;
      })
    );
  }

  /**
   * Handle no factory method, as well as falsy values for factory result.
   * @param factory Factory method providing actions
   * @param result Value to be returned in a switchMap
   */
  private handleActionFactory<ResultType>(
    factory: ((result: ResultType) => any | any[]) | undefined,
    result: ResultType
  ): undefined | Observable<any> {
    if (!factory) {
      return;
    }
    const factoryResult = factory(result);
    return factoryResult && this.store.dispatch(factoryResult);
  }
}

function updateBooleans(setState: any, getState: any): void {
  setState({
    ...getState(),
    isBusySending: getState().busySendingCount !== 0,
    isBusyReceiving: getState().busyReceivingCount !== 0
  });
}

/**
 * This function is used to determine if a isBusy value can be reduced safely.
 * If the value to be decreased is not higher than 0, return 0.
 * Otherwise return the provided value - 1
 * @param value Value to be decremented
 */
function safeDecrementUntilZero(value: number): number {
  if (isNumber(value) && value > 0) return value - 1;

  // If this happens it is likely the state is reset incorrectly.
  // DO NOT just use `setState(new SomeStateModel())`
  // Instead, provide the previous state value so the counters and booleans managed by EntityState are retained
  // `setState(new SomeStateModel(getState()));`
  console.error(`Value '${value}' cannot be decreased safely, returning 0`);
  return 0;
}
