import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { BrushBehavior, BrushSelection } from 'd3-brush';
import * as d3 from 'd3';
import { BaseType, D3BrushEvent, select, Selection } from 'd3';
import { DestroyableMixin, OnDestroyMixin, OnDestroyProvider } from '../../../../core/common/on-destroy.mixin';
import { MixinBase } from '../../../../core/common/constructor-type.mixin';
import { TimeSlotScaleProvider } from '../../scales/d3-graph-time-slot-scale-provider';
import { switchMap } from 'rxjs/operators';
import { TimeSlot } from '../../../../core/date-time/time-slot';
import { addSeconds, differenceInSeconds, max, min, subSeconds } from 'date-fns';
import { SubjectProvider } from '../../common';
import { ScaleBand, ScaleContinuousNumeric, ScaleTime } from 'd3-scale';
import { D3GraphDynamicScaleProvider } from '../../scales/d3-graph-dynamic-scale-provider';

type SupportedYScale = ScaleContinuousNumeric<any, any> | ScaleBand<any>;
type SupportedXScale = ScaleTime<any, any>;

export class BrushEndMouseEvent extends Event {
  x: number;
  clientX: number;
  y: number;
  clientY: number;
  selection: BrushSelection | null;

  constructor(endEvent: D3BrushEvent<any>) {
    super('brushEndMouseUp');
    const sourceEvent = endEvent.sourceEvent;
    this.x = sourceEvent.x;
    this.clientX = sourceEvent.clientX;
    this.y = sourceEvent.y;
    this.clientY = sourceEvent.clientY;
    this.selection = endEvent.selection;
  }
}

export class D3GraphXScaleZoom extends DestroyableMixin(OnDestroyMixin(MixinBase)) {
  private dynamicTimeSlotScaleProvider = new D3GraphDynamicScaleProvider<ScaleTime<number, number>, Date>(
    this
  ) as unknown as TimeSlotScaleProvider & D3GraphDynamicScaleProvider<ScaleTime<number, number>, Date>;

  /**
   * Provide the value that will be sent to the TimeSlotScaleProvider when the selection is reset
   */
  private initialSelectionProvider = new SubjectProvider(this, new BehaviorSubject<TimeSlot>(TimeSlot.today()));
  private xScale$ = this.dynamicTimeSlotScaleProvider.scale$;

  private overlayProvider = new SubjectProvider<Selection<any, any, any, any>>(this);
  overlay$ = this.overlayProvider.value$;

  private yScaleProvider = new SubjectProvider<SupportedYScale>(this);
  private brushEndSubject = new Subject<D3BrushEvent<any>>();

  private d3Brush: BrushBehavior<any>;
  private node: Selection<any, any, any, any>;

  constructor(onDestroyProvider: OnDestroyProvider) {
    super();
    this.registerOnDestroyProvider(onDestroyProvider);

    this.d3Brush = d3.brushX();

    this.d3Brush.on('end', (event) => {
      // Re-emit mouseup event as brushEndMouseUp, since the mouseup event is cancelled by d3. Used by d3-graph-cursor.
      if (event.sourceEvent?.type === 'mouseup') {
        const reThrownEvent = new BrushEndMouseEvent(event);
        this.overlayProvider.value.each(function (): void {
          // eslint-disable-next-line no-invalid-this
          this.dispatchEvent(reThrownEvent);
        });
      }
      this.brushEndSubject.next(event);
    });

    combineLatest([this.brushEndSubject, this.xScale$]).subscribe(([brushEnd, xScale]) => {
      this.onBrushEnd(brushEnd, xScale, this.dynamicTimeSlotScaleProvider);
    });

    this.initialSelectionProvider.value$.subscribe((newInitialSelection) => {
      // Fix FA-2682
      if (newInitialSelection && this.dynamicTimeSlotScaleProvider.setTimeSlot) {
        this.dynamicTimeSlotScaleProvider.setTimeSlot(newInitialSelection);
      }
    });
  }

  attach(mainSvg: Selection<BaseType, any, any, any>): void {
    this.node = mainSvg.append('g').attr('class', 'x-axis-zoom');

    combineLatest([this.xScale$, this.yScaleProvider.value$]).subscribe(([xScale, yScale]) => {
      // Update extent before call, otherwise the overlay size and position is empty/incorrect.
      this.updateBrushExtent(xScale, yScale);

      this.node.call(this.d3Brush);

      // This will be called every time the scale changes snice the this.node.call(this.d3Brush) will re-create the element (as of time of writing)
      this.overlayProvider.next(mainSvg.select('.overlay'));
    });
  }

  destroy(): void {
    this.ngOnDestroy();
  }

  setTimeSlotScale(scale: TimeSlotScaleProvider): void {
    this.dynamicTimeSlotScaleProvider.next(scale);
  }

  setYScale(scale: SupportedYScale): void {
    this.yScaleProvider.next(scale);
  }

  setYScale$(scale$: Observable<SupportedYScale>): void {
    this.yScaleProvider.follow(scale$);
  }

  setInitialSelection(timeSlot: TimeSlot): void {
    this.initialSelectionProvider.next(timeSlot);
  }

  setInitialSelection$(timeSlot$: Observable<TimeSlot>): void {
    this.initialSelectionProvider.follow(timeSlot$);
  }

  /**
   * Update xScale timeSlot on brush end
   */
  private onBrushEnd(event: D3BrushEvent<any>, xScale: ScaleTime<number, number>, timeSlotScale: TimeSlotScaleProvider): void {
    // Source Event is not set when brush ends programmatically, we only handle user events here
    if (!event.sourceEvent) {
      return;
    }

    let newTimeSlot: TimeSlot = this.initialSelectionProvider.value;

    if (event.selection) {
      let selectionStartDate = new Date(xScale.invert(event.selection[0] as number));
      let selectionEndDate = new Date(xScale.invert(event.selection[1] as number));

      const diffInSeconds = differenceInSeconds(selectionEndDate, selectionStartDate);

      const secondsInHour = 3600;
      if (Math.abs(diffInSeconds) < secondsInHour) {
        // Less than 1 hour
        const middleDate = addSeconds(selectionStartDate, diffInSeconds / 2);
        selectionStartDate = subSeconds(middleDate, secondsInHour / 2);
        selectionEndDate = addSeconds(middleDate, secondsInHour / 2);
      }

      const domainStartDate = xScale.domain()[0];
      const domainEndDate = xScale.domain()[1];

      newTimeSlot = {
        startDateTime: max([domainStartDate, selectionStartDate]).toISOString(),
        toDateTime: min([domainEndDate, selectionEndDate]).toISOString()
      };

      this.d3Brush.clear(this.node);
    }

    timeSlotScale.setTimeSlot(newTimeSlot);
  }

  private updateBrushExtent(xScale: SupportedXScale, yScale: SupportedYScale): void {
    const yRange = yScale.range();
    const xRange = xScale.range();

    this.d3Brush.extent([
      [xRange[0], yRange[0]],
      [xRange[1], yRange[1]]
    ]);
  }
}
