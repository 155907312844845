<ng-content></ng-content>

<ng-template #modalContent>
  <h1 mat-dialog-title>{{ dialogTitle }}</h1>
  <mat-dialog-content [ngClass]="{ dialogContentFullWidth: dialogContentFullWidth }">
    <ng-container *ngTemplateOutlet="dialogContent"></ng-container>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button *ngIf="!hideDialogCancel" [mat-dialog-close]="undefined" mat-stroked-button type="button">
      <ph-flex-vertical-center>
        <mat-icon [svgIcon]="dialogCancelIcon" *ngIf="dialogCancelIcon"></mat-icon>
        <span>
          <ng-container *ngIf="dialogCancelLabel === 'Cancel'" i18n="@@dialogModal-dialogCancelLabel"> Cancel</ng-container>
          <ng-container *ngIf="dialogCancelLabel !== 'Cancel'">
            {{ dialogCancelLabel }}
          </ng-container>
        </span>
      </ph-flex-vertical-center>
    </button>
    <ph-flex-save-button [pending]="pending" [error]="error" (clickChanged)="handleConfirmButtonClick()">
      <ph-flex-vertical-center>
        <mat-icon [svgIcon]="dialogActionIcon" *ngIf="dialogActionIcon"></mat-icon>
        <span *ngIf="dialogActionLabel; else defaultDialogActionLabel">
          {{ dialogActionLabel }}
        </span>
      </ph-flex-vertical-center>
    </ph-flex-save-button>
  </mat-dialog-actions>
</ng-template>

<ng-template #defaultDialogActionLabel>
  <ng-container i18n="@@dialogModal-defaultDialogActionLabel">Ok</ng-container>
</ng-template>
