import { APP_INITIALIZER, Injector, Provider, StaticProvider } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { first, map, timeout } from 'rxjs/operators';
import { ConstructorType } from './constructor-type.mixin';

export let AppInjector: Injector;
export const AppInjector$ = new ReplaySubject<Injector>(1);

export function setAppInjector(injector: Injector): void {
  if (!injector) {
    return;
  }
  AppInjector = injector;
  AppInjector$.next(injector);
}

export function getFromInjector<T extends ConstructorType<any>>(type: T): Observable<InstanceType<T>> {
  return AppInjector$.pipe(
    map((injector) => injector.get(type, null)),
    timeout(5000),
    first()
  );
}

export function setAppInjectorFactory(injector: Injector): any {
  setAppInjector(injector);
  return () => {};
}

/***
 * Use this const to provide and AppInjector to your unit tests. For example, see below.
 * Note that the *order* of the providers matters. Best to place InjectorTestProvider at the top.
 *
 *  Example:
 *
 *    TestBed.configureTestingModule({
 *        imports: [NgxsModule.forRoot([IntraDayState])],
 *        providers: [
 *          appInjectorProvider, // must be first in the list of providers
 *          { provide: IntraDayService, useValue: intraDayService},
 *          { provide: Router, useValue: router}
 *        ]
 *      })
 *      .compileComponents()
 *      .then();
 */
export const appInjectorProvider = {
  provide: APP_INITIALIZER,
  multi: true,
  deps: [Injector],
  useFactory: setAppInjectorFactory
};

/**
 * Can be used in tests to init the app injector without a testing module
 */
export function appInjectorBeforeEach(providers: StaticProvider[]): () => void {
  return () => {
    const newInjector = Injector.create({
      providers
    });
    setAppInjector(newInjector);
  };
}
