import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatCalendar, MatCalendarView } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { OnDestroyMixin } from '../../../core/common/on-destroy.mixin';
import { MixinBase } from '../../../core/common/constructor-type.mixin';
import { takeUntil } from 'rxjs/operators';
import { AVAILABLE_PERIOD_UNITS, PeriodUnit } from '../default-period';

enum ACTIVE_DATE_DIRECTION {
  FORWARD,
  BACKWARD
}

@Component({
  selector: 'ph-flex-date-range-fixed-period-date-picker-header',
  templateUrl: './date-range-fixed-period-calendar-header.component.html',
  styleUrls: ['./date-range-fixed-period-calendar-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateRangeFixedPeriodCalendarHeaderComponent<D> extends OnDestroyMixin(MixinBase) implements OnInit {
  view: MatCalendarView;

  constructor(
    private calendar: MatCalendar<D>,
    private dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private dateFormats: MatDateFormats,
    private cdr: ChangeDetectorRef,
    @Inject(AVAILABLE_PERIOD_UNITS) private availablePeriodUnits: PeriodUnit[]
  ) {
    super();

    // make sure the header stays in sync with the calendar
    calendar.stateChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => cdr.markForCheck());
  }

  get periodType(): string {
    const onlyYear = this.availablePeriodUnits?.length === 1 && this.availablePeriodUnits[0] === PeriodUnit.YEAR;
    return onlyYear ? 'text' : 'button';
  }

  get periodLabel(): string {
    return this.dateAdapter.format(this.calendar.activeDate, this.dateFormats.display.monthYearLabel).toLocaleUpperCase();
  }

  ngOnInit(): void {
    this.view = this.calendar.currentView;

    this.calendar.viewChanged.pipe(takeUntil(this.onDestroy$)).subscribe((view) => (this.view = view));
  }

  /** Handles user clicks on the period label. */
  periodClicked(): void {
    let currentView: MatCalendarView;

    if (this.calendar.currentView === 'year' && this.availablePeriodUnits.includes(PeriodUnit.DAY)) {
      currentView = 'month';
    } else if (this.calendar.currentView === 'multi-year' && this.availablePeriodUnits.includes(PeriodUnit.MONTH)) {
      currentView = 'year';
    } else {
      currentView = 'multi-year';
    }

    this.calendar.currentView = currentView;
  }

  previousClicked(): void {
    this.setActiveDate(ACTIVE_DATE_DIRECTION.BACKWARD);
  }

  nextClicked(): void {
    this.setActiveDate(ACTIVE_DATE_DIRECTION.FORWARD);
  }

  private setActiveDate(direction: ACTIVE_DATE_DIRECTION): void {
    let amount;

    if (direction === ACTIVE_DATE_DIRECTION.FORWARD) {
      amount = { days: 1, months: 1, years: 4 * 6 };
    }
    if (direction === ACTIVE_DATE_DIRECTION.BACKWARD) {
      amount = { days: -1, months: -1, years: -(4 * 6) };
    }

    switch (this.view) {
      case 'month':
        this.calendar.activeDate = this.dateAdapter.addCalendarMonths(this.calendar.activeDate, amount.days);
        break;
      case 'year':
        this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, amount.months);
        break;
      case 'multi-year':
        this.calendar.activeDate = this.dateAdapter.addCalendarYears(this.calendar.activeDate, amount.years);
        break;
    }
  }
}
