import { D3GraphSubmodule } from '../d3-graph-submodule';
import { BaseType, ScaleTime, select, Selection } from 'd3';
import { pointer } from 'd3-selection';
import { animationFrameScheduler, BehaviorSubject, combineLatest, Observable, Subject, tap } from 'rxjs';
import { ScaleBand, ScaleContinuousNumeric } from 'd3-scale';
import { shareReplay, subscribeOn } from 'rxjs/operators';
import { MixinBase } from 'projects/flex-app-shared/src/lib/core/common/constructor-type.mixin';
import { DynamicDataHelper, Margins, PortalDatum, PortalSubModuleMixin, SubjectProvider } from '../../common';
import { ApplicationRef, ComponentFactoryResolver, EmbeddedViewRef } from '@angular/core';
import { DomPortalOutlet, Portal, TemplatePortal } from '@angular/cdk/portal';
import { AppInjector } from '../../../../core/common/app-injector';
import { default as theme } from 'projects/flex-app-shared/design-tokens/theme.json';
import { format } from 'date-fns';

interface CursorSeriesDatum {
  color: string;
  label: string;
  value: string;
}

export interface CursorDatum extends PortalDatum<CursorDatum> {
  x: number;
  y: number;
  series: CursorSeriesDatum[];
  containerMargins: Margins;
  contentMargins: Margins;
  containerWidth: number;
  containerHeight: number;
  contentHeight: number;
  contentWidth: number;
  lineYOffset: number;
  xLocationValue: string;

  /**
   * Portal Outlet after it has been created
   */
  portalOutlet?: DomPortalOutlet;

  portal: TemplatePortal;
}

class Config {
  containerMargins: Margins = new Margins();
  contentMargins: Margins = new Margins();
  lineYOffset: number = 0;
  contentWidth: number = 400; // How wide the content should be
  contentHeight: number = 100;
  contentBaseHeight: number; // Height without rows
  contentHeightPerRow: number; // Amount of px to add for each series datum
}

type SupportedXScale<DomainType> = ScaleTime<number, DomainType> | ScaleContinuousNumeric<number, DomainType>;
type SupportedYScale<DomainType> = ScaleBand<any> | ScaleContinuousNumeric<number, DomainType>;

/**
 * Show a vertical line and an overlay when the user hovers over the graph.
 * Supports ScaleTime as xScale (due to formatting requirements), any scale as yScale
 *
 * The y scale value is calculated by using TODO the inverse of the yScale and formattin ghte value by calling the appropriate formatter.
 * Multiple x scale values are shown according to some data input and some way to get the domain value of a series from the x position?
 */
export class D3GraphCursor extends PortalSubModuleMixin(MixinBase) implements D3GraphSubmodule {
  private configProvider = new SubjectProvider<Config>(this, new BehaviorSubject(new Config()));

  /**
   * Combined mousemove, mouseenter and mouseleave events
   */
  private mouseEventSubject = new Subject<MouseEvent>();

  private xScaleProvider = new SubjectProvider<SupportedXScale<any>>(this);
  private yScaleProvider = new SubjectProvider<SupportedYScale<any>>(this);

  /**
   * The overlay node is used to register mouseevents. Can be mainSvg, but if zoom is used, the overlay that is used there should be used here.
   */
  private overlayNodeProvider = new SubjectProvider<Selection<any, any, any, any>>(this);

  /**
   * TemplatePortal to use in the tooltip
   */
  private portalProvider = new SubjectProvider<Portal<any>>(this);

  private cursorHelper: CursorHelper;

  setYScale$(yScale$: Observable<SupportedYScale<any>>): void {
    this.yScaleProvider.follow(yScale$);
  }

  setXScale$(yScale$: Observable<SupportedXScale<any>>): void {
    this.xScaleProvider.follow(yScale$);
  }

  setPortal(portal: Portal<any>): void {
    this.portalProvider.next(portal);
  }

  setConfig(config: Partial<Config>): void {
    this.configProvider.next({
      ...this.configProvider.value,
      ...config
    });
  }

  setOverlay$(node$: Observable<Selection<any, any, any, any>>): void {
    this.overlayNodeProvider.follow(node$);
  }

  setOverlay(node: Selection<any, any, any, any>): void {
    this.overlayNodeProvider.next(node);
  }

  attach(mainSvg: Selection<BaseType, any, any, any>): () => any {
    const cursorNode = mainSvg.append('g').attr('class', 'cursors');

    this.cursorHelper = new CursorHelper(cursorNode);

    this.overlayNodeProvider.value$.subscribe((selection) => {
      selection.on('mousemove.cursor mouseenter.cursor mouseleave.cursor mousedown.cursor mouseup.cursor brushEndMouseUp.cursor', (event) =>
        this.mouseEventSubject.next(event)
      );
    });

    const subscription = combineLatest([
      this.mouseEventSubject,
      this.configProvider.value$,
      this.xScaleProvider.value$,
      this.yScaleProvider.value$,
      this.cfrProvider.value$,
      this.appRefProvider.value$,
      this.portalProvider.value$,
      this.overlayNodeProvider.value$
    ])
      .pipe(subscribeOn(animationFrameScheduler))
      .subscribe(
        ([mouseEvent, config, xScale, yScale, cfr, appRef, portal, overlayNode]: [
          MouseEvent,
          Config,
          SupportedXScale<any>,
          SupportedYScale<any>,
          ComponentFactoryResolver,
          ApplicationRef,
          TemplatePortal,
          Selection<any, any, any, any>
        ]) => {
          // Use d3-select pointer to map the mouseEvent coordinates to the local coordinates of the overlayNode for positioning
          const pointerResult = pointer(mouseEvent, overlayNode.node());
          const yScaleRange = yScale.range();
          const xScaleRange = xScale.range();

          // Check bounds
          if (
            pointerResult[0] < Math.min(...xScaleRange) ||
            pointerResult[0] > Math.max(...xScaleRange) ||
            pointerResult[1] < Math.min(...yScaleRange) ||
            pointerResult[1] > Math.max(...yScaleRange)
          ) {
            this.cursorHelper.setData([]);
            return;
          }

          const scaleYOffset = Math.min(...yScaleRange); // The top offset of the graph as determined by the y scale range

          switch (mouseEvent.type) {
            case 'mouseenter':
            case 'mousemove':
            case 'mouseup':
            case 'brushEndMouseUp':
              const oldDataArray = this.cursorHelper.getData();
              const oldData = oldDataArray && oldDataArray.length > 0 ? oldDataArray[0] : {};

              this.cursorHelper.setData([
                {
                  ...oldData,
                  x: pointerResult[0],
                  y: pointerResult[1],
                  series: [],
                  containerMargins: {
                    ...config.containerMargins,
                    top: Math.min(yScaleRange[0], yScaleRange[1]) // Container margin used for y axis offset compared to the svg.
                  },
                  contentMargins: config.contentMargins,
                  containerHeight: yScaleRange[1] - yScaleRange[0],
                  containerWidth: xScaleRange[1] - xScaleRange[0],
                  contentHeight: config.contentHeight,
                  contentWidth: config.contentWidth,
                  lineYOffset: config.lineYOffset + scaleYOffset,
                  xLocationValue: format(xScale.invert(pointerResult[0]), 'HH:mm'),
                  cfr,
                  appRef,
                  portal
                }
              ]);
              break;
            case 'mousedown':
            case 'mouseleave':
            default:
              this.cursorHelper.setData([]);
          }
        }
      );

    return () => subscription.unsubscribe();
  }
}

export class CursorHelper extends DynamicDataHelper<CursorDatum> {
  protected class = 'cursor';
  protected nodeName = 'g';

  /* eslint-disable no-invalid-this */
  protected setDynamic(selectAllWithData: Selection<BaseType, CursorDatum, BaseType, any>): void {
    const that = this;
    selectAllWithData.each(function (datum: CursorDatum): void {
      const currentTime = select(this).select('.current-time');
      const line = select(this).select('line');
      const foreignObject = select(this).select('foreignObject');
      const text = select(this).select('text');

      foreignObject.attr('width', datum.contentWidth).attr('height', datum.contentHeight);

      const position = getContentPosition(
        {
          width: datum.containerWidth,
          height: datum.containerHeight,
          margins: datum.containerMargins
        },
        {
          margins: datum.contentMargins,
          height: datum.contentHeight,
          width: datum.contentWidth
        },
        datum
      );

      if (datum.datumSubject) {
        datum.datumSubject.next(datum);
      }

      if (!datum.portalOutlet) {
        const targetNode = select(this).select('.replace-me');

        that.initPortal(targetNode.node() as HTMLElement, datum);
      }

      foreignObject.attr('x', position.x).attr('y', position.y);

      line
        .attr('class', 'time-cursor-line')
        .attr('x1', datum.x)
        .attr('x2', datum.x)
        .attr('y1', datum.lineYOffset)
        .attr('y2', datum.containerHeight + datum.lineYOffset);

      currentTime
        .attr('class', 'current-time')
        .attr('x', datum.x - 25)
        .attr('y', datum.containerHeight + datum.lineYOffset + 10)
        .attr('width', 50)
        .attr('height', 30)
        .attr('fill', 'white');

      text
        .attr('class', 'time-value')
        .attr('x', datum.x)
        .attr('y', datum.containerHeight + datum.lineYOffset + 30)
        .attr('text-anchor', 'middle')
        .attr('font-weight', 'bold')
        .text(datum.xLocationValue);
    });
  }

  protected setStatic(selectAllWithData: Selection<BaseType, CursorDatum, BaseType, any>): void {
    const tooltip = selectAllWithData.append('g').attr('class', 'tooltip').attr('pointer-events', 'none');

    tooltip
      .append('foreignObject')
      .attr('width', '100')
      .attr('height', '100')
      .append('xhtml:div')
      .append('div')
      .attr('class', 'replace-me');

    selectAllWithData
      .append('line')
      .attr('pointer-events', 'none')
      .style('stroke', theme.color.brand.primary)
      .style('stroke-opacity', '.5')
      .style('stroke-width', 1.5)
      .attr('shape-rendering', 'crispEdges');

    selectAllWithData.append('rect');

    selectAllWithData.append('text');
  }

  protected exit(selectAllWithData: Selection<BaseType, CursorDatum, BaseType, any>): void {
    selectAllWithData
      .exit()
      .each((datum: CursorDatum) => {
        if (datum.portal?.isAttached) {
          datum.portal.detach();
        }
        if (!datum.datumSubject?.closed) {
          datum.datumSubject.complete();
        }
      })
      .remove();
  }

  private initPortal(targetNode: HTMLElement, datum: CursorDatum): void {
    datum.datumSubject = new BehaviorSubject<CursorDatum>(datum);

    // Set portal outlet
    datum.portalOutlet = new DomPortalOutlet(targetNode, datum.cfr, datum.appRef, AppInjector);

    let embeddedViewRef: EmbeddedViewRef<any>;
    embeddedViewRef = datum.portal.attach(datum.portalOutlet, {
      $implicit: datum.datumSubject.asObservable().pipe(
        tap(() => embeddedViewRef?.detectChanges()),
        shareReplay(1)
      )
    });
  }
}

/**
 * Position the cursor preferentially to the right, centered on the cursor.
 * y position will remain constrained within the container.
 * if conflicts arise due to x conflict, the position will flip from right to left.
 *
 * Container margins define the area which can be used for displaying the overlay.
 * Container margins can be negative.
 *
 * Content margins define the minimum spacing around the shown content between the edge of the container or the vertical line indicating cursor position.
 */
export function getContentPosition(
  container: { width: number; height: number; margins: Margins },
  content: { width: number; height: number; margins: Margins },
  cursor: { x: number; y: number }
): { x: number; y: number } {
  const effectiveContentWidth = content.width + content.margins.left + content.margins.right;

  // Determine min/max Y constraints
  const minYPosition = container.margins.top + content.margins.top;
  const maxYPosition = container.height - container.margins.bottom - content.height - content.margins.bottom + container.margins.top;

  // Determine x flip condition
  const flipLowerThanX = effectiveContentWidth + container.margins.left;

  // Calculate the center Y position to position the content around
  const anchorY = cursor.y - content.height / 2;
  const calculatedY = Math.max(minYPosition, Math.min(maxYPosition, anchorY));

  if (cursor.x < flipLowerThanX) {
    // Position right, vertically centered
    return {
      x: cursor.x + content.margins.left,
      y: calculatedY
    };
  }

  // Position left, vertically centered
  return {
    x: cursor.x - content.margins.right - content.width,
    y: calculatedY
  };
}
