import { ModuleWithProviders, NgModule } from '@angular/core';
import { Store } from '@ngxs/store';
import { AsyncMessagingService } from './async-messaging.service';
import { isNil } from 'lodash-es';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { ConnectionStatusComponent } from './connection-status/connection-status.component';
import { AsyncMessageReceivedEvent, AsyncStatusChangedEvent } from './async-messaging.actions';
import { FlexWebsocketService } from './websocket/websocket.service';
import { FlexCoreModule } from '../core.module';

@NgModule({
  declarations: [ConnectionStatusComponent],
  exports: [ConnectionStatusComponent],
  imports: [CommonModule, MatIconModule, FlexCoreModule]
})
export class FlexAsyncMessagingModule {
  constructor(private asyncMessagingService: AsyncMessagingService, private store: Store) {
    asyncMessagingService.onMessage$.subscribe((message) => {
      this.store.dispatch(new AsyncMessageReceivedEvent(message));
    });

    let lastIsConnectedState: boolean;
    asyncMessagingService.isConnected$.subscribe((isConnected) => {
      if (isNil(lastIsConnectedState) || lastIsConnectedState !== isConnected) {
        this.store.dispatch(new AsyncStatusChangedEvent(isConnected));
        lastIsConnectedState = isConnected;
      }
      // Do not emit the same value twice
    });
  }

  static forRootWebsocket(): ModuleWithProviders<FlexAsyncMessagingModule> {
    return {
      ngModule: FlexAsyncMessagingModule,
      providers: [
        FlexWebsocketService,
        {
          provide: AsyncMessagingService,
          useExisting: FlexWebsocketService
        }
      ]
    };
  }
}
