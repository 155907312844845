import { AfterViewInit, Component, forwardRef, Host, Input, OnInit, Optional } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CalendarPeriod } from '../../core/date-time/calendar-period';
import { SubscriptSizing } from '@angular/material/form-field';

@Component({
  selector: 'ph-flex-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeComponent),
      multi: true
    }
  ]
})
export class DateRangeComponent implements AfterViewInit, ControlValueAccessor {
  @Input()
  limit?: CalendarPeriod = { startDate: '', endDate: '' };

  @Input() placeholder: string = 'Period';
  isInitialized = false;

  @Input() subscriptSizing: SubscriptSizing = 'fixed';

  range = this.fb.group({
    startDate: ['', [Validators.required]],
    endDate: ['', [Validators.required]]
  });

  data$: Observable<any>;
  onChange: any;
  onTouch: any;

  constructor(
    @Optional() @Host() public formControl: UntypedFormControl,
    public fb: UntypedFormBuilder
  ) {}

  ngAfterViewInit(): void {
    this.range.valueChanges.pipe(debounceTime(200)).subscribe((event) => {
      if (event.startDate && event.endDate && this.onChange) {
        this.onChange(CalendarPeriod.toCalendarPeriod(event.startDate, event.endDate));
      }
    });
  }

  dateMonthSelected(path: string, date: string): void {
    this.range.get(path).setValue(date);
    this.range.get(path).markAsDirty();
    this.range.get(path).markAsTouched();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    const action = isDisabled ? 'disable' : 'enable';
    this.range.get('startDate')[action]();
    this.range.get('endDate')[action]();
  }

  /*
   * When a change happens on the outside of this component
   */
  writeValue(period: CalendarPeriod): void {
    this.range.get('startDate').setValue(period?.startDate);
    this.range.get('endDate').setValue(period?.endDate);
  }
}
