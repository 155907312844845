import { CollectionViewer } from '@angular/cdk/collections';
import { DataSource } from '@angular/cdk/table';
import { TreeControl } from '@angular/cdk/tree';
import { AbstractControl } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSelect } from '@angular/material/select';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatSort, SortDirection } from '@angular/material/sort';
import { Sort } from '@angular/material/sort';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, merge, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { auditTime, delay, distinctUntilChanged, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { MixinBase } from '../../core/common/constructor-type.mixin';
import { DestroyableMixin, OnDestroyMixin, OnDestroyProvider } from '../../core/common/on-destroy.mixin';
import { PaginatedResponse } from '../../core/common/paginated-response';
import { TableFilterComponent } from '../table-filter/table-filter.component';
import { NgZone } from '@angular/core';

export abstract class DataSourceFilterProvider<T, ChangedResult> extends DestroyableMixin(MixinBase) {
  item: T;
  protected changedSubscription: Subscription;
  protected onChangesSubject = new Subject<ChangedResult>();
  onChanges$ = this.onChangesSubject.asObservable();

  constructor(onDestroyProvider: OnDestroyProvider) {
    super();
    this.registerOnDestroyProvider(onDestroyProvider);
  }

  // TODO add onInit for subject/observable creation to prevent memory leaks

  destroy(): void {
    this.onChangesSubject.complete();
    this.item = undefined;
  }

  register(item: T): void {
    if (this.changedSubscription) {
      this.changedSubscription.unsubscribe();
    }
    this.item = item;

    this.changedSubscription = this.getChangedObservable(item).subscribe((result) => this.onChangesSubject.next(result));
  }

  protected abstract getChangedObservable(item: T): Observable<ChangedResult>;
}

export class ControlFilterProvider<T> extends DataSourceFilterProvider<AbstractControl, T> {
  protected getChangedObservable(item: AbstractControl): Observable<T> {
    return item.valueChanges.pipe(distinctUntilChanged(isEqual));
  }
}

export class TreeControlFilterProvider<T> extends DataSourceFilterProvider<TreeControl<T>, T[]> {
  protected dataNodesSubscription: Subscription;

  registerDataNodesObservable(observable: Observable<T[]>): void {
    this.dataNodesSubscription?.unsubscribe();

    this.dataNodesSubscription = observable.subscribe((result) => {
      this.item.dataNodes = result;
    });
  }

  destroy(): void {
    super.destroy();
    this.dataNodesSubscription?.unsubscribe();
  }

  protected getChangedObservable(item: TreeControl<T>): Observable<T[]> {
    return item.expansionModel.changed.pipe(map((change) => change.source.selected));
  }
}

export class SelectFilterProvider<T> extends DataSourceFilterProvider<MatSelect, any> {
  protected getChangedObservable(item: MatSelect): Observable<T> {
    return item.valueChange;
  }
}

export class MatSlideToggleFilterProvider extends DataSourceFilterProvider<MatSlideToggle, boolean> {
  protected getChangedObservable(item: MatSlideToggle): Observable<boolean> {
    return item.change.pipe(map((result) => result.checked));
  }
}

export class PaginatorProvider extends DataSourceFilterProvider<MatPaginator, PageEvent> {
  protected getChangedObservable(item: MatPaginator): Observable<PageEvent> {
    return item.page;
  }
}

export class TableFilterProvider extends DataSourceFilterProvider<TableFilterComponent, string | undefined> {
  protected filterTermDebounceDelay = 300;

  protected getChangedObservable(item: TableFilterComponent): Observable<string | undefined> {
    return item.filterChanged.pipe(switchMap((filterTerm) => of(filterTerm).pipe(delay(this.filterTermDebounceDelay))));
  }
}

export class SortProvider extends DataSourceFilterProvider<MatSort, Sort> {
  protected getChangedObservable(item: MatSort): Observable<Sort> {
    return item.sortChange;
  }
}

export abstract class PaginatedDataSource<T> extends OnDestroyMixin(MixinBase) implements DataSource<T> {
  static DEFAULT_PAGE_SIZE = 30;
  static DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 30, 50, 100];

  isLoading$: Observable<boolean>;

  protected isLoadingSubject: BehaviorSubject<boolean>;

  data: T[] = [];
  protected dataSubject = new ReplaySubject<T[]>(1);
  data$: Observable<T[]> = this.dataSubject.asObservable().pipe(tap((value) => (this.data = value)));
  protected tableFilterProvider = new TableFilterProvider(this);
  protected refreshDataObservables: Observable<any>[] = [this.tableFilterProvider.onChanges$];
  protected sortFilterProvider = new SortProvider(this);
  protected resetPaginatorObservables: Observable<any>[] = [this.tableFilterProvider.onChanges$, this.sortFilterProvider.onChanges$];
  private paginatorProvider = new PaginatorProvider(this);
  protected refreshDataAlwaysObservables: Observable<any>[] = [this.paginatorProvider.onChanges$, this.sortFilterProvider.onChanges$];
  private updateDataSubject = new ReplaySubject<void>(1);
  updateData$ = this.updateDataSubject.pipe(switchMap(() => of({}).pipe(delay(10))));

  private _updateDataWhenFilterChanges = true;

  get updateDataWhenFilterChanges(): boolean {
    return this._updateDataWhenFilterChanges;
  }

  set updateDataWhenFilterChanges(value: boolean) {
    this._updateDataWhenFilterChanges = value;
  }

  get paginator(): MatPaginator {
    return this.paginatorProvider.item;
  }

  set paginator(value: MatPaginator) {
    if (value.pageSizeOptions.length === 0) {
      // Set default page size options
      setTimeout(() => {
        value.pageSizeOptions = PaginatedDataSource.DEFAULT_PAGE_SIZE_OPTIONS;
        value.pageSize = PaginatedDataSource.DEFAULT_PAGE_SIZE;
      });
    }

    this.paginatorProvider.register(value);
  }

  get filter(): TableFilterComponent {
    return this.tableFilterProvider.item;
  }

  set filter(value: TableFilterComponent) {
    this.tableFilterProvider.register(value);
  }

  get sort(): MatSort {
    if (this.sortFilterProvider) {
      return this.sortFilterProvider.item;
    }
  }

  set sort(value: MatSort) {
    if (this.sortFilterProvider) {
      this.sortFilterProvider.register(value);
    }
  }

  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    this.internalLoadData();

    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    // Add an 100ms intrinsic delay to isLoading changes.
    // Prevents changed after checked issues as well as provide some user feedback on changes
    this.isLoading$ = this.isLoadingSubject.asObservable().pipe(auditTime(100));

    // Reset paginator
    merge(...this.resetPaginatorObservables)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        if (this.paginator) {
          this.paginator.pageIndex = 0;
        }
      });

    // Load data
    merge(...this.refreshDataAlwaysObservables)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.internalLoadData(true));

    merge(...this.refreshDataObservables)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => this.internalLoadData());

    this.updateData$.subscribe(() => {
      this.loadData(this.paginator?.pageIndex, this.paginator?.pageSize, this.filter?.filterTerm, this.getSortString());
    });

    return this.data$;
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.ngOnDestroy();

    // Disconnect can be called before/without connect, so the isLoadingSubject may not be defined
    if (this.isLoadingSubject) {
      this.isLoadingSubject.complete();
    }

    this.dataSubject.complete();
    this.updateDataSubject.complete();
  }

  abstract loadData(pageIndex?: number, pageSize?: number, searchTerm?: string, sort?: string): void;

  doLoadData(): void {
    if (!this._updateDataWhenFilterChanges) {
      this.updateDataSubject.next();
    }
  }

  protected internalLoadData(forceLoading: boolean = false): void {
    if (this.updateDataWhenFilterChanges || forceLoading) {
      this.updateDataSubject.next();
    }
  }

  protected updatePaginatorFromPaginatedResponse(result: PaginatedResponse<T>): void {
    if (!this.paginator || !result.pageable) {
      return;
    }

    this.updatePaginator(result.pageable.pageNumber, result.pageable.pageSize, result.totalElements);
  }

  protected updatePaginator(pageIndex: number, pageSize: number, length: number): void {
    if (this.paginator) {
      this.paginator.length = length || 0;
      this.paginator.pageIndex = pageIndex || 0;
      this.paginator.pageSize = pageSize || PaginatedDataSource.DEFAULT_PAGE_SIZE;
    }
  }

  protected getSortString(): string {
    return this.sort && this.buildSortString(this.sort.active, this.sort.direction);
  }

  protected internalAlwaysLoadData(): void {
    this.updateDataSubject.next();
  }

  private buildSortString(property: string, direction: SortDirection): string {
    return property && direction ? property + ' ' + direction : '';
  }
}
