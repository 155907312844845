import { Observable, share } from 'rxjs';
import { addDays, differenceInMilliseconds, startOfDay } from 'date-fns';

/**
 * Get an observable which will re-emit at defined intervals
 * @param delayFn Return the amount in ms before the next emit
 * @param minDelay Safety delay in case the delayFn returns 0 or a negative value.
 */
export function temporalChange(delayFn: () => number, minDelay: number = 5000): Observable<void> {
  return new Observable<void>((sub) => {
    let timeout = null;

    (function push(): void {
      timeout = setTimeout(() => {
        sub.next();
        push();
      }, Math.max(delayFn(), minDelay));
    })();

    return () => {
      clearTimeout(timeout);
    };
  }).pipe(
    share() // The setTimeouts can be re-used over multiple subscriptions
  );
}

export const dayChange$ = temporalChange(() => differenceInMilliseconds(startOfDay(addDays(new Date(), 1)), new Date()));
