import {
  ApplicationRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  InjectionToken,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { cloneDeep, defaults, get, isEqual } from 'lodash-es';
import { ComponentPortal, DomPortalOutlet } from '@angular/cdk/portal';
import { BACKGROUND_COLOR, BackgroundColor } from '../ph-flex-input-theme.directive';
import { OnDestroyMixin } from '../../../core/common/on-destroy.mixin';
import { MixinBase } from '../../../core/common/constructor-type.mixin';
import { SubjectProvider } from '../../../d3-graph/d3-graph/common';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ObservableInputsMixin } from '../../../core/observable-inputs/observable-inputs';
import { BreakpointObserver } from '@angular/cdk/layout';
import theme from '../../../../../design-tokens/theme.json';

export const FILTER_FORM = new InjectionToken<UntypedFormGroup>('Form group to bind to the form');
type widthBreakPoints = 'xs' | 's' | 'm' | 'l' | 'xl';

@Component({
  selector: 'ph-flex-filter-wrapper',
  template: ``
})
export class FlexFilterWrapperComponent implements OnChanges {
  @Input() formComponent: any;
  @Input() formGroup: UntypedFormGroup;

  @Input() inDialog: boolean = false;

  domPortalOutlet: DomPortalOutlet;
  portal: ComponentPortal<any>;

  isAttached = false;

  constructor(
    @Optional() @Inject(BACKGROUND_COLOR) currentBgColor: BackgroundColor,
    private hostElement: ElementRef,
    private cfr: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private vcr: ViewContainerRef
  ) {
    this.domPortalOutlet = new DomPortalOutlet(hostElement.nativeElement, cfr, appRef, injector);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.formComponent && !this.isAttached) {
      this.isAttached = true;

      const dialogData = this.inDialog
        ? [
            {
              provide: MAT_DIALOG_DATA,
              useValue: true
            }
          ]
        : [];

      const injector = Injector.create({
        providers: [
          {
            provide: FILTER_FORM,
            useValue: this.formGroup
          },
          ...dialogData
        ],
        parent: this.injector
      });
      if (this.portal) {
        this.portal.detach();
      }
      this.portal = new ComponentPortal(this.formComponent, this.vcr, injector, this.cfr);
      this.domPortalOutlet.attach(this.portal);
    }
  }
}

@Component({
  selector: 'ph-flex-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FlexFiltersComponent extends ObservableInputsMixin(OnDestroyMixin(MixinBase)) implements OnInit, OnChanges {
  filtersProvider = new SubjectProvider<any>(this);
  filters$ = this.filtersProvider.value$;

  draftSelectedFilters = [];
  selectedFiltersSubjectProvider = new SubjectProvider<string[]>(this, new BehaviorSubject([]));
  selectedFilters$ = this.selectedFiltersSubjectProvider.value$;

  initialControls: any;
  hideFilterSettingsButton: boolean = false;
  hasFilters$: Observable<boolean> = this.filters$.pipe(map((value) => !!value?.length));

  @Input() filterForm: UntypedFormGroup;
  @Input() dialogOptionsTemplate?: TemplateRef<any>;
  @Input() formComponent: any;
  @Input() allowedNumberOfFilters = 3;

  /**
   * Default value is determined by the initial control value normally. Sometimes we want to be able to force a certain value as the default.
   * An example of this is the customerId, which can be set through localStorage, but should be reset when the filter is deselected using the dialog.
   */
  @Input() defaultValueOverrides: any;

  @Output() filterChanges = new EventEmitter<void>();
  @Output() selectedFiltersChanged = this.selectedFilters$;

  @HostBinding('class.collapsed')
  isCollapsed: boolean = false;

  /**
   * Will hide filter inputs and display a button to open the filters in a dialog when hit
   */
  @Input()
  breakPoint: widthBreakPoints = 'l';
  breakPoint$ = this.oi.observe(() => this.breakPoint);

  showFilterInputsBreakpointMatches$ = this.breakPoint$.pipe(
    map((breakPoint) => `(min-width: ${theme.layout.width[breakPoint] || theme.layout.width.l}px)`),
    switchMap((breakPointQuery) => this.breakPointObserver.observe(breakPointQuery)),
    map((state) => state.matches)
  );

  constructor(private breakPointObserver: BreakpointObserver) {
    super();
  }

  ngOnInit(): void {
    if (Object.keys(this.filterForm.controls).length <= this.allowedNumberOfFilters) {
      this.hideFilterSettingsButton = true;
    }

    this.initialControls = cloneDeep(this.filterForm.controls);

    this.showFilterInputsBreakpointMatches$.subscribe((showFilter) => (this.isCollapsed = !showFilter));

    // When the provided filter form changes, update the current filters based on the (enabled) controls that have a value
    this.selectedFiltersSubjectProvider.follow(
      this.filters$.pipe(
        map(() => {
          if (this.filterForm.enabled) {
            // If the form is disabled, .value will return all the values anyway. So we should return [] intead.
            return Object.keys(this.filterForm.value);
          }

          return [];
        })
      )
    );

    this.selectedFilters$.subscribe((selectedFilters) => {
      // Whenever a selected filters value is committed, update the draft selected filters
      this.draftSelectedFilters = selectedFilters;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
    if (changes.filterForm) {
      this.filtersProvider.follow(
        this.filterForm.valueChanges.pipe(
          map(() => Object.keys(this.filterForm.controls)),
          startWith(Object.keys(this.filterForm.controls)),
          distinctUntilChanged(isEqual)
        )
      );
    }
  }

  changeFilters(modalConfirmed: any): any {
    if (modalConfirmed) {
      Object.keys(this.filterForm.controls).forEach((controlName) => {
        if (this.draftSelectedFilters.includes(controlName)) {
          this.filterForm.controls[controlName].enable();
        } else {
          this.filterForm.controls[controlName].reset({
            value: this.getDefaultValue(controlName),
            disabled: true
          });
        }
      });

      this.selectedFiltersSubjectProvider.next(this.draftSelectedFilters);
    }
  }

  private getDefaultValue(controlName: string): any {
    return get(this.defaultValueOverrides, controlName) ?? get(this.filterForm.controls, controlName)?.value;
  }

  getDialogData(): any {
    return true;
  }

  selectedFiltersUpdated($event: string[]): any {
    this.draftSelectedFilters = $event;
  }
}
