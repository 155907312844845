import { ScaleBand, ScaleContinuousNumeric, ScaleTime } from 'd3-scale';
import { BaseType, Selection } from 'd3';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { DestroyableMixin, OnDestroyMixin, OnDestroyProvider } from 'projects/flex-app-shared/src/lib/core/common/on-destroy.mixin';
import { D3GraphSubmodule } from '../d3-graph-submodule';
import { MixinBase } from 'projects/flex-app-shared/src/lib/core/common/constructor-type.mixin';
import { SubjectProvider } from '../../common';
import { VerticalLinesHelper } from '../vertical-lines/d3-graph-scale-band-vertical-lines';
import { default as theme } from 'projects/flex-app-shared/design-tokens/theme.json';
import { RectHelper } from './rect-helper';

const color = theme.color;

type SupportedXScale = ScaleContinuousNumeric<any, any> | ScaleTime<number, number>;
type SupportedYScale = ScaleContinuousNumeric<any, any> | ScaleBand<any>;

export class D3GraphDateVerticalLineConfig {
  drawFutureOverlay = true;
  dateLineColour = color.brand.accent;
}

/**
 * Show the current time with a (blue) vertical line.
 * Show an opaque overlay over data in the future, if configured.
 * Show nothing on the graph if it is fully in the past.
 */
export class D3GraphDateVerticalLine extends DestroyableMixin(OnDestroyMixin(MixinBase)) implements D3GraphSubmodule {
  private configProvider = new SubjectProvider<D3GraphDateVerticalLineConfig>(
    this,
    new BehaviorSubject(new D3GraphDateVerticalLineConfig())
  );
  private xScaleProvider = new SubjectProvider<SupportedXScale>(this);
  private yScaleProvider = new SubjectProvider<SupportedYScale>(this);
  private dateProvider = new SubjectProvider<Date>(this);
  private verticalLinesHelper: VerticalLinesHelper;
  private rectHelper: RectHelper;

  constructor(onDestroyProvider: OnDestroyProvider) {
    super();

    this.registerOnDestroyProvider(onDestroyProvider);
  }

  destroy(): void {
    this.ngOnDestroy();
  }

  setXScale$(scale$: Observable<SupportedXScale>): void {
    this.xScaleProvider.follow(scale$);
  }

  setYScale$(scale$: Observable<SupportedYScale>): void {
    this.yScaleProvider.follow(scale$);
  }

  setDate$(date$: Observable<Date>): void {
    this.dateProvider.follow(date$);
  }

  patchConfig(config: Partial<D3GraphDateVerticalLineConfig>): void {
    this.configProvider.next({
      ...this.configProvider.value,
      ...config
    });
  }

  attach(mainSvg: Selection<BaseType, any, any, any>): () => any {
    const node = mainSvg.append('g').attr('class', 'current-time-vertical-line');

    // Create group nodes to preserve ordering
    const rectNode = node.append('g');
    const verticalLineNode = node.append('g');

    this.rectHelper = new RectHelper(rectNode);
    this.verticalLinesHelper = new VerticalLinesHelper(verticalLineNode);

    const subscription = combineLatest([
      this.xScaleProvider.value$,
      this.yScaleProvider.value$,
      this.dateProvider.value$,
      this.configProvider.value$
    ]).subscribe(([xScale, yScale, date, config]) => {
      const xPosition = xScale(date);
      const xRange = xScale.range();

      if (!date || (Math.min(...xRange) >= xPosition && Math.max(...xRange) <= xPosition)) {
        // Out of bounds
        this.verticalLinesHelper.setData([]);
      } else {
        this.verticalLinesHelper.setData([
          {
            xPosition,
            color: config.dateLineColour,
            yMax: Math.max(...yScale.range()),
            yMin: Math.min(...yScale.range()),
            strokeWidthPx: 1
          }
        ]);
      }

      if (!config.drawFutureOverlay) {
        // Only draw rect if it is enabled.
        this.rectHelper.setData([]);
        return;
      }

      if (Math.min(...xRange) > xPosition) {
        // Time in future
        this.rectHelper.setData([
          {
            y: Math.min(...yScale.range()),
            x: Math.min(...xScale.range()),
            height: Math.max(...yScale.range()) - Math.min(...yScale.range()),
            width: Math.max(...xScale.range()) - Math.min(...xScale.range())
          }
        ]);
      } else if (Math.max(...xRange) > xPosition) {
        // Partially in future
        this.rectHelper.setData([
          {
            y: Math.min(...yScale.range()),
            x: Math.min(xPosition),
            height: Math.max(...yScale.range()) - Math.min(...yScale.range()),
            width: Math.max(...xScale.range()) - xPosition
          }
        ]);
      } else {
        // In past
        this.rectHelper.setData([]);
      }
    });

    return () => subscription.unsubscribe();
  }
}
