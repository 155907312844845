export enum Level {
  ERROR,
  INFO
}

export class Message {
  message: string;
  level: Level;

  constructor(message: string, level: Level) {
    this.message = message;
    this.level = level;
  }
}
