import { AfterViewInit, Directive, ElementRef, Inject, InjectionToken, Optional, Renderer2 } from '@angular/core';

export type BackgroundColor = 'dark' | 'light';
export const BACKGROUND_COLOR = new InjectionToken<BackgroundColor>('Background color for e.g. Input styling');

@Directive({
  selector: '[phFlexInputTheme]'
})
export class PhFlexMatFormFieldOverrideDirective implements AfterViewInit {
  constructor(
    @Optional() @Inject(BACKGROUND_COLOR) private currentBgColor: BackgroundColor,
    private renderer: Renderer2,
    private hostElement: ElementRef
  ) {}

  ngAfterViewInit(): void {
    if (this.currentBgColor === 'dark') {
      this.renderer.addClass(this.hostElement.nativeElement, 'ph-flex-mat-form-field-on-dark-bg');
    }
  }
}

@Directive({
  selector: '[phFlexDark]',
  providers: [
    {
      provide: BACKGROUND_COLOR,
      useValue: 'dark'
    }
  ]
})
export class PhFlexDarkDirective {
  constructor() {}
}
