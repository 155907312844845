import { Control } from '../../../../api-private/control/control';

export class ResetControlsCommand {
  public static readonly type = '[Controls] Reset controls data';
}

export class LoadControlsCommand {
  public static readonly type = '[Controls] Load controls data';
}

export class ControlsLoadedEvent {
  public static readonly type = '[Controls] Controls data loaded';

  constructor(public payload: ReadonlyArray<Control>) {}
}
