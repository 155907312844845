import { Directive } from '@angular/core';
import { MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { MomentDisplayFormatters } from './moment-formatters';
import { DateFnsDisplayFormatters } from './date-fns-formatters';

export const MY_FORMATS = {
  parse: {
    dateInput: MomentDisplayFormatters.MONTH
  },
  display: {
    dateInput: MomentDisplayFormatters.MONTH,
    monthYearLabel: MomentDisplayFormatters.MONTH_NICE,
    dateA11yLabel: 'LL',
    monthYearA11yLabel: MomentDisplayFormatters.MONTH
  }
};

const dateFnsFormats: MatDateFormats = {
  parse: {
    dateInput: DateFnsDisplayFormatters.DAY
  },
  display: {
    dateInput: DateFnsDisplayFormatters.DAY,
    monthYearLabel: DateFnsDisplayFormatters.MONTH_NICE,
    dateA11yLabel: 'PP',
    monthYearA11yLabel: DateFnsDisplayFormatters.MONTH
  }
};

@Directive({
  selector: '[phFlexDateAsMonth]',
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class DateAsMonthDirective {}
