export class Money {
  amount: number; // Amount as number
  currency: string; // ISO 4217 currency code

  /**
   * Output the amount of the money object.
   * @throws Error if currency !== EUR
   */
  static toEUR(money: Money): number {
    if (money.currency !== 'EUR') {
      throw new Error('Currency not supported in toEUR');
    }
    return money?.amount;
  }

  static asEUR(value: number): Money {
    return {
      currency: 'EUR',
      amount: value
    };
  }
}
