import { Directive, Inject, Injectable, Input, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { routerParamDataSourceFactory } from '../../core/common/router-param-data-source.factory';
import { AUTOCOMPLETE_DATA_SOURCE } from '../../material/autocomplete/autocomplete.component';
import { LazyLoadDataSource } from '../../material/autocomplete/lazy-load-data-source';
import { GridPoint } from './grid-point';
import { GridPointService } from './grid-point.service';
import { sortBy, toLower } from 'lodash-es';
import { map } from 'rxjs/operators';

@Injectable()
export class GridPointDatasource extends LazyLoadDataSource<GridPoint> {
  dataSource$: Observable<GridPoint[]>;

  public clearSelectionWhenNoCustomerSelected: boolean = true;

  constructor(private gridPointService: GridPointService, @Optional() private router: Router) {
    super();

    if (this.router) {
      this.dataSource$ = routerParamDataSourceFactory(['customerId'], ([customerId]) => {
        if (customerId) {
          // scope by customerId
          return this.gridPointService.getByCustomerId(customerId);
        } else if (this.clearSelectionWhenNoCustomerSelected) {
          // clear drop down when not in the context of a customer (effectively disallowing the user to select a grid point if no customer has been selected first)
          return of([]);
        } else {
          return this.gridPointService.getAll();
        }
      });
    } else {
      if (this.clearSelectionWhenNoCustomerSelected) {
        // see comment above: disallow selection of a grid point without first selecting a customer.
        this.dataSource$ = of([]);
      } else {
        this.dataSource$ = this.gridPointService.getAll();
      }
    }

    this.dataSource$ = this.dataSource$.pipe(map((result) => sortBy(result, [(item) => toLower(item.description)])));
  }

  filter(entities: ReadonlyArray<GridPoint>, filterString: string): ReadonlyArray<GridPoint> {
    if (!filterString) {
      return entities;
    }
    return entities.filter(
      (entity) =>
        entity.description.toLowerCase().includes(filterString.toLowerCase()) ||
        entity.ean.toLowerCase().includes(filterString.toLowerCase())
    );
  }

  displayEntityWith(value: GridPoint): string {
    return `${value.description} - ${value.ean}`;
  }

  identifyWith(value: GridPoint): string {
    return value.id;
  }
}

@Directive({
  selector: '[phFlexGridPointDataSource]',
  providers: [
    {
      provide: AUTOCOMPLETE_DATA_SOURCE,
      useClass: GridPointDatasource
    }
  ]
})
export class GridPointDatasourceDirective implements OnInit {
  @Input('phFlexGridPointDataSource') config: { clearSelectionWhenNoCustomerSelected: boolean } = {
    clearSelectionWhenNoCustomerSelected: true
  };

  constructor(@Inject(AUTOCOMPLETE_DATA_SOURCE) private gridPointDatasource: GridPointDatasource) {}

  ngOnInit(): void {
    this.gridPointDatasource.clearSelectionWhenNoCustomerSelected = this.config.clearSelectionWhenNoCustomerSelected;
  }
}
