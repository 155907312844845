<div class="panel-backdrop">
  <mat-card class="no-permissions-panel mat-elevation-z15">
    <mat-card-content>
      <mat-icon svgIcon="ic-old:alert-warning"></mat-icon>
      <div [ngSwitch]="errorType" class="mat-body">
        <p *ngSwitchCase="'unauthorized'" i18n="@@errorComponent-unauthorized">
          You do not have permission to use this application. <br />Please contact your administrator.
        </p>
        <p *ngSwitchCase="'unavailable'" i18n="@@errorComponent-unavailable">
          The application is currently unavailable. Please try again later.
        </p>
        <p *ngSwitchDefault i18n="@@errorComponent-default">Something went wrong, please try again later.</p>
      </div>
      <button mat-flat-button color="primary" (click)="logout.emit()">Logout</button>
    </mat-card-content>
  </mat-card>
</div>
