/**
 * Use a factory to get localStorage instead of using localStorage directly. Otherwise this will not work in a minified/optimized production build.
 */

export class StorageFactory {
  static localStorageHasBeenChecked = false;
  static localStorageIsAvailable = false;

  static localStorageFactoryWithFallback(): Storage {
    if (StorageFactory.isLocalStorageAvailable()) {
      return StorageFactory.localStorageFactory();
    }
    return StorageFactory.sessionStorageFactory();
  }

  static sessionStorageFactory(): Storage {
    return sessionStorage;
  }

  /**
   * Protected to prevent usage from functional code, but to still allow it to be overridden by unit tests
   */
  protected static localStorageFactory(): Storage {
    return localStorage;
  }

  protected static determineLocalStorageAvailability(): void {
    const currentLocalStorage = StorageFactory.localStorageFactory();
    const testKey = 'testKey';

    try {
      currentLocalStorage.setItem(testKey, 'testValue');
    } catch (err: any) {
      // localStorage is not available, fall back to session storage
      StorageFactory.localStorageIsAvailable = false;
      return;
    }

    currentLocalStorage.removeItem(testKey);
    StorageFactory.localStorageIsAvailable = true;
    return;
  }

  protected static isLocalStorageAvailable(): boolean {
    if (!StorageFactory.localStorageHasBeenChecked) {
      StorageFactory.localStorageHasBeenChecked = true;
      StorageFactory.determineLocalStorageAvailability();
    }

    return StorageFactory.localStorageIsAvailable;
  }
}
