import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { OAuthErrorEvent, OAuthService, OAuthStorage } from 'angular-oauth2-oidc';
import { get } from 'lodash-es';
import moment from 'moment';
import { EMPTY, from, fromEvent, merge, throwError, timer } from 'rxjs';
import { catchError, filter, map, retry, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ConfigService } from '../../core/config/config.service';
import { LOGIN_REDIRECTS_STORAGE_KEY } from '../../core/storage-store/browser-storage';
import { AzureB2CErrorCodes } from './error-codes';
import { getExpiredTimeout, LoginRequiredComponent } from './login-required/login-required.component';
import { TokenService } from './token.service';
import { TokenWatchdogService } from './token-watchdog.service';
import { TokenHelper } from './utils/validate-token';
import { getRetryConfig } from '../../core/common/rxjs-utils';
import { LoginRedirectService } from '../landing-page/login-redirect.service';

export const REQUIRE_AUTHENTICATION = new InjectionToken<boolean>('REQUIRE_AUTHENTICATION');
export const CONFIG_APPLICATION_TYPE = new InjectionToken<string>('path variable to retrieve user preferences');

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  /**
   * True when the user  has requested to reset their password.
   * This will initialize the implicit flow with the reset password discovery document.
   * Do not take redirect count into consideration when this happens.
   */
  resetPassword = false;

  /**
   * True when the user has, for example, canceled their login and wants to try again.
   * Do not take redirect count into consideration when this happens.
   */
  resetImplicit = false;

  /**
   * User activate flow is active
   */
  activateUser = false;

  /**
   * User asked to change their password.
   * This will trigger the change password policy using the
   */
  changePassword = false;

  private tokenUpdatedByEvent$ = this.oauthService.events.pipe(
    filter((event) => {
      console.warn('got event: ', event.type);
      return ['token_refreshed', 'token_received'].includes(event.type);
    })
  );

  private tokenUpdatedByLocalStorage$ = fromEvent(window, 'storage').pipe(
    // Note that storage events do not get fired for the current tab, only from other tabs.
    filter((storageEvent: StorageEvent) => storageEvent.key === 'id_token')
  );

  constructor(
    private configService: ConfigService,
    private oauthService: OAuthService,
    private tokenService: TokenService,
    private tokenWatchdog: TokenWatchdogService,
    @Optional() @Inject(REQUIRE_AUTHENTICATION) private requireAuthentication: boolean = true,
    @Inject(OAuthStorage) private storage: Storage,
    private bottomSheet: MatBottomSheet,
    private loginRedirectService: LoginRedirectService
  ) {
    if (requireAuthentication) {
      this.activateUser = this.isActivateUrl();
      merge(this.tokenUpdatedByEvent$, this.tokenUpdatedByLocalStorage$)
        .pipe(map(() => this.oauthService.getIdToken()))
        .subscribe((token) => this.tokenService.updateToken(token));

      // Fallback refresh to get tokens if they are not available
      this.tokenService.tokensAvailable$
        .pipe(
          switchMap((tokensAvailable) => {
            if (tokensAvailable) {
              console.warn('Do not fetch tokens with refreshToken');
              return EMPTY;
            }

            console.warn('Start fetching tokens with refreshToken');
            return timer(1000).pipe(
              switchMap(() => from(this.oauthService.refreshToken())),
              retry(
                getRetryConfig({
                  maxRetryAttempts: Number.POSITIVE_INFINITY,
                  maxScalingDurationMultiplier: 12,
                  scalingDuration: 5000
                })
              )
            );
          })
        )
        .subscribe((result) => {
          console.warn('Got new token using the tokensAvailable$ fallback', result);
        });

      this.configureOAuthService();
    } else if (this.oauthService.getIdToken()) {
      // Logout because authorization is not required
      this.oauthService.logOut(true);
    }
  }

  userName$ = this.tokenService.name$;

  logout(): void {
    this.oauthService.logOut();
  }

  redirectOnError(errorEvent: OAuthErrorEvent): void {
    const errorCode = this.getErrorCode(errorEvent);
    if (errorCode) {
      if (errorCode === AzureB2CErrorCodes.CLAIM_EXCHANGE_HTTP_ERROR) {
        this.showError('Error: login could not be completed. Please try again later or contact support.');
        return;
      }
      if (errorCode === AzureB2CErrorCodes.ID_TOKEN_HINT_EXPIRED) {
        this.showError(
          'De "Activeer account"-link is verlopen. Je kunt een nieuwe aanvragen via Powerhouse Support. Sluit deze browser tab.'
        );
        return;
      }
    }

    const maxRedirectsCount = 2;
    const redirectCounter = this.getRedirectsCounter();

    if (this.resetPassword || this.resetImplicit) {
      // Whenever reset password is requested, or resetImplicit, we also reset the redirect counter
      this.setRedirectsCounter(0);
      this.oauthService.initLoginFlow();
    } else if (redirectCounter < maxRedirectsCount) {
      // Check if redirect counter is below max redirect, or if the password reset flow is initiated
      this.setRedirectsCounter(redirectCounter + 1);
      this.oauthService.initLoginFlow();
    } else {
      this.showError(`Error: Can't finish login procedure due to too many redirects`);
    }
  }

  getRedirectsCounter(): number {
    return Number(this.storage.getItem(LOGIN_REDIRECTS_STORAGE_KEY));
  }

  setRedirectsCounter(value: number): void {
    this.storage.setItem(LOGIN_REDIRECTS_STORAGE_KEY, String(value));
  }

  resetPasswordFlow(): void {
    this.resetPassword = true;
    this.oauthService.initImplicitFlow();
  }

  resetImplicitFlow(): void {
    this.resetImplicit = true;
    this.oauthService.initImplicitFlow();
  }

  changePasswordFlow(): void {
    this.changePassword = true;
    this.configureOAuthService();
  }

  private showError(message: string): void {
    const errorMessageDiv = document.createElement('div');
    errorMessageDiv.id = 'error-message';
    errorMessageDiv.innerHTML = message;
    errorMessageDiv.style.zIndex = '1000';
    errorMessageDiv.style.fontSize = '1.2em';

    document.getElementById('preloader').appendChild(errorMessageDiv);
  }

  private getErrorCode(errorEvent: OAuthErrorEvent): string | null {
    const errorDescription = get(errorEvent.params, 'error_description') as any;
    if (!errorDescription) {
      return null;
    }
    return errorDescription.split(':')[0];
  }

  private configureOAuthService(): void {
    this.configService.config$.subscribe((config) => {
      this.oauthService.configure({
        issuer: this.getIssuer(config),
        redirectUri: this.getCallbackUrl(),
        responseType: 'code',
        clientId: config.clientId,
        scope: config.oauthScope || 'openid profile email offline_access',
        strictDiscoveryDocumentValidation: false,
        logoutUrl: this.getLogoutUrl(config),
        skipIssuerCheck: true,
        timeoutFactor: 0.7 + Math.random() * 0.1, // Add timeout factor around 75% of lifetime. Should be random to facilitate multiple tabs, otherwise they overwrite nonces in localstorage
        clockSkewInSec: 60 * TokenHelper.DEFAULT_CLOCK_SKEW_MINUTES // 5 minutes
      });

      this.oauthService.loadDiscoveryDocument(this.getDiscoveryDocumentUrl(config)).then(
        () => {
          if (this.activateUser) {
            const activationToken = this.getActivationToken();
            this.oauthService.customQueryParams = {
              id_token_hint: activationToken
            };
          } else {
            this.oauthService.customQueryParams = {};
          }
          const currentToken = this.oauthService.getIdToken();
          if (
            !!currentToken &&
            this.oauthService.hasValidIdToken() &&
            this.doesTokenContainCorrectClaims() &&
            !this.shouldTokenBeRefreshed()
          ) {
            // Set the initial value to the current token if it is valid
            this.tokenService.updateToken(this.oauthService.getIdToken());
          }

          let loginResult;
          if (this.changePassword) {
            this.oauthService.initImplicitFlow(); // reset so the flow is actually executed, otherwise user change password screen is never shown
          }
          if (this.activateUser || this.changePassword) {
            loginResult = this.oauthService.tryLoginImplicitFlow();
          } else {
            loginResult = this.oauthService.tryLoginCodeFlow();
          }
          loginResult.then(() => {
            if (!this.doesTokenContainCorrectClaims()) {
              // If the token no longer matches our requirements, due to a config change for example, we should logout and get a new token
              this.oauthService.logOut(true);
            }
            if (!this.oauthService.hasValidIdToken() || this.shouldTokenBeRefreshed()) {
              this.oauthService.refreshToken().catch((error) => {
                console.warn('refresh token error in tryLogin main loop', error);
                if (error === 'Token has expired' || (error?.status >= 400 && error?.status < 600)) {
                  // Either a token has expired error, or a failed response when trying to refresh the token
                  this.oauthService.initCodeFlow(this.loginRedirectService.buildState());
                }
              });
              return;
            }

            if (this.oauthService.hasValidIdToken()) {
              this.setRedirectsCounter(0);
            }

            this.initRefreshLoginBottomSheet();
            this.oauthService.setupAutomaticSilentRefresh({}, 'id_token');
          }, this.redirectOnError.bind(this));
        },
        () => {
          console.warn('loading discovery document failed');
        }
      );
    });
  }

  private shouldTokenBeRefreshed(): boolean {
    // Check if the token has passed the expiry, if this is the case, also do a silent refresh to update the token
    const iat = moment.unix(get(this.oauthService.getIdentityClaims(), 'iat'));
    const exp = moment.unix(get(this.oauthService.getIdentityClaims(), 'exp'));

    // @ts-ignore
    const expiryMoment = iat.add(exp.diff(iat, 's') * this.oauthService.config.timeoutFactor, 's');

    return expiryMoment.isBefore(moment.now());
  }

  private initRefreshLoginBottomSheet(): void {
    let ref;

    const showBottomSheet = () => {
      if (ref) {
        return;
      }

      const expiresAt = get(this.oauthService.getIdentityClaims(), 'exp');
      const oldIdToken = this.oauthService.getIdToken();
      ref = this.bottomSheet.open(LoginRequiredComponent, {
        data: {
          expiresAt
        },
        disableClose: true
      });

      this.tokenService.parsedIdToken$
        .pipe(
          filter((newToken) => newToken?.originalToken !== oldIdToken),
          takeUntil(ref.afterDismissed())
        )
        .subscribe(() => ref?.dismiss());

      ref.afterDismissed().subscribe((result) => {
        if (result) {
          this.oauthService.initLoginFlow();
        } else {
          const expiredTimeout = getExpiredTimeout(expiresAt);
          if (expiredTimeout > 0) {
            // The token has not expired yet, do a recursive call when the token does eventually expire
            setTimeout(() => showBottomSheet(), expiredTimeout);
          }
        }
        ref = null;
      });
    };

    this.oauthService.events.pipe(filter((event) => event.type === 'token_refresh_error')).subscribe(() => {
      showBottomSheet();
    });
  }

  private doesTokenContainCorrectClaims(): boolean {
    // Existing id tokens are not verified again to check if the issuer or scope has changed, so we need to check ourselves
    const identityClaims = this.oauthService.getIdentityClaims() as any;
    if (!identityClaims) {
      return false;
    }
    const hasName = !!identityClaims.name;
    const sameIssuer = identityClaims.iss === this.oauthService.issuer;
    return sameIssuer && hasName;
  }

  private getIssuer(config: any): string {
    const issuer = config.domain.includes('//') ? `${config.domain}` : `https://${config.domain}`;
    if (issuer[issuer.length - 1] !== '/') {
      return issuer + '/';
    }
    return issuer;
  }

  private getDiscoveryDocumentUrl(config: any): string {
    let result = null;
    if (!config.oidcDiscoveryUrl) {
      result = `${this.getIssuer(config)}.well-known/openid-configuration`;
    } else if (this.resetPassword) {
      result = config.passwordResetUrl;
    } else if (this.activateUser) {
      result = config.activateUrl;
    } else if (this.changePassword) {
      result = config.changePasswordUrl;
    } else {
      result = config.oidcDiscoveryUrl;
    }
    return result;
  }

  private getLogoutUrl(config: any): string {
    return `${this.getIssuer(config)}v2/logout?client_id=${config.clientId}&returnTo=${this.getOrigin()}/logout`;
  }

  private isActivateUrl(): boolean {
    const result = document.location.pathname;
    const token = this.getActivationToken();
    return result.includes('/activate') && token != null;
  }

  private getActivationToken(): string {
    const searchParams = new URLSearchParams(document.location.search);
    return searchParams.get('token');
  }

  private getOrigin(): string {
    const result = document.baseURI || document.location.origin || document.location.href.split('/').slice(0, 3).join('/');

    if (result && result.length > 0 && result[result.length - 1] === '/') {
      return result.split('/').slice(0, -1).join('/');
    }
    return result;
  }

  private getCallbackUrl(): string {
    return `${this.getOrigin()}/callback`;
  }
}
