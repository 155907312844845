<h3 [ngSwitch]="expiryState">
  <ng-container *ngSwitchCase="ExpiryState.EXPIRED" i18n="@@loginRequiredBottomSheet-tokenExpiredHeading"
    >Your token has expired.</ng-container
  >
  <ng-container *ngSwitchCase="ExpiryState.WILL_EXPIRE" i18n="@@loginRequiredBottomSheet-tokenWillExpireHeading"
    >Your token will expire soon.</ng-container
  >
</h3>

<p [ngSwitch]="expiryState">
  <ng-container *ngSwitchCase="ExpiryState.EXPIRED" i18n="@@loginRequiredBottomSheet-tokenExpiredMessage"
    >No further changes can be made and no new data will be shown. You can still close this message and save any unsaved data you may have.
    Please refresh the page afterwards to get a new token.</ng-container
  >
  <ng-container *ngSwitchCase="ExpiryState.WILL_EXPIRE" i18n="@@loginRequiredBottomSheet-tokenWillExpireMessage"
    >You can close this message, but please save or note down any unsaved changes and refresh the page.</ng-container
  >
</p>
<div class="button-container">
  <button mat-stroked-button color="primary" (click)="close()"><ng-container i18n="@@cancelButton">Cancel</ng-container></button>
  <button mat-flat-button color="primary" (click)="reloadPage()">
    <ph-flex-vertical-center>
      <span i18n="@@loginRequiredBottomSheet-refreshButton">Refresh now</span>
    </ph-flex-vertical-center>
  </button>
</div>
