import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ph-flex-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent {
  @Input()
  errorType: 'error' | 'unauthorized' | 'unavailable';

  @Output()
  logout = new EventEmitter();
}
