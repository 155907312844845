import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { parseISO } from 'date-fns/fp';
import { distinctUntilChanged, map } from 'rxjs/operators';
import * as d3 from 'd3';
import { CountableTimeInterval, TimeInterval } from 'd3';
import { ScaleTime } from 'd3-scale';
import { isEqual } from 'lodash-es';
import { Margins, SubjectProvider } from '../common';
import { DestroyableMixin, OnDestroyMixin, OnDestroyProvider } from '../../../core/common/on-destroy.mixin';
import { MixinBase } from '../../../core/common/constructor-type.mixin';
import { TimeSlot } from '../../../core/date-time/time-slot';
import { D3GraphScaleProvider } from './d3-graph-scale-provider';
import { expectObservableValue } from '../../../core/common/expect-observable-value';
import { CalendarPeriod } from '../../../core/date-time/calendar-period';
import { differenceInHours } from 'date-fns';

interface Domain {
  start: Date;
  end: Date;
}

/**
 * Get a d3.scaleTime() instance with the domain and range set based on width, margins and a TimeSlot.
 */
export class TimeSlotScaleProvider
  extends DestroyableMixin(OnDestroyMixin(MixinBase))
  implements D3GraphScaleProvider<ScaleTime<number, number>, Date>
{
  private widthProvider = new SubjectProvider<number>(this);
  private marginsProvider = new SubjectProvider(this, new BehaviorSubject<Pick<Margins, 'left' | 'right'>>({ left: 0, right: 0 }));
  private domainProvider = new SubjectProvider<Domain>(this);

  /**
   * Last scale that was emitted by this.scale$
   */
  scale: ScaleTime<number, number>;

  /**
   * Output scale based on updated width, margins and domain.
   */
  scale$: Observable<ScaleTime<number, number>> = combineLatest([
    this.widthProvider.value$,
    this.marginsProvider.value$,
    this.domainProvider.value$
  ]).pipe(
    distinctUntilChanged(isEqual),
    map(([width, margins, domain]) => {
      const scaleRange = [margins.left, width - margins.right];
      const scaleDomain = [domain.start, domain.end] as unknown as [Date, Date];

      return d3.scaleTime().domain(scaleDomain).range(scaleRange);
    })
    // shareReplay(1)
  );

  constructor(onDestroyProvider: OnDestroyProvider) {
    super();

    expectObservableValue(this.widthProvider.value$, 'Width was not set in GraphTimeSlotScaleProvider');
    expectObservableValue(this.domainProvider.value$, 'Domain was not set in GraphTimeSlotScaleProvider');
    expectObservableValue(this.scale$, 'Scale did not output a value in GraphTimeSlotScaleProvider');

    this.registerOnDestroyProvider(onDestroyProvider);

    this.scale$.subscribe((scale) => (this.scale = scale));
  }

  ticks$: Observable<Date[]> = this.scale$.pipe(map((scale) => scale.ticks(7)));

  setWidth(width: number): void {
    this.widthProvider.next(width);
  }

  setMargin(margin: Partial<Pick<Margins, 'left' | 'right'>>): void {
    this.marginsProvider.next({
      ...this.marginsProvider.value,
      ...margin
    });
  }

  /**
   * Set the domain parameters based on the provided TimeSlot.
   */
  setTimeSlot(timeSlot: TimeSlot): void {
    this.domainProvider.next({
      start: parseISO(timeSlot.startDateTime),
      end: parseISO(timeSlot.toDateTime)
    });
  }

  setTimeSlot$(timeSlot$: Observable<TimeSlot>): void {
    this.domainProvider.follow(
      timeSlot$.pipe(
        map((timeSlot) => ({
          start: parseISO(timeSlot.startDateTime),
          end: parseISO(timeSlot.toDateTime)
        }))
      )
    );
  }

  destroy(): void {
    this.ngOnDestroy();
  }

  /**
   * Convert a value in px to a Date based on the last Scale.
   * Returns null if no scale is available.
   */
  pxToDate(px: number): Date | null {
    if (!this.scale) {
      return null;
    }

    return this.scale.invert(px);
  }

  /**
   * Convert a Date to a value in px based on the last Scale.
   * Returns null if no scale is available.
   */
  dateToPx(date: Date): number | null {
    if (!this.scale) {
      return null;
    }

    return this.scale(date);
  }

  /**
   * Helper to convert the value returned by brush.extent (https://github.com/d3/d3-brush#brush_extent) to a TimeSlot
   */
  extentToTimeSlot(extent: [[number, number], [number, number]]): TimeSlot {
    const left: number = extent[0][0];
    const right: number = extent[1][0];

    return {
      startDateTime: this.pxToDate(left).toISOString(),
      toDateTime: this.pxToDate(right).toISOString()
    };
  }
}

enum TicksUnit {
  hour = 'hour',
  quarter = 'quarter',
  fiveMinutes = 'fiveMinutes'
}

function getTicksUnit(scale: ScaleTime<number, number>): TicksUnit {
  const domain = scale.domain();
  const diffInHours = differenceInHours(domain[1], domain[0]);

  // If more than 6 hours, make a tick for each hour.
  if (diffInHours > 3) {
    return TicksUnit.hour;
  }

  if (diffInHours > 3) {
    return TicksUnit.quarter;
  }

  return TicksUnit.fiveMinutes;
}

function getTimeInterval(ticksUnit: TicksUnit): TimeInterval {
  switch (ticksUnit) {
    case TicksUnit.hour:
    default:
      return d3.timeHour;

    case TicksUnit.fiveMinutes:
      return d3.timeMinute.every(5);

    case TicksUnit.quarter:
      return d3.timeMinute.every(15);
  }
}

/**
 * Return sensible number of ticks based on the input scale.
 * Only works until max 1 day (at this time)
 * TODO Maybe move this to the relevant scaleProvider? Since ticks$ is part of the interface now?
 */
export class DateTicksHelper {
  private ticksUnit$: Observable<TicksUnit> = this.scale$.pipe(map((scale) => getTicksUnit(scale)));

  ticks$: Observable<Date[]> = combineLatest([this.scale$, this.ticksUnit$]).pipe(
    map(([scale, ticksUnit]) => {
      const timeInterval = getTimeInterval(ticksUnit);

      return scale.ticks(timeInterval);
    })
  );

  /**
   * Return datefns format for https://date-fns.org/v2.17.0/docs/format based on the ticks unit
   */
  labelFormat$: Observable<string> = this.ticksUnit$.pipe(
    map((ticksUnit) => {
      switch (ticksUnit) {
        case TicksUnit.hour:
        default:
          return 'HH:mm';
      }

      // TODO add support for smaller periods
    })
  );

  constructor(private scale$: Observable<ScaleTime<number, number>>) {}
}
