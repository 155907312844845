import { GridPoint } from '../../../../api-private/grid-point/grid-point';

export class ResetGridPointCommand {
  public static readonly type = '[Grid Point] Reset grid point action';

  constructor(public payload?: Partial<GridPoint>, public keepContext: boolean = false) {}
}

export class LoadGridPointCommand {
  public static readonly type = '[Grid Point] Load grid point data';

  constructor(public id: string, public showForm: boolean = false) {}
}

export class GridPointLoadedEvent {
  public static readonly type = '[Grid Point] Grid point loaded event';

  constructor(public payload: GridPoint, public showForm: boolean = false) {}
}

export class SaveGridPointCommand {
  public static readonly type = '[Grid Point] Save grid point data';

  constructor(public navigateAfterSave: boolean = true) {}
}

export class DeleteGridPointCommand {
  public static readonly type = '[Grid Point] Delete grid point';

  constructor(public gridPoint: GridPoint) {}
}

export class RetrieveContractsForGridPointCommand {
  public static readonly type = '[Grid Point] Retrieve contracts for grid point';
}
