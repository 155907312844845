import { BaseType, ScaleTime, Selection } from 'd3';
import { combineLatest, Observable } from 'rxjs';
import { NumberRange, RangeMap } from 'range-ts';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { ScaleBand, ScaleContinuousNumeric } from 'd3-scale';
import { isArray, isNil } from 'lodash-es';
import { D3GraphDynamicScaleProvider } from '../../scales/d3-graph-dynamic-scale-provider';
import { D3GraphStepLineHelper, D3GraphStepLineHelperDataPointCoordinate } from '../../data-helpers/d3-graph-step-line.helper';
import { MixinBase } from '../../../../core/common/constructor-type.mixin';
import { SubjectProvider } from '../../common';
import { D3GraphStepFillHelper } from '../../data-helpers/d3-graph-step-fill.helper';
import { DataPointStep } from '../../scales/d3-graph-line-scale-provider';
import { D3GraphScaleProvider } from '../../scales/d3-graph-scale-provider';
import { DestroyableMixin, OnDestroyMixin } from '../../../../core/common/on-destroy.mixin';
import { D3GraphSeriesSubModule } from '../d3-graph-submodule';
import { expectObservableValue } from '../../../../core/common/expect-observable-value';

type SupportedXScale = ScaleTime<number, number>;
type SupportedYScale = ScaleContinuousNumeric<any, any> | ScaleBand<any>;

type GraphStepConfig = {
  fillColor: string;
  lineColor: string;
};

export class D3GraphSteppedLine
  extends OnDestroyMixin(DestroyableMixin(MixinBase))
  implements D3GraphSeriesSubModule<SupportedXScale, D3GraphDynamicScaleProvider<SupportedYScale, number>, GraphStepConfig, DataPointStep>
{
  private configProvider = new SubjectProvider<GraphStepConfig>(this);
  private dataProvider = new SubjectProvider<DataPointStep[]>(this);
  /**
   * Has Data indicates whether or not the label should be shown, and the axis should be marked as used for usedYScale$
   */
  hasData$: Observable<boolean> = this.dataProvider.value$.pipe(
    map((value) => {
      if (!value) {
        return false;
      }

      if (isArray(value)) {
        return value.some((v) => !isNil(v?.value));
      }

      return true;
    })
  );
  private dynamicYScaleProvider = new D3GraphDynamicScaleProvider<SupportedYScale, number>(this);
  usedYScaleProvider$ = this.hasData$.pipe(map((hasData) => (hasData ? this.dynamicYScaleProvider : null)));
  private xScaleProvider = new SubjectProvider<any>(this);
  private d3GraphStepFillHelper: D3GraphStepFillHelper;
  private d3GraphStepLineHelper: D3GraphStepLineHelper;
  private yScale$ = this.dynamicYScaleProvider.scale$;
  private dataPointStepRangeMap$: Observable<RangeMap<DataPointStep>> = this.dataProvider.value$.pipe(
    map((data) => {
      const rangeMap = new RangeMap<DataPointStep>();

      data.forEach((value) => rangeMap.putCoalescing(NumberRange.closedOpen(value.startDate, value.toDate), value));
      return rangeMap;
    }),
    shareReplay(1)
  );

  attach(mainSvg: Selection<BaseType, any, any, any>): () => void {
    const node = mainSvg.append('g').attr('class', 'step-container');
    this.d3GraphStepFillHelper = new D3GraphStepFillHelper(node);
    this.d3GraphStepLineHelper = new D3GraphStepLineHelper(node);

    expectObservableValue(this.dataProvider.value$, 'No dataProvider in D3GraphSteppedLine');
    expectObservableValue(this.yScale$, 'No yScale$ in D3GraphSteppedLine');
    expectObservableValue(this.xScaleProvider.value$, 'No xScaleProvider.value$ in D3GraphSteppedLine');
    expectObservableValue(this.configProvider.value$, 'No configProvider.value$ in D3GraphSteppedLine');

    const subscription = combineLatest([
      this.dataProvider.value$,
      this.yScale$,
      this.xScaleProvider.value$,
      this.configProvider.value$
    ]).subscribe({
      next: ([data, yScale, xScale, config]) => {
        data.map((datum) => ({
          value: datum.value,
          startDate: datum.startDate,
          toDate: datum.toDate
        }));

        const yBase = Math.min(yScale.range()[1], Math.max(yScale.range()[0], yScale(0)));

        const coordinates: D3GraphStepLineHelperDataPointCoordinate[] = data.map((tick, i) => ({
          y0: yScale(tick.value),
          y1: yBase,
          x: xScale(tick.toDate)
        }));

        if (coordinates.length > 0) {
          // Add an additional coordinate for the first tick in the series to provide a correct starting point.
          // Normally this would be the toDate coordinate of the previous series, but the first coordinate does not have that.
          coordinates.unshift({
            y0: yScale(data[0].value),
            y1: yBase,
            x: xScale(data[0].startDate)
          });
        }

        this.d3GraphStepFillHelper.setData([
          {
            fillColor: config.fillColor,
            lineColor: config.lineColor,
            coordinates
          }
        ]);
        this.d3GraphStepLineHelper.setData([
          {
            fillColor: config.fillColor,
            lineColor: config.lineColor,
            coordinates
          }
        ]);
      },
      complete: () => {
        node.remove();
      }
    });

    return () => {
      subscription.unsubscribe();
      node.remove();
    };
  }

  attachLabel(labelSvg: Selection<BaseType, any, any, any>): void {
    // Assumes a 16x16 canvas for the label, may be configurable later.
    const itemSize = 16;

    labelSvg.attr('width', itemSize).attr('height', itemSize);
    const node = labelSvg.append('g');

    const d3GraphStepLineHelper = new D3GraphStepLineHelper(node);
    const d3GraphStepFillHelper = new D3GraphStepFillHelper(node);

    this.configProvider.value$.subscribe((config) => {
      const data = [
        {
          fillColor: config.fillColor,
          lineColor: config.lineColor,
          coordinates: [
            {
              x: 0,
              y0: itemSize / 2,
              y1: itemSize
            },
            {
              x: itemSize,
              y0: itemSize / 2,
              y1: itemSize
            }
          ]
        }
      ];
      d3GraphStepLineHelper.setData(data);
      d3GraphStepFillHelper.setData(data);
    });
  }

  setData$(data$: Observable<DataPointStep[]>): void {
    this.dataProvider.follow(data$);
  }

  setConfig(config: Partial<GraphStepConfig>): void {
    this.configProvider.next({
      ...this.configProvider.value,
      ...config
    });
  }

  setYScaleProvider(yScaleProvider: D3GraphScaleProvider<SupportedYScale, number>): void {
    this.dynamicYScaleProvider.next(yScaleProvider);
  }

  setXScale$(xScale$: Observable<SupportedXScale>): void {
    this.xScaleProvider.follow(xScale$);
  }

  /**
   * Determine the Y value for the given X value. Used to determine the value to show in the tooltip
   */
  getYDomainValue$(x: Date): Observable<number> {
    return combineLatest([this.dataPointStepRangeMap$, this.dynamicYScaleProvider.scale$, this.xScaleProvider.value$]).pipe(
      map(([rangeMap]) => {
        return rangeMap.get(x.valueOf())?.value;
      }),
      takeUntil(this.onDestroy$)
    );
  }

  setConfig$(config$: Observable<GraphStepConfig>): void {
    this.configProvider.follow(
      config$.pipe(
        map((newConfig) => ({
          ...(this.configProvider.value ?? {}),
          ...newConfig
        }))
      )
    );
  }

  setData(data: DataPointStep[]): void {
    this.dataProvider.next(data);
  }
}
