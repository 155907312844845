import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MessageService } from '../../core/messages/message.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
  constructor(private messageService: MessageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        () => {},
        (event: HttpErrorResponse) => {
          if (event.url.includes('b2clogin.com')) {
            // prevent creating messages for errors from B2C end point calls
            return;
          }
          if (event.error) {
            if (event.error.hasOwnProperty('error_description')) {
              this.messageService.error(event.error.error_description);
            } else if (event.error.message) {
              this.messageService.error(event.error.message);
            } else {
              const statusText = this.getFixedDefaultStatusText(event);
              if (statusText !== 'OK') {
                this.messageService.error(statusText);
              }
            }
          } else if (event.message) {
            this.messageService.error(event.message);
          }
        }
      )
    );
  }

  //
  // When Angular is *not* using the the angular-cli proxy, AND the server response does not contain any statusText the default
  // statusText for 401 and 403 is set to 'OK', regardless of the status code. This can lead to '403 OK' and '401 OK' responses,
  // which is weird at best.
  // When Angular *is* using the angular-cli proxy statusText is set correctly (i.e. 'Forbidden' or 'Unauthorized')
  //
  // On our local machines we are using the angular-cli proxy, so all statusTexts appear to be ok. But when we deploy to dev, acc or prd,
  // the angular-cli proxy is no longer used, and the incorrect default 'ok' statusText is shown for 401s and 403s.
  //
  // This function corrects the incorrect default OK message to a more appropriate one, only for 401s and 403s.
  //
  // For details, see:
  // https://github.com/angular/angular/issues/23334
  // and https://github.com/angular/angular/blob/7.2.0/packages/common/http/src/response.ts
  //
  private getFixedDefaultStatusText(event: HttpErrorResponse): string {
    if (!event.status.toString().startsWith('2') && event.statusText === 'OK') {
      switch (event.status) {
        case 401:
          return 'Unauthorized';
        case 403:
          return 'Forbidden';
      }
      return event.statusText;
    }
  }
}
