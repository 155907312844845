{
  "color": {
    "brand": {
      "primary": "#09273F",
      "primary-dark": "#051624",
      "primary-middle": "#11222F",
      "primary-light": "#153249",
      "accent": "#0b5fb1",
      "promo": "#b18c00"
    },
    "miscellaneous": {
      "primary": {
        "dark": "#8287f4",
        "light": "#b6c0e5"
      },
      "secondary": {
        "dark": "#c98728",
        "light": "#DBB93C"
      },
      "tertiary": {
        "dark": "#e49390",
        "light": "#edbfbe"
      },
      "quaternary": {
        "dark": "#6d8b95",
        "light": "#98b6bf"
      }
    },
    "graphs": {
      "primary": {
        "dark": "#C98728",
        "light": "#DBB93C"
      },
      "secondary": {
        "dark": "#E49390",
        "light": "#EDBFBE"
      },
      "tertiary": {
        "dark": "#8287F4",
        "light": "#B6C0E5"
      },
      "quaternary": {
        "dark": "#6D8B95",
        "light": "#98B6BF"
      }
    },
    "markets": {
      "day-ahead": {
        "translucent": "#3A83E640",
        "regular": "#3A83E6"
      },
      "intraday": {
        "translucent": "#5B45D640",
        "regular": "#5B45D6",
        "regular-production": "#5B45D6",
        "regular-consumption": "#C267C8"
      },
      "imbalance": {
        "translucent": "#43957740",
        "regular": "#439577"
      },
      "reserve": {
        "translucent10": "#CAA12A10",
        "translucent": "#CAA12A40",
        "regular": "#CAA12A"
      }
    },
    "ui": {
      "support": {
        "white": "#fff",
        "black": "#000",
        "grey": "#A3B0BA",
        "grey-dark": "#556A7B",
        "grey-medium": "#E3E7EA",
        "grey-light": "#EDF1F5",
        "cta": "#F2C628"
      },
      "alerts": {
        "error": "#cb4141",
        "error-light": "#F5DBDB",
        "warning": "#eda212",
        "warning-light": "#FBEED2",
        "success": "#5dc237",
        "success-light": "#E0F4D9",
        "info": "#00a3ff",
        "info-light": "#DEEEF7",
        "info2": "#6c4eb5",
        "info2-light": "#E3DDF1"
      },
      "dispatching": {
        "sell": "#49d4bb",
        "buy": "#f4947e",
        "switch": "#448BE3"
      },
      "text": {
        "active": "rgba(#000, 87%)",
        "inactive": "#556A7B"
      }
    }
  },
  "borders": {
    "width": {
      "small": "1px",
      "medium": "2px",
      "large": "4px"
    },
    "radius": {
      "component": "4px",
      "layout": "8px"
    }
  },
  "strokes": {
    "width": {
      "medium": 1.5
    }
  },
  "fontFamilies": {
    "ui": "\"'Quicksand', sans-serif\"",
    "headings": "\"'Quicksand', sans-serif\"",
    "running": "\"'Sarabun', sans-serif\""
  },
  "fontSizes": {
    "micro": 10,
    "xsmall": 12,
    "small": 14,
    "medium": 16,
    "large": 20
  },
  "lineHeights": {
    "small": 1.2,
    "medium": 1.5,
    "large": 1.75,
    "xlarge": 2
  },
  "fontWeight": {
    "regular": 400,
    "medium": 600,
    "strong": 800
  },
  "space": {
    "gutter": 6,
    "xsmall": 4,
    "small": 8,
    "medium": 16,
    "large": 24,
    "xlarge": 32,
    "xxlarge": 40
  },
  "layout": {
    "width": {
      "xs": 444,
      "s": 600,
      "m": 960,
      "l": 1280,
      "xl": 1500
    },
    "height": {
      "m": 320,
      "xs": 0
    }
  },
  "components": {
    "content": {
      "minHeight": "172px"
    },
    "top-bar": {
      "min-height": {
        "xs": "56px",
        "m": "64px"
      }
    },
    "filter-bar": {
      "min-height": {
        "xs": "56px",
        "m": "64px"
      }
    },
    "announcement": {
      "min-height": {
        "slim": 56,
        "regular": 72
      }
    }
  }
}
