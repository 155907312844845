import * as d3 from 'd3';
import { BaseType, ScaleTime, Selection } from 'd3';
import { combineLatest, Observable } from 'rxjs';
import { ScaleContinuousNumeric } from 'd3-scale';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { isArray, isNil } from 'lodash-es';
import { D3GraphDynamicScaleProvider } from '../../scales/d3-graph-dynamic-scale-provider';
import { MixinBase } from '../../../../core/common/constructor-type.mixin';
import { SubjectProvider } from '../../common';
import { D3GraphLineHelper, DataPointCoordinate } from '../../data-helpers/d3-graph-line.helper';
import { expectObservableValue } from '../../../../core/common/expect-observable-value';
import { D3GraphScaleProvider } from '../../scales/d3-graph-scale-provider';
import { DestroyableMixin, OnDestroyMixin } from '../../../../core/common/on-destroy.mixin';
import { DataPointLine } from '../../scales/d3-graph-line-scale-provider';
import { D3GraphSeriesSubModule } from '../d3-graph-submodule';

export type SupportedXScale = ScaleTime<number, number>;
export type SupportedYScale = ScaleContinuousNumeric<any, any>;

export type GraphLineConfig = {
  lineColor: string;
  strokeDasharray?: number[];
};

export class D3GraphLine
  extends OnDestroyMixin(DestroyableMixin(MixinBase))
  implements D3GraphSeriesSubModule<SupportedXScale, D3GraphDynamicScaleProvider<SupportedYScale, number>, GraphLineConfig, DataPointLine>
{
  private configProvider = new SubjectProvider<GraphLineConfig>(this);
  private dataProvider = new SubjectProvider<DataPointLine[]>(this);
  private dynamicYScaleProvider = new D3GraphDynamicScaleProvider<SupportedYScale, number>(this);

  private xScaleProvider = new SubjectProvider<SupportedXScale>(this);
  private d3GraphLineHelper: D3GraphLineHelper;
  private yScale$ = this.dynamicYScaleProvider.scale$;

  hasData$: Observable<boolean> = this.dataProvider.value$.pipe(
    map((value) => {
      if (!value) {
        return false;
      }

      if (isArray(value)) {
        return value.some((v) => !isNil(v?.value));
      }

      return true;
    })
  );

  usedYScaleProvider$ = this.hasData$.pipe(map((hasData) => (hasData ? this.dynamicYScaleProvider : null)));

  attach(mainSvg: Selection<BaseType, any, any, any>): () => void {
    const node = mainSvg.append('g').attr('class', 'line-container');
    this.d3GraphLineHelper = new D3GraphLineHelper(node);

    expectObservableValue(this.dataProvider.value$, 'Did not receive data for dataProvider.value$ in D3GraphLine');
    expectObservableValue(this.yScale$, 'Did not receive data for yScale$ in D3GraphLine');
    expectObservableValue(this.xScaleProvider.value$, 'Did not receive data for this.xScaleProvider.value$ in D3GraphLine');
    expectObservableValue(this.configProvider.value$, 'Did not receive data for this.configProvider.value$ in D3GraphLine');

    const subscription = combineLatest([
      this.dataProvider.value$,
      this.yScale$,
      this.xScaleProvider.value$,
      this.configProvider.value$
    ]).subscribe({
      next: ([data, yScale, xScale, config]) => {
        const coordinates: DataPointCoordinate[] = data.map((datum) => {
          // Null or undefine should be filtered out, but 0 should pass
          if (isNil(datum.value)) return;
          return {
            x: xScale(datum.date),
            y: yScale(datum.value)
          };
        });

        this.d3GraphLineHelper.setData([
          {
            lineColor: config.lineColor,
            coordinates,
            strokeDasharray: config.strokeDasharray
          }
        ]);
      },
      complete: () => {
        node.remove();
      }
    });

    return () => {
      subscription.unsubscribe();
      node.remove();
    };
  }

  attachLabel(labelSvg: Selection<BaseType, any, any, any>): void {
    // Assumes a 16x16 canvas for the label, may be configurable later.
    const itemSize = 16;

    labelSvg.attr('width', itemSize).attr('height', itemSize);
    const node = labelSvg.append('g');

    const d3GraphStepLineHelper = new D3GraphLineHelper(node);

    this.configProvider.value$.subscribe((config) => {
      const data = [
        {
          lineColor: config.lineColor,
          coordinates: [
            {
              x: itemSize,
              y: itemSize / 2
            },
            {
              x: 0,
              y: itemSize / 2
            }
          ],
          strokeDasharray: config.strokeDasharray
        }
      ];

      d3GraphStepLineHelper.setData(data);
    });
  }

  setData$(data$: Observable<DataPointLine[]>): void {
    this.dataProvider.follow(data$);
  }

  setData(data: DataPointLine[]): void {
    this.dataProvider.next(data);
  }

  setConfig(config: Partial<GraphLineConfig>): void {
    this.configProvider.next({
      ...this.configProvider.value,
      ...config
    });
  }

  setConfig$(config$: Observable<Partial<GraphLineConfig>>): void {
    this.configProvider.follow(
      config$.pipe(
        map((partialConfig) => ({
          ...this.configProvider.value,
          ...partialConfig
        }))
      )
    );
  }

  setYScaleProvider(yScaleProvider: D3GraphScaleProvider<SupportedYScale, number>): void {
    this.dynamicYScaleProvider.next(yScaleProvider);
  }

  setYScaleProvider$(yScaleProvider$: Observable<D3GraphScaleProvider<SupportedYScale, number>>): void {
    this.dynamicYScaleProvider.follow(yScaleProvider$);
  }

  setXScale$(xScale$: Observable<SupportedXScale>): void {
    this.xScaleProvider.follow(xScale$);
  }

  /**
   * Determine the Y value for the given X value. Used to determine the value to show in the tooltip
   */
  getYDomainValue$(x: Date): Observable<number> {
    return combineLatest([this.dataProvider.value$, this.dynamicYScaleProvider.scale$, this.xScaleProvider.value$]).pipe(
      map(([data, yScale, xScale]) => {
        const bisect = d3.bisector((datum: DataPointLine) => datum.date).right;
        const dataLocation = bisect(data, x);
        return data[dataLocation]?.value;
      }),
      takeUntil(this.onDestroy$)
    );
  }
}
