import { DestroyableMixin, OnDestroyMixin, OnDestroyProvider } from '../../../core/common/on-destroy.mixin';
import { MixinBase } from '../../../core/common/constructor-type.mixin';
import { D3GraphScaleProvider } from './d3-graph-scale-provider';
import { ScaleTime } from 'd3-scale';
import { Margins, SubjectProvider } from '../common';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

// @ts-ignore
import { discontinuityRange, scaleDiscontinuous } from 'd3fc-discontinuous-scale';
import { scaleTime } from 'd3';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { flatMap, isEqual, subtract } from 'lodash-es';
import { TimeSlot } from '../../../core/date-time/time-slot';
import { addHours, addMinutes, differenceInHours, differenceInSeconds, endOfHour, parseISO, startOfHour, subMinutes } from 'date-fns';
import { expectObservableValue } from '../../../core/common/expect-observable-value';

interface Domain {
  start: Date;
  end: Date;
}

export class TimeSlotWithGapsScaleProvider
  extends DestroyableMixin(OnDestroyMixin(MixinBase))
  implements D3GraphScaleProvider<ScaleTime<number, number>, Date>
{
  /**
   * Last scale that was emitted by this.scale$
   */
  scale: ScaleTime<number, number>;
  private widthProvider = new SubjectProvider<number>(this);
  private marginsProvider = new SubjectProvider(this, new BehaviorSubject<Pick<Margins, 'left' | 'right'>>({ left: 0, right: 0 }));
  private domainProvider = new SubjectProvider<Domain>(this);
  private gapsProvider = new SubjectProvider<TimeSlot[]>(this);
  /**
   * Output scale based on updated width, margins and domain.
   */
  scale$: Observable<ScaleTime<number, number>> = combineLatest([
    this.widthProvider.value$,
    this.marginsProvider.value$,
    this.domainProvider.value$,
    this.gapsProvider.value$
  ]).pipe(
    distinctUntilChanged(isEqual),
    map(([width, margins, domain, gaps]) => {
      const scaleRange = [margins.left, width - margins.right];
      const scaleDomain = [domain.start, domain.end] as unknown as [Date, Date];

      const ranges = gaps.map((gap) => [parseISO(gap.startDateTime), parseISO(gap.toDateTime)]);

      return scaleDiscontinuous(scaleTime())
        .discontinuityProvider(discontinuityRange(...ranges))
        .domain(scaleDomain)
        .range(scaleRange);
    })
  );

  ticks$: Observable<Date[]> = this.scale$.pipe(map((scale) => scale.ticks(7)));

  constructor(onDestroyProvider: OnDestroyProvider) {
    super();

    expectObservableValue(this.widthProvider.value$, 'Width was not set in TimeSlotWithGapsScaleProvider');
    expectObservableValue(this.domainProvider.value$, 'Domain was not set in TimeSlotWithGapsScaleProvider');
    expectObservableValue(this.scale$, 'Scale did not output a value in TimeSlotWithGapsScaleProvider');
    expectObservableValue(this.gapsProvider.value$, 'Gaps did not output a value in TimeSlotWithGapsScaleProvider');

    this.registerOnDestroyProvider(onDestroyProvider);

    this.scale$.subscribe((scale) => (this.scale = scale));
  }

  setGaps(gaps: TimeSlot[]): void {
    this.gapsProvider.next(gaps);
  }

  setGaps$(gaps$: Observable<TimeSlot[]>): void {
    this.gapsProvider.follow(gaps$);
  }

  setWidth(width: number): void {
    this.widthProvider.next(width);
  }

  setMargin(margin: Partial<Pick<Margins, 'left' | 'right'>>): void {
    this.marginsProvider.next({
      ...this.marginsProvider.value,
      ...margin
    });
  }

  /**
   * Set the domain parameters based on the provided TimeSlot.
   */
  setTimeSlot(timeSlot: TimeSlot): void {
    this.domainProvider.next({
      start: parseISO(timeSlot.startDateTime),
      end: parseISO(timeSlot.toDateTime)
    });
  }

  setTimeSlot$(timeSlot$: Observable<TimeSlot>): void {
    this.domainProvider.follow(
      timeSlot$.pipe(
        map((timeSlot) => ({
          start: parseISO(timeSlot.startDateTime),
          end: parseISO(timeSlot.toDateTime)
        }))
      )
    );
  }

  destroy(): void {
    this.ngOnDestroy();
  }

  /**
   * Convert a value in px to a Date based on the last Scale.
   * Returns null if no scale is available.
   */
  pxToDate(px: number): Date | null {
    if (!this.scale) {
      return null;
    }

    return this.scale.invert(px);
  }

  /**
   * Convert a Date to a value in px based on the last Scale.
   * Returns null if no scale is available.
   */
  dateToPx(date: Date): number | null {
    if (!this.scale) {
      return null;
    }

    return this.scale(date);
  }
}

/**
 * Return gaps between 2 timeslots which are larger than the specified minGapSeconds/
 * Assumes the data is sorted ASCENDING (the oldest data has the lowest index) and not overlapping.
 */
export function getGapsInTimeSlots(data: TimeSlot[], minGapSeconds: number): TimeSlot[] {
  const gaps: TimeSlot[] = [];

  if (!data || data.length < 2) {
    return [];
  }

  for (let i = 0; i < data.length - 1; i++) {
    const current = parseISO(data[i].toDateTime);
    const next = parseISO(data[i + 1].startDateTime);

    const diff = Math.abs(differenceInSeconds(next, current));

    if (diff > minGapSeconds) {
      gaps.push({
        startDateTime: current.toISOString(),
        toDateTime: next.toISOString()
      });
    }
  }

  return gaps;
}

/**
 * Returns a list of gaps that are multiple hours long, and compressed to a single hour for consistency.
 */
export function getGapsOfMultipleHoursAsOneHour(data: TimeSlot[]): TimeSlot[] {
  const gaps = getGapsInTimeSlots(data, 60 * 60);

  return flatMap(gaps, (gap) => {
    const start = startOfHour(addHours(parseISO(gap.startDateTime), 1));
    const end = startOfHour(parseISO(gap.toDateTime));

    if (differenceInHours(end, start) < 2) {
      return [];
    }

    return [
      {
        startDateTime: addMinutes(start, 30).toISOString(),
        toDateTime: subMinutes(end, 30).toISOString()
      }
    ];
  });
}
