// Session Storage
import { getFromInjector } from '../common/app-injector';
import { Store } from '@ngxs/store';
import { FlexStorageEvent } from './storage-store.actions';

export const CUSTOMER_ID_SESSION_STORAGE_KEY = 'globalCustomerId';
export const DISPATCH_SCHEDULE_SELECTED_DATE_SESSION_STORAGE_KEY = 'dispatchScheduleSelectedDate';
export const DISPATCH_SCHEDULE_GRID_POINT_ID_SESSION_STORAGE_KEY = 'dispatchScheduleGridPointId';

// Local Storage
export const LOGIN_REDIRECTS_STORAGE_KEY = 'on-login-redirects';
export const IDCONS_ANNOUNCEMENT_IDS_READ_STORAGE_KEY = 'last-read-idcons-announcement-ids';

// Configurable (see usage)
export const MOTD_STORAGE_KEY = 'flexMotd';

/**
 * Provide a generic implementation to store, fetch, remove and validate objects serialized as JSON to/from local or session storage.
 * Will emit a StorageEvent on NgXS, so implementations that execute logic on changes can be put there (instead of having separate paths for same tab and different tab changes)
 */
export class StorageHelper<T> {
  private ngxsStore$ = getFromInjector(Store);

  constructor(private storage: Storage, private storageKey: string) {}

  store(value: T, dispatchEvent: boolean = true): void {
    if (!this.isValid(value)) {
      this.storage.removeItem(this.storageKey);
      return;
    }
    this.storage.setItem(this.storageKey, JSON.stringify(value));

    if (dispatchEvent) {
      this.ngxsStore$.subscribe((store) => store.dispatch(new FlexStorageEvent(this.storageKey, value, this.storage)));
    }
  }

  fetch(): T | null {
    const foundItem = this.storage.getItem(this.storageKey);
    if (!foundItem) {
      return null;
    }
    const parsedItem = JSON.parse(foundItem);

    return this.isValid(parsedItem) ? parsedItem : null;
  }

  clear(): void {
    this.storage.removeItem(this.storageKey);
  }

  /**
   * Check if a given FlexStorageEvent matches the storage and storageKey defined for this helper
   */
  isMatchingEvent(event: FlexStorageEvent): boolean {
    if (!event) {
      return false;
    }

    return event.key && event.key === this.storageKey && event.storage === this.storage;
  }

  protected isValid(value: T): boolean {
    return !!value;
  }
}
