// see: https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_.22Unicode_Problem.22
import { addMinutes, subMinutes } from 'date-fns';

export function b64DecodeUnicode(str: string): string {
  const base64 = str.replace(/\-/g, '+').replace(/\_/g, '/');

  return decodeURIComponent(
    atob(base64)
      .split('')
      .map((c: string) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  );
}

export function base64UrlEncode(str: string): string {
  const base64 = btoa(str);
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '');
}

export interface ParsedIdToken {
  headers: any;
  claims: {
    exp: number;
    nbf: number;

    // user_name in sec_user
    sub: string;
    aud: string;
    iat: number;
    auth_time: number;

    email: string;
    name: string;
    given_name: string;
    family_name: string;
  };

  originalToken: string;
}

export function parseIdToken(idToken: string): ParsedIdToken {
  if (!idToken) {
    return {
      headers: {},
      claims: {} as any,
      originalToken: null
    };
  }

  const [headers, claims] = idToken
    .split('.')
    .slice(0, 2) // Only parse header and body, not the signature
    .map((rawPart) => b64DecodeUnicode(rawPart))
    .map((decodedPart) => JSON.parse(decodedPart));

  return {
    headers,
    claims,
    originalToken: idToken
  };
}

/**
 * For testing.
 */
export function unparseIdToken(parsedIdToken: ParsedIdToken): string {
  return [parsedIdToken.headers, parsedIdToken.claims]
    .map((part) => JSON.stringify(part))
    .map((stringPart) => base64UrlEncode(stringPart))
    .join('.');
}

/**
 * For testing.
 * Creates a token that is fvalid for 14 minutes starting 1 minute ago.
 */
export function createValidIdToken(): string {
  const now = new Date();

  // Expires after 15 minutes
  const exp = addMinutes(now, 14).valueOf() / 1000;

  // Issued 1 minute ago
  const nbf = subMinutes(now, 1).valueOf() / 1000;

  return unparseIdToken({
    headers: {},
    claims: {
      exp,
      nbf
    }
  } as any);
}
