import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationStart, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { flatMap } from 'lodash-es';
import { filter, map, takeUntil } from 'rxjs/operators';
import { findNotNullParameterValue } from '../common/find-parameter-value';
import { OnDestroyMixin } from '../common/on-destroy.mixin';
import { CUSTOMER_STORAGE_TOKEN } from '../core.module';
import { MixinBase } from '../common/constructor-type.mixin';
import { CUSTOMER_ID_SESSION_STORAGE_KEY } from '../storage-store/browser-storage';

export const CUSTOMER_ID_PARAM = 'customerId';

@Injectable({
  providedIn: 'root'
})
export class DefaultCustomerGuardService extends OnDestroyMixin(MixinBase) {
  constructor(private router: Router, @Inject(CUSTOMER_STORAGE_TOKEN) private customerStorage: Storage) {
    super();

    if (customerStorage) {
      router.events
        .pipe(
          filter((e) => e instanceof ActivationStart),
          map((e: ActivationStart) => findNotNullParameterValue(e.snapshot, CUSTOMER_ID_PARAM)),
          filter((customerId) => !!customerId),
          takeUntil(this.onDestroy$)
        )
        .subscribe((customerId) => {
          customerStorage.setItem(CUSTOMER_ID_SESSION_STORAGE_KEY, customerId);
        });
    }
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.customerStorage) {
      // Only evaluate when sessionStorage is available
      return true;
    }

    // Check if the route has an already set customerID parameter
    const customerId = this.customerStorage.getItem(CUSTOMER_ID_SESSION_STORAGE_KEY);
    if (findNotNullParameterValue(route, CUSTOMER_ID_PARAM) || !customerId) {
      return true;
    }

    if (!this.isCustomerIdValid(customerId)) {
      this.customerStorage.removeItem(CUSTOMER_ID_SESSION_STORAGE_KEY);
      return true;
    }

    // Build url from root, a relative url will be relative to the root of this module and from the app
    const customerBaseUrl = flatMap(route.pathFromRoot, (p) => p.url.toString())
      .concat([route.data.customerRoutePrefix])
      .filter((a) => !!a);

    return this.router.navigate(customerBaseUrl.concat([customerId]));
  }

  private isCustomerIdValid(customerId: string): boolean {
    return `${customerId}`.length === 36;
  }
}
