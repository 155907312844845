export interface NgxsFormState<T> {
  model: T;
  status: FormStatus | null;
  dirty: boolean | null;
}

export enum FormStatus {
  VALID = 'VALID',
  INVALID = 'INVALID'
}
