<ng-container
  *ngIf="
    errors?.maxNumberOfDecimals && errors?.maxNumberOfDecimals.max === 0 && errors?.maxNumberOfDecimals.min === 0;
    else numberOfDecimalsMessages
  "
>
  <mat-error i18n="@@formErrorMessages-noDecimalsAllowed"> Enter {{ actualDescriptiveLowerCaseName }} without decimals.</mat-error>
</ng-container>

<mat-error *ngIf="errors?.required; let error" i18n="@@formErrorMessages-required"
  >{{ actualDescriptiveCapitalizedName }} is required.
</mat-error>
<mat-error *ngIf="errors?.atLeastOneRequired; let error" i18n="@@formErrorMessages-atLeastOneRequired"
  >At least one of {{ actualDescriptiveLowerCaseName }} is required.
</mat-error>
<mat-error *ngIf="errors?.atLeastOneGreaterThanZero; let error" i18n="@@formErrorMessages-atLeastOneGreaterThanZero"
  >At least one of {{ actualDescriptiveLowerCaseName }} is required and must be greater than 0.
</mat-error>
<mat-error *ngIf="errors?.maxlength; let error" i18n="@@formErrorMessages-maxLength"
  >{{ actualDescriptiveCapitalizedName }} must not be longer than {{ formatErrorParameter(error.requiredLength) }} characters.
</mat-error>

<mat-error *ngIf="errors?.min; let error" i18n="@@formErrorMessages-min">
  {{ actualDescriptiveCapitalizedName }} should be more than or equal to {{ formatErrorParameter(error.min) }}.
</mat-error>
<mat-error *ngIf="errors?.minExclusive; let error" i18n="@@formErrorMessages-minExclusive">
  {{ actualDescriptiveCapitalizedName }} should be more than {{ formatErrorParameter(error.min) }}.
</mat-error>
<mat-error *ngIf="errors?.max; let error" i18n="@@formErrorMessages-max">
  {{ actualDescriptiveCapitalizedName }} should be less than or equal to {{ formatErrorParameter(error.max) }}.
</mat-error>
<mat-error *ngIf="errors?.invalidNumber; let error" i18n="@@formErrorMessages-invalidNumber">
  {{ actualDescriptiveCapitalizedName }} should be a number.
</mat-error>
<mat-error *ngIf="errors?.invalidEntity; let error" i18n="@@formErrorMessages-invalidEntity">
  No {{ actualDescriptiveLowerCaseName }} found with this name.
</mat-error>

<mat-error *ngIf="errors?.invalidMultipleOf; let error" i18n="@@formErrorMessages-invalidMultipleOf">
  {{ actualDescriptiveCapitalizedName }} should be a multiple of {{ formatErrorParameter(error.multiple) }}.
</mat-error>

<mat-error *ngIf="errors?.matDatepickerParse; let error" i18n="@@formErrorMessages-invalidDatepickerParse">
  Use the date picker to select a valid {{ actualDescriptiveLowerCaseName }}.
</mat-error>

<mat-error *ngIf="errors?.pattern; let error" i18n="@@formErrorMessages-pattern">
  {{ actualDescriptiveCapitalizedName }} should match the given pattern: {{ errors.pattern.requiredPattern }}
</mat-error>

<ng-template #numberOfDecimalsMessages>
  <mat-error *ngIf="errors?.minNumberOfDecimals; let error" i18n="@@formErrorMessages-minNumberOfDecimals">
    Enter a {{ actualDescriptiveLowerCaseName }} with minimum {{ error.min }} decimals.
  </mat-error>
  <mat-error *ngIf="errors?.maxNumberOfDecimals; let error" i18n="@@formErrorMessages-maxNumberOfDecimals">
    Enter a {{ actualDescriptiveLowerCaseName }} with maximum {{ error.max }} decimals.
  </mat-error>
</ng-template>
