import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Level, Message } from './message';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private errorMessagesSubject = new BehaviorSubject([]);
  private infoMessagesSubject = new BehaviorSubject([]);
  private debugMessagesSubject = new BehaviorSubject([]);

  errors$: Observable<string[]> = this.errorMessagesSubject.asObservable().pipe(map((messages) => messages.map((a) => a.message)));
  infos$: Observable<string[]> = this.infoMessagesSubject.asObservable().pipe(map((messages) => messages.map((a) => a.message)));
  debugs$: Observable<string[]> = this.debugMessagesSubject.asObservable().pipe(map((messages) => messages.map((a) => a.message)));

  debug(message: string): void {
    this.debugMessagesSubject.next(this.errorMessagesSubject.value.concat([new Message(message, Level.INFO)]));
  }

  info(message: string): void {
    this.infoMessagesSubject.next(this.infoMessagesSubject.value.concat([new Message(message, Level.INFO)]));
  }

  error(message: string): void {
    this.errorMessagesSubject.next(this.errorMessagesSubject.value.concat([new Message(message, Level.ERROR)]));
  }

  clear(): void {
    this.errorMessagesSubject.next([]);
    this.infoMessagesSubject.next([]);
    this.debugMessagesSubject.next([]);
  }

  clearErrors(): void {
    this.errorMessagesSubject.next([]);
  }

  clearInfos(): void {
    this.infoMessagesSubject.next([]);
  }

  clearDebug(): void {
    this.debugMessagesSubject.next([]);
  }
}
