import { __spreadArray as e, __assign as t } from "tslib";
import "reflect-metadata";
var r = function () {
    function e() {}
    return e.getBaseClass = function (e) {
      return e ? Reflect.getPrototypeOf(e) : void 0;
    }, e.getJsonPropertiesMetadata = function (t, r) {
      if (t) {
        var i = "" + e.apiMap + (r || t.constructor.name);
        return Reflect.getMetadata(i, t);
      }
    }, e.getParamTypes = function (t) {
      return t ? Reflect.getMetadata(e.designParamTypes, t) : void 0;
    }, e.getJsonObjectMetadata = function (t) {
      return t ? Reflect.getMetadata(e.apiMapJsonObject, t) : void 0;
    }, e.getType = function (t, r) {
      return t ? Reflect.getMetadata(e.designType, t, r) : void 0;
    }, e.isJsonObject = function (t) {
      return !!t && Reflect.hasOwnMetadata(e.apiMapJsonObject, t);
    }, e.setJsonPropertiesMetadata = function (t, r) {
      if (r) {
        var i = "" + e.apiMap + r.constructor.name;
        Reflect.defineMetadata(i, t, r);
      }
    }, e.setJsonObject = function (t, r) {
      r && Reflect.defineMetadata(e.apiMapJsonObject, t, r);
    }, e.setType = function (t, r, i) {
      r && t && Reflect.defineMetadata(e.designType, t, r, i);
    }, e.apiMap = "api:map:", e.apiMapJsonObject = e.apiMap + "jsonObject", e.designType = "design:type", e.designParamTypes = "design:paramtypes", e;
  }(),
  i = function (e) {
    return "string" == typeof e;
  },
  o = function (e) {
    return null !== e && "object" == typeof e && !n(e);
  },
  n = function (e) {
    return Array.isArray(e);
  },
  a = function (e) {
    return "[object Date]" === toString.call(e);
  },
  s = function (e) {
    return r.isJsonObject(e);
  },
  l = function (e) {
    return [null, void 0].includes(e);
  },
  u = function (e) {
    return e instanceof Set;
  },
  c = function (e) {
    try {
      var t = JSON.parse(e);
      return "object" == typeof t ? t : e;
    } catch (t) {
      return e;
    }
  },
  p = function (e, t) {
    return e.filter(function (e) {
      return !t.some(function (t) {
        return e === t;
      });
    });
  },
  d = function () {
    this.errorCallback = y, this.nullishPolicy = {
      undefined: "remove",
      null: "allow"
    }, this.additionalPropertiesPolicy = "remove";
  },
  f = function (e) {
    throw new Error(e);
  },
  y = function (e) {
    console.error(e);
  },
  v = function () {
    function f(e) {
      this.options = new d(), this.options = t(t({}, this.options), e);
    }
    return f.prototype.deserialize = function (e, t) {
      return i(e) && (e = c(e)), n(e) ? this.deserializeObjectArray(e, t) : o(e) ? this.deserializeObject(e, t) : void this.error("Fail to deserialize: value is not an Array nor an Object.\nReceived: " + JSON.stringify(e) + ".");
    }, f.prototype.deserializeObject = function (t, n) {
      var a,
        s = this;
      if (null === t) return "disallow" === this.options.nullishPolicy.null && this.error("Fail to deserialize: null is not assignable to type Object."), null;
      if (void 0 !== t) {
        if (i(t) && (t = c(t)), o(t)) {
          var u;
          if (function (e) {
            if ("function" != typeof e) return !1;
            try {
              Reflect.construct(String, [], e);
            } catch (e) {
              return !1;
            }
            return !0;
          }(n)) {
            var d = Object.create(n.prototype),
              f = r.getJsonObjectMetadata(d.constructor),
              y = null !== (a = null == f ? void 0 : f.constructorParams) && void 0 !== a ? a : [];
            u = new (n.bind.apply(n, e([void 0], y)))();
          } else u = n;
          var v = this.getJsonPropertiesMetadata(u);
          if (!v) return u;
          var h = Object.keys(v);
          if (h.forEach(function (e) {
            var r = v[e],
              i = s.deserializeProperty(u, e, t, r);
            s.checkRequiredProperty(r, u, e, i, t, !1);
            var o = u[e];
            i === o || (null !== i || void 0 !== o) && l(i) || (o = i), s.isAllowedProperty(e, o) && (u[e] = o);
          }), "remove" === this.options.additionalPropertiesPolicy) return u;
          var P = p(Object.keys(t), h);
          return P.length ? ("disallow" === this.options.additionalPropertiesPolicy ? this.error("Additional properties detected in " + JSON.stringify(t) + ": " + P + ".") : "allow" === this.options.additionalPropertiesPolicy && P.forEach(function (e) {
            return u[e] = t[e];
          }), u) : u;
        }
        this.error("Fail to deserialize: type '" + typeof t + "' is not assignable to type 'Object'.\nReceived: " + JSON.stringify(t));
      } else "disallow" === this.options.nullishPolicy.undefined && this.error("Fail to deserialize: undefined is not assignable to type Object.");
    }, f.prototype.deserializeObjectArray = function (e, t) {
      var r = this;
      if (null === e) return "disallow" === this.options.nullishPolicy.null && this.error("Fail to deserialize: null is not assignable to type Array."), null;
      if (void 0 !== e) {
        if (i(e) && (e = c(e)), n(e)) return e.reduce(function (e, i) {
          var o = r.deserializeObject(i, t);
          return (!l(o) || null === o && "remove" !== r.options.nullishPolicy.null || void 0 === o && "remove" !== r.options.nullishPolicy.undefined) && e.push(o), e;
        }, []);
        this.error("Fail to deserialize: type '" + typeof e + "' is not assignable to type 'Array'.\nReceived: " + JSON.stringify(e));
      } else "disallow" === this.options.nullishPolicy.undefined && this.error("Fail to deserialize: undefined is not assignable to type Array.");
    }, f.prototype.serialize = function (e) {
      return n(e) ? this.serializeObjectArray(e) : o(e) ? this.serializeObject(e) : void this.error("Fail to serialize: value is not an Array nor an Object.\nReceived: " + JSON.stringify(e) + ".");
    }, f.prototype.serializeObject = function (e) {
      var t = this;
      if (null === e) return "disallow" === this.options.nullishPolicy.null && this.error("Fail to serialize: null is not assignable to type Object."), null;
      if (void 0 !== e) {
        if (!o(e)) return e;
        var r = this.getJsonPropertiesMetadata(e);
        if (!r) return e;
        var i = {},
          a = Object.keys(e),
          s = Object.keys(r);
        if (s.forEach(function (o) {
          var s = r[o];
          if (a.includes(o)) {
            var l = void 0;
            s.beforeSerialize && (l = e[o], e[o] = s.beforeSerialize(e[o], e));
            var u = t.serializeProperty(e, o, s);
            if (s.afterSerialize && (u = s.afterSerialize(u, e)), e[o] = l || e[o], n(s.name)) s.name.forEach(function (e) {
              t.isAllowedProperty(e, u[e]) && (i[e] = u[e]);
            });else if (t.checkRequiredProperty(s, e, o, u, e), t.isAllowedProperty(o, u)) if (s.isNameOverridden || void 0 === t.options.formatPropertyName) i[s.name] = u;else {
              var c = t.options.formatPropertyName(s.name);
              i[c] = u;
            }
          } else n(s.name) ? s.name.forEach(function (e) {
            t.isAllowedProperty(e, void 0) && (i[e] = void 0);
          }) : (t.checkRequiredProperty(s, e, o, void 0, e), t.isAllowedProperty(o, void 0) && (i[s.name] = void 0));
        }), "remove" === this.options.additionalPropertiesPolicy) return i;
        var l = p(a, s);
        return l.length ? ("disallow" === this.options.additionalPropertiesPolicy ? this.error("Additional properties detected in " + JSON.stringify(e) + ": " + l + ".") : "allow" === this.options.additionalPropertiesPolicy && l.forEach(function (t) {
          return i[t] = e[t];
        }), i) : i;
      }
      "disallow" === this.options.nullishPolicy.undefined && this.error("Fail to serialize: undefined is not assignable to type Object.");
    }, f.prototype.serializeObjectArray = function (e) {
      var t = this;
      if (null === e) return "disallow" === this.options.nullishPolicy.null && this.error("Fail to serialize: null is not assignable to type Array."), null;
      if (void 0 !== e) {
        if (n(e)) return e.reduce(function (e, r) {
          var i = t.serializeObject(r);
          return (!l(i) || null === i && "remove" !== t.options.nullishPolicy.null || void 0 === i && "remove" !== t.options.nullishPolicy.undefined) && e.push(i), e;
        }, []);
        this.error("Fail to serialize: type '" + typeof e + "' is not assignable to type 'Array'.\nReceived: " + JSON.stringify(e) + ".");
      } else "disallow" === this.options.nullishPolicy.undefined && this.error("Fail to serialize: undefined is not assignable to type Array.");
    }, f.prototype.checkRequiredProperty = function (e, t, r, i, o, n) {
      if (void 0 === n && (n = !0), e.required && l(i) && l(t[r])) {
        var a = t.constructor.name;
        this.error("Fail to " + (n ? "serialize" : "deserialize") + ": Property '" + r + "' is required in " + a + " " + JSON.stringify(o) + ".");
      }
    }, f.prototype.deserializeProperty = function (e, t, i, o) {
      if (!l(i)) {
        var n = this.getDataSource(i, o, this.options.formatPropertyName);
        if (l(n)) return n;
        var a,
          u = r.getType(e, t),
          c = this.getDataStructureInformation(u, e[t], o),
          p = c.isArrayProperty,
          d = c.isSetProperty,
          f = c.isMapProperty,
          y = c.isDictionaryProperty,
          v = o.type || u;
        o.beforeDeserialize && (n = o.beforeDeserialize(n, e));
        var h = o.predicate;
        return y || f ? (a = this.deserializeDictionary(n, v, h), f && (a = new Map(Object.entries(a)))) : p || d ? (a = this.deserializeArray(n, v, h), d && (a = new Set(a))) : !s(v) && !h || h && !h(n, i) ? a = this.deserializePrimitive(n, v.name) : (v = o.predicate ? o.predicate(n, i) : v, a = this.deserializeObject(n, v)), o.afterDeserialize && (a = o.afterDeserialize(a, e)), a;
      }
    }, f.prototype.deserializePrimitive = function (e, t) {
      if (l(t)) return e;
      if (typeof e === (t = t.toLowerCase())) return e;
      var r = "Fail to deserialize: type '" + typeof e + "' is not assignable to type '" + t + "'.\nReceived: " + JSON.stringify(e);
      switch (t) {
        case "string":
          var i = e.toString();
          return "[object Object]" === i ? void this.error(r) : i;
        case "number":
          return function (e) {
            return "number" == typeof e;
          }(e) ? +e : void this.error(r);
        case "boolean":
          return void this.error(r);
        case "date":
          return function (e) {
            return !a(e) && !n(e) && !isNaN(Date.parse(e));
          }(e) ? new Date(e) : void this.error(r);
        default:
          return e;
      }
    }, f.prototype.deserializeDictionary = function (e, t, r) {
      var i = this;
      if (o(e)) {
        var a = {};
        return Object.keys(e).forEach(function (o) {
          var s = e[o];
          a[o] = n(s) ? s.map(function (o) {
            return i.deserializeDictionaryProperty(e, o, t, r);
          }) : i.deserializeDictionaryProperty(e, s, t, r);
        }), a;
      }
      this.error("Fail to deserialize: type '" + typeof e + "' is not assignable to type 'Dictionary'.\nReceived: " + JSON.stringify(e) + ".");
    }, f.prototype.deserializeDictionaryProperty = function (e, t, r, i) {
      var o = i ? i(t, e) : void 0;
      return s(r) || o ? this.deserializeObject(t, o || r) : this.deserializePrimitive(t, typeof t);
    }, f.prototype.deserializeArray = function (e, t, r) {
      var i = this;
      if (n(e)) return e.reduce(function (o, n) {
        var a;
        return s(t) || r ? (t = r ? r(n, e) : t, a = i.deserializeObject(n, t)) : a = i.deserializePrimitive(n, typeof n), (!l(a) || null === a && "remove" !== i.options.nullishPolicy.null || void 0 === a && "remove" !== i.options.nullishPolicy.undefined) && o.push(a), o;
      }, []);
      this.error("Fail to deserialize: type '" + typeof e + "' is not assignable to type 'Array'.\nReceived: " + JSON.stringify(e));
    }, f.prototype.error = function (e) {
      this.options.errorCallback && this.options.errorCallback(e);
    }, f.prototype.getClassesJsonPropertiesMetadata = function (e, t) {
      return e ? e.reduce(function (e, i) {
        var o = r.getJsonPropertiesMetadata(t, i);
        return o && e.push(o), e;
      }, []) : [];
    }, f.prototype.getDataSource = function (e, t, r) {
      var i = t.name,
        o = t.isNameOverridden;
      if (n(i)) {
        var a = {};
        return i.forEach(function (t) {
          return a[t] = e[t];
        }), a;
      }
      return !o && r ? (i = r(i), e[i]) : e[i];
    }, f.prototype.getDataStructureInformation = function (e, t, r) {
      var i, o, a, s, l;
      if (r.dataStructure) return {
        isArrayProperty: null !== (i = "array" === r.dataStructure) && void 0 !== i && i,
        isDictionaryProperty: null !== (o = "dictionary" === r.dataStructure) && void 0 !== o && o,
        isMapProperty: null !== (a = "map" === r.dataStructure) && void 0 !== a && a,
        isSetProperty: null !== (s = "set" === r.dataStructure) && void 0 !== s && s
      };
      var c,
        p = null === (l = null == e ? void 0 : e.name) || void 0 === l ? void 0 : l.toLowerCase();
      return "object" === p ? {
        isArrayProperty: n(t),
        isDictionaryProperty: !1,
        isMapProperty: (c = t, c instanceof Map),
        isSetProperty: u(t)
      } : {
        isArrayProperty: "array" === p,
        isDictionaryProperty: !1,
        isMapProperty: "map" === p,
        isSetProperty: "set" === p
      };
    }, f.prototype.getJsonPropertiesMetadata = function (t) {
      var i,
        o = (null !== (i = r.getJsonObjectMetadata(t.constructor)) && void 0 !== i ? i : {}).baseClassNames,
        n = r.getJsonPropertiesMetadata(t);
      if (!(n || o && o.length)) return n;
      if (o && o.length) {
        var a = this.getClassesJsonPropertiesMetadata(o, t);
        return this.mergeJsonPropertiesMetadata.apply(this, e(e([], a), [n]));
      }
      return n;
    }, f.prototype.isAllowedProperty = function (e, t) {
      if (l(t)) {
        if ("disallow" === this.options.nullishPolicy["" + t]) return this.error("Disallowed " + t + " value detected: " + e + "."), !1;
        if ("remove" === this.options.nullishPolicy["" + t]) return !1;
      }
      return !0;
    }, f.prototype.mergeJsonPropertiesMetadata = function () {
      for (var e = [], r = 0; r < arguments.length; r++) e[r] = arguments[r];
      var i = {};
      return e.forEach(function (e) {
        e && Object.keys(e).forEach(function (r) {
          i[r] = t(t({}, i[r]), e[r]);
        });
      }), i;
    }, f.prototype.serializeDictionary = function (e) {
      var t = this;
      if (o(e)) return Object.keys(e).reduce(function (r, i) {
        var o = e[i];
        return r[i] = n(o) ? t.serializeObjectArray(o) : t.serializeObject(o), r;
      }, {});
      this.error("Fail to serialize: type '" + typeof e + "' is not assignable to type 'Dictionary'.\nReceived: " + JSON.stringify(e) + ".");
    }, f.prototype.serializeProperty = function (e, t, o) {
      var n,
        l = this,
        u = e[t],
        c = r.getType(e, t),
        p = this.getDataStructureInformation(c, u, o),
        d = p.isArrayProperty,
        f = p.isDictionaryProperty,
        y = p.isMapProperty,
        v = p.isSetProperty,
        h = o.predicate,
        P = o.type || c,
        g = s(P);
      if (u && (g || h)) {
        if (d || v) {
          var b = v ? Array.from(u) : u;
          return this.serializeObjectArray(b);
        }
        if (f || y) {
          if (!y) return this.serializeDictionary(u);
          var m = {};
          return u.forEach(function (e, t) {
            i(t) ? m[t] = e : l.error("Fail to serialize: type of '" + typeof t + "' is not assignable to type 'string'.\nReceived: " + JSON.stringify(t) + ".");
          }), this.serializeDictionary(m);
        }
        return this.serializeObject(u);
      }
      return "date" === (null === (n = null == P ? void 0 : P.name) || void 0 === n ? void 0 : n.toLocaleLowerCase()) && a(u) ? u.toISOString() : u;
    }, f;
  }(),
  h = function (t) {
    var i = r.getBaseClass(t);
    return i && i.name ? e(e([], h(i)), [i.name]) : [];
  },
  P = function (e) {
    return function (t) {
      var i,
        o = h(t),
        n = null !== (i = null == e ? void 0 : e.constructorParams) && void 0 !== i ? i : [];
      r.setJsonObject({
        baseClassNames: o,
        constructorParams: n
      }, t);
    };
  },
  g = function (e) {
    return function (t, i, o) {
      var n;
      if (void 0 === i && t.prototype) {
        var a = r.getParamTypes(t)[o];
        i = b(t.prototype.constructor).get(o), t = t.prototype, r.setType(a, t, i);
      }
      var s = null !== (n = r.getJsonPropertiesMetadata(t)) && void 0 !== n ? n : {};
      s[i] = m(i, e), r.setJsonPropertiesMetadata(s, t);
    };
  },
  b = function (e) {
    var t,
      r = e.toString().split("}")[0].replace(/(\/\*[\s\S]*?\*\/|\/\/.*$)/gm, "").replace(/[\r\t\n\v\f ]/g, ""),
      i = r.length;
    "," === r[i - 2] && (t = r[i - 1]);
    var o = t ? new RegExp("(?:(this|" + t + "|\\(" + t + "=t.call\\(this(,.)*\\)\\))\\.)([^,;\n}]+)", "gm") : new RegExp("(?:(this)\\.)([^,;\n}]+)", "gm"),
      n = new Map(),
      a = /(?:.*(?:constructor|function).*?(?=\())(?:\()(.+?(?=\)))/m.exec(r);
    if (!a || !a.length) return n;
    for (var s, l = a[1].split(","), u = function () {
        var e = s.length - 1,
          t = s[e].split("="),
          r = l.findIndex(function (e) {
            return e === t[1];
          });
        r > -1 && n.set(r, t[0]);
      }; s = o.exec(r);) u();
    return n;
  },
  m = function (e, n) {
    var a = {
      name: e.toString()
    };
    return n ? i(n) ? (a.name = n, a.isNameOverridden = !0, a) : (o(n) && (a = t(t({}, a), n), n.name && (a.name = n.name, a.isNameOverridden = !0), function (e) {
      if (!e) return !1;
      var t = r.getParamTypes(e),
        i = e.length;
      return (1 === i || 2 === i) && !t;
    }(n.type) && (delete a.type, a.predicate = n.type)), a) : a;
  };
export { P as JsonObject, g as JsonProperty, v as JsonSerializer, d as JsonSerializerOptions, l as isNullish, y as logError, f as throwError };