import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  Input,
  OnChanges,
  OnInit,
  QueryList,
  TemplateRef,
  ViewEncapsulation,
  SimpleChanges
} from '@angular/core';
import { CalendarPeriodListItemDirective } from './calendar-period-list-item.directive';
import { CalendarPeriod, CalendarPeriodTimeUnit } from '../calendar-period';
import { takeUntil, tap } from 'rxjs/operators';
import { OnDestroyMixin } from '../../common/on-destroy.mixin';
import { MixinBase } from '../../common/constructor-type.mixin';
import { CollectionViewer, DataSource } from '@angular/cdk/collections';
import { Observable, ReplaySubject } from 'rxjs';
import { Moment, unitOfTime } from 'moment';
import moment from 'moment';

/**
 * Provides a list of child periods for the given period
 */
export abstract class CalendarPeriodDataSource extends OnDestroyMixin(MixinBase) implements DataSource<CalendarPeriod> {
  data: CalendarPeriod[] = [];
  protected dataSubject = new ReplaySubject<CalendarPeriod[]>(1);
  data$ = this.dataSubject.asObservable().pipe(tap((value) => (this.data = value)));

  private activeDate: Moment = moment();
  private viewPeriod: CalendarPeriod;
  private periodView: PeriodViewType = 'year';
  private itemView: CalendarPeriodTimeUnit = 'month';

  normalizedPeriodView(): string {
    if (this.periodView === 'paddedMonth') {
      return this.periodView;
    }

    return moment.normalizeUnits(this.periodView);
  }

  normalizedItemView(): string {
    return moment.normalizeUnits(this.itemView);
  }

  setPeriodView(timeUnit: PeriodViewType): void {
    this.periodView = timeUnit;
    this.internalUpdateData();
  }

  setItemView(timeUnit: CalendarPeriodTimeUnit): void {
    this.itemView = timeUnit;
    this.internalUpdateData();
  }

  private internalUpdateData(): void {
    if (this.periodView === 'paddedMonth') {
      this.viewPeriod = CalendarPeriod.fromDateAndTimeUnit(this.activeDate, 'month', 'isoWeek');
    } else {
      this.viewPeriod = CalendarPeriod.fromDateAndTimeUnit(this.activeDate, this.periodView);
    }

    this.dataSubject.next(CalendarPeriod.asTimeUnit(this.viewPeriod, this.itemView));
  }

  /**
   * Page the data source to show the requested moment
   */
  showDate(targetMoment: Moment): void {
    this.activeDate = targetMoment;
    this.internalUpdateData();
  }

  constructor() {
    super();
    this.showDate(moment());
  }

  abstract connect(collectionViewer: CollectionViewer): Observable<CalendarPeriod[] | ReadonlyArray<CalendarPeriod>>;

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.ngOnDestroy();
  }
}

/**
 * A list of items for a specified period, as a specified unit of time
 */
@Component({
  selector: 'ph-flex-calendar-period-list',
  templateUrl: './calendar-period-list.component.html',
  styleUrls: ['./calendar-period-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarPeriodListComponent extends OnDestroyMixin(MixinBase) implements OnChanges {
  @Input() dataSource: CalendarPeriodDataSource;

  @ContentChildren(CalendarPeriodListItemDirective) children: QueryList<CalendarPeriodListItemDirective>;

  data$;

  constructor(private cdk: ChangeDetectorRef) {
    super();
  }

  trackByPeriod(index: number, period: CalendarPeriod): string {
    return `${period.startDate} ${period.endDate}`;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource) {
      this.data$ = this.dataSource.connect(null);
    }
  }

  getMatchingTemplate(period: CalendarPeriod): TemplateRef<any> {
    return this.children.find((child) => !child.phFlexCalendarPeriodListItemWhen || child.phFlexCalendarPeriodListItemWhen(period))
      ?.template;
  }
}

export type PeriodViewType = 'week' | 'isoWeek' | 'month' | 'paddedMonth' | 'year';
