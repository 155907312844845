import { TimeSlot } from '../../core/date-time/time-slot';
import { Capacity } from '../../core/domain/capacity';

export enum MeasurementMethod {
  MANUAL = 'MANUAL',
  AUTO = 'AUTO'
}

export enum MeasurementProvider {
  FUDURA = 'FUDURA',
  ANEXO = 'ANEXO'
}

export class GridPoint {
  id: string;
  customerId: string;
  customerName: string;
  customerLegalName: string;
  balanceResponsiblePartyId: string;
  balanceResponsiblePartyName: string;
  measurementDataId?: string;
  timeZone?: string;
  ean: string;
  description: string;
  measurementMethodAutomatic: boolean;
  measurementMethod: string;
  measurementCompanyId: string;
  measurementCompanyName: string;
  measurementProvider: MeasurementProvider;
  productionMeasurementChannels: ChannelInfo[];
  consumptionMeasurementChannels: ChannelInfo[];
  fiveMinuteDataAvailable: boolean;
  preQualified: boolean;
  gopacsPreQualified: boolean;
  pulseConsumptionChannelName?: string;
  pulseConsumptionWeight?: number;
  pulseConsumptionPeak?: Capacity;
  pulseDeviceId?: string;
  pulseProductionChannelName?: string;
  pulseProductionWeight?: number;
  pulseProductionPeak?: Capacity;
  pulseConsumptionDataAvailableForLastHour?: boolean;
  pulseProductionDataAvailableForLastHour?: boolean;
}

export class MeasurementProviderConfig {
  measurementMethod: MeasurementMethod;
  measurementCompanyId: string;
  measurementChannels: ChannelInfo[];
  fiveMinuteDataAvailable: boolean;
}

export class MeasurementProviderValidationConfig {
  measurementProvider: MeasurementProvider;
  channels: ChannelInfo[];
}

export class ChannelValidateResult {
  channel: string;
  status: string;
  interval: bigint;
}

export class ValidateResult {
  message: string;
  fiveMinuteDataAvailable: boolean;
  results: ChannelValidateResult[];
}

export class RetrieveMeasurementDataResult {
  ean: string;
  period: TimeSlot;
  numberOfMeasurements: bigint;
  timeTaken: string;
}

export class MeasurementProviderInfo {
  provider: MeasurementProvider;
  description: string;
}

export class ChannelInfo {
  name: string;
  description: string;
  direction: string;
}
