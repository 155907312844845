import { Directive, Injectable, InjectionToken, Optional } from '@angular/core';
import { AUTOCOMPLETE_DATA_SOURCE } from '../../material/autocomplete/autocomplete.component';
import { LazyLoadDataSource } from '../../material/autocomplete/lazy-load-data-source';
import { Customer } from './customer';
import { CustomerService } from './customer.service';
import { sortBy, toLower } from 'lodash-es';
import { map } from 'rxjs/operators';

@Injectable()
export abstract class CustomerDatasource extends LazyLoadDataSource<Customer> {
  constructor(protected customerService: CustomerService) {
    super();

    this.dataSource$ = this.customerService.getAll().pipe(
      map(result => sortBy(result, ['legalName']))
    );
  }

  filter(entities: ReadonlyArray<Customer>, filter: string | null): ReadonlyArray<Customer> {
    return entities.filter(
      (entity) => !filter || toLower(entity.legalName).includes(toLower(filter)) || toLower(entity.name).includes(toLower(filter))
    );
  }

  identifyWith(value: Customer): string {
    return value.id;
  }
}

@Injectable()
export class CustomerNameDatasource extends CustomerDatasource {
  displayEntityWith(value: Customer): string {
    return `${value.name}`;
  }
}

@Injectable()
export class CustomerLegalNameDatasource extends CustomerDatasource {
  displayEntityWith(value: Customer): string {
    return `${value.legalName}`;
  }
}

export function customerDatasourceFactory(
  shouldShowCustomerName: boolean | undefined,
  customerService: CustomerService
): CustomerDatasource {
  if (shouldShowCustomerName) {
    return new CustomerNameDatasource(customerService);
  } else {
    // false or undefined if not provided
    return new CustomerLegalNameDatasource(customerService);
  }
}

export const SHOULD_SHOW_CUSTOMER_NAME = new InjectionToken<boolean>(`Display customer names in autocomplete instead of legal names`);

@Directive({
  selector: '[phFlexCustomerDataSource]',
  providers: [
    {
      provide: AUTOCOMPLETE_DATA_SOURCE,
      useFactory: customerDatasourceFactory,
      deps: [[new Optional(), SHOULD_SHOW_CUSTOMER_NAME], CustomerService]
    }
  ]
})
export class CustomerDatasourceDirective {
}
