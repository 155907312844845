import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Money } from '../domain/money';

/**
 * Currency pipe but with money object
 */
@Pipe({
  name: 'money'
})
export class MoneyPipe extends CurrencyPipe implements PipeTransform {
  // TODO do not extend CurrencyPipe
  // @ts-ignore
  transform(
    value: Money,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string | null {
    if (!value) {
      return '';
    }
    return super.transform(value.amount, value.currency, display, digitsInfo, locale);
  }
}
