import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { flatMap } from 'lodash-es';
import { CUSTOMER_STORAGE_TOKEN } from '../core.module';
import { CUSTOMER_ID_SESSION_STORAGE_KEY } from '../storage-store/browser-storage';

@Injectable({
  providedIn: 'root'
})
export class ClearDefaultCustomerGuardService {
  constructor(private router: Router, @Inject(CUSTOMER_STORAGE_TOKEN) private customerStorage: Storage) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.customerStorage) {
      // Only evaluate when sessionStorage is available
      const customerId = this.customerStorage.getItem(CUSTOMER_ID_SESSION_STORAGE_KEY);

      if (customerId) {
        this.customerStorage.removeItem(CUSTOMER_ID_SESSION_STORAGE_KEY);
      }
    }

    // Route to root after clearing customer storage
    const baseUrl = flatMap(route.pathFromRoot.slice(0, -1), (p) => p.url.toString()).filter((a) => !!a);

    return this.router.navigate(baseUrl);
  }
}
