import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { DynamicOverlay } from '../../core/dynamic-overlay/dynamic-overlay';
import { SubjectProvider } from '../../d3-graph/d3-graph/common';
import { OnDestroyMixin } from '../../core/common/on-destroy.mixin';
import { MixinBase } from '../../core/common/constructor-type.mixin';

@Component({
  selector: 'ph-flex-loading-context',
  templateUrl: './loading-context.component.html',
  styleUrls: ['./loading-context.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingContextComponent extends OnDestroyMixin(MixinBase) implements OnChanges, OnDestroy {
  @Input()
  isLoading: boolean;

  isLoadingSubjectProvider = new SubjectProvider(this);

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isLoading) {
      this.isLoadingSubjectProvider.next(changes.isLoading.currentValue);
    }
  }
}
