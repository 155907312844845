export enum DateFnsDisplayFormatters {
  YEAR = 'yyyy',
  MONTH = 'MM-yyyy',
  MONTH_NICE = 'MMMM yyyy',
  DAY = 'dd-MM-yyyy',
  DAY_NICE = 'dd MMMM yyyy'
}

export enum DateFnsParseFormatters {
  YEAR = 'yyyy',
  MONTH = 'yyyy-MM',
  DAY = 'yyyy-MM-dd'
}
