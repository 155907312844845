import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateAsMonthDirective } from './date-as-month.directive';
import { DateAsYearDirective } from './date-as-year.directive';
import { DateAsDayDirective } from './date-as-day.directive';

@NgModule({
  declarations: [DateAsDayDirective, DateAsMonthDirective, DateAsYearDirective],
  imports: [CommonModule],
  exports: [DateAsDayDirective, DateAsMonthDirective, DateAsYearDirective]
})
export class FlexDateFormattersModule {}
