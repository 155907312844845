import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { SaveButtonComponent } from './save-button/save-button.component';
import { EntitySaveButtonComponent } from './entity-save-button/entity-save-button.component';
import { FlexCoreModule } from '../../core/core.module';

@NgModule({
  declarations: [EntitySaveButtonComponent, SaveButtonComponent],
  imports: [CommonModule, MatButtonModule, MatTooltipModule, MatIconModule, MatProgressSpinnerModule, FlexCoreModule],
  exports: [EntitySaveButtonComponent, SaveButtonComponent]
})
export class FlexSaveButtonModule {}
