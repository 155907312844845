import { Inject, Injectable } from '@angular/core';
import { APP_NAME, READABLE_APP_NAME } from '../core/core.module';
import { Router } from '@angular/router';
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { AppInsightsEnabled } from './app-insights.module';
import { FRONTEND_APP_INFO, FrontendAppInfo } from '../core/app-info/app-info.service';

@Injectable()
export class AppInsightsService {
  appInsights: ApplicationInsights;

  constructor(
    @Inject(APP_NAME) private appName: string,
    private router: Router,
    @Inject(AppInsightsEnabled) private isEnabled: boolean,
    @Inject(FRONTEND_APP_INFO) private frontendAppInfo: FrontendAppInfo
  ) {
    if (isEnabled) {
      this.init();
    }
  }

  private init(): void {
    // @ts-ignore
    const key = window.instrumentationKey;
    // @ts-ignore
    const prefix = window.instrumentationRolePrefix ?? '';

    if (!key) {
      console.warn('Application Insights NOT loaded');
      return;
    }

    const angularPlugin = new AngularPlugin();
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: key,
        extensions: [angularPlugin as any],
        extensionConfig: {
          [angularPlugin.identifier]: { router: this.router }
        }
      }
    });
    this.appInsights.loadAppInsights();
    this.appInsights.addTelemetryInitializer((envelop) => {
      envelop.tags['ai.cloud.role'] = prefix + this.appName;

      if (envelop.baseData.properties) {
        envelop.baseData.properties['Frontend Version'] = this.frontendAppInfo.version;
      } else {
        envelop.baseData.properties = {
          'Frontend Version': this.frontendAppInfo.version
        };
      }
    });
  }
}
