import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AsyncMessagingService } from '../async-messaging.service';

@Component({
  selector: 'ph-flex-connection-status',
  templateUrl: './connection-status.component.html',
  styleUrls: ['./connection-status.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'open',
        style({
          marginTop: 0
        })
      ),
      state(
        'closed',
        style({
          marginTop: '24px'
        })
      ),
      transition('open => closed', [animate('0.2s ease-in')]),
      transition('closed => open', [animate('0.2s ease-out')])
    ])
  ]
})
export class ConnectionStatusComponent implements OnInit {
  @ViewChild('content', { static: false }) child: ElementRef;

  state = 'closed';
  isConnected = false;

  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private asyncMessagingService: AsyncMessagingService,
    private cdr: ChangeDetectorRef
  ) {}

  @HostListener('mousemove', ['$event'])
  handleMouseMove(event: MouseEvent): void {
    if (this.state !== 'open') {
      return;
    }

    requestAnimationFrame(() => {
      const boundingRect = this.child.nativeElement.getBoundingClientRect();
      const isOverlapX = event.clientX >= boundingRect.left && event.clientX <= boundingRect.right;

      const actualOffset = isOverlapX ? Math.max(event.clientY - boundingRect.top, 0) : 0;
      if (this.child) {
        this.renderer.setStyle(this.child.nativeElement, 'padding-top', `${actualOffset}px`);
      }
    });
  }

  ngOnInit(): void {
    this.asyncMessagingService.isConnected$.subscribe((isConnected) => {
      this.isConnected = isConnected;
      this.state = 'open';
      this.cdr.detectChanges();

      if (isConnected) {
        setTimeout(() => {
          this.state = 'closed';
          this.cdr.detectChanges();
        }, 3000);
      }
    });
  }
}
