import { OnDestroyMixin } from '../../core/common/on-destroy.mixin';
import { MixinBase } from '../../core/common/constructor-type.mixin';
import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, combineLatest, distinctUntilChanged, Observable, of, startWith } from 'rxjs';
import { auditTime, map } from 'rxjs/operators';
import { memoize } from 'lodash-es';
import { SubjectProvider } from '../../d3-graph/d3-graph/common';
import { FrontendPaginatedDataSource } from '../paginated-data-source/frontend-paginated-data-source';
import { tableContextHeaders } from './table-selection-header-columns';
import { MatPaginator } from '@angular/material/paginator';
import { PaginatedDataSource } from '../paginated-data-source/paginated-data-source';

/**
 * Use to get consistent behavior for a multi select table.
 *
 * Should not be created in components, you should use the phFlexTableSelectionModel directive instead.
 *
 * Example usage for a header cell:
 *
 *   <mat-header-cell *matHeaderCellDef>
 *     <mat-checkbox
 *       (change)="tableSelectionModel.toggleAll()"
 *       [checked]="tableSelectionModel.getToggleAllChecked()"
 *       [indeterminate]="tableSelectionModel.getToggleAllIndeterminate()"
 *     ></mat-checkbox>
 *   </mat-header-cell>
 *
 * Example usage for a cell:
 *
 *   <mat-cell *matCellDef="let user; let i = index">
 *     <mat-checkbox (change)="tableSelectionModel.toggle(user, i)" [checked]="tableSelectionModel.isChecked$(user, i) | async"></mat-checkbox>
 *   </mat-cell>
 */
export class TableSelectionModel<T> extends OnDestroyMixin(MixinBase) {
  private selectionModel = new SelectionModel(true, []);

  private allRowsSelectedProvider = new SubjectProvider(this, new BehaviorSubject(false));

  /**
   * If true, all items are selected. This includes items that are not in the current page list.
   */
  get allRowsSelected(): boolean {
    return this.allRowsSelectedProvider.value;
  }

  set allRowsSelected(value: boolean) {
    this.allRowsSelectedProvider.next(value);
  }

  /**
   * If true, it means the current selection is not empty, and it was caused by a toggleAll call.
   */
  shouldShowContextRow = false;

  /**
   * Emit when selection model has changed, and with null at the start
   */
  private selectionChanged$ = this.selectionModel.changed.pipe(startWith(null));

  private pageDataProvider = new SubjectProvider<T[]>(this, new BehaviorSubject([]));

  private paginatorProvider = new SubjectProvider<MatPaginator>(this, new BehaviorSubject(null));

  /**
   * Get the count from the paginator
   */
  totalRowCount$ = this.paginatorProvider.value$.pipe(map((paginator) => paginator.length));

  /**
   * True if the selection model has at least one selected item.
   */
  hasSelection$: Observable<boolean> = this.selectionChanged$.pipe(map(() => this.selectionModel.selected.length > 0));
  toggleAllChecked$ = combineLatest([this.selectionChanged$, this.pageDataProvider.value$]).pipe(
    map(([_, pageData]) => pageData.length > 0 && pageData.every((data, i) => this.selectionModel.isSelected(this.trackBy(i, data))))
  );

  /**
   * Return an observable which returns true if at least one item is selected, and not all items are selected
   */
  toggleAllIndeterminate$ = this.selectionChanged$.pipe(
    map(() => {
      const pageSelection = this.pageDataProvider.value.filter((data, index) =>
        this.selectionModel.selected.includes(this.trackBy(index, data))
      );
      const hasPageSelection = pageSelection.length > 0;
      const fullPageSelected = pageSelection.length === this.pageDataProvider.value.length;
      return hasPageSelection && !fullPageSelected;
    })
  );

  /**
   * Return the state of allRowsSelected, but will also return true when the paginator total row count is equal to the selected row count
   */
  allRowsSelected$ = combineLatest([this.allRowsSelectedProvider.value$, this.totalRowCount$]).pipe(
    map(([allRowsSelected, totalRowCount]) => {
      return allRowsSelected || totalRowCount === this.selectionModel.selected.length;
    })
  );

  /**
   * Return an observable which provides the checked status for a given value.
   *
   * Use to determine checkbox values in cells
   */
  isChecked$ = memoize((value: T, index: number): Observable<boolean> => {
    return this.selectionChanged$.pipe(map(() => this.isChecked(value, index)));
  });

  contextColumns$ = this.selectionChanged$.pipe(map(() => (this.shouldShowContextRow ? tableContextHeaders : [])));

  trackBy: (index: number, item: T) => string = (index: number) => `${index}`;

  /**
   * Follow data, generic implementation for custom use cases.
   */
  followData(data$: Observable<T[]>, pageData$: Observable<T[]> = data$): void {
    this.pageDataProvider.follow(pageData$);
    data$.subscribe(() => this.clear());
  }

  /**
   * Follow data provided by a FrontendPaginatedDataSource
   */
  followFrontendPaginatedDataSource(paginatedDataSource: FrontendPaginatedDataSource<T>): void {
    if (!paginatedDataSource.paginator) {
      console.error('Paginator was not set on the provided paginated data source');
    } else {
      this.paginatorProvider.next(paginatedDataSource.paginator);
    }

    this.followData(
      paginatedDataSource.data$.pipe(
        map(() => paginatedDataSource.paginationHelper.originalData),
        distinctUntilChanged()
      ),
      paginatedDataSource.data$.pipe(distinctUntilChanged())
    );
  }

  /**
   * Follow data provided by a PaginatedDataSource
   */
  followPaginatedDataSource(paginatedDataSource: PaginatedDataSource<T>): void {
    if (!paginatedDataSource.paginator) {
      console.error('Paginator was not set on the provided paginated data source');
    } else {
      this.paginatorProvider.next(paginatedDataSource.paginator);
    }

    this.followData(paginatedDataSource.data$.pipe(distinctUntilChanged()));
  }

  /**
   * Selects all items on the page, if nothing is selected.
   * Otherwise, remove selection.
   */
  togglePage(): void {
    if (this.selectionModel.selected.length > 0) {
      this.clear();
    } else {
      this.shouldShowContextRow = true;
      this.allRowsSelected = false;
      this.selectionModel.select(...this.pageDataProvider.value.map((a, i) => this.trackBy(i, a)));
    }
  }

  /**
   * Select all items
   */
  selectAll(): void {
    this.shouldShowContextRow = true;
    this.allRowsSelected = true;
  }

  clear(): void {
    this.shouldShowContextRow = false;
    this.allRowsSelected = false;
    this.selectionModel.clear();
  }

  toggle(value: T, index: number): void {
    this.shouldShowContextRow = false;
    this.allRowsSelected = false;
    this.selectionModel.toggle(this.trackBy(index, value));
  }

  getSelectedRowCount$(): Observable<number> {
    if (this.allRowsSelected) {
      return this.totalRowCount$;
    } else {
      return of(this.selectionModel.selected.length);
    }
  }

  getSelectedRows(): any[] {
    return this.selectionModel.selected;
  }

  isChecked(value: T, index: number): boolean {
    return this.selectionModel.isSelected(this.trackBy(index, value));
  }
}
