import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ph-flex-vertical-center',
  templateUrl: './vertical-center.component.html',
  styleUrls: ['./vertical-center.component.scss']
})
export class VerticalCenterComponent {
  @Input()
  @HostBinding('class.add-mat-icon-spacing')
  iconSpacing: boolean = true;

  @Input()
  @HostBinding('class.add-spinner-spacing')
  spinnerSpacing: boolean = true;

  @Input()
  @HostBinding('class.vertical-center-dialog')
  centerDialog: boolean = true;

  @Input()
  @HostBinding('class.apply-text-crop-to-child-span')
  textCrop: boolean = true;

  @Input()
  @HostBinding('class.align-right')
  alignRight: boolean = false;

  @Input()
  @HostBinding('class.align-center')
  alignCenter: boolean = false;

  @Input()
  @HostBinding('class.apply-vertical-center-to-mat-slide-toggle-label')
  slideToggleLabel: boolean = true;

  constructor() {}
}
