import { isArray, isNil } from 'lodash-es';

/**
 * Return an array with a value
 * Returns the value if the value is an array
 * Returns an array with the value if the value is not undefined or null
 * Returns an empty array if the value is undefined or null
 * @param defaultVal A value or array
 */
export function arrayWithDefault<T>(defaultVal?: T | T[] | null): T[] {
  if (isNil(defaultVal)) {
    return [];
  }

  return isArray(defaultVal) ? defaultVal : [defaultVal];
}
