import { ScaleBand, ScaleContinuousNumeric, ScaleTime } from 'd3-scale';
import { BaseType, Selection } from 'd3';
import { D3GraphSubmodule } from '../d3-graph-submodule';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DestroyableMixin, OnDestroyMixin, OnDestroyProvider } from 'projects/flex-app-shared/src/lib/core/common/on-destroy.mixin';
import { MixinBase } from '../../../../core/common/constructor-type.mixin';
import { DynamicDataHelper, SubjectProvider } from '../../common';

export interface VerticalLinesDatum {
  /**
   * X position used for x1 and x2
   */
  xPosition: number;

  /**
   * Color of the line
   */
  color: string;

  /**
   *  Start of the line (y1)
   */
  yMin: number;

  /**
   * End of the line (y2)
   */
  yMax: number;

  /**
   * Stroke width in px for the vertical lines
   */
  strokeWidthPx: number;
}

export class VerticalLinesConfig {
  /**
   * Stroke width in px for the vertical lines
   */
  strokeWidthPx: number = 1;

  /**
   * Stroke color
   */
  strokeColor: string = '#f6f8fa';
}

type SupportedXScale = ScaleContinuousNumeric<any, any> | ScaleTime<number, number>;
type SupportedYScale = ScaleContinuousNumeric<any, any> | ScaleBand<any>;

/**
 * Draw vertical lines (only) at the start and end of the provided x scale
 */
export class D3GraphScaleBandVerticalLines extends DestroyableMixin(OnDestroyMixin(MixinBase)) implements D3GraphSubmodule {
  private xScaleProvider = new SubjectProvider<SupportedXScale>(this);
  private yScaleProvider = new SubjectProvider<SupportedYScale>(this);
  private configProvider = new SubjectProvider<VerticalLinesConfig>(this, new BehaviorSubject(new VerticalLinesConfig()));
  private verticalLinesHelper: VerticalLinesHelper;

  constructor(onDestroyProvider: OnDestroyProvider) {
    super();

    this.registerOnDestroyProvider(onDestroyProvider);
  }

  destroy(): void {
    this.ngOnDestroy();
  }

  setXScale$(scale$: Observable<SupportedXScale>): void {
    this.xScaleProvider.follow(scale$);
  }

  setXScale(scale: SupportedXScale): void {
    this.xScaleProvider.next(scale);
  }

  setYScale$(scale$: Observable<SupportedYScale>): void {
    this.yScaleProvider.follow(scale$);
  }

  setYScale(scale: SupportedYScale): void {
    this.yScaleProvider.next(scale);
  }

  setConfig(config: Partial<VerticalLinesConfig>): void {
    this.configProvider.next({
      ...this.configProvider.value,
      ...config
    });
  }

  attach(mainSvg: Selection<BaseType, any, any, any>): () => any {
    const node = mainSvg.append('g').attr('class', 'vertical-lines');

    this.verticalLinesHelper = new VerticalLinesHelper(node);

    const subscription = combineLatest([this.xScaleProvider.value$, this.yScaleProvider.value$, this.configProvider.value$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(([xScale, yScale, config]) => {
        const yRange = yScale.range();
        this.verticalLinesHelper.setData(
          getLineXPositionsFromScale(xScale).map((xPosition) => ({
            xPosition,
            color: config.strokeColor,
            yMin: yRange[0],
            yMax: yRange[1],
            strokeWidthPx: config.strokeWidthPx
          }))
        );
      });

    return () => subscription.unsubscribe();
  }
}

/**
 * Draw vertical lines for the provided x values
 */
export class VerticalLinesHelper extends DynamicDataHelper<VerticalLinesDatum> {
  protected nodeName = 'line';
  protected class = 'vertical-line';

  protected setDynamic(selectAllWithData: Selection<BaseType, VerticalLinesDatum, BaseType, any>): void {
    selectAllWithData
      .attr('x1', (datum) => datum.xPosition)
      .attr('y1', (datum) => datum.yMin)
      .attr('x2', (datum) => datum.xPosition)
      .attr('y2', (datum) => datum.yMax)
      .attr('stroke', (datum) => datum.color)
      .attr('stroke-width', (datum) => datum.strokeWidthPx);
  }

  protected setStatic(selectAllWithData: Selection<BaseType, VerticalLinesDatum, BaseType, any>): void {
    selectAllWithData.attr('shape-rendering', 'crispEdges');
  }
}

/**
 * Get the horizontal y positions to draw lines on given a supported scale.
 */
export function getLineXPositionsFromScale(scale: SupportedXScale): number[] {
  const range = scale.range();
  return [
    range[0], // Add the first ...
    range[1] // And the last line
  ];
}
