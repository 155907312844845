import {
  Component,
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Renderer2,
  RendererFactory2,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { CdkPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { flatMap, isEmpty, isEqual } from 'lodash-es';
import { AbstractControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ErrorStateMatcher } from '@angular/material/core';
import { DestroyableMixin, OnDestroyMixin } from '../../core/common/on-destroy.mixin';
import { AppInjector } from '../../core/common/app-injector';
import { MixinBase } from '../../core/common/constructor-type.mixin';
import { FlexTooltipContainerComponent } from './flex-tooltip-container.component';
import { PortalOverlayManager } from './portal-overlay-manager';
import { ComponentPortalOverlayManager } from './component-portal-overlay-manager';
import { EmbeddedComponentOverlayManager } from './embedded-component-overlay-manager';

@Component({
  selector: 'ph-flex-tooltip-arrow',
  styleUrls: ['tooltip-arrow.scss'],
  encapsulation: ViewEncapsulation.None,
  template: ''
})
export class FlexTooltipArrowComponent {}

enum TooltipTrigger {
  NONE,
  CLICK,
  HOVER
}

/**
 * Show tooltip based on hover state.
 * No scrolling support
 * Supports cdkPortals only
 */
@Directive({
  selector: '[phFlexTooltip]'
})
export class TooltipDirective extends OnDestroyMixin(MixinBase) implements OnInit, OnChanges {
  @Input('phFlexTooltip') input: CdkPortal;
  @Input() style: 'normal' | 'warn' | 'error' = 'error';
  @Input() trigger: TooltipTrigger = TooltipTrigger.HOVER;
  @Input() context: any = {};
  @Input() phFlexTooltipDisabled: boolean = false;

  private arrowSizePx = ArrowSize.SMALL;
  private arrowInset = 2;
  private mainPortalOverlayManager: EmbeddedComponentOverlayManager;
  private mainPortalTriggerBehavior: TooltipTriggerBehavior;
  private arrowPortalOverlayManager: ComponentPortalOverlayManager;
  private arrowPortalTriggerBehavior: TooltipTriggerBehavior;

  constructor(private overlay: Overlay, private elementRef: ElementRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.context && changes.context.firstChange && !isEmpty(this.input.context) && !isEqual(this.input.context, this.context)) {
      console.error(
        'Tooltip directive got a portal that already has a context, make sure the template is initialized for each instance of tooltip.'
      );
      // TODO is the current setup the correct way to do it? Should it be a template instead, which then gets initialized as a cdkPortal?
      // This would add more complexity, since it has to be attached to a different spot in the DOM.
    }

    if (changes.input) {
      if (this.mainPortalOverlayManager) {
        this.mainPortalOverlayManager.destroy();
        this.mainPortalTriggerBehavior.destroy();
      }

      // Register context to be injected into the template
      this.input.context = {
        ...this.context
      };

      this.mainPortalOverlayManager = new EmbeddedComponentOverlayManager(this, this.getMainOverlayRef());
      this.mainPortalOverlayManager.registerEmbeddedComponent(FlexTooltipContainerComponent, this.input);
      this.mainPortalOverlayManager.disabled = this.phFlexTooltipDisabled;

      this.mainPortalTriggerBehavior = new TooltipTriggerBehavior(this.elementRef)
        .registerOnDestroyProvider(this)
        .registerPortalOverlayManager(this.mainPortalOverlayManager)
        .attach();
    } else if (changes.context) {
      this.input.context = {
        ...this.context
      };
      this.mainPortalOverlayManager.registerEmbeddedComponent(FlexTooltipContainerComponent, this.input);
    }

    if (changes.phFlexTooltipDisabled && this.arrowPortalOverlayManager) {
      this.mainPortalOverlayManager.disabled = this.phFlexTooltipDisabled;
      this.arrowPortalOverlayManager.disabled = this.phFlexTooltipDisabled;
    }
  }

  ngOnInit(): void {
    this.arrowPortalOverlayManager = new ComponentPortalOverlayManager(this, this.getArrowOverlayRef());
    this.arrowPortalOverlayManager.registerComponent(FlexTooltipArrowComponent);
    this.arrowPortalOverlayManager.disabled = this.phFlexTooltipDisabled;

    this.arrowPortalTriggerBehavior = new TooltipTriggerBehavior(this.elementRef)
      .registerOnDestroyProvider(this)
      .registerPortalOverlayManager(this.arrowPortalOverlayManager)
      .attach();
  }

  private getMainOverlayRef(): OverlayRef {
    const mainPositionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: -this.arrowSizePx + this.arrowInset
        }
      ]);

    return this.overlay.create({
      panelClass: ['flex-pop-over-context', this.style],
      positionStrategy: mainPositionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition({
        autoClose: true
      })
    });
  }

  private getArrowOverlayRef(): OverlayRef {
    const arrowPositionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(this.elementRef)
      .withFlexibleDimensions(false)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          offsetY: this.arrowInset
        }
      ]);

    return this.overlay.create({
      panelClass: ['flex-pop-over-context-arrow', this.style, 'small'],
      positionStrategy: arrowPositionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition({
        autoClose: true
      })
    });
  }
}

export abstract class InteractionTriggerBehavior extends DestroyableMixin(OnDestroyMixin(MixinBase)) {
  protected portalOverlayManager: PortalOverlayManager;

  protected unlisteners: (() => void)[] = [];

  protected renderer: Renderer2;
  protected zone: NgZone;

  constructor() {
    super();
    this.renderer = AppInjector.get(RendererFactory2).createRenderer(null, null);
    this.zone = AppInjector.get(NgZone);
  }

  abstract attach(): this;

  registerPortalOverlayManager(portalOverlayManager: PortalOverlayManager): this {
    this.portalOverlayManager = portalOverlayManager;
    return this;
  }

  unattach(): this {
    this.unlisteners.forEach((unlistener) => unlistener());
    return this;
  }

  destroy(): void {
    this.unattach();
  }
}

/**
 * Implement click outisde of element
 */
export class ClickTriggerBehavior extends InteractionTriggerBehavior {
  private elementRefs: ElementRef[] = [];

  constructor(...elementRefs: ElementRef[]) {
    super();
    this.elementRefs = elementRefs;
  }

  attach(): this {
    this.zone.runOutsideAngular(() => {
      this.unlisteners = [
        ...this.unlisteners,
        ...this.elementRefs.map((elementRef) => {
          return this.renderer.listen(elementRef.nativeElement, 'click', () => this.zone.run(() => this.portalOverlayManager.toggle()));
        })
      ];
    });
    return this;
  }
}

export class FormControlErrorTriggerBehavior extends InteractionTriggerBehavior {
  private formControl: AbstractControl;
  private errorStateMatcher: ErrorStateMatcher;
  private forceShow = false;

  setForceShow(forceShow: boolean): this {
    this.forceShow = forceShow;
    this.doCheck();
    return this;
  }

  registerFormControl(control: AbstractControl): this {
    this.formControl = control;
    return this;
  }

  registerErrorStateMatcher(errorStateMatcher: ErrorStateMatcher): this {
    this.errorStateMatcher = errorStateMatcher;
    return this;
  }

  doCheck(): void {
    if (this.forceShow || this.formControl.invalid) {
      this.portalOverlayManager.open();
    } else {
      this.portalOverlayManager.close();
    }
  }

  attach(): this {
    this.doCheck();
    this.formControl.statusChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      return this.doCheck();
    });
    return this;
  }
}

/**
 * TODO Implement listening for mouseenter and mouseleave on portal
 */
export class TooltipTriggerBehavior extends InteractionTriggerBehavior {
  private elementRefs: ElementRef[] = [];
  private hoverCounter = 0;
  private checkScheduled = false;

  constructor(...elementRefs: ElementRef[]) {
    super();
    this.elementRefs = elementRefs;
  }

  attach(): this {
    this.zone.runOutsideAngular(() => {
      this.unlisteners = [
        ...this.unlisteners,
        ...flatMap(this.elementRefs, (elementRef) => {
          return [
            this.renderer.listen(elementRef.nativeElement, 'mouseenter', () => this.zone.run(() => this.mouseEnter())),
            this.renderer.listen(elementRef.nativeElement, 'mouseleave', () => this.zone.run(() => this.mouseLeave())),
            this.renderer.listen(elementRef.nativeElement, 'click', () => this.zone.run(() => this.portalOverlayManager.toggle()))
          ];
        })
      ];
    });
    return this;
  }

  private mouseEnter(): void {
    this.hoverCounter += 1;
    this.checkHover();
  }

  private mouseLeave(): void {
    this.hoverCounter -= 1;
    this.checkHover();
  }

  private checkHover(): void {
    if (this.checkScheduled) {
      return;
    }

    this.checkScheduled = true;
    this.zone.run(() => {
      this.checkScheduled = false;

      if (this.hoverCounter > 0) {
        this.portalOverlayManager.open();
      } else if (this.hoverCounter === 0) {
        this.portalOverlayManager.close();
      }
    });
  }
}

export enum ArrowSize {
  SMALL = 6,
  LARGE = 12
}
