import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateRangeFixedPeriodComponent } from './date-range-fixed-period/date-range-fixed-period.component';
import { DateRangeFixedPeriodDayComponent } from './date-range-fixed-period-day/date-range-fixed-period-day.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { DateRangeFixedPeriodDefaultDayDirective } from './date-range-fixed-period-default/date-range-fixed-period-default-day.directive';
import { MomentDateModule } from '@angular/material-moment-adapter';
import { DateRangeFixedPeriodMonthComponent } from './date-range-fixed-period-month/date-range-fixed-period-month.component';
import { DateRangeFixedPeriodStringValueAccessorDirective } from './date-range-fixed-period-string-value-accessor.directive';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { DateRangeFixedPeriodYearComponent } from './date-range-fixed-period-year/date-range-fixed-period-year.component';
import { DateRangeFixedPeriodButtonGroupComponent } from './date-range-fixed-period-button-group/date-range-fixed-period-button-group.component';
import { DateRangeFixedPeriodCombinedInputComponent } from './date-range-fixed-period-combined-input/date-range-fixed-period-combined-input.component';
import { MatIconModule } from '@angular/material/icon';
import { DateRangeFixedPeriodToolbarDirective } from './date-range-fixed-period-toolbar/date-range-fixed-period-toolbar.directive';
import { FlexDateFormattersModule } from '../../core/date-formatters/flex-date-formatters.module';
import { FlexFiltersModule } from '../filters/filters.module';
import { DateRangeFixedPeriodDialogDirective } from './date-range-fixed-period-dialog/date-range-fixed-period-dialog.directive';
import { FlexErrorMessagesModule } from '../error-messages/error-messages.module';
import { DateRangeFixedPeriodDefaultYearDirective } from './date-range-fixed-period-default/date-range-fixed-period-default-year.directive';
import { MatButtonModule } from '@angular/material/button';
import { DateRangeFixedPeriodCalendarHeaderComponent } from './date-range-fixed-period-date-picker-header/date-range-fixed-period-calendar-header.component';
import { DateRangeFixedPeriodDefaultMonthDirective } from './date-range-fixed-period-default/date-range-fixed-period-default-month.directive';

@NgModule({
  declarations: [
    DateRangeFixedPeriodComponent,
    DateRangeFixedPeriodDayComponent,
    DateRangeFixedPeriodDefaultDayDirective,
    DateRangeFixedPeriodDefaultMonthDirective,
    DateRangeFixedPeriodDefaultYearDirective,
    DateRangeFixedPeriodMonthComponent,
    DateRangeFixedPeriodYearComponent,
    DateRangeFixedPeriodStringValueAccessorDirective,
    DateRangeFixedPeriodButtonGroupComponent,
    DateRangeFixedPeriodCombinedInputComponent,
    DateRangeFixedPeriodToolbarDirective,
    DateRangeFixedPeriodDialogDirective,
    DateRangeFixedPeriodCalendarHeaderComponent
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatInputModule,
    FlexFiltersModule,
    MomentDateModule,
    ReactiveFormsModule,
    MatSelectModule,
    FlexDateFormattersModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatIconModule,
    FlexErrorMessagesModule
  ],
  exports: [
    DateRangeFixedPeriodComponent,
    DateRangeFixedPeriodDefaultDayDirective,
    DateRangeFixedPeriodDefaultMonthDirective,
    DateRangeFixedPeriodDefaultYearDirective,
    DateRangeFixedPeriodStringValueAccessorDirective,
    DateRangeFixedPeriodButtonGroupComponent,
    DateRangeFixedPeriodCombinedInputComponent,
    DateRangeFixedPeriodToolbarDirective,
    DateRangeFixedPeriodDialogDirective,
    DateRangeFixedPeriodDayComponent,
    DateRangeFixedPeriodMonthComponent
  ]
})
export class FlexDateRangeFixedPeriodModule {}
