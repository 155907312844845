import { Location } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RouterStateSnapshot } from '@angular/router';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Control, ControlView } from '../../../../api-private/control/control';
import { ControlService } from '../../../../api-private/control/control.service';
import { findParameterValue } from '../../../../core/common/find-parameter-value';
import { PeriodView } from '../../../../core/domain/period';
import { MessageService } from '../../../../core/messages/message.service';
import { EntityState, EntityStateModel, FetchEntity, SaveEntity } from '../../../common/entity.state';
import { FormStatus, NgxsFormState } from '../../../common/ngxs-form-state';
import { RouterInitializer } from '../../../common/router-initializer';
import { LoadControlsCommand } from '../controls/controls.actions';
import {
  ControlLoadedEvent,
  DeleteControlCommand,
  LoadControlCommand,
  ResetControlCommand,
  SaveControlCommand,
  SaveControlConfigurationCommand,
  SendScheduleCommand
} from './control.actions';
import { tap } from 'rxjs/operators';
import { Direction } from '../../../../core/common/direction';
import { Capacity } from '../../../../core/domain/capacity';

export class ControlStateModel extends EntityStateModel<Control> implements NgxsFormState<Control> {
  model: Control = {
    id: null,
    customerId: null,
    customerName: null,
    description: '',
    direction: Direction.PRODUCTION,
    power: Capacity.kW(0),
    deviceId: null,
    deviceContactId: null,
    gridPoints: [],
    enabled: true,
    upwardsR3Participation: false,
    downwardsR3Participation: false,
    incidentReserveOnly: false,
    switchCooldownTime: 0
  };
  dirty: boolean | null;
  status: FormStatus | null;
}

@State<ControlStateModel>({
  name: 'control',
  defaults: new ControlStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class ControlState extends EntityState<Control> implements RouterInitializer<ControlStateModel> {
  constructor(
    private controlService: ControlService,
    private messageService: MessageService,
    private snackBarService: MatSnackBar,
    protected store: Store,
    private location: Location,
    // private deviceService: DeviceService,
    private zone: NgZone
  ) {
    super(store);
  }

  @Selector()
  public static getControl(state: ControlStateModel): ControlView {
    return {
      ...state.model
    } as ControlView;
  }

  @Selector()
  public static isBusy(state: ControlStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  public static isInitialized(state: ControlStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  public static isBusySending(state: ControlStateModel): boolean {
    return state.isBusySending;
  }

  @Selector()
  public static error(state: ControlStateModel): string | null {
    return state.sendError;
  }

  @Selector()
  public static isFormDirty(state: ControlStateModel): boolean {
    return state.dirty;
  }

  @Selector()
  public static isFormValid(state: ControlStateModel): boolean {
    return state.status === FormStatus.VALID;
  }

  @Action(RouterNavigation)
  routerInit(
    { getState, dispatch, setState }: StateContext<ControlStateModel>,
    { routerState }: RouterNavigation<RouterStateSnapshot>
  ): void {
    const controlId = findParameterValue(routerState, 'controlId');
    const customerId = findParameterValue(routerState, 'customerId');
    const state = getState();

    if (controlId && controlId !== 'new') {
      // FetchEntity control unit data for id
      dispatch(new LoadControlCommand(controlId));
      // } else if (getState().model.id || getState().flatDeviceContacts.length > 0 || controlId === 'new') {
    } else if (getState().model.id || controlId === 'new') {
      // If we had control unit data, remove it
      dispatch(new ResetControlCommand({ customerId }));
    } else if (state.model.customerId !== customerId) {
      setState({ ...state, model: { ...state.model, customerId } });
    }
  }

  @Action(ResetControlCommand)
  resetControl({ setState, getState, dispatch }: StateContext<ControlStateModel>, { payload }: ResetControlCommand): void {
    const newState = new ControlStateModel();
    setState({ ...newState, model: { ...newState.model, ...payload } });

    // TODO verify if this does not break anything
    // dispatch(new ResetGridPointsCommand());
  }

  @FetchEntity()
  @Action(LoadControlCommand)
  initOperationData({ dispatch }: StateContext<ControlStateModel>, { id, showForm }: LoadControlCommand): Observable<Control> | undefined {
    if (id === 'new') {
      return;
    }

    return this.controlService.getById(id).pipe(
      tap(
        (control) => {
          return dispatch(new ControlLoadedEvent(control, showForm));
        },
        (err) => this.messageService.error(err.message)
      )
    );
  }

  @SaveEntity()
  @Action(SaveControlCommand)
  saveOperation({ getState, setState, dispatch }: StateContext<ControlStateModel>, action: SaveControlCommand): Observable<Control> {
    const control = getState().model as Control;
    const observable = control.id ? this.controlService.update(control) : this.controlService.add(control);
    return observable.pipe(
      tap(
        (result) => (action.navigateAfterSave ? this.location.back() : dispatch(new ControlLoadedEvent(result))),
        (err) => this.messageService.error(err.message)
      )
    );
  }

  @SaveEntity()
  @Action(SaveControlConfigurationCommand)
  saveControlConfigurationOperation(
    { getState, setState, dispatch }: StateContext<ControlStateModel>,
    action: SaveControlConfigurationCommand
  ): Observable<Control> {
    const control = getState().model as Control;
    const observable = this.controlService.configureControl(control);
    return observable.pipe(
      tap(
        (result) => (action.navigateAfterSave ? this.location.back() : dispatch(new ControlLoadedEvent(result))),
        (err) => this.messageService.error(err.message)
      )
    );
  }

  @Action(ControlLoadedEvent)
  setControlData({ setState, getState }: StateContext<ControlStateModel>, { payload }: ControlLoadedEvent): void {
    const state = getState();
    setState({
      ...state,
      model: {
        ...state.model,
        ...payload
      }
    });
  }

  @SaveEntity()
  @Action(SendScheduleCommand)
  sendSchedule({ getState, setState, dispatch }: StateContext<ControlStateModel>, command: SendScheduleCommand): Observable<object> {
    return this.controlService
      .sendTestSchedule(getState().model.id, command.schedule.state, PeriodView.serialize(command.schedule.period))
      .pipe(
        tap(
          (v) => this.zone.run(() => this.snackBarService.open('Schedule sent to device successfully', 'OK')),
          (err) => {
            const msg = `Unable to send schedule to device${err.message ? ': ' + err.message : ''}`;
            return this.messageService.error(msg);
          }
        )
      );
  }

  @Action(DeleteControlCommand)
  deleteControl({ dispatch }: StateContext<ControlStateModel>, { control }: DeleteControlCommand): Observable<any> {
    return this.controlService.delete(control).pipe(tap(() => dispatch(new LoadControlsCommand())));
  }
}
