import { addDays, differenceInCalendarDays, differenceInMilliseconds, endOfDay, format, parseISO, startOfDay, subDays } from 'date-fns';
import { BoundType, NumberRange } from 'range-ts';
import { DateFnsParseFormatters } from '../date-formatters/date-fns-formatters';
import { Period } from '../domain/period';
import { addTimeUnit, endOfTimeUnit, isSameTimeUnit, normalizeToDate, startOfTimeUnit } from './utils';

export type CalendarPeriodTimeUnit = 'hour' | 'day' | 'week' | 'isoWeek' | 'month' | 'year';

// Note that weeks start on Sunday, and isoWeeks on monday
const defaultPeriodViews: CalendarPeriodTimeUnit[] = ['day', 'month', 'year'];

export class CalendarPeriod {
  startDate: string;
  endDate: string;

  /**
   * Returns a list of calendarPeriods representing the start of each time period
   */
  static asTimeUnit(calendarPeriod: CalendarPeriod, timeUnit: CalendarPeriodTimeUnit): CalendarPeriod[] {
    if (!calendarPeriod) {
      return [];
    }

    const start = normalizeToDate(calendarPeriod.startDate);
    const end = normalizeToDate(calendarPeriod.endDate);

    const calendarPeriods = [];
    for (
      let currentDate = start;
      currentDate <= end || isSameTimeUnit(currentDate, end, timeUnit);
      currentDate = addTimeUnit(currentDate, 1, timeUnit)
    ) {
      calendarPeriods.push(CalendarPeriod.fromDateAndTimeUnit(currentDate, timeUnit));
    }

    return calendarPeriods;
  }

  /**
   * Get the unitOfTime of individual items within a given period. E.g. when a period of one year is given, expect 'months' to be the output.
   */
  static getPeriodView(
    calendarPeriod: CalendarPeriod,
    supportedPeriods: CalendarPeriodTimeUnit[] = defaultPeriodViews
  ): CalendarPeriodTimeUnit | undefined {
    const foundIndex = getPeriodIndex(calendarPeriod, supportedPeriods);

    if (foundIndex === -1) {
      return undefined;
    }

    return supportedPeriods[Math.max(0, foundIndex - 1)];
  }

  /**
   * Get the unitOfTime of a given period. E.g. when a period of one year is given, expect 'year' to be the output.
   */
  static getView(
    calendarPeriod: CalendarPeriod,
    supportedPeriods: CalendarPeriodTimeUnit[] = defaultPeriodViews
  ): CalendarPeriodTimeUnit | undefined {
    const foundIndex = getPeriodIndex(calendarPeriod, supportedPeriods);

    if (foundIndex === -1) {
      return undefined;
    }

    return supportedPeriods[Math.max(0, foundIndex)];
  }

  static toCalendarPeriod(startDate: any, endDate: any): CalendarPeriod | null {
    const normalizedStartDate = normalizeToDate(startDate);
    const normalizedEndDate = normalizeToDate(endDate);
    if (!normalizedEndDate || !normalizedStartDate) {
      return null;
    }
    return {
      startDate: format(normalizedStartDate, DateFnsParseFormatters.DAY),
      endDate: format(normalizedEndDate, DateFnsParseFormatters.DAY)
    };
  }

  /**
   * Return the period surrounding the provided moment
   */
  static fromDateAndTimeUnit(
    target: any,
    timeUnit: CalendarPeriodTimeUnit,
    paddingTimeUnit: CalendarPeriodTimeUnit = timeUnit
  ): CalendarPeriod {
    return CalendarPeriod.toCalendarPeriod(
      startOfTimeUnit(startOfTimeUnit(target, timeUnit), paddingTimeUnit),
      endOfTimeUnit(endOfTimeUnit(target, timeUnit), paddingTimeUnit)
    );
  }

  /**
   * Return the period within a CalendarPeriod
   */
  static diff(calendarPeriod: CalendarPeriod): number {
    return differenceInMilliseconds(endOfDay(normalizeToDate(calendarPeriod.endDate)), normalizeToDate(calendarPeriod.startDate));
  }

  static toNumberRange(calendarPeriod: CalendarPeriod | Period): NumberRange {
    // Updated to generate numberRanges based on the Europe/Amsterdam timezone

    return NumberRange.closedOpen(
      startOfDay(normalizeToDate(calendarPeriod.startDate)).valueOf(),
      startOfDay(addDays(normalizeToDate(calendarPeriod.endDate), 1)).valueOf()
    );
  }

  static fromNumberRange(numberRange: NumberRange): CalendarPeriod | null {
    if (!numberRange) {
      return null;
    }

    if (numberRange.lowerBoundType !== BoundType.CLOSED || numberRange.upperBoundType !== BoundType.OPEN) {
      console.warn('Could not convert non closed numberRange to calendarPeriod');
      return null;
    }

    return CalendarPeriod.toCalendarPeriod(
      normalizeToDate(numberRange.lowerEndpoint).toISOString(),
      endOfDay(subDays(normalizeToDate(numberRange.upperEndpoint), 1))
    );
  }

  /**
   * Calendar period contains the provided moment
   */
  static contains(calendarPeriod: CalendarPeriod, contains: any): boolean {
    const containsDate = normalizeToDate(contains);
    const numberRange = CalendarPeriod.toNumberRange(calendarPeriod);
    return numberRange.contains(containsDate.valueOf());
  }

  /**
   * Return all calendar days for the given calendar period span
   */
  static calendarDays(calendarPeriod: CalendarPeriod): CalendarPeriod[] {
    const start = startOfDay(parseISO(calendarPeriod.startDate));
    const end = startOfDay(addDays(parseISO(calendarPeriod.endDate), 1));

    const diffInDays = differenceInCalendarDays(end, start);

    const calendarDays = [];

    for (let daysToAdd = 0; daysToAdd < diffInDays; daysToAdd++) {
      calendarDays.push(CalendarPeriod.fromDateAndTimeUnit(addDays(start, daysToAdd), 'day'));
    }

    return calendarDays;
  }
}

function getPeriodIndex(calendarPeriod: CalendarPeriod, supportedPeriods: CalendarPeriodTimeUnit[] = defaultPeriodViews): number {
  return supportedPeriods.findIndex((timeUnit) => isSameTimeUnit(calendarPeriod.startDate, calendarPeriod.endDate, timeUnit));
}
