<div class="mat-calendar-controls">
  <div [ngSwitch]="periodType">
    <div class="period-label" *ngSwitchCase="'text'">
      {{ periodLabel }}
    </div>
    <ng-container *ngSwitchDefault>
      <button (click)="periodClicked()" mat-stroked-button class="mat-calendar-period-button">{{ periodLabel }}</button>
    </ng-container>
  </div>

  <div class="next-previous">
    <button (click)="previousClicked()" mat-icon-button>
      <mat-icon svgIcon="ic-old:chevron-left"></mat-icon>
    </button>
    <button (click)="nextClicked()" mat-icon-button>
      <mat-icon svgIcon="ic-old:chevron-right"></mat-icon>
    </button>
  </div>
</div>
