import { Injectable } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ChannelInfo,
  GridPoint,
  MeasurementProvider,
  MeasurementProviderConfig,
  MeasurementProviderInfo,
  MeasurementProviderValidationConfig,
  RetrieveMeasurementDataResult,
  ValidateResult
} from './grid-point';
import { Period } from '../../core/domain/period';
import { formatMomentAsLocalDate } from '../../core/common/format-moment-as-local-date';
import { RestClientService } from '../rest-client/rest-client.service';
import { DownloadService } from '../../core/download/download.service';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({ providedIn: 'root' })
export class GridPointService extends RestClientService<GridPoint> {
  constructor(private downloadService: DownloadService) {
    super('/api/v1/grid-points');
  }

  getAll(): Observable<GridPoint[]> {
    return this.http.get<GridPoint[]>(this.endpoint).pipe(tap(() => this.log('fetched GridPoints')));
  }

  getById(id: string): Observable<GridPoint> {
    const url = `${this.endpoint}/${id}`;
    return this.http.get<GridPoint>(url).pipe(tap(() => this.log(`fetched GridPoint id=${id}`)));
  }

  getByCustomerId(id: string): Observable<GridPoint[]> {
    const url = `${this.endpoint}?customerId=${id}`;
    return this.http.get<GridPoint[]>(url).pipe(tap(() => this.log(`fetched GridPoints`)));
  }

  add(gridPoint: GridPoint): Observable<GridPoint> {
    return this.http
      .post<GridPoint>(this.endpoint, gridPoint, httpOptions)
      .pipe(tap((newGridPoint: GridPoint) => this.log(`added gridPoint with id=${newGridPoint.id}`)));
  }

  update(gridPoint: GridPoint): Observable<GridPoint> {
    const url = `${this.endpoint}/${gridPoint.id}`;
    return this.http.put<GridPoint>(url, gridPoint, httpOptions).pipe(tap(() => this.log(`updated gridPoint id=${gridPoint.id}`)));
  }

  delete(gridPoint: GridPoint): Observable<any> {
    const url = `${this.endpoint}/${gridPoint.id}`;
    return this.http.delete(url, httpOptions).pipe(tap(() => this.log(`deleted gridPointid=${gridPoint.id}`)));
  }

  retrieveContracts(gridPoint: GridPoint): Observable<any> {
    const url = `${this.endpoint}/${gridPoint.id}/retrieve-contracts`;
    return this.http.post(url, null, httpOptions).pipe(tap(() => this.log(`retrieved contracts for gridPoint id=${gridPoint.id}`)));
  }

  configureMeasurement(id: string, providerConfig: MeasurementProviderConfig): Observable<any> {
    const url = `${this.endpoint}/${id}/configure-measurement`;
    return this.http.put<GridPoint>(url, providerConfig, httpOptions).pipe(tap(() => this.log(`updated measurement for id=${id}`)));
  }

  measurementValidate(id: string, providerValidationConfig: MeasurementProviderValidationConfig): Observable<any> {
    const url = `${this.endpoint}/${id}/validate-measurement`;
    return this.http
      .put<ValidateResult>(url, providerValidationConfig, httpOptions)
      .pipe(tap(() => this.log(`validate measurement for id=${id}`)));
  }

  retrieveMeasurementData(id: string, period: Period): Observable<any> {
    const url = `${this.endpoint}/${id}/retrieve-measurement-data`;
    formatMomentAsLocalDate(period, ['startDate', 'endDate']);

    return this.http
      .put<RetrieveMeasurementDataResult>(url, period, httpOptions)
      .pipe(tap(() => this.log(`retrieve measurement data for id=${id}, period=${period}`)));
  }

  measurementProviders(): Observable<any> {
    const url = `${this.endpoint}/measurement-providers`;
    return this.http.get<MeasurementProviderInfo[]>(url, httpOptions).pipe(tap(() => this.log('fetched measurementProviders')));
  }

  availableChannels(id: string, provider: MeasurementProvider): Observable<any> {
    const url = `${this.endpoint}/${id}/${provider}/available-channels`;
    return this.http.get<ChannelInfo[]>(url, httpOptions).pipe(tap(() => this.log('fetched availableChannels')));
  }

  downloadGridPointOverview(): Observable<HttpResponse<Blob>> {
    return this.downloadService.downloadExcel(this.endpoint);
  }
}
