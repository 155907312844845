import { Component, Input, OnChanges, Optional, SimpleChanges } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { capitalize, omit, pick, toLower } from 'lodash-es';

/**
 * Common component to contain default translations for form errors.
 *
 * To be used within a <mat-error> component due to content projection limitations. See also:
 * https://github.com/angular/angular/issues/37319
 * https://github.com/angular/components/issues/9411
 */
@Component({
  selector: 'ph-flex-form-error-messages',
  templateUrl: './form-error-messages.component.html',
  styleUrls: ['./form-error-messages.component.scss']
})
export class FormErrorMessagesComponent implements OnChanges {
  /**
   * The control to display error messages for
   */
  @Input()
  control: AbstractControl;

  /**
   * Describe the value being entered, e.g. price
   * "Enter a price with 2 decimals"
   *
   * Value is saved as lowercase and Capitalized for usage within error messages.
   */
  @Input()
  descriptiveName: string = 'value';

  /**
   * Override for descriptiveLowerCaseName.
   * Should be used when the descriptive name has special capitalization, e.g. "CO₂-demand". If not set it would have been "co₂-demand".
   */
  @Input()
  descriptiveLowerCaseName: string;

  /**
   * Override for descriptiveCapitalizedName.
   * Should be used when the descriptive name has special capitalization, e.g. "CO₂-demand". If not set it would have been "Co₂-demand".
   */
  @Input()
  descriptiveCapitalizedName: string;

  derivedDescriptiveLowerCaseName: string = 'value';
  derivedDescriptiveCapitalizedName: string = 'Value';

  get actualDescriptiveLowerCaseName(): string {
    return this.descriptiveLowerCaseName ?? this.derivedDescriptiveLowerCaseName;
  }

  get actualDescriptiveCapitalizedName(): string {
    return this.descriptiveCapitalizedName ?? this.derivedDescriptiveCapitalizedName;
  }

  /**
   * Value to add after error message parameters. E.g. min: 5 with '%' postfix would be '5%'
   */
  @Input()
  valuePostfix: string = '';

  /**
   * Value to add before error message parameters. E.g. min: 5 with '€ ' prefix would be '€ 5'.
   * Note, in this example you could use '€' as pre and ',00' as postfix to get '€ 5,00' as the error message.
   */
  @Input()
  valuePrefix: string = '';

  /**
   * Only properties defined in the provided list may be shown by this component.
   */
  @Input()
  @Optional()
  whiteListErrors: string[];

  /**
   * Properties defined in this list are not shown
   */
  @Input()
  @Optional()
  blackListErrors: string[];

  get errors(): any {
    let errors = this.control?.errors || {};

    if (this.whiteListErrors) {
      errors = pick(errors, this.whiteListErrors);
    }

    if (this.blackListErrors) {
      errors = omit(errors, this.blackListErrors);
    }

    return errors;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.descriptiveName) {
      // Update derived values
      this.derivedDescriptiveLowerCaseName = toLower(this.descriptiveName);
      this.derivedDescriptiveCapitalizedName = capitalize(this.descriptiveName);
    }
  }

  formatErrorParameter(value: string): string {
    return `${this.valuePrefix}${value}${this.valuePostfix}`;
  }
}
