import { PaginatedDataSource } from './paginated-data-source';
import { ReplaySubject } from 'rxjs';
import { FrontendPaginationHelper } from './frontend-pagination-helper';
import { OnDestroyProvider } from '../../core/common/on-destroy.mixin';
import { takeUntil } from 'rxjs/operators';

export abstract class FrontendPaginatedDataSource<T> extends PaginatedDataSource<T> {
  loadDataSubject = new ReplaySubject(1);

  abstract paginationHelper: FrontendPaginationHelper<T>;

  protected constructor(onDestroyProvider: OnDestroyProvider) {
    super();

    onDestroyProvider.onDestroy$.pipe(takeUntil(this.onDestroy$)).subscribe(() => this.ngOnDestroy());
  }

  loadData(pageIndex: number | undefined, pageSize: number | undefined, searchTerm: string | undefined, sort: string | undefined): void {
    this.loadDataSubject.next({
      pageIndex,
      pageSize,
      searchTerm,
      sort
    });
  }
}
