import { Directive } from '@angular/core';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MomentDisplayFormatters } from './moment-formatters';

export const MY_FORMATS = {
  parse: {
    dateInput: MomentDisplayFormatters.YEAR
  },
  display: {
    dateInput: MomentDisplayFormatters.YEAR,
    monthYearLabel: MomentDisplayFormatters.YEAR,
    dateA11yLabel: 'LL',
    monthYearA11yLabel: MomentDisplayFormatters.YEAR
  }
};

@Directive({
  selector: '[phFlexDateAsYear]',
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }]
})
export class DateAsYearDirective {}
