import { ParsedIdToken } from './parse-token';
import { concat, EMPTY, map, merge, NEVER, Observable, timer } from 'rxjs';
import { addMilliseconds, differenceInMilliseconds, isAfter, subMilliseconds } from 'date-fns';

export class TokenHelper {
  static DEFAULT_CLOCK_SKEW_MINUTES = 5;
  static DEFAULT_CLOCK_SKEW_MS = 60 * TokenHelper.DEFAULT_CLOCK_SKEW_MINUTES * 1000;

  constructor(
    public idToken: ParsedIdToken,
    public clockSkewMs: number = TokenHelper.DEFAULT_CLOCK_SKEW_MS,
    public getNowDate: () => Date = () => new Date() // For testing purposes
  ) {}

  get nowDate(): Date {
    return this.getNowDate();
  }

  hasBeenIssued(): boolean {
    if (!this.idToken) {
      return false;
    }

    const { nbf } = this.idToken.claims;
    const now = this.nowDate.valueOf();
    const issuedAtMSec = nbf * 1000;
    return issuedAtMSec - this.clockSkewMs < now;
  }

  hasNotExpired(): boolean {
    if (!this.idToken) {
      return false;
    }

    const { exp } = this.idToken.claims;
    const now = this.nowDate.valueOf();
    const expiresAtMSec = exp * 1000;
    return expiresAtMSec + this.clockSkewMs > now;
  }

  isValid(): boolean {
    return this.hasBeenIssued() && this.hasNotExpired();
  }

  /**
   * Create an observable that matches the temporal validity of the provided idToken.
   * Completes when the token expires or has expired.
   * Includes clock skew, so the token will be marked as valid before nbf, and will remain valid after EXP for the clock skew duration.
   *
   * If token is not yet issued: *nothing* -> TOKEN -> null | completes
   * If token is already issued and not yet expired: TOKEN -> null | completes
   * If token is expired: *nothing* | completes
   */
  asObservable(): Observable<ParsedIdToken | null> {
    if (!this.idToken) {
      return EMPTY;
    }

    const { nbf, exp } = this.idToken.claims;
    const nowDate = this.nowDate;

    // Calculate dates based on token claims
    const issuedAtDate = subMilliseconds(new Date(nbf * 1000), this.clockSkewMs);
    const expiresAtDate = addMilliseconds(new Date(exp * 1000), this.clockSkewMs);

    const willBeIssuedInMs = isAfter(nowDate, issuedAtDate) ? 0 : differenceInMilliseconds(issuedAtDate, nowDate);
    const willExpireIn = isAfter(nowDate, expiresAtDate) ? 0 : differenceInMilliseconds(expiresAtDate, nowDate);

    const issued$ = timer(willBeIssuedInMs).pipe(map(() => this.idToken));
    const expired$ = timer(willExpireIn).pipe(map(() => null));

    // Return EMPTY if the token has already expired, since we do not want to emit an expired token.
    return this.hasNotExpired() ? merge(issued$, expired$) : EMPTY;
  }
}
