import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

export type NotificationType = 'error' | 'warn' | 'info' | 'info2' | 'success';

@Component({
  selector: 'ph-flex-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnChanges {
  @Input()
  type: NotificationType = 'info';

  icon: string = 'ic-old:info_outline';

  @Input()
  @HostBinding('class.full-width')
  fullWidth = false;

  @Input()
  @HostBinding('class.card')
  isCard = false;

  @HostBinding('class.info')
  get isInfo(): boolean {
    return this.type === 'info';
  }

  @HostBinding('class.info2')
  get isInfo2(): boolean {
    return this.type === 'info2';
  }

  @HostBinding('class.error')
  get isError(): boolean {
    return this.type === 'error';
  }

  @HostBinding('class.warn')
  get isWarn(): boolean {
    return this.type === 'warn';
  }

  @HostBinding('class.success')
  get isSuccess(): boolean {
    return this.type === 'success';
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.type) {
      this.updateIcon();
    }
  }

  private updateIcon(): void {
    switch (this.type) {
      case 'info':
      case 'info2':
        this.icon = 'ic-old:info_outline';
        break;
      case 'error':
        this.icon = 'ic-old:alert-error';
        break;
      case 'success':
        this.icon = 'ic-old:check-circle-o';
        break;
      case 'warn':
        this.icon = 'ic-old:alert-warning';
    }
  }
}
