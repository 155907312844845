<div (click)="handleClick($event)">
  <button
    [attr.cdkFocusInitial]="shouldGetInitialFocus || null"
    mat-flat-button
    class="save"
    [color]="(error$ | async) || color === 'warn' ? 'warn' : 'cta'"
    [disabled]="_isDisabled"
    [matTooltip]="error$ | async"
    [matTooltipDisabled]="!(error$ | async)"
    [class.spinner-visible]="pending$ | async"
  >
    <span class="button-contents">
      <ph-flex-vertical-center>
        <mat-icon [svgIcon]="buttonIcon" *ngIf="buttonIcon"></mat-icon>
        <span *ngIf="buttonLabel">
          {{ buttonLabel }}
        </span>
        <span *ngIf="!buttonLabel" i18n="@@saveButton">Save</span>
      </ph-flex-vertical-center>
    </span>
    <div class="spinner">
      <mat-spinner diameter="20"></mat-spinner>
    </div>
  </button>
</div>
