import * as d3 from 'd3';
import { BaseType, Selection } from 'd3';
import { DynamicDataHelper } from '../common';

export type D3GraphStepLineHelperDataPointCoordinate = {
  x: number;
  y0: number;
  y1: number;
};

export interface D3GraphStepLineHelperDataPointDatum {
  coordinates: D3GraphStepLineHelperDataPointCoordinate[];
  fillColor: string;
  lineColor: string;
}

export class D3GraphStepLineHelper extends DynamicDataHelper<D3GraphStepLineHelperDataPointDatum> {
  protected nodeName = 'path';
  protected class = 'line';

  protected setDynamic(selectAllWithData: Selection<BaseType, D3GraphStepLineHelperDataPointDatum, BaseType, any>): void {
    const lineGenerator = d3
      .line<D3GraphStepLineHelperDataPointCoordinate>()
      .y((c) => c.y0)
      .x((c) => c.x)
      .curve(d3.curveStepBefore);

    selectAllWithData.attr('d', (datum) => lineGenerator(datum.coordinates)).style('stroke', (datum) => datum.lineColor);
  }

  protected setStatic(selectAllWithData: Selection<BaseType, D3GraphStepLineHelperDataPointDatum, BaseType, any>): void {}
}
