import { ApplicationRef, Injectable, InjectionToken, Injector } from '@angular/core';
import { Subject } from 'rxjs';
import { SwUpdate } from '@angular/service-worker';
import { NavigationEnd, Router } from '@angular/router';
import { AsyncMessagingService } from '../async-messaging/async-messaging.service';
import { MatSnackBar } from '@angular/material/snack-bar';

export const SERVICE_WORKER_ENABLED = new InjectionToken('Service worker enabled');

@Injectable()
export class ApplicationUpdateService {
  private newVersionAvailable: boolean = false;
  private userActivityTimeout: any;
  private userInactive: Subject<void> = new Subject();
  private inactivityTimeoutInSeconds = 5 * 60; // 5 minutes
  private hasCheckedForUpdateRecently: boolean = false;
  private hasResetInactivityTimeoutRecently: boolean = false;

  constructor(private injector: Injector) {
    let intervalHandle;
    intervalHandle = setInterval(() => {
      if (this.appRef) {
        clearInterval(intervalHandle);
      } else {
        return;
      }

      if (!this.injector.get(SERVICE_WORKER_ENABLED)) {
        return;
      }

      this.subscribeToAppUpdates();
      this.updateAppOnNavigation();
      this.checkForUpdateOnNewSseConnection();
      this.registerUserActivityEventListeners();
      this.checkForUpdateOnUserInactivity();
    }, 10);
  }

  get update(): SwUpdate {
    return this.injector.get(SwUpdate);
  }

  get snackBar(): MatSnackBar {
    return this.injector.get(MatSnackBar);
  }

  get asyncMessagingService(): AsyncMessagingService {
    let asyncMessagingService;
    try {
      asyncMessagingService = this.injector.get(AsyncMessagingService);
    } catch (e) {
      return null;
    }

    return asyncMessagingService;
  }

  get router(): Router {
    return this.injector.get(Router);
  }

  get appRef(): ApplicationRef {
    return this.injector.get(ApplicationRef);
  }

  /**
   * Subscribe to the SwUpdate update service in order to detect application updates
   */
  subscribeToAppUpdates(): void {
    this.update.versionUpdates.subscribe((up) => {
      if (up.type === 'VERSION_READY') {
        this.newVersionAvailable = true;
      }
    });
  }

  /**
   * If a new version of the application is available, load it on navigation
   */
  updateAppOnNavigation(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (!this.hasCheckedForUpdateRecently) {
          this.hasCheckedForUpdateRecently = true;
          // Prevent too many requests for a new version. Only do it during navigation, once per minute
          setTimeout(() => (this.hasCheckedForUpdateRecently = false), 60000);
          this.checkForUpdate();
        }
        this.appRef.isStable.subscribe(() => {
          this.reloadPageOnNewVersion();
        });
      }
    });
  }

  /**
   * Check for new application version when the AsyncMessagingService reconnects. A reconnect could imply that a new version of the app just
   * has been deployed.
   */
  checkForUpdateOnNewSseConnection(): void {
    if (!this.asyncMessagingService) {
      return;
    }

    this.asyncMessagingService.isConnected$.subscribe(() => {
      this.checkForUpdate();
    });
  }

  /**
   * When the application detects a working backend after a period of unavailability, it should
   */
  updateOrRefreshAfterReconnect(): void {
    if (!this.injector.get(SERVICE_WORKER_ENABLED)) {
      // Always reload if service workers are disabled
      window.location.reload();
      return;
    }

    this.checkForUpdate();
    this.appRef.isStable.subscribe(() => {
      setTimeout(() => window.location.reload(), 1000);
    });
  }

  /**
   * When the user has been inactive (no mouse, keyboard or touch events) for this.inactivityTimeoutInSeconds seconds,
   * check for a new version and reload the page if one is found.
   */
  checkForUpdateOnUserInactivity(): void {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      clearTimeout(this.userActivityTimeout);
      this.setTimeout();
      this.checkForUpdate();
      this.appRef.isStable.subscribe(() => {
        setTimeout(() => this.reloadPageOnNewVersion(), 1000);
      });
    });
  }

  /**
   * Call check for update, but wrap in try catch to handle cases where web workers are blocked by e.g. private browsing
   * @private
   */
  private checkForUpdate(): void {
    this.update.checkForUpdate().catch((e) => {
      console.warn('Could not perform update check', e);
    });
  }

  /**
   * Register event listeners on specified user-events, to reset inactivity timeout when activity has been detected.
   * Note: to prevent degrading 'snappyness' off the app, the timer is only reset max once per 5 seconds.
   */
  private registerUserActivityEventListeners(): void {
    const activityEvents = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart'];

    activityEvents.forEach((eventName) => {
      document.addEventListener(
        eventName,
        () => {
          if (this.hasResetInactivityTimeoutRecently) {
            return;
          }
          this.hasResetInactivityTimeoutRecently = true;
          setTimeout(() => (this.hasResetInactivityTimeoutRecently = false), 5000);
          clearTimeout(this.userActivityTimeout);
          this.setTimeout();
        },
        true
      );
    });
  }

  /**
   * Set the user activity timout to this.inactivityTimeoutInSeconds seconds
   */
  private setTimeout(): void {
    this.userActivityTimeout = setTimeout(() => this.userInactive.next(), this.inactivityTimeoutInSeconds * 1000);
  }

  /**
   * Reload the page when a new version of the app is available
   */
  private reloadPageOnNewVersion(): void {
    if (this.newVersionAvailable) {
      window.location.reload();
    }
  }
}
