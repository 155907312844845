import { NgModule } from '@angular/core';
import { DateRangeComponent } from './date-range.component';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { FlexFiltersModule } from '../filters/filters.module';

@NgModule({
  declarations: [DateRangeComponent],
  imports: [CommonModule, MatFormFieldModule, MatDatepickerModule, ReactiveFormsModule, MatMomentDateModule, FlexFiltersModule],
  exports: [DateRangeComponent]
})
export class FlexDateRangeModule {}
