import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export enum MessageType {
  Response = 'Response',
  FailureResponse = 'FailureResponse',
  TsoMonthlyPoolUpdateFailedSseEvent = 'TsoMonthlyPoolUpdateFailedSseEvent',
  TsoMonthlyPoolUpdatedSseEvent = 'TsoMonthlyPoolUpdatedSseEvent',
  OperationStartedSseEvent = 'OperationStartedSseEvent',
  InitialPoolSentToTsoSseEvent = 'InitialPoolSentToTsoSseEvent',
  FailedToSendInitialPoolToTsoSseEvent = 'FailedToSendInitialPoolToTsoSseEvent',
  OperationStartedOnAllDevicesSseEvent = 'OperationStartedOnAllDevicesSseEvent',
  OperationEndedOnAllDevicesSseEvent = 'OperationEndedOnAllDevicesSseEvent',
  LatestOperationSseEvent = 'LatestOperationSseEvent',
  OperationContactPersonsChangedSseEvent = 'OperationContactPersonsChangedSseEvent',
  AllStartActivationMessagesSentSseEvent = 'AllStartActivationMessagesSentSseEvent',
  AllEndActivationMessagesSentSseEvent = 'AllEndActivationMessagesSentSseEvent',
  AllActivationCancelledMessagesSentSseEvent = 'AllActivationCancelledMessagesSentSseEvent',
  UnavailabilitiesDeclaredWithTsoSseEvent = 'UnavailabilitiesDeclaredWithTsoSseEvent',
  UnavailabilitiesClearedWithTsoSseEvent = 'UnavailabilitiesClearedWithTsoSseEvent',
  MeasurementsRequestedSseEvent = 'MeasurementsRequestedSseEvent',
  IntraDayDealStatusSseEvent = 'IntraDayDealStatusSseEvent',
  IntraDayPricesReceivedSseEvent = 'IntraDayPricesReceivedSseEvent',
  IntraDayPriceCapacityAvailabilitySseEvent = 'IntraDayPriceCapacityAvailabilitySseEvent',
  IntraDayControlPositionChangedSseEvent = 'IntraDayControlPositionChangedSseEvent',
  NewIntraDayDealSseEvent = 'NewIntraDayDealSseEvent',
  DayAheadDealsReceivedSseEvent = 'DayAheadDealsReceivedSseEvent',
  AnnouncementSseEvent = 'AnnouncementSseEvent',
  AvailabilityAdjustmentTimeForTomorrowExpiredSseEvent = 'AvailabilityAdjustmentTimeForTomorrowExpiredSseEvent',
  TsoAgreementPublicationTimeExpiredSseEvent = 'TsoAgreementPublicationTimeExpiredSseEvent',
  IntraDayOrderCreatedSseEvent = 'IntraDayOrderCreatedSseEvent',
  IntraDayOrderUpdatedSseEvent = 'IntraDayOrderUpdatedSseEvent',
  TradingIntraDayDealCreatedSseEvent = 'TradingIntraDayDealCreatedSseEvent',
  IdconsAnnouncementClosedSseEvent = 'IdconsAnnouncementClosedSseEvent',
  IdconsAnnouncementLogicallyDeletedSseEvent = 'IdconsAnnouncementLogicallyDeletedSseEvent',
  IdconsAnnouncementCreatedSseEvent = 'IdconsAnnouncementCreatedSseEvent',
  IdconsAnnouncementUpdatedSseEvent = 'IdconsAnnouncementUpdatedSseEvent',
  IntraDayDealableDaysClearedEvent = 'IntraDayDealableDaysClearedEvent',
  IntraDayDealableDaysRefreshedEvent = 'IntraDayDealableDaysRefreshedEvent',
  IntraDayIdconsDealingSseEvent = 'IntraDayIdconsDealingSseEvent',
  IntraDayRemLimitOrdersSseEvent = 'IntraDayRemLimitOrdersSseEvent',
  IntraDayGridPointDealDirectionClaimApprovedSseEvent = 'IntraDayGridPointDealDirectionClaimApprovedSseEvent',
  IncidentReserveInvoiceDataCalculatedSseEvent = 'IncidentReserveInvoiceDataCalculatedSseEvent',
  LastKnownEvent = 'LastKnownEvent'
}

export class AsyncMessage {
  // Create for regular response
  constructor(
    public conversationId: string,
    public payload: any,
    public type: MessageType,
    public identifier?: string
  ) {}
}

@Injectable()
export abstract class AsyncMessagingService {
  onMessage$: Observable<AsyncMessage>;
  isConnected: boolean;
  isConnected$: Observable<boolean>;

  abstract onMessageOfType(...messageTypes: MessageType[]): Observable<AsyncMessage>;

  abstract onConversationMessageOfType(conversationId: string, ...messageTypes: MessageType[]): Observable<AsyncMessage>;

  abstract onMessageOfTypeWithInit(httpCall$: Observable<any>, ...messageTypes: MessageType[]): Observable<AsyncMessage>;

  abstract handleSubscriptionRequest(
    request$: Observable<any>,
    messageTypes: ReadonlyArray<MessageType>,
    numberOfEvents?: number
  ): Observable<AsyncMessage>;
}
