import { Money } from './money';
import { VolumeUnit } from './volume';
import { Parser } from 'expr-eval';

export class EnergyPrice {
  static kWhFactor = 3;
  static MWhFactor = 6;

  static converter = Parser.parse('inValue * 10 ^ (outFactor - inFactor)');

  money: Money;
  unit: VolumeUnit;

  static factor(energyPrice: EnergyPrice): number {
    switch (energyPrice.unit) {
      case VolumeUnit.kWh:
        return EnergyPrice.kWhFactor;
      case VolumeUnit.MWh:
        return EnergyPrice.MWhFactor;
      default:
        return 0;
    }
  }

  static fromEURPerMWh(euroPrice: number): EnergyPrice {
    if (!euroPrice && euroPrice !== 0) {
      return null;
    }

    return {
      money: Money.asEUR(euroPrice),
      unit: VolumeUnit.MWh
    };
  }

  static asEURPerKWh(energyPrice: EnergyPrice): number {
    if (!energyPrice) {
      return null;
    }

    if (energyPrice.unit === VolumeUnit.kWh) {
      return Money.toEUR(energyPrice.money);
    }

    return EnergyPrice.converter.evaluate({
      inValue: Money.toEUR(energyPrice.money),
      inFactor: EnergyPrice.factor(energyPrice),
      outFactor: EnergyPrice.kWhFactor
    });
  }

  static asEURPerMWh(energyPrice: EnergyPrice): number {
    if (!energyPrice) {
      return null;
    }

    if (energyPrice.unit === VolumeUnit.MWh) {
      return Money.toEUR(energyPrice.money);
    }

    return EnergyPrice.converter.evaluate({
      inValue: Money.toEUR(energyPrice.money),
      inFactor: EnergyPrice.factor(energyPrice),
      outFactor: EnergyPrice.MWhFactor
    });
  }
}
