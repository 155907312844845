import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import moment from 'moment';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';

enum ExpiryState {
  EXPIRED = 'expired',
  WILL_EXPIRE = 'will_expire'
}

@Component({
  selector: 'ph-flex-login-required',
  templateUrl: './login-required.component.html',
  styleUrls: ['./login-required.component.scss']
})
export class LoginRequiredComponent {
  ExpiryState = ExpiryState;

  expiryState: ExpiryState = ExpiryState.WILL_EXPIRE;

  constructor(
    private bottomSheetRef: MatBottomSheetRef<LoginRequiredComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) private data: { expiresAt: number },
    private cdr: ChangeDetectorRef
  ) {
    setTimeout(() => {
      this.expiryState = ExpiryState.EXPIRED;
      this.cdr.detectChanges();
    }, getExpiredTimeout(this.data.expiresAt));
  }

  close(): void {
    this.bottomSheetRef.dismiss();
  }

  reloadPage(): void {
    this.bottomSheetRef.dismiss(true);
  }
}

export function getExpiredTimeout(expiresAt: number): number {
  return moment.unix(expiresAt).diff(moment.now(), 'ms') || 0;
}
