import { NgModule } from '@angular/core';
import { TableFilterComponent } from './table-filter.component';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexAutocompleteModule } from '../autocomplete/flex-autocomplete.module';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [TableFilterComponent],
  imports: [CommonModule, MatFormFieldModule, ReactiveFormsModule, FlexAutocompleteModule, MatInputModule],
  exports: [TableFilterComponent]
})
export class FlexTableFilterModule {}
