import { InjectionToken } from '@angular/core';
import { format, startOfDay, startOfMonth, startOfYear } from 'date-fns';
import { DateFnsParseFormatters } from '../../core/date-formatters/date-fns-formatters';
import { isString } from 'lodash-es';

export enum PeriodUnit {
  DAY = 1,
  MONTH = 2,
  YEAR = 3
}

export const AVAILABLE_PERIOD_UNITS = new InjectionToken<PeriodUnit[]>('Available period units');

export const DEFAULT_PERIOD = new InjectionToken<PeriodUnit>('Default period for fixed period selector');

/**
 * Set date to startOf periodUnit
 */
export function constrainDate(date: Date, constraint: PeriodUnit): Date {
  switch (constraint) {
    case PeriodUnit.DAY:
      return startOfDay(date);
    case PeriodUnit.MONTH:
      return startOfMonth(date);
    case PeriodUnit.YEAR:
      return startOfYear(date);
  }
  return date;
}

/**
 * Parse Date as localDate year/month/day or toISOString as fallback when constraint is not known
 */
export function formatAsISO(date: Date, constraint: PeriodUnit): string | null {
  if (!date) {
    return null;
  }

  switch (constraint) {
    case PeriodUnit.DAY:
      return format(date, DateFnsParseFormatters.DAY);
    case PeriodUnit.MONTH:
      return format(date, DateFnsParseFormatters.MONTH);
    case PeriodUnit.YEAR:
      return format(date, DateFnsParseFormatters.YEAR);
  }
  return date?.toISOString();
}

export function isPeriodSmallerThan(assumedLargerPeriod: PeriodUnit, assumedSmallerPeriod: PeriodUnit): boolean {
  switch (assumedLargerPeriod) {
    case PeriodUnit.YEAR:
      return assumedSmallerPeriod !== PeriodUnit.YEAR;
    case PeriodUnit.MONTH:
      return assumedSmallerPeriod === PeriodUnit.DAY;
    case PeriodUnit.DAY:
      return false;
  }

  return false;
}

/**
 * Return PeriodUnit if the input string matches the LocalDate format of the corresponding period unit.
 * See tests for detailed behavior.
 */
export function inferPeriodUnitFromStringFormat(inputString: string): PeriodUnit | null {
  if (!isString(inputString) || inputString.includes('T') || inputString.includes(' ')) {
    return null;
  }

  const splitString = inputString?.split('-') ?? [];

  if (splitString.some((val) => !/^[\d-]+$/.test(val))) {
    return null;
  }

  switch (splitString.length) {
    case 1:
      return PeriodUnit.YEAR;
    case 2:
      return PeriodUnit.MONTH;
    case 3:
      return PeriodUnit.DAY;
    default:
      return null;
  }
}
