import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { b64DecodeUnicode, base64UrlEncode } from '../authentication/utils/parse-token';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class LoginRedirectService {
  constructor(
    private router: Router,
    private oauthService: OAuthService
  ) {}
  getStateBeforeLogout() {
    const href = window.location.href;
    const baseUri = document.baseURI;
    return href.slice(baseUri.length);
  }

  restoreState(url: string) {
    return this.router.navigateByUrl(url);
  }

  buildState() {
    return base64UrlEncode(
      JSON.stringify({
        desiredState: this.getStateBeforeLogout()
      })
    );
  }

  getParsedState() {
    return (this.oauthService.state && JSON.parse(b64DecodeUnicode(this.oauthService.state))) || {};
  }

  getRedirectUri() {
    return this.getParsedState().desiredState;
  }
}
