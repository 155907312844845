import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { default as theme } from '../../../../design-tokens/theme.json';
import { ComponentType } from '@angular/cdk/overlay';

const layout = theme.layout;

export type DialogModalSize = 'xs' | 's' | 'm' | 'l' | 'xl';

type Sizes = { [K in DialogModalSize]: { width: number; minHeight: number } };

export class FlexDialogConfig extends MatDialogConfig {
  size: DialogModalSize;
}

@Injectable({
  providedIn: 'root'
})
export class FlexDialogService {
  private static defaultSize: DialogModalSize = 'm';
  private static sizes: Sizes = {
    xs: { width: layout.width.xs, minHeight: layout.height.xs },
    s: { width: layout.width.s, minHeight: layout.height.m },
    m: { width: layout.width.m, minHeight: layout.height.m },
    l: { width: layout.width.l, minHeight: layout.height.m },
    xl: { width: layout.width.xl, minHeight: layout.height.m }
  };

  static getModalWidth(modalSize: DialogModalSize): string {
    return `${FlexDialogService.sizes[modalSize].width}px`;
  }

  static getModalMinHeight(modalSize: DialogModalSize): string {
    return `${FlexDialogService.sizes[modalSize].minHeight}px`;
  }

  constructor(private dialog: MatDialog) {}

  /**
   * Provide a reusable method that sets the width and height of the dialog based on the requested size
   */
  open(template: ComponentType<any> | TemplateRef<any>, config: FlexDialogConfig = new FlexDialogConfig()): MatDialogRef<any> {
    const width = FlexDialogService.getModalWidth(config.size || FlexDialogService.defaultSize);
    const minHeight = FlexDialogService.getModalMinHeight(config.size || FlexDialogService.defaultSize);

    return this.dialog.open(template, {
      ...config,
      width,
      minHeight
    });
  }
}
