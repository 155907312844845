import { Action, Selector, State, StateContext } from '@ngxs/store';
import { clone } from 'lodash-es';

import { EntityStateModel, FetchEntity } from '../../../common/entity.state';
import {
  DownloadGridPointsCommand,
  GridPointsLoadedEvent,
  LoadGridPointsByCustomerIdCommand,
  LoadGridPointsCommand,
  ResetGridPointsCommand
} from './grid-points.actions';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { GridPoint } from '../../../../api-private/grid-point/grid-point';
import { GridPointService } from '../../../../api-private/grid-point/grid-point.service';
import { findParameterValue } from '../../../../core/common/find-parameter-value';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

export class GridPointsStateModel extends EntityStateModel<ReadonlyArray<GridPoint>> {
  model: ReadonlyArray<GridPoint> = [];
  selectedCustomerId: string | null = null;
  isBusyDownloading: boolean = false;
}

@State({
  name: 'gridPoints',
  defaults: new GridPointsStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class GridPointsState {
  constructor(private activatedRoute: ActivatedRoute, private gridPointService: GridPointService) {}

  @Selector()
  public static getGridPoints(state: GridPointsStateModel): ReadonlyArray<GridPoint> {
    if (state.isBusyReceiving) {
      return [];
    }
    return clone(state.model);
  }

  @Selector()
  public static isBusy(state: GridPointsStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  public static isInitialized(state: GridPointsStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  public static isBusySending(state: GridPointsStateModel): boolean {
    return state.isBusySending;
  }

  @Selector()
  public static error(state: GridPointsStateModel): any {
    return state.sendError;
  }

  @Selector()
  public static getSelectedCustomerId(state: GridPointsStateModel): string | null {
    return state.selectedCustomerId;
  }

  @Selector()
  public static isBusyDownloading(state: GridPointsStateModel): boolean {
    return state.isBusyDownloading;
  }

  @FetchEntity()
  @Action(LoadGridPointsByCustomerIdCommand)
  updateGridPoints(
    { setState, getState, dispatch }: StateContext<GridPointsStateModel>,
    { customerId }: LoadGridPointsByCustomerIdCommand
  ): void {
    let observable;

    if (customerId) {
      observable = this.gridPointService.getByCustomerId(customerId);
      setState({ ...getState(), selectedCustomerId: customerId });
    } else {
      observable = this.gridPointService.getAll();
      setState({ ...getState(), selectedCustomerId: null });
    }

    return observable.pipe(tap((gridPoints: ReadonlyArray<GridPoint>) => setState({ ...getState(), model: gridPoints })));
  }

  @Action(ResetGridPointsCommand)
  resetGridPoints({ setState }: StateContext<GridPointsStateModel>): void {
    setState(new GridPointsStateModel());
  }

  @Action(GridPointsLoadedEvent)
  setGridPoints({ setState, getState }: StateContext<GridPointsStateModel>, { payload }: GridPointsLoadedEvent): void {
    const state = getState();
    setState({
      ...state,
      model: payload
    });
  }

  @FetchEntity()
  @Action(LoadGridPointsCommand)
  loadGridPoints({ setState, getState, dispatch }: StateContext<GridPointsStateModel>): Observable<ReadonlyArray<GridPoint>> {
    const selectedCustomerId = findParameterValue(this.activatedRoute.snapshot, 'customerId');
    setState({ ...getState(), selectedCustomerId });

    if (selectedCustomerId) {
      return this.gridPointService.getByCustomerId(selectedCustomerId).pipe(
        tap((gridPoints) => {
          dispatch(new GridPointsLoadedEvent(gridPoints));
        })
      );
    } else {
      return this.gridPointService.getAll().pipe(
        tap((gridPoints) => {
          dispatch(new GridPointsLoadedEvent(gridPoints));
        })
      );
    }
  }

  @Action(DownloadGridPointsCommand)
  downloadGridPoints({ setState, getState }: StateContext<GridPointsStateModel>): Observable<any> {
    setState({
      ...getState(),
      isBusyDownloading: true
    });

    return this.gridPointService.downloadGridPointOverview().pipe(
      tap({
        complete: () =>
          setState({
            ...getState(),
            isBusyDownloading: false
          })
      })
    );
  }
}
