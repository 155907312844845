import { Directive, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseControlValueAccessor } from '../../core/control-value-accessor/base-control-value-accessor';
import { normalizeToDate } from '../../core/date-time/utils';
import { DateRangeFixedPeriodComponent } from './date-range-fixed-period/date-range-fixed-period.component';
import { constrainDate, formatAsISO, inferPeriodUnitFromStringFormat, PeriodUnit } from './default-period';
import { combineLatest, Observable } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { DateRangeFixedSelectedDateHelperService } from './date-range-fixed-selected-date-helper.service';
import { isEqual } from 'lodash-es';

export const formats = {
  [PeriodUnit.DAY]: 'yyyy-MM-dd',
  [PeriodUnit.MONTH]: 'yyyy-MM',
  [PeriodUnit.YEAR]: 'yyyy'
};

@Directive({
  selector: '[phFlexDateRangeFixedPeriodStringValueAccessor]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DateRangeFixedPeriodStringValueAccessorDirective,
      multi: true
    }
  ]
})
export class DateRangeFixedPeriodStringValueAccessorDirective extends BaseControlValueAccessor<string> implements OnInit {
  selectedDate$: Observable<Date>;
  selectedPeriod$: Observable<PeriodUnit>;

  constructor(
    private dateRangeFixedPeriodComponent: DateRangeFixedPeriodComponent,
    private dateRangeFixedSelectedDateHelperService: DateRangeFixedSelectedDateHelperService
  ) {
    super();
  }

  writeValue(obj: any): void {
    const inferredPeriodUnit = inferPeriodUnitFromStringFormat(obj);

    if (inferredPeriodUnit && inferredPeriodUnit !== this.dateRangeFixedPeriodComponent.getSelectedPeriod()) {
      this.dateRangeFixedPeriodComponent.setSelectedPeriod(inferredPeriodUnit);
    }

    this.dateRangeFixedPeriodComponent.setDate(this.getDateFromInputString(obj));
  }

  ngOnInit(): void {
    this.selectedDate$ = this.dateRangeFixedPeriodComponent.selectedDate$.pipe(
      tap((selectedDate) => this.dateRangeFixedSelectedDateHelperService.updateDate(selectedDate))
    );

    this.selectedPeriod$ = this.dateRangeFixedPeriodComponent.selectedPeriod$.pipe(
      tap((selectedPeriod) => this.dateRangeFixedSelectedDateHelperService.updatePeriodUnit(selectedPeriod))
    );

    // Handle changes from the dateRangeFixedPeriod
    combineLatest([this.selectedDate$, this.selectedPeriod$])
      .pipe(
        filter(([selectedDate, selectedPeriod]) => !!selectedDate && !!selectedPeriod),
        takeUntil(this.onDestroy$)
      )
      .subscribe(([selectedDate, selectedPeriod]) => {
        const outputDate = constrainDate(this.dateRangeFixedSelectedDateHelperService.getDate(), selectedPeriod);

        // Since calling setDate will trigger an  update of selectedDate, check if the date is different, and only update if they are.
        if (!isEqual(outputDate, selectedDate)) {
          this.dateRangeFixedPeriodComponent.setDate(outputDate);
        }

        this.onChange(formatAsISO(outputDate, this.dateRangeFixedPeriodComponent.getSelectedPeriod()));
      });
  }

  private getDateFromInputString(input: string): Date {
    const normalizedDate = normalizeToDate(input);
    if (normalizedDate) {
      return normalizedDate;
    }

    console.warn('Was unable to parse input for date range fixed period string value accessor, got: ', input);
  }
}
