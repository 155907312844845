import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { normalizeUnits, unitOfTime } from 'moment';
import { CalendarPeriod } from './calendar-period';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'calendarPeriod'
})
export class CalendarPeriodPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) public locale: string) {}

  transform(value: CalendarPeriod, formatType: FormatType = 'abbreviated'): string {
    return formatCalendarPeriod(value, formatType, this.locale);
  }
}

export function formatCalendarPeriod(value: CalendarPeriod, formatType: FormatType = 'abbreviated', locale: string = 'en'): string {
  const periodView = CalendarPeriod.getView(value);
  const dateFormat = temporalUnitToDateFormat(periodView, formatType);

  return formatDate(value.startDate, dateFormat, locale);
}

export const temporalUnits: unitOfTime.All[] = ['seconds', 'minutes', 'hours', 'days', 'weeks', 'months', 'years'];

export type FormatType = 'numeric' | 'numericPadded' | 'abbreviated' | 'wide' | 'narrow';

const formatTypes: FormatType[] = ['numeric', 'numericPadded', 'abbreviated', 'wide', 'narrow'];

export function temporalUnitToDateFormat(temporalUnit: unitOfTime.All, formatType: FormatType): string {
  const normalizedTemporalUnit = normalizeUnits(temporalUnit);

  switch (normalizedTemporalUnit) {
    case 'day':
      if (['numeric', 'numericPadded'].includes(formatType)) {
        return formatTypeString('d', formatType); // Format numeric and numericPadded as dayOfMonth instead of week day
      }
      return formatTypeString('E', formatType);
    case 'week':
    case 'isoWeek':
      return formatTypeString('w', formatType, 2);
    case 'month':
      return formatTypeString('M', formatType);
    case 'year':
      return formatTypeString('y', formatType);
  }

  // Fallback to default times
  switch (formatType) {
    case 'numeric':
    case 'numericPadded':
      return 'short';
    default:
      return 'medium';
  }
}

export function formatTypeString(type: string, formatType: FormatType, maxNumberOfCharacters: number = 5): string {
  let builder = type;
  const numberOfCharacters = Math.min(formatTypes.indexOf(formatType) + 1, maxNumberOfCharacters);

  for (let i = 1; i < numberOfCharacters; i++) {
    builder += type;
  }
  return builder;
}
