import { NgModule } from '@angular/core';
import { AutocompleteComponent } from './autocomplete.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { DataSourceFilterClearValueIfNotSelectableDirective } from './datasource-filter-clear-not-selectable-value.directive';
import { DataSourceFilterRemoveSelectedValueDirective } from './datasource-filter-remove-already-selected-value.directive';

@NgModule({
  declarations: [AutocompleteComponent, DataSourceFilterClearValueIfNotSelectableDirective, DataSourceFilterRemoveSelectedValueDirective],
  imports: [CommonModule, FormsModule, MatAutocompleteModule, MatOptionModule, MatInputModule],
  exports: [AutocompleteComponent, DataSourceFilterClearValueIfNotSelectableDirective, DataSourceFilterRemoveSelectedValueDirective]
})
export class FlexAutocompleteModule {}
