import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { AnalogInput, InputKind } from './analog-input';

/**
 * Base service that provides analog inputs
 */
@Injectable({
  providedIn: 'root'
})
export class AnalogInputService {
  endpoint: string = '/api/v1/analog-inputs';
  kind: InputKind = InputKind.ANALOG;

  constructor(private http: HttpClient) {}

  getAll(): Observable<AnalogInput[]> {
    return this.http
      .get<AnalogInput[]>(this.endpoint)
      .pipe(map((result) => result.filter((item) => !this.kind || item.kind === this.kind)));
  }

  getById(id: string): Observable<AnalogInput> {
    const url = `${this.endpoint}/${id}`;
    return this.http.get<AnalogInput>(url);
  }

  getByCustomerId(customerId: string): Observable<AnalogInput[]> {
    return this.http
      .get<AnalogInput[]>(this.endpoint, { params: { customerId } })
      .pipe(map((result) => result.filter((item) => !this.kind || item.kind === this.kind)));
  }

  create(input: AnalogInput): Observable<AnalogInput> {
    return this.http.post<AnalogInput>(this.endpoint, input);
  }

  update(input: AnalogInput): Observable<AnalogInput> {
    const url = `${this.endpoint}/${input.id}`;
    return this.http.put<AnalogInput>(url, input);
  }

  delete(id: string): Observable<any> {
    const url = `${this.endpoint}/${id}`;
    return this.http.delete(url);
  }
}
