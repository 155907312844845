import { State } from '@ngxs/store';
import { GridPointState } from './states/grid-point/grid-point.state';
import { GridPointsState } from './states/grid-points/grid-points.state';
import { PulseMeasurementConfigurationState } from './states/grid-point/pulse-measurement-configuration/pulse-measurement-configuration.state';
import { Injectable } from '@angular/core';

const GridPointStateChildren = [GridPointState, GridPointsState];

export const GridPointStates = [...GridPointStateChildren, PulseMeasurementConfigurationState];

@State({
  name: 'gridPointStateModule',
  children: GridPointStateChildren
})
@Injectable({
  providedIn: 'root'
})
export class GridPointStateModule {}
