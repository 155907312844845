import { D3GraphSubmodule } from '../d3-graph-submodule';
import * as d3 from 'd3';
import { BaseType, Selection } from 'd3';
import { DynamicDataHelper, Margins, PortalDatum, PortalSubModuleMixin, SubjectProvider } from '../../common';
import { MixinBase } from '../../../../core/common/constructor-type.mixin';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { ScaleBand, ScaleContinuousNumeric } from 'd3-scale';
import { DomPortalOutlet, Portal } from '@angular/cdk/portal';
import { isString } from 'lodash-es';
import { expectObservableValue } from '../../../../core/common/expect-observable-value';
import { AppInjector } from '../../../../core/common/app-injector';

export interface LabelsMap {
  [key: string]: string | Portal<any>;
}

class Config {
  /**
   * Pip size in px
   */
  pipSize: number = 4;

  /**
   * Text padding in px between pip and text start, and the min width between the end of the text and the start of the xScale (excluding margin.right)
   */
  textPadding: number = 8;
}

interface LeftLabelPositions {
  left: number;
  right: number;
  width: number;
}

class LabelDatum extends PortalDatum<LabelDatum> {
  /**
   * Label text or Portal
   */
  label: string | Portal<any>;

  /**
   * Y position of the label (content is vertically centered)
   */
  y: number;

  /**
   * Color of the small circle before the label
   */
  pipColor: string;

  /**
   * Pip size in px
   */
  pipSize: number = 8;

  /**
   * x position of the pip
   */
  pipX: number;

  /**
   * X position of the label text
   */
  textX: number = 8;

  /**
   * Maximum allowed width of the text
   */
  textMaxWidth: number;

  /**
   * Portal Outlet after it has been created
   */
  labelPortalOutlet?: DomPortalOutlet;
}

type SupportedYScale = ScaleBand<string>;
type SupportedXScale = ScaleContinuousNumeric<any, any>;

/**
 *
 * textPadding: 8px;
 *
 * margin.left --- pipSize --- textPadding --- [text] --- textPadding --- margin.right
 */
export class D3GraphScaleBandAxisLabels extends PortalSubModuleMixin(MixinBase) implements D3GraphSubmodule {
  private marginsProvider = new SubjectProvider<Margins>(this, new BehaviorSubject(new Margins()));
  private configProvider = new SubjectProvider<Config>(this, new BehaviorSubject(new Config()));
  private scaleBandProvider = new SubjectProvider<SupportedYScale>(this);
  private availableWidthProvider = new SubjectProvider<number>(this);
  private labelsMapProvider = new SubjectProvider<LabelsMap>(this);

  private scaleBandLabelsHelper: ScaleBandLabelsHelper;

  attach(mainSvg: Selection<BaseType, any, any, any>): () => any {
    const node = mainSvg.append('g').attr('class', 'left-labels');

    this.scaleBandLabelsHelper = new ScaleBandLabelsHelper(node);

    const subscription = combineLatest([
      this.marginsProvider.value$,
      this.configProvider.value$,
      this.availableWidthProvider.value$,
      this.scaleBandProvider.value$,
      this.labelsMapProvider.value$,
      this.cfrProvider.value$,
      this.appRefProvider.value$
    ]).subscribe(([margins, config, availableWidth, scaleBand, labelsMap, cfr, appRef]) => {
      /**
       * Calculate the margins from the perspective of the text label.
       * Only left and right is calculated, for the text overflow.
       * Top and bottom is irrelevant since it is vertically centered.
       */
      const textX = margins.left + config.pipSize + config.textPadding;
      const textRight = config.textPadding + margins.right;
      const textMaxWidth = availableWidth - (textX + textRight);

      // Set pip x position. Offset based on radius is calculated in the DynamicDataHelper
      const pipX = margins.left;

      const data: LabelDatum[] = scaleBand.domain().map((domain) => {
        const label = labelsMap[domain];

        if (!label) {
          // This condition may occur if the grid points cannot be fetched. See FA-7148.
          throw new Error(`Could not find label for given domain ${domain}`);
        }

        return {
          pipColor: 'gray',
          pipSize: config.pipSize,
          pipX,
          textX,
          textMaxWidth,
          y: scaleBand(domain) + scaleBand.bandwidth() / 2,
          label,
          cfr,
          appRef
        };
      });

      this.scaleBandLabelsHelper.setData(data);
    });

    expectObservableValue(this.scaleBandProvider.value$, 'Scale band was not set for d3 graph scale band axis labels');
    expectObservableValue(this.availableWidthProvider.value$, 'Available width was not set for d3 graph scale band axis labels');
    expectObservableValue(this.labelsMapProvider.value$, 'Labels map was not set for d3 graph scale band axis labels');

    return () => subscription.unsubscribe();
  }

  setMargin(margin: Partial<Margins>): void {
    this.marginsProvider.next({
      ...this.marginsProvider.value,
      ...margin
    });
  }

  setScaleBand$(scale$: Observable<SupportedYScale>): void {
    this.scaleBandProvider.follow(scale$);
  }

  setScaleBand(scale: SupportedYScale): void {
    this.scaleBandProvider.next(scale);
  }

  setAvailableWidth(availableWidth: number): void {
    this.availableWidthProvider.next(availableWidth);
  }

  setLabelsMap$(labelsMap$: Observable<LabelsMap>): void {
    this.labelsMapProvider.follow(labelsMap$);
  }

  setLabelsMap(labelsMap: LabelsMap): void {
    this.labelsMapProvider.next(labelsMap);
  }

  setConfig(config: Partial<Config>): void {
    this.configProvider.next({
      ...this.configProvider.value,
      ...config
    });
  }
}

class ScaleBandLabelsHelper extends DynamicDataHelper<LabelDatum> {
  protected class = 'scale-band-label';
  protected nodeName = 'g';

  /* eslint-disable no-invalid-this */
  protected setDynamic(selectAllWithData: Selection<BaseType, LabelDatum, BaseType, any>): void {
    const that = this;
    selectAllWithData.each(function (parentDatum: LabelDatum): void {
      // Update pip
      d3.select(this)
        .selectAll('circle')
        .attr('cx', () => parentDatum.pipX + parentDatum.pipSize)
        .attr('cy', () => parentDatum.y)
        .attr('r', () => parentDatum.pipSize)
        .attr('fill', () => parentDatum.pipColor);

      if (isString(parentDatum.label)) {
        // String only
        if (d3.select(this).select('g.label-text').selectAll('text').empty()) {
          // Clear existing group first
          d3.select(this).select('g.label-text').selectChildren().remove();

          // Add text element
          d3.select(this).select('g.label-text').append('text').attr('dominant-baseline', 'central');
        }

        // Update label text element
        d3.select(this)
          .select('g.label-text')
          .selectAll('text')
          .text(() => parentDatum.label as string)
          .attr('x', () => parentDatum.textX)
          .attr('y', () => parentDatum.y)
          .each(textOverflowEllipsisFactory(parentDatum));
      } else {
        // Is portal
        if (d3.select(this).select('g.label-text').selectAll('foreignObject').empty()) {
          // Clear existing group first
          d3.select(this).select('g.label-text').selectChildren().remove();

          // Add foreignObject element
          d3.select(this).select('g.label-text').append('foreignObject').append('xhtml:div');

          const height = 50;

          // Set height and width
          d3.select(this)
            .select('g.label-text')
            .select('foreignObject')
            .attr('height', height)
            .attr('width', () => parentDatum.textMaxWidth)
            .attr('x', () => parentDatum.textX)
            .attr('y', () => parentDatum.y - height / 2);

          d3.select(this)
            .select('g.label-text')
            .select('foreignObject')
            .select('div')
            .style('display', 'flex')
            .style('height', '100%')
            .style('align-items', 'center')
            .style('color', 'black'); // This is set to prevent the parent color setting the text color, since this is ignored for <text>

          if (!parentDatum.labelPortalOutlet && parentDatum.cfr && parentDatum.appRef) {
            const targetNode = d3.select(this).select('g.label-text').selectAll('foreignObject').select('div');

            that.initPortal(targetNode.node() as HTMLElement, parentDatum);
          }
        }

        if (parentDatum.datumSubject) {
          parentDatum.datumSubject.next(parentDatum);
        }
      }
    });
  }

  protected setStatic(selectAllWithData: Selection<BaseType, LabelDatum, BaseType, any>): void {
    selectAllWithData.append('g').attr('class', 'label-text');

    selectAllWithData.append('circle');
  }

  private initPortal(targetNode: HTMLElement, datum: LabelDatum): void {
    datum.datumSubject = new BehaviorSubject<LabelDatum>(datum);

    // Set portal outlet
    datum.labelPortalOutlet = new DomPortalOutlet(targetNode, datum.cfr, datum.appRef, AppInjector);

    // If label does not exist, do not attach
    (datum.label as Portal<any>)?.attach(datum.labelPortalOutlet);
  }
}

/**
 * https://stackoverflow.com/questions/15975440/add-ellipses-to-overflowing-text-in-svg
 */
export function textOverflowEllipsisFactory(datum: LabelDatum): any {
  return function wrap(): void {
    // eslint-disable-next-line no-invalid-this
    const self = d3.select(this);

    let textLength = self.node().getComputedTextLength();
    let text = self.text();
    while (textLength > datum.textMaxWidth && text.length > 0) {
      text = text.slice(0, -1);
      self.text(text + '...');
      textLength = self.node().getComputedTextLength();
    }
  };
}
