import { HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { EMPTY, interval, merge, Observable } from 'rxjs';
import { map, retry, startWith, switchMap, tap } from 'rxjs/operators';
import { Operation, OperationContactPerson, OperationPoolGridPoint, OperationView, R3Activation } from './operation';
import { RestClientService } from '../rest-client/rest-client.service';
import { Capacity } from '../../core/domain/capacity';
import { AsyncMessage, AsyncMessagingService, MessageType } from '../../core/async-messaging/async-messaging.service';
import { DateAndTime } from '../../core/domain/date-and-time';
import { TimeSlot } from '../../core/date-time/time-slot';
import { PeriodView } from '../../core/domain/period';
import { DownloadService } from '../../core/download/download.service';
import { AdjustmentDirection } from '../../core/common/adjustment-direction';
import { getRetryConfig } from '../../core/common/rxjs-utils';

export class OperationMeasurements {
  period: TimeSlot;
  baselinePower: Capacity;
  baselinePeriod: TimeSlot;
  deliveryTargetPower: Capacity; // Target power for measurements (measurement target based on baseline and target)
  targetPower: Capacity; // Target power (positive value)
  targetPeriod: TimeSlot;
  rampUpPeriod: TimeSlot;
  rampDownPeriod: TimeSlot;
  measurements: ReadonlyArray<OperationMeasurement>;
}

export class OperationMeasurement {
  period: TimeSlot;
  capacity: Capacity;
}

export interface ManualActivationData {
  /**
   * Contacts to call in non-standard cases
   */
  contacts: OperationContactPerson[];

  /**
   * Direction of the current operation. If no operation is currently active, this is null.
   */
  currentActiveIncidentReserveDirection: AdjustmentDirection | null;

  /**
   * If an R3 operation is currently active, this is the currently active operation start notification time.
   * If no R3 operation is currently active, this is the stop notification time of the most recent operation.
   */
  lastIncidentReserveNotificationTime: string;
}

@Injectable({
  providedIn: 'root'
})
export class OperationService extends RestClientService<OperationView, Operation> {
  constructor(private asyncMessagingService: AsyncMessagingService, private downloadService: DownloadService) {
    super('/api/v1/operations');
  }

  deactivate(id: string): Observable<Operation> {
    const url = `${this.endpoint}/${id}/deactivate`;
    return this.http.put<Operation>(url, {}).pipe(tap(() => this.log(`deactivate id=${id}`)));
  }

  activate(id: string): Observable<Operation> {
    const url = `${this.endpoint}/${id}/activate`;
    return this.http.put<Operation>(url, {}).pipe(tap(() => this.log(`activate id=${id}`)));
  }

  add(value: OperationView): Observable<Operation> {
    return this.http.post<Operation>(this.endpoint, {
      ...value,
      period: PeriodView.serialize(value.period)
    });
  }

  update(value: OperationView): Observable<Operation> {
    const url = `${this.endpoint}/${value.id}`;
    return this.http.put<Operation>(url, {
      ...value,
      period: PeriodView.serialize(value.period)
    });
  }

  sendOperationToDevices(operationId: string): Observable<AsyncMessage> {
    const url = `${this.endpoint}/${operationId}/send-to-devices`;
    const request = this.http.put<Operation>(url, null, {
      observe: 'response'
    });
    return this.asyncMessagingService.handleSubscriptionRequest(
      request,
      [
        MessageType.InitialPoolSentToTsoSseEvent,
        MessageType.FailedToSendInitialPoolToTsoSseEvent,
        MessageType.OperationStartedOnAllDevicesSseEvent,
        MessageType.OperationEndedOnAllDevicesSseEvent,
        MessageType.AllStartActivationMessagesSentSseEvent,
        MessageType.AllEndActivationMessagesSentSseEvent,
        MessageType.AllActivationCancelledMessagesSentSseEvent
      ],
      2
    );
  }

  sendActivatedPoolToTennet(operationId: string): Observable<object> {
    const url = `${this.endpoint}/${operationId}/send-pool-to-tso`;
    return this.http.put(url, null);
  }

  sendStartActivationMessage(operationId: string): Observable<AsyncMessage> {
    const url = `${this.endpoint}/${operationId}/send-start-activation-message`;
    const request = this.http.post(url, null, { observe: 'response' });
    return this.asyncMessagingService.handleSubscriptionRequest(request, [MessageType.AllStartActivationMessagesSentSseEvent]);
  }

  sendEndActivationMessage(operationId: string): Observable<AsyncMessage> {
    const url = `${this.endpoint}/${operationId}/send-end-activation-message`;
    const request = this.http.post(url, null, { observe: 'response' });
    return this.asyncMessagingService.handleSubscriptionRequest(request, [MessageType.AllEndActivationMessagesSentSseEvent]);
  }

  manuallyStartOperation({ direction }: { direction: AdjustmentDirection }): Observable<any> {
    const url = `${this.endpoint}/start-manual`;
    return this.http.post(url, {
      direction
    });
  }

  manuallyEndOperation(): Observable<any> {
    const url = `${this.endpoint}/end-manual`;
    return this.http.put(url, {});
  }

  getPoolCandidates(
    operationDirection: string,
    period: PeriodView,
    notificationTime: DateAndTime,
    requestedPower: number
  ): Observable<OperationPoolGridPoint[]> {
    const url = `${this.endpoint}/pool-candidates`;
    let params = new HttpParams();
    params = params.set('startDateTime', DateAndTime.serialize(period.startDateTime));
    params = params.set('toDateTime', DateAndTime.serialize(period.toDateTime));
    params = params.set('notificationTime', DateAndTime.serialize(notificationTime));
    params = params.set('direction', operationDirection);
    if (!isNil(requestedPower)) {
      params = params.set('requestedPower', requestedPower.toString());
    }
    return this.http.get<OperationPoolGridPoint[]>(url, { params });
  }

  getPoolMeasurements(operationId: string): Observable<OperationMeasurements> {
    const url = `${this.endpoint}/${operationId}/measurements`;
    return this.http.get<OperationMeasurements>(url);
  }

  getPoolMeasurementsForServiceAgreement(operationId: string, serviceAgreementId: string): Observable<OperationMeasurements> {
    const url = `${this.endpoint}/${operationId}/measurements`;
    return this.http.get<OperationMeasurements>(url, { params: { serviceAgreementId } });
  }

  downloadMeasurementData(operationId: string): Observable<HttpResponse<Blob>> {
    return this.downloadService.download(
      this.http.get<Blob>(`${this.endpoint}/${operationId}/export-measurements`, {
        observe: 'response',
        responseType: 'blob' as 'json'
      })
    );
  }

  retrieveOperationMeasurementData(operationId: string, conversationId: string): Observable<HttpResponse<any>> {
    const url = `${this.endpoint}/${operationId}/manual-operation-measurements`;
    return this.http.post(url, operationId, { observe: 'response' });
  }

  manualActivationData$(): Observable<ManualActivationData> {
    const httpGet$ = this.http.get<ManualActivationData>(`${this.endpoint}/latest`);

    const pollingOrSse$ = merge(
      this.asyncMessagingService
        .onMessageOfType(MessageType.LatestOperationSseEvent, MessageType.OperationContactPersonsChangedSseEvent)
        .pipe(map((result) => JSON.parse(result.payload))), // This relies on SSE events being a Partial<ManualActivationData>.
      this.asyncMessagingService.isConnected$.pipe(
        switchMap((isConnected) => (isConnected ? EMPTY : interval(1000).pipe(switchMap(() => httpGet$))))
      )
    );

    return httpGet$.pipe(
      retry(getRetryConfig({ maxRetryAttempts: Infinity })),
      switchMap((httpResult) => {
        let lastResult: ManualActivationData = httpResult;

        return pollingOrSse$.pipe(
          startWith({}),
          map((pollIngOrSseResult) => {
            lastResult = {
              ...lastResult,
              ...pollIngOrSseResult
            };
            return lastResult;
          })
        );
      }),
      tap({
        error: () => this.messageService.clearErrors()
      })
    );
  }

  getActivationsForServiceAgreement(serviceAgreementId: string): Observable<R3Activation[]> {
    return this.http.get<R3Activation[]>(`${this.endpoint}`, {
      params: {
        serviceAgreementId
      }
    });
  }

  downloadOperationPoolData(operationId: string): Observable<any> {
    // TODO determine if the operation pool should be a separate endpoint (which should then also be used to fill the table)
    return this.downloadService.downloadExcel(`${this.endpoint}/${operationId}/pool`);
  }
}
