import { D3GraphStepLineHelperDataPointCoordinate, D3GraphStepLineHelperDataPointDatum } from './d3-graph-step-line.helper';
import * as d3 from 'd3';
import { BaseType, Selection } from 'd3';
import { DynamicDataHelper } from '../common';

export class D3GraphStepFillHelper extends DynamicDataHelper<D3GraphStepLineHelperDataPointDatum> {
  protected nodeName = 'path';
  protected class = 'fill';

  protected setDynamic(selectAllWithData: Selection<BaseType, D3GraphStepLineHelperDataPointDatum, BaseType, any>): void {
    const areaGenerator = d3
      .area<D3GraphStepLineHelperDataPointCoordinate>()
      .y0((c) => c.y0)
      .y1((c) => c.y1)
      .x((c) => c.x)
      .curve(d3.curveStepBefore);

    selectAllWithData.attr('d', (datum) => areaGenerator(datum.coordinates)).style('fill', (datum) => datum.fillColor);
  }

  protected setStatic(selectAllWithData: Selection<BaseType, D3GraphStepLineHelperDataPointDatum, BaseType, any>): void {}
}
