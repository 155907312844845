import { Directive, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AutocompleteComponent, AUTOCOMPLETE_DATA_SOURCE_FILTER, DataSourceFilter } from './autocomplete.component';

@Injectable()
export class DataSourceFilterClearValueIfNotSelectable implements DataSourceFilter<any> {
  isPostFilter = true;

  getFilter(values: any[], autocomplete: AutocompleteComponent): Observable<boolean[]> {
    if (
      autocomplete.dataSource.isValidId(autocomplete.inputValue) &&
      // @ts-ignore
      !values.some((value) => autocomplete.dataSource.identifyWith(value) === autocomplete.inputValue)
    ) {
      autocomplete.inputValue = '';
      autocomplete.onChange('');
    }

    return of(values.map((v) => !!v));
  }
}

/**
 * Clear the current selected value if it is no longer in the selectable list.
 * Must be run BEFORE DataSourceFilterRemoveSelectedValue (see priority) to ensure that this will not remove the selected option.
 */
@Directive({
  selector: '[phFlexDataSourceFilterClearValueIfNotSelectable]',
  providers: [
    {
      provide: AUTOCOMPLETE_DATA_SOURCE_FILTER,
      multi: true,
      useClass: DataSourceFilterClearValueIfNotSelectable
    }
  ]
})
export class DataSourceFilterClearValueIfNotSelectableDirective {}
