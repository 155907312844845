import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { clone } from 'lodash-es';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Control } from '../../../../api-private/control/control';
import { ControlService } from '../../../../api-private/control/control.service';
import { findParameterValue } from '../../../../core/common/find-parameter-value';
import { EntityStateModel, FetchEntity } from '../../../common/entity.state';
import { ControlsLoadedEvent, LoadControlsCommand, ResetControlsCommand } from './controls.actions';

export class ControlsStateModel extends EntityStateModel<ReadonlyArray<Control>> {
  model: ReadonlyArray<Control> = [];
}

@State({
  name: 'controls',
  defaults: new ControlsStateModel()
})
@Injectable({
  providedIn: 'root'
})
export class ControlsState {
  @Selector()
  public static getControls(state: ControlsStateModel): ReadonlyArray<Control> {
    if (state.isBusyReceiving) {
      return [];
    }
    return clone(state.model);
  }

  @Selector()
  public static getNonIncidentReserveOnlyControls(state: ControlsStateModel): ReadonlyArray<Control> {
    if (state.isBusyReceiving) {
      return [];
    }
    return clone(state.model.filter((control) => control.incidentReserveOnly === false));
  }

  @Selector()
  public static getIncidentReserveOnlyControls(state: ControlsStateModel): ReadonlyArray<Control> {
    if (state.isBusyReceiving) {
      return [];
    }
    return clone(state.model.filter((control) => control.incidentReserveOnly === true));
  }

  @Selector()
  public static isBusy(state: ControlsStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  public static isInitialized(state: ControlsStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  public static isBusySending(state: ControlsStateModel): boolean {
    return state.isBusySending;
  }

  @Selector()
  public static error(state: ControlsStateModel): any {
    return state.sendError;
  }

  constructor(private activatedRoute: ActivatedRoute, private controlService: ControlService) {}

  @Action(ResetControlsCommand)
  resetControls({ setState }: StateContext<ControlsStateModel>): void {
    setState(new ControlsStateModel());
  }

  @FetchEntity()
  @Action(LoadControlsCommand)
  loadControls({ dispatch }: StateContext<ControlsStateModel>): Observable<ReadonlyArray<Control>> {
    const customerId = findParameterValue(this.activatedRoute.snapshot, 'customerId');
    const gridPointId = findParameterValue(this.activatedRoute.snapshot, 'gridPointId');

    if (customerId) {
      return this.controlService
        .getFiltered({ customerId, gridPointId })
        .pipe(tap((controls) => dispatch(new ControlsLoadedEvent(controls))));
    } else {
      return this.controlService.getAll().pipe(tap((controls) => dispatch(new ControlsLoadedEvent(controls))));
    }
  }

  @Action(ControlsLoadedEvent)
  setControls({ setState, getState }: StateContext<ControlsStateModel>, { payload }: ControlsLoadedEvent): void {
    const state = getState();
    setState({
      ...state,
      model: payload
    });
  }
}
