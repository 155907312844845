import { OnDestroyMixin } from '../../core/common/on-destroy.mixin';
import { AutoCompleteDataViewer, FilteredDataSource } from './shared';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { delay, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { isUUid } from '../../core/common/isUuid';
import { MixinBase } from '../../core/common/constructor-type.mixin';

export abstract class LazyLoadDataSource<T> extends OnDestroyMixin(MixinBase) implements FilteredDataSource<T> {
  // Most up to date synchronous data
  latest: ReadonlyArray<T> = [];
  latestFiltered: ReadonlyArray<T> = [];

  /**
   * TODO evaluate if this should be moved to the generic interface
   */
  latestFilteredWithCurrent: ReadonlyArray<T> = [];
  isInitialized = false;
  dataSourceSubscription: Subscription;
  // Observable that should provide updated data when subscribed to
  protected dataSource$: Observable<T[]> = of(null);
  // Cache/multicast to update multiple subscribers
  private entitiesSubject = new ReplaySubject<T[]>(1);

  // Prevent multiple subscriptions to dataSource$ within the same frame, but allow frequent updates.
  private subscriptionMap = new Map<AutoCompleteDataViewer<T>, Subject<void>>();
  // TODO change to switchMap?
  private crudeUpdateTimeoutHandle: any = null;

  protected constructor() {
    super();
    this.entitiesSubject.pipe(first(), takeUntil(this.onDestroy$)).subscribe(() => (this.isInitialized = true));
  }

  isValidId(value: string): boolean {
    return this.latest.some((knownEntity) => this.identifyWith(knownEntity) === value);
  }

  update(): void {
    if (this.crudeUpdateTimeoutHandle !== null) {
      clearTimeout(this.crudeUpdateTimeoutHandle);
    }

    this.crudeUpdateTimeoutHandle = setTimeout(() => {
      this.crudeUpdateTimeoutHandle = null;
      if (this.dataSourceSubscription) {
        this.dataSourceSubscription.unsubscribe();
      }
      this.dataSourceSubscription = this.dataSource$.pipe(takeUntil(this.onDestroy$)).subscribe((result) => {
        this.entitiesSubject.next(result);
      });
    }, 10);
  }

  connect(filteredDataViewer: AutoCompleteDataViewer<T>): Observable<T[] | ReadonlyArray<T>> {
    if (this.subscriptionMap.has(filteredDataViewer)) {
      return this.entitiesSubject.asObservable().pipe(takeUntil(this.subscriptionMap.get(filteredDataViewer)));
    }

    this.update();

    // Set up hook to unsubscribe when disconnected
    const takeUntilSubject = new Subject<void>();
    this.subscriptionMap.set(filteredDataViewer, takeUntilSubject);

    // Set up hook that updates the filter, but als preserves the last value when a new subscription happens
    const filterSubject = new BehaviorSubject<string>(null);
    filteredDataViewer.filterChange.pipe(takeUntil(takeUntilSubject)).subscribe((value) => filterSubject.next(value));

    // Return an observable that filters according to the filter specified by the specific AutoCompleteDataViewer
    return this.entitiesSubject.asObservable().pipe(
      tap((result) => {
        this.latest = result || [];
      }),
      // We use '' instead of null or undefined as a filter value so we can show all items instead of none (in ase the filter implementation uses String.includes())
      switchMap((newEntities) =>
        filterSubject.pipe(
          delay(1), // Introduce a small delay to enforce subscribers getting values in the correct order
          map((filter) => {
            // Store filtered items including current in latestFilteredWithCurrent
            this.latestFilteredWithCurrent = this.filter(newEntities, this.createFilterString(filter));

            // Filter out current
            return this.latestFilteredWithCurrent.filter((entity) => {
              return this.identifyWith(entity) !== filter;
            });
          })
        )
      ),
      tap((result) => {
        this.latestFiltered = result;
      }),
      takeUntil(takeUntilSubject)
    );
  }

  isValidFilterValue(value: string): boolean {
    return !(isUUid(value) || !value);
  }

  /**
   * Disconnect the data viewer from the data source and remove all links
   */
  disconnect(filteredDataViewer: AutoCompleteDataViewer<T>): void {
    if (this.subscriptionMap.has(filteredDataViewer)) {
      this.subscriptionMap.get(filteredDataViewer).next();
      this.subscriptionMap.get(filteredDataViewer).complete();
      this.subscriptionMap.delete(filteredDataViewer);
    } else {
      console.warn('Tried to disconnect from a LazyLoadDataSource that this class has already disconnected from.');
    }
  }

  abstract filter(entities: ReadonlyArray<T>, filter: string): ReadonlyArray<T>;

  /**
   * Default implementation using displayEntityWith and identifyWith
   */
  displayIdWith(id: string): string | null {
    const result = this.latest && this.latest.find((entity) => this.identifyWith(entity) === id);
    if (result) {
      return this.displayEntityWith(result);
    }
    return null;
  }

  abstract displayEntityWith(value: T): string;

  abstract identifyWith(value: T): string;

  /**
   * This should filter out IDs if you want a full selection when a valid option is selected
   */
  protected createFilterString(currentFilter: string): string {
    return this.isValidFilterValue(currentFilter) ? currentFilter || '' : '';
  }
}
