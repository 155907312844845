import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MotdDialogComponent } from '../motd-dialog/motd-dialog.component';
import { MotdMessage, MotdService } from '../motd.service';
import { OnDestroyMixin } from '../../../core/common/on-destroy.mixin';
import { MixinBase } from '../../../core/common/constructor-type.mixin';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ph-flex-motd-button',
  templateUrl: './motd-button.component.html',
  styleUrls: ['./motd-button.component.scss']
})
export class MotdButtonComponent extends OnDestroyMixin(MixinBase) implements OnInit {
  public currentMotd: MotdMessage;
  private currentMatDialogRef: MatDialogRef<any>;

  constructor(private matDialog: MatDialog, private motdService: MotdService, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.motdService.motd$.pipe(takeUntil(this.onDestroy$)).subscribe((motd) => {
      this.currentMotd = motd;

      if (!motd?.id) {
        this.currentMatDialogRef?.close();
        this.cdr.detectChanges();
        return;
      }

      if (!motd.hasBeenShown) {
        this.openDialog();
      }

      this.cdr.detectChanges();
    });
  }

  openDialog(): void {
    this.currentMatDialogRef?.close();

    this.currentMatDialogRef = this.matDialog.open(MotdDialogComponent, {
      data: this.currentMotd,
      width: '500px'
    });

    this.currentMatDialogRef
      .afterClosed()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.currentMatDialogRef = null;
      });
  }
}
