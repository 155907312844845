import moment from 'moment';
import { Moment } from 'moment';

/**
 * Serializer/deserializer for date-only moments.
 * API compatible with DateAndTime
 */
export class LocalDate {
  date: Moment;

  private constructor() {}

  static deserialize(dateTime: string | Date | Moment): LocalDate {
    return LocalDate.fromMoment(moment(dateTime, 'YYYY-MM-DD'));
  }

  static serialize(localDate: LocalDate): string {
    return LocalDate.toMoment(localDate).format('YYYY-MM-DD');
  }

  static fromMoment(dateTime: Moment): LocalDate {
    return {
      date: dateTime
    };
  }

  static toMoment(localDate: LocalDate): Moment | null {
    if (!localDate) {
      return null;
    }

    return moment(localDate.date);
  }

  static clone(source: LocalDate): LocalDate {
    return LocalDate.fromMoment(LocalDate.toMoment(source).clone());
  }

  static compare(a: LocalDate, b: LocalDate): number {
    if (a.date.isAfter(b.date)) {
      return 1;
    }
    if (a.date.isBefore(b.date)) {
      return -1;
    }
    return 0;
  }
}
