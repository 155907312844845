import { ScaleBand } from 'd3-scale';
import { BaseType, Selection } from 'd3';
import { D3GraphSubmodule } from '../d3-graph-submodule';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { OnDestroyMixin } from 'projects/flex-app-shared/src/lib/core/common/on-destroy.mixin';
import { MixinBase } from '../../../../core/common/constructor-type.mixin';
import { DynamicDataHelper, SubjectProvider } from '../../common';

export interface HorizontalLineScaleBandDatum {
  /**
   * Y position used for y1 and y2
   */
  yPosition: number;

  /**
   * Color of the line
   */
  color: string;
}

type SupportedScale = ScaleBand<any>;

/**
 * Draw horizontal lines at the start and end of the provided y scale, and between each item in the scale.
 */
export class D3GraphScaleBandHorizontalLines extends OnDestroyMixin(MixinBase) implements D3GraphSubmodule {
  private yScaleProvider = new SubjectProvider<SupportedScale>(this);
  private horizontalLinesHelper: HorizontalLinesScaleBandHelper;

  setYScale$(scale$: Observable<SupportedScale>): void {
    this.yScaleProvider.follow(scale$);
  }

  attach(mainSvg: Selection<BaseType, any, any, any>): () => any {
    const node = mainSvg.append('g').attr('class', 'horizontal-lines');

    this.horizontalLinesHelper = new HorizontalLinesScaleBandHelper(node);

    const subscription = this.yScaleProvider.value$.pipe(takeUntil(this.onDestroy$)).subscribe((scale) => {
      this.horizontalLinesHelper.setData(
        getLineYPositionsFromScaleBand(scale).map((yPosition) => ({
          yPosition,
          color: '#f6f8fa'
        }))
      );
    });

    return () => subscription.unsubscribe();
  }
}

/**
 * Draw horizontal lines for the provided y values
 */
export class HorizontalLinesScaleBandHelper extends DynamicDataHelper<HorizontalLineScaleBandDatum> {
  protected nodeName = 'line';
  protected class = 'horizontal-line';

  protected setDynamic(selectAllWithData: Selection<BaseType, HorizontalLineScaleBandDatum, BaseType, any>): void {
    selectAllWithData
      .attr('y1', (datum) => Math.max(datum.yPosition, 1))
      .attr('y2', (datum) => datum.yPosition)
      .attr('stroke', (datum) => datum.color);
  }

  protected setStatic(selectAllWithData: Selection<BaseType, HorizontalLineScaleBandDatum, BaseType, any>): void {
    selectAllWithData.attr('x2', '100%').attr('shape-rendering', 'crispEdges');
  }
}

/**
 * Get the horizontal y positions to draw lines on given a ScaleBand.
 */
export function getLineYPositionsFromScaleBand(scale: ScaleBand<any>): number[] {
  const range = scale.range();
  return [
    range[0], // Always add the first ...
    ...scale
      .domain()
      .slice(1)
      .map((a, i) => (i + 1) * scale.step()), // For every line except for the first (0 and 1 domain item both produce 2 lines), draw a dividing line based on the step size and index.
    range[1] // And the last line
  ];
}
