import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationUpdateService } from './application-update.service';

@NgModule({
  declarations: [],
  imports: [CommonModule]
})
export class FlexApplicationUpdateModule {
  static forRoot(): ModuleWithProviders<FlexApplicationUpdateModule> {
    return {
      ngModule: FlexApplicationUpdateModule,
      providers: [
        ApplicationUpdateService,
        {
          provide: APP_INITIALIZER,
          multi: true,
          useFactory: initializerFactory,
          deps: [ApplicationUpdateService]
        }
      ]
    };
  }
}

export function initializerFactory(): () => void {
  return () => {};
}
