import { ControlValueAccessor } from '@angular/forms';
import { OnDestroyMixin } from '../common/on-destroy.mixin';
import { MixinBase } from '../common/constructor-type.mixin';

export abstract class BaseControlValueAccessor<T> extends OnDestroyMixin(MixinBase) implements ControlValueAccessor {
  public disabled = false;

  // Call when value has changed programmatically
  public onChange(newVal: T): void {}

  public onTouched(_?: any): void {}

  abstract writeValue(obj: any): void;

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
