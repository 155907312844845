import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class UriEncoderInterceptor implements HttpInterceptor {
  /**
   * Encode URI because it can contain a pipe (|) character because some browsers do not automatically encode this.
   *
   * @param req The request
   * @param next The next handler
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const newReq = req.clone({ url: encodeURI(req.url) });
    return next.handle(newReq);
  }
}
