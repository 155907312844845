import { OverlayContainer } from '@angular/cdk/overlay';
import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';

@Injectable({
  providedIn: 'root'
})
export class DynamicOverlayContainer extends OverlayContainer {
  constructor(@Inject(DOCUMENT) document: any, _platform: Platform) {
    super(document, _platform);
  }

  public setContainerElement(containerElement: HTMLElement): void {
    this._containerElement = containerElement;
  }
}
