import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexFiltersComponent, FlexFilterWrapperComponent } from './filters/filters.component';
import { FlexDialogModule } from '../dialog/dialog.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { CustomPluralPipe } from './plural.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PortalModule } from '@angular/cdk/portal';
import { PhFlexDarkDirective, PhFlexMatFormFieldOverrideDirective } from './ph-flex-input-theme.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FlexCoreModule } from '../../core/core.module';

@NgModule({
  declarations: [
    FlexFiltersComponent,
    FilterDialogComponent,
    CustomPluralPipe,
    PhFlexMatFormFieldOverrideDirective,
    PhFlexDarkDirective,
    FlexFilterWrapperComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexDialogModule,
    MatButtonModule,
    FormsModule,
    MatCheckboxModule,
    MatIconModule,
    PortalModule,
    MatFormFieldModule,
    MatInputModule,
    FlexCoreModule
  ],
  exports: [FlexFiltersComponent, FilterDialogComponent, PhFlexMatFormFieldOverrideDirective, PhFlexDarkDirective]
})
export class FlexFiltersModule {}
