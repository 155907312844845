<mat-list dense class="sidenav">
  <!--Toggle class "acceptance" for acceptance environment.-->
  <div class="environment-tag acceptance">Acceptance environment</div>

  <!--Toggle class "test" for test environment.-->
  <div *ngIf="appInfo$ | async as appInfo" class="environment-tag test">Environment: {{ appInfo.env }}</div>

  <mat-list-item class="logo" (click)="logoClicked.emit($event)">
    <img [src]="logoUrl" alt="logo" />
  </mat-list-item>
  <mat-divider class="spacer__small"></mat-divider>
  <ng-container *ngIf="sidenavMenuItemDirective">
    <mat-list-item *ngFor="let menuItem of menuItems">
      <ng-container *ngTemplateOutlet="sidenavMenuItemDirective.template; context: { $implicit: menuItem }"></ng-container>
    </mat-list-item>
  </ng-container>
</mat-list>
<div class="versioning" style="display: none" *ngIf="appInfo$ | async as appInfo">
  <b>Version:</b> FE: {{ appInfo.frontendVersion }}, BE: {{ appInfo.backendVersion }}
  <br />
  <span title="Click to copy commit hash" (click)="clickToClipBoard(appInfo.frontendCommitId)">
    <b>CommitID:</b> <span>{{ appInfo.frontendCommitId }}</span>
  </span>
</div>
