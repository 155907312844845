import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexWebsocketService } from './websocket.service';
import { RxStomp } from '@stomp/rx-stomp';

@NgModule()
export class FlexWebSocketModule {
  static forRoot(): ModuleWithProviders<FlexWebSocketModule> {
    return {
      ngModule: FlexWebSocketModule,
      providers: [
        FlexWebsocketService,
        {
          provide: RxStomp,
          useFactory: () => new RxStomp()
        }
      ]
    };
  }
}
