/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from '@angular/core';
import { from, map, merge, NEVER, Observable, of, race, timer } from 'rxjs';
import { SINGLETON } from '../../core/common/on-destroy.mixin';
import { SubjectProvider } from '../../d3-graph/d3-graph/common';
import { ParsedIdToken, parseIdToken } from './utils/parse-token';
import { TokenHelper } from './utils/validate-token';
import { filter, first, shareReplay, switchMap, tap } from 'rxjs/operators';
import { AzureMetric, CounterProvider } from '../../app-insights/metrics-provider';

function filterFirstIdToken(value: any, index: number): boolean {
  return index === 0 ? !!value : true;
}

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  static EMIT_TOKENS_AVAILABLE_TIMEOUT_MS = 5000;

  private reEmmitCounter = new CounterProvider(SINGLETON, AzureMetric.REQUEST_TIMEOUT_TOKEN_SIGNALING);
  private tokensAvailableProvider = new SubjectProvider<boolean>(SINGLETON);

  get validIdTokenAvailable(): boolean {
    return !!this.validIdTokenProvider.value;
  }

  /**
   * Emits false if no tokens are available (and they should be refreshed)
   * Emits true if tokens are valid or will be valid in the future.
   */
  tokensAvailable$ = this.tokensAvailableProvider.value$;

  get tokensAvailable(): boolean {
    return this.tokensAvailableProvider.value;
  }

  get validIdToken(): ParsedIdToken {
    return this.validIdTokenProvider.value;
  }

  /**
   * List of all tokens that are known, and are valid or will be valid in the future.
   */
  private futureOrCurrentTokens: ParsedIdToken[] = [];

  /**
   * Provide a decoded idToken based on the data in idTokenProvider
   */
  private parsedIdTokenProvider = new SubjectProvider<ParsedIdToken>(SINGLETON);
  parsedIdToken$ = this.parsedIdTokenProvider.value$;
  /**
   * Contains the subject (or user_name in sec_user) of the current token
   */
  subject$ = this.parsedIdTokenProvider.value$.pipe(map((token) => token?.claims?.sub));
  name$ = this.parsedIdTokenProvider.value$.pipe(map((token) => token?.claims?.name));
  private validIdTokenProvider = new SubjectProvider<ParsedIdToken>(SINGLETON);

  /**
   * Observable with valid id tokens.
   * Will emit null when no valid tokens are available and the current token expires.
   * Otherwise will emit new tokens when they are available.
   */
  validIdToken$: Observable<ParsedIdToken> = race(
    this.validIdTokenProvider.value$.pipe(filter(filterFirstIdToken)),
    timer(TokenService.EMIT_TOKENS_AVAILABLE_TIMEOUT_MS).pipe(
      tap(() => {
        // If the validIdTokenProvider didn't emit a value within EMIT_TOKENS_AVAILABLE_TIMEOUT_MS, also emit tokensAvailable false.
        // Then switch back to listen for the updated value, if available.
        this.reEmmitCounter.increment();
        this.tokensAvailableProvider.next(false);
      }),
      switchMap(() => this.validIdTokenProvider.value$.pipe(filter(filterFirstIdToken)))
    )
  );

  isValid(token: string): boolean {
    const parsedToken = parseIdToken(token);
    const tokenHelper = new TokenHelper(parsedToken, TokenHelper.DEFAULT_CLOCK_SKEW_MS, this.nowProvider);
    this.addNewKnownToken(tokenHelper);
    return tokenHelper.isValid();
  }

  constructor() {
    this.tokensAvailable$.subscribe((isAvailable) => {
      console.warn('Are tokens available: ', isAvailable);
    });

    this.validIdTokenProvider.follow(
      this.parsedIdTokenProvider.value$.pipe(
        switchMap((parsedToken) => {
          const tokenHelper = new TokenHelper(parsedToken, TokenHelper.DEFAULT_CLOCK_SKEW_MS, this.nowProvider);
          this.addNewKnownToken(tokenHelper);
          return tokenHelper.asObservable().pipe(
            tap({
              complete: () => {
                this.removeKnownToken(tokenHelper);
              }
            })
          );
        }),
        shareReplay(1),
        switchMap((result, index) => {
          console.warn('in validIdTokenProvider switchMap', result);
          // Re-emit value, but also signal that a token has been unavailable for a set duration, which could trigger a token refresh
          if (result) {
            return of(result);
          }

          return merge(
            from(of(result)),
            timer(TokenService.EMIT_TOKENS_AVAILABLE_TIMEOUT_MS).pipe(
              tap(() => {
                // Emit when a token is not emitted when starting from a falsy value on parsedIdTokenProvider.
                // When there hasn't been a valid token yet, this will not emit, and the fallback path in validIdToken$ race is instead used.
                this.reEmmitCounter.increment();
                this.tokensAvailableProvider.next(this.tokensAvailableProvider.value);
              }),
              switchMap(() => NEVER)
            )
          );
        }),
        tap((output) => console.warn('validIdTokenProvider output: ', output))
      )
    );
  }

  // Override for testing
  public nowProvider: () => Date = () => new Date();

  /**
   * Set the provided token as the new active token
   */
  updateToken(newToken: string): void {
    // Only set token if it is valid

    const parsedToken = parseIdToken(newToken);
    const tokenHelper = new TokenHelper(parsedToken, TokenHelper.DEFAULT_CLOCK_SKEW_MS, this.nowProvider);

    if (tokenHelper.hasNotExpired()) {
      this.parsedIdTokenProvider.next(parsedToken);
    }
  }

  /**
   * Remove the current token (e.g. when the token expires or when the user has logged out)
   */
  clearToken(): void {
    this.futureOrCurrentTokens = [];
    this.parsedIdTokenProvider.next(null);
    this.validIdTokenProvider.next(null);
  }

  refreshTokenIfNotAvailable(): void {
    // This re-emits tokensAvailableProvider if it is false, which should trigger a refresh
    if (!this.tokensAvailable) {
      this.tokensAvailableProvider.next(false);
    }
  }

  private addNewKnownToken(tokenHelper: TokenHelper): void {
    const isNew = !this.futureOrCurrentTokens.find((current) => current?.originalToken === tokenHelper.idToken?.originalToken);
    const isNotExpired = tokenHelper.hasNotExpired();
    if (isNew && isNotExpired) {
      // Add
      this.futureOrCurrentTokens.push(tokenHelper.idToken);
      this.tokensAvailableProvider.next(true);
    }
  }

  private removeKnownToken(tokenHelper: TokenHelper): void {
    const oldLength = this.futureOrCurrentTokens.length;

    this.futureOrCurrentTokens = this.futureOrCurrentTokens.filter((current) => {
      return current.originalToken !== tokenHelper.idToken?.originalToken;
    });

    if (oldLength !== this.futureOrCurrentTokens.length) {
      if (this.futureOrCurrentTokens.length === 0) {
        this.tokensAvailableProvider.next(false);
      }
    }
  }
}
