import { BaseType, ScaleTime, Selection } from 'd3';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { DynamicDataHelper, Margins, SubjectProvider } from '../../common';
import { format } from 'date-fns';
import { DestroyableMixin, OnDestroyMixin, OnDestroyProvider } from '../../../../core/common/on-destroy.mixin';
import { MixinBase } from '../../../../core/common/constructor-type.mixin';
import { D3GraphSubmodule } from '../d3-graph-submodule';
import { ScaleBand, ScaleContinuousNumeric } from 'd3-scale';
import { expectObservableValue } from '../../../../core/common/expect-observable-value';

type SupportedXScale = ScaleTime<number, number>;
type SupportedYScale = ScaleContinuousNumeric<any, any> | ScaleBand<any>;

interface AxisLabel {
  offsetX: number;
  offsetY: number;
  label: string;
}

/**
 * Render x axis labels.
 *
 * Deps:
 *  - Scale to create labels for
 *  - Ticks to determine the 'skip' interval TODO change this behavior
 *
 *  TODO provide a top offset somehow, or specify that the parent needs to style the g.x-axis node
 */
export class D3GraphXAxisLabels extends DestroyableMixin(OnDestroyMixin(MixinBase)) implements D3GraphSubmodule {
  private ticksProvider = new SubjectProvider<Date[]>(this);
  private xScaleProvider = new SubjectProvider<SupportedXScale>(this);
  private yScaleProvider = new SubjectProvider<SupportedYScale>(this);
  private dateFormatProvider = new SubjectProvider<string>(this);
  private marginProvider = new SubjectProvider(this, new BehaviorSubject<Pick<Margins, 'top'>>({ top: 20 }));

  private d3GraphXAxisHelper: D3GraphXAxisHelper;

  private subscriptions: Subscription[] = [];

  /**
   * Emit when updated (e.g. to re-evaluate graph height)
   * TODO Possibly add this to other SubModules as well, and use this in a generic graph height update mechanism?
   */
  private updatedSubject = new Subject<void>();
  updated$ = this.updatedSubject.asObservable();

  constructor(onDestroyProvider: OnDestroyProvider) {
    super(onDestroyProvider);
  }

  setTicks(ticks: Date[]): void {
    this.ticksProvider.next(ticks);
  }

  setTicks$(ticks$: Observable<Date[]>): void {
    this.ticksProvider.follow(ticks$);
  }

  setXScale(scale: SupportedXScale): void {
    this.xScaleProvider.next(scale);
  }

  setXScale$(xScale$: Observable<SupportedXScale>): void {
    this.xScaleProvider.follow(xScale$);
  }

  setYScale$(yScale$: Observable<SupportedYScale>): void {
    this.yScaleProvider.follow(yScale$);
  }

  setYScale(scale: SupportedYScale): void {
    this.yScaleProvider.next(scale);
  }

  /**
   * Set the label format according to https://date-fns.org/v2.17.0/docs/format
   */
  setDateFormat(dateFormat: string): void {
    this.dateFormatProvider.next(dateFormat);
  }

  setDateFormat$(dateFormat$: Observable<string>): void {
    this.dateFormatProvider.follow(dateFormat$);
  }

  destroy(): void {
    this.subscriptions.forEach((subscription) => !subscription.closed && subscription.unsubscribe());
    this.ngOnDestroy();
  }

  /**
   * Create the graph in the parent selection, e.g. a <svg> node.
   * It will update automatically based on the other inputs of this class.
   * @return Detach callback
   */
  attach(mainSvg: Selection<BaseType, any, any, any>): () => void {
    const node = mainSvg.append('g').attr('class', 'x-axis');

    this.d3GraphXAxisHelper = new D3GraphXAxisHelper(node);

    expectObservableValue(this.ticksProvider.value$, 'No ticksProvider in D3GraphXAxisLabels');
    expectObservableValue(this.xScaleProvider.value$, 'No xScaleProvider in D3GraphXAxisLabels');
    expectObservableValue(this.yScaleProvider.value$, 'No yScaleProvider in D3GraphXAxisLabels');
    expectObservableValue(this.dateFormatProvider.value$, 'No dateFormatProvider in D3GraphXAxisLabels');
    expectObservableValue(this.marginProvider.value$, 'No marginProvider in D3GraphXAxisLabels');

    const subscription = combineLatest([
      this.ticksProvider.value$,
      this.xScaleProvider.value$,
      this.yScaleProvider.value$,
      this.dateFormatProvider.value$,
      this.marginProvider.value$
    ]).subscribe({
      next: ([ticks, xScale, yScale, dateFormat, margin]) => {
        let divisor = 3;
        if (ticks.length < 6) {
          divisor = 1;
        } else if (ticks.length < 12) {
          divisor = 2;
        }

        const data: AxisLabel[] = ticks
          .filter((value, index) => index % divisor === 0)
          .map((tick, index) => {
            return {
              offsetX: xScale(tick),
              label: this.formatTick(tick, dateFormat, index),
              offsetY: yScale.range()[1] + margin.top
            };
          });

        this.d3GraphXAxisHelper.setData(data);
        this.updatedSubject.next();
      },
      complete: () => {
        this.subscriptions = this.subscriptions.filter((current) => current !== subscription);
        subscription.unsubscribe();
        node.remove();
      }
    });

    this.subscriptions.push(subscription);

    return () => {
      this.subscriptions = this.subscriptions.filter((current) => current !== subscription);
      subscription.unsubscribe();
      node.remove();
    };
  }

  /**
   * Format a tick/Date according to the format, but ensure that 00:00 at the end of the day is 24:00.
   */
  private formatTick(tick: Date, dateFormat: string, tickIndex: number): string {
    const formattedDate = format(tick, dateFormat);

    if (tickIndex > 0 && formattedDate === '00:00') {
      return '24:00';
    }
    return formattedDate;
  }

  setMarginTop(marginTop: Margins['top']): void {
    this.marginProvider.next({ top: marginTop });
  }

  // /**
  //  * Determine which Nth ticks should be shown.
  //  * e.g. 1 = every tick is shown, 2 = every other tick is shown
  //  */
  // private determineTickFrequency(numberOfTicks: number, scale: ScaleTime<number, number>): number {
  //   const maxLabels = 9;
  //   const minLabels = 0;
  //   const minWidthOfTicksPx = 100;
  //   const availableWidth = scale.range()[1] - scale.range()[0];
  //
  //   let currentDivisor = 1;
  //   let numberOfLabels = numberOfTicks;
  //
  //   while (
  //     numberOfLabels > minLabels // Check min number of labels constraint
  //     || (availableWidth / numberOfLabels) < minWidthOfTicksPx // Check width constraint
  //     || numberOfLabels > maxLabels // Check max labels constraint
  //
  //     ) {
  //
  //   }
  //
  //   if (currentDivisor >= maxLabels) {
  //     return 0; // No labels
  //   }
  //
  // }
}

export class D3GraphXAxisHelper extends DynamicDataHelper<AxisLabel> {
  protected nodeName = 'text';
  protected class = 'label-x';

  protected setDynamic(selectAllWithData: Selection<BaseType, AxisLabel, BaseType, any>): void {
    selectAllWithData
      .attr('x', (datum: AxisLabel) => datum.offsetX)
      .attr('y', (datum: AxisLabel) => datum.offsetY)
      .text((datum: AxisLabel) => datum.label);
  }

  protected setStatic(selectAllWithData: Selection<BaseType, AxisLabel, BaseType, any>): void {
    selectAllWithData.attr('text-anchor', 'middle').attr('vertical-align', 'top').attr('dominant-baseline', 'hanging');
  }
}
