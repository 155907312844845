import { AsyncMessage } from './async-messaging.service';

export class AsyncMessageReceivedEvent {
  static type: string = '[Async Messaging] Async message';

  constructor(public message: AsyncMessage) {}
}

export class AsyncStatusChangedEvent {
  static type: string = '[Async Messaging] Async connection status changed';

  constructor(public isConnected: boolean) {}
}
