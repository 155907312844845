import { Directive, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AUTOCOMPLETE_DATA_SOURCE_FILTER, AutocompleteComponent, DataSourceFilter } from './autocomplete.component';

@Injectable()
export class DataSourceFilterRemoveSelectedValue implements DataSourceFilter<any> {
  isPostFilter = true;
  priority = 999;

  getFilter(values: any[], autocomplete: AutocompleteComponent): Observable<boolean[]> {
    return of(values.map((value) => autocomplete.dataSource.identifyWith(value) !== autocomplete.inputValue));
  }
}

/**
 * Remove the currently selected value from the selectable options list
 */
@Directive({
  selector: '[phFlexDataSourceFilterRemoveSelectedValue]',
  providers: [
    {
      provide: AUTOCOMPLETE_DATA_SOURCE_FILTER,
      multi: true,
      useClass: DataSourceFilterRemoveSelectedValue
    }
  ]
})
export class DataSourceFilterRemoveSelectedValueDirective {}
