import { Component, Inject } from '@angular/core';
import { MotdMessage } from '../motd.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'ph-flex-motd-dialog',
  templateUrl: './motd-dialog.component.html',
  styleUrls: ['./motd-dialog.component.scss']
})
export class MotdDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: MotdMessage) {}
}
