import moment, { Moment } from 'moment';

export abstract class DateAndTime {
  date: Moment;
  time: string;

  static deserialize(dateTime: string | Date | Moment): DateAndTime {
    return DateAndTime.fromMoment(moment(dateTime));
  }

  static serialize(dateAndTime: DateAndTime): string {
    return DateAndTime.toMoment(dateAndTime).toISOString();
  }

  static fromMoment(dateTime: Moment): DateAndTime {
    return {
      date: dateTime,
      time: dateTime.format('HH:mm')
    };
  }

  static toMoment(dateAndTime: DateAndTime): Moment | null {
    if (!dateAndTime || !dateAndTime.time || !dateAndTime.date) {
      return null;
    }

    return moment(`${dateAndTime.date.format('DD-MM-YYYY')} ${dateAndTime.time}`, 'DD-MM-YYYY HH:mm');
  }

  static clone(source: DateAndTime): DateAndTime {
    return DateAndTime.fromMoment(DateAndTime.toMoment(source).clone());
  }

  static now(): DateAndTime {
    return DateAndTime.fromMoment(moment());
  }
}
