import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReplaySubject } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private configSubject = new ReplaySubject<any>(1);
  public config$ = this.configSubject.asObservable().pipe(first());

  constructor(private http: HttpClient) {
    this.http.get('/config/config.json').subscribe(
      (config) => {
        this.configSubject.next(config);
      },
      (error) => {
        console.error(`Fetching config failed: ${error}`);
        return {};
      }
    );
  }
}
