import { RestEntity } from '../rest-client/rest-entity';
import { Capacity } from '../../core/domain/capacity';
import { AdjustmentDirection } from '../../core/common/adjustment-direction';
import { Period, ZonedPeriod } from '../../core/domain/period';
import { TimePeriod } from '../../core/date-time/time-period';

export class ServiceAgreement extends RestEntity {
  customerId: string;
  customerName: string;
  systemReference: string;
  reference: string;
  product: Product;
  period: Period;
  maxCapacityUpward: Capacity;
  maxCapacityDownward: Capacity;
  availabilityFeePercBaseLoad: number;
  availabilityFeePercTimeTable: number;
  gridPoints: ReadonlyArray<ServiceAgreementGridPoint>;
  availabilitySchedule: AvailabilitySchedule;
  incidentReservePreQualified: boolean;

  static isEmergencyPowerServiceAgreement(serviceAgreement: ServiceAgreement): boolean {
    return serviceAgreement?.product === Product.R3_EMERGENCY_POWER;
  }
  static isIntraDayServiceAgreement(serviceAgreement: ServiceAgreement): boolean {
    return serviceAgreement?.product === Product.INTRA_DAY;
  }
}

export class ServiceAgreementGridPoint extends RestEntity {
  gridPointId: string;
  ean: string;
  description: string;
  mandatoryR3Participation: boolean;
  voluntaryR3Participation: boolean;
  incidentReservePreQualified: boolean;
}

export class AvailabilitySchedule {
  availabilities: ReadonlyArray<Availability> = [];
}

export enum AvailabilityType {
  OFF = 'OFF',
  ASSURED = 'ASSURED',
  VOLUNTARY = 'VOLUNTARY'
}

export class Availability {
  period: Period;
  timePeriod: TimePeriod;
  direction: AdjustmentDirection;
  availabilityType: AvailabilityType;
  power?: Capacity;
}

export enum VoluntaryR3Participation {
  ALL = 'ALL',
  SOME = 'SOME',
  NONE = 'NONE'
}

export enum Product {
  R3_EMERGENCY_POWER = 'R3_EMERGENCY_POWER',
  INTRA_DAY = 'INTRA_DAY'
}

export class AvailabilityPeriod {
  startDate: string;
  endDate: string;
}

export class ServiceAgreementOption {
  serviceAgreementId: string;
  systemReference: number;
  period: AvailabilityPeriod;
  label: string;
}

export class ImbalanceReserveAvailabilities {
  customerId: string;
  serviceAgreementAvailabilities: {
    [serviceAgreementId: string]: ImbalanceAvailabilityPerYear;
  };
  years: number[];

  startDateFirstServiceAgreement: string;
  endDateOfLastServiceAgreement?: string;
  earliestEditableMonth: string;
}

export class ImbalanceAvailabilityPerYear {
  serviceAgreementId: string;
  availabilitiesPerYear: {
    [year: string]: {
      year: number;
      availabilities: ImbalanceAvailabilityPerMonth[];
    };
  };
}

export class ImbalanceAvailabilityPerMonth {
  period: ZonedPeriod;
  serviceAgreementId: string;
  readOnly: boolean;
  noAvailabilities: boolean;
  otherServiceAgreements: boolean;
  multipleHourlyAvailabilities: boolean;
  capacityUpward?: Capacity;
  capacityDownward?: Capacity;
  maxCapacityUpward?: Capacity;
  maxCapacityDownward?: Capacity;
}
