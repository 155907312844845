import { LoadingContextComponent } from './loading-context.component';
import { NgModule } from '@angular/core';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [LoadingContextComponent],
  imports: [CommonModule, MatProgressSpinnerModule, PortalModule],
  exports: [LoadingContextComponent]
})
export class FlexLoadingContextModule {}
