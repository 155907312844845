import { ErrorHandler, InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { AppInsightsService } from './app-insights.service';

export const AppInsightsEnabled = new InjectionToken('Enable AppInsights');

@NgModule({
  imports: [CommonModule]
})
export class FlexAppInsightsModule {
  static forRoot(enable: boolean): ModuleWithProviders<FlexAppInsightsModule> {
    const baseProviders: any[] = [
      {
        useValue: enable,
        provide: AppInsightsEnabled
      }
    ];

    if (enable) {
      baseProviders.push(AppInsightsService);

      baseProviders.push({
        provide: ErrorHandler,
        useClass: ApplicationinsightsAngularpluginErrorService
      });
    }

    return {
      ngModule: FlexAppInsightsModule,
      providers: baseProviders
    };
  }
}
