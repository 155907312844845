import { EMPTY, Observable, switchMap } from 'rxjs';
import { MixinBase } from '../../../core/common/constructor-type.mixin';
import { SubjectProvider } from '../common';
import { D3GraphScaleProvider } from './d3-graph-scale-provider';
import { DestroyableMixin, OnDestroyMixin, OnDestroyProvider } from '../../../core/common/on-destroy.mixin';

/**
 * Can be used as a "ProviderProvider", where an instance of a D3GraphScaleProvider is required but will be set later, similar to how ObservableProvider and SubjectProvider can be used.
 * This class returns a Proxied object when the constructor is called.
 * Any methods or properties CALLED (not set) are first checked on the instance, and if they do not exist, are proxied to the current instance provided by scaleProviderProvider.
 *
 * Please note that this does require some type casting, e.g.:
 *  new D3GraphDynamicScaleProvider<ScaleTime<number, number>, Date>(this) as unknown as (TimeSlotScaleProvider & D3GraphDynamicScaleProvider<ScaleTime<number, number>, Date>);
 * See also d3-graph-x-scale-xoom.ts for a usage example
 */
export class D3GraphDynamicScaleProvider<ScaleType, ScaleValueType>
  extends DestroyableMixin(OnDestroyMixin(MixinBase))
  implements D3GraphScaleProvider<ScaleType, ScaleValueType>
{
  private scaleProviderProvider = new SubjectProvider<D3GraphScaleProvider<ScaleType, ScaleValueType>>(this);

  scaleProvider$ = this.scaleProviderProvider.value$;

  get scale(): ScaleType {
    return this.scaleProviderProvider.value?.scale;
  }

  scale$: Observable<ScaleType> = this.scaleProviderProvider.value$.pipe(switchMap((scaleProvider) => scaleProvider?.scale$ ?? EMPTY));

  ticks$: Observable<ScaleValueType[] | ScaleValueType> = this.scaleProviderProvider.value$.pipe(
    switchMap((scaleProvider) => scaleProvider?.ticks$ ?? EMPTY)
  );

  next(scaleProvider: D3GraphScaleProvider<ScaleType, ScaleValueType>): void {
    this.scaleProviderProvider.next(scaleProvider);
  }

  follow(scaleProvider$: Observable<D3GraphScaleProvider<ScaleType, ScaleValueType>>): void {
    this.scaleProviderProvider.follow(scaleProvider$);
  }

  destroy(): void {
    this.ngOnDestroy();
  }

  constructor(onDestroyProvider: OnDestroyProvider) {
    super();
    this.registerOnDestroyProvider(onDestroyProvider);

    return new Proxy(this, {
      get: (oTarget, sKey) => {
        // If sKey is part of this class, return the value.
        // If not, check if scaleProviderProvider has a value, if it does return the value of sKey of that.
        // This is done to support any non-standard methods or properties on the D3GraphScaleProvider that is used.
        if (sKey in oTarget || !this.scaleProviderProvider.value) {
          return oTarget[sKey];
        }

        return this.scaleProviderProvider.value[sKey];
      }
    });
  }
}
