import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

export function findParameterValue(snapshot: RouterStateSnapshot | ActivatedRouteSnapshot, key: string): string | null | undefined {
  const foundChild = recursiveFindParameterValue(snapshot.root.children, key);
  if (foundChild) {
    return foundChild.paramMap.get(key);
  }
}

export function findNotNullParameterValue(snapshot: RouterStateSnapshot | ActivatedRouteSnapshot, key: string): string | null {
  const foundParameterValue = findParameterValue(snapshot, key);
  if (foundParameterValue === 'new' || !foundParameterValue) {
    return null;
  }
  return foundParameterValue;
}

function recursiveFindParameterValue(snapshots: ActivatedRouteSnapshot[], key: string): ActivatedRouteSnapshot | null | undefined {
  const found = snapshots.map((it) => recursiveFindParameterValue(it.children || [], key)).find((it) => it !== null);
  if (found) {
    return found;
  }
  return snapshots.find((child) => child.paramMap.has(key));
}

/**
 * Get the full url of the given snapshot.
 * Starts with '/' and has '/' between each route that has a non undefined/null/'' path
 */
export function getRouterConfigUrl(snapShot: ActivatedRouteSnapshot, skipUrlPartType?: string): string {
  const currentPath = snapShot.routeConfig?.path;
  const currentPathPrefix = snapShot.root === snapShot ? '/' : '';

  if (snapShot.firstChild) {
    const childPath = getRouterConfigUrl(snapShot.firstChild, skipUrlPartType);

    if (skipUrlPartType && snapShot.data?.skipUrlPartType?.includes(skipUrlPartType)) {
      // Skip current path if skipUrlPartType is set and the skipUrlPart data property contains the same string as skipUrlPartType
      return childPath;
    }

    const currentPathPostfix = currentPath && childPath ? '/' : '';

    return `${currentPathPrefix}${currentPath || ''}${currentPathPostfix}${childPath}`;
  }

  if (skipUrlPartType && snapShot.data?.skipUrlPartType?.includes(skipUrlPartType)) {
    return '';
  }
  return currentPath;
}
