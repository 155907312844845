import { Pipe, PipeTransform } from '@angular/core';
import { GridPointService } from './grid-point.service';
import { GridPoint } from './grid-point';
import { Observable, of, Subscription } from 'rxjs';
import { distinctUntilChanged, map, retry, shareReplay, switchMap } from 'rxjs/operators';
import { OnDestroyMixin } from '../../core/common/on-destroy.mixin';
import { MixinBase } from '../../core/common/constructor-type.mixin';
import { SubjectProvider } from '../../d3-graph/d3-graph/common';
import { isObject } from 'lodash-es';
import { getRetryConfig } from '../../core/common/rxjs-utils';

/**
 * Return an OBSERVABLE of the grid point description for the given gridPointId.
 */
@Pipe({
  name: 'gridPoint$'
})
export class GridPointPipe extends OnDestroyMixin(MixinBase) implements PipeTransform {
  gridPointIdProvider = new SubjectProvider<string>(this);

  gridPoint$ = this.gridPointIdProvider.value$.pipe(
    // Only let changed grid point IDs through
    distinctUntilChanged(),
    // Emit a null, and then a gridPointId. This will cause '...' to show while the grid point is loading
    switchMap((gridPointId) => of(null, gridPointId)),
    // Next, fetch the grid point by ID. If the provided value is falsy, show '...'
    switchMap((gridPointId) => (gridPointId ? this.service.getById(gridPointId).pipe(retry(getRetryConfig())) : of(null))),
    map((gridPoint) => GridPointPipe.getDescription(gridPoint)),
    // If the observable is re-used, share the output.
    // This should normally not happen when used with AsyncPipe, but it makes no sense to call twice for the same ID if it does happen.
    shareReplay(1)
  );

  private static getDescription(gridPoint: GridPoint | string): string {
    if (!gridPoint || !isObject(gridPoint)) {
      return '...';
    }
    return `${gridPoint.ean} - ${gridPoint.description}`;
  }

  constructor(private service: GridPointService) {
    super();
  }

  transform(gridPointId: string): Observable<string> {
    this.gridPointIdProvider.next(gridPointId);

    return this.gridPoint$;
  }
}
