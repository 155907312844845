import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidenavMenuComponent } from './sidenav-menu.component';
import { SidenavMenuItemContentDef } from './sidenav-menu-item-content-def.directive';
import { SidenavMenuItemComponent } from './sidenav-menu-item/sidenav-menu-item.component';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';

@NgModule({
  declarations: [SidenavMenuComponent, SidenavMenuItemContentDef, SidenavMenuItemComponent],
  imports: [CommonModule, MatListModule, MatButtonModule],
  exports: [SidenavMenuComponent, SidenavMenuItemContentDef, SidenavMenuItemComponent]
})
export class FlexSidenavMenuModule {}
