import { NgModule, Sanitizer } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const ICON_ASSET_BASE_PATH = 'icons';

@NgModule({
  declarations: [],
  imports: [CommonModule, MatIconModule]
})
export class FlexIconsModule {
  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
    this.addIcons();
  }

  addIcons(): void {
    // This code is generated
    this.addSvgIcon('ic-new', 'activity', 'activity.8a3a1a1d.svg');
    this.addSvgIcon('ic-new', 'adapt', 'adapt.7e343ccd.svg');
    this.addSvgIcon('ic-new', 'agree1', 'agree1.43eed19c.svg');
    this.addSvgIcon('ic-new', 'alert-triangle', 'alert-triangle.ee28202b.svg');
    this.addSvgIcon('ic-new', 'archive', 'archive.481d6e14.svg');
    this.addSvgIcon('ic-new', 'arrow-down-circle', 'arrow-down-circle.e107e774.svg');
    this.addSvgIcon('ic-new', 'arrow-down-left', 'arrow-down-left.cf10eb8a.svg');
    this.addSvgIcon('ic-new', 'arrow-down-right', 'arrow-down-right.a49a2195.svg');
    this.addSvgIcon('ic-new', 'arrow-down', 'arrow-down.4b15b7e0.svg');
    this.addSvgIcon('ic-new', 'arrow-left-circle', 'arrow-left-circle.def6a90e.svg');
    this.addSvgIcon('ic-new', 'arrow-left', 'arrow-left.3aea39aa.svg');
    this.addSvgIcon('ic-new', 'arrow-right-circle', 'arrow-right-circle.796bc6fe.svg');
    this.addSvgIcon('ic-new', 'arrow-right', 'arrow-right.f866562a.svg');
    this.addSvgIcon('ic-new', 'arrow-up-circle', 'arrow-up-circle.8523412c.svg');
    this.addSvgIcon('ic-new', 'arrow-up-left', 'arrow-up-left.237eaa55.svg');
    this.addSvgIcon('ic-new', 'arrow-up-right', 'arrow-up-right.0fea5c3b.svg');
    this.addSvgIcon('ic-new', 'arrow-up', 'arrow-up.7a18af1c.svg');
    this.addSvgIcon('ic-new', 'balance', 'balance.7cc4db4b.svg');
    this.addSvgIcon('ic-new', 'bar-chart-2', 'bar-chart-2.7bcf3f9e.svg');
    this.addSvgIcon('ic-new', 'bar-chart', 'bar-chart.d9f1c33d.svg');
    this.addSvgIcon('ic-new', 'beat', 'beat.4b68d1da.svg');
    this.addSvgIcon('ic-new', 'bell', 'bell.5d5f33b1.svg');
    this.addSvgIcon('ic-new', 'book', 'book.abdb0d02.svg');
    this.addSvgIcon('ic-new', 'calendar', 'calendar.e449b9d0.svg');
    this.addSvgIcon('ic-new', 'check-square', 'check-square.d385ad18.svg');
    this.addSvgIcon('ic-new', 'check', 'check.0233aacb.svg');
    this.addSvgIcon('ic-new', 'chevron-down', 'chevron-down.276a1b84.svg');
    this.addSvgIcon('ic-new', 'chevron-left', 'chevron-left.d6786218.svg');
    this.addSvgIcon('ic-new', 'chevron-right', 'chevron-right.67c2710c.svg');
    this.addSvgIcon('ic-new', 'chevron-up', 'chevron-up.e6167d5c.svg');
    this.addSvgIcon('ic-new', 'chevrons-left', 'chevrons-left.96617b88.svg');
    this.addSvgIcon('ic-new', 'chevrons-right', 'chevrons-right.b28b8dee.svg');
    this.addSvgIcon('ic-new', 'clock', 'clock.387ca1c4.svg');
    this.addSvgIcon('ic-new', 'close', 'close.88c78954.svg');
    this.addSvgIcon('ic-new', 'cloud-lightning', 'cloud-lightning.62909b9f.svg');
    this.addSvgIcon('ic-new', 'cloudy', 'cloudy.50bd1c61.svg');
    this.addSvgIcon('ic-new', 'code', 'code.712db8aa.svg');
    this.addSvgIcon('ic-new', 'collect', 'collect.5440f8b1.svg');
    this.addSvgIcon('ic-new', 'compass', 'compass.f65e6e0b.svg');
    this.addSvgIcon('ic-new', 'copy', 'copy.3de6c5ff.svg');
    this.addSvgIcon('ic-new', 'corner-down-right', 'corner-down-right.c80a50db.svg');
    this.addSvgIcon('ic-new', 'corner-right-down', 'corner-right-down.fc893eed.svg');
    this.addSvgIcon('ic-new', 'corner-up-right', 'corner-up-right.2e54f99c.svg');
    this.addSvgIcon('ic-new', 'cry1', 'cry1.a1e08210.svg');
    this.addSvgIcon('ic-new', 'deliver', 'deliver.d2bc2db3.svg');
    this.addSvgIcon('ic-new', 'divide', 'divide.fd493044.svg');
    this.addSvgIcon('ic-new', 'dollar-sign', 'dollar-sign.e23be752.svg');
    this.addSvgIcon('ic-new', 'download', 'download.fe07d94e.svg');
    this.addSvgIcon('ic-new', 'edit-3', 'edit-3.f3648f56.svg');
    this.addSvgIcon('ic-new', 'edit', 'edit.dc43b954.svg');
    this.addSvgIcon('ic-new', 'external-link', 'external-link.7894c7fd.svg');
    this.addSvgIcon('ic-new', 'eye', 'eye.d0221d96.svg');
    this.addSvgIcon('ic-new', 'face-glasses', 'face-glasses.24d76c2b.svg');
    this.addSvgIcon('ic-new', 'face-glasses1', 'face-glasses1.12015510.svg');
    this.addSvgIcon('ic-new', 'face-moustache', 'face-moustache.25134e68.svg');
    this.addSvgIcon('ic-new', 'face-moustache1', 'face-moustache1.2abd8214.svg');
    this.addSvgIcon('ic-new', 'face-sunglasses', 'face-sunglasses.da926a9a.svg');
    this.addSvgIcon('ic-new', 'file-text', 'file-text.1db6c71d.svg');
    this.addSvgIcon('ic-new', 'file', 'file.f1c29102.svg');
    this.addSvgIcon('ic-new', 'filter', 'filter.033a22a5.svg');
    this.addSvgIcon('ic-new', 'fix', 'fix.3a172655.svg');
    this.addSvgIcon('ic-new', 'flame', 'flame.869a8e82.svg');
    this.addSvgIcon('ic-new', 'format', 'format.a683ce5a.svg');
    this.addSvgIcon('ic-new', 'glance', 'glance.81f81c6f.svg');
    this.addSvgIcon('ic-new', 'heat', 'heat.c214bce5.svg');
    this.addSvgIcon('ic-new', 'help-circle', 'help-circle.b54410d2.svg');
    this.addSvgIcon('ic-new', 'info', 'info.a407756b.svg');
    this.addSvgIcon('ic-new', 'laughing-face', 'laughing-face.16ccadf3.svg');
    this.addSvgIcon('ic-new', 'layers', 'layers.50172030.svg');
    this.addSvgIcon('ic-new', 'light', 'light.0c349ed9.svg');
    this.addSvgIcon('ic-new', 'live1', 'live1.77361939.svg');
    this.addSvgIcon('ic-new', 'mail', 'mail.fb0b9e2e.svg');
    this.addSvgIcon('ic-new', 'manage', 'manage.6b9785f8.svg');
    this.addSvgIcon('ic-new', 'manufacture', 'manufacture.b48f734f.svg');
    this.addSvgIcon('ic-new', 'maximize-2', 'maximize-2.1f1a5818.svg');
    this.addSvgIcon('ic-new', 'measure', 'measure.8111cc50.svg');
    this.addSvgIcon('ic-new', 'menu', 'menu.83249497.svg');
    this.addSvgIcon('ic-new', 'mill', 'mill.05829c7d.svg');
    this.addSvgIcon('ic-new', 'minimize-2', 'minimize-2.b7b9f3e2.svg');
    this.addSvgIcon('ic-new', 'minus-square', 'minus-square.4ac9f630.svg');
    this.addSvgIcon('ic-new', 'minus', 'minus.488ff7eb.svg');
    this.addSvgIcon('ic-new', 'more-horizontal', 'more-horizontal.92e983da.svg');
    this.addSvgIcon('ic-new', 'more-vertical', 'more-vertical.60e3efd7.svg');
    this.addSvgIcon('ic-new', 'open1', 'open1.44672615.svg');
    this.addSvgIcon('ic-new', 'pause-circle', 'pause-circle.51880856.svg');
    this.addSvgIcon('ic-new', 'pause', 'pause.7885b57d.svg');
    this.addSvgIcon('ic-new', 'percent', 'percent.1abb7427.svg');
    this.addSvgIcon('ic-new', 'pie-chart', 'pie-chart.b88fc695.svg');
    this.addSvgIcon('ic-new', 'plan1', 'plan1.0f3e5a3a.svg');
    this.addSvgIcon('ic-new', 'play-circle', 'play-circle.ec300597.svg');
    this.addSvgIcon('ic-new', 'play', 'play.1cf0b41e.svg');
    this.addSvgIcon('ic-new', 'plus-circle', 'plus-circle.7166c759.svg');
    this.addSvgIcon('ic-new', 'plus-square', 'plus-square.5bb63568.svg');
    this.addSvgIcon('ic-new', 'plus', 'plus.352c31ec.svg');
    this.addSvgIcon('ic-new', 'power', 'power.140cbfc6.svg');
    this.addSvgIcon('ic-new', 'press', 'press.2d0b7d49.svg');
    this.addSvgIcon('ic-new', 'publish', 'publish.c6af7088.svg');
    this.addSvgIcon('ic-new', 'radiate', 'radiate.2b2d7e57.svg');
    this.addSvgIcon('ic-new', 'rainy', 'rainy.cbdf1d54.svg');
    this.addSvgIcon('ic-new', 'realize', 'realize.2f2ae39d.svg');
    this.addSvgIcon('ic-new', 'repeat', 'repeat.58d7acfa.svg');
    this.addSvgIcon('ic-new', 'report', 'report.27d9aa3e.svg');
    this.addSvgIcon('ic-new', 'ring1', 'ring1.1d2fc567.svg');
    this.addSvgIcon('ic-new', 'rotate-cw', 'rotate-cw.dd7c0c4d.svg');
    this.addSvgIcon('ic-new', 'rss', 'rss.d720da14.svg');
    this.addSvgIcon('ic-new', 'sad-face-eyebrows', 'sad-face-eyebrows.9edced71.svg');
    this.addSvgIcon('ic-new', 'save', 'save.62371276.svg');
    this.addSvgIcon('ic-new', 'schedule', 'schedule.56a26da9.svg');
    this.addSvgIcon('ic-new', 'search', 'search.24556c26.svg');
    this.addSvgIcon('ic-new', 'see1', 'see1.84037611.svg');
    this.addSvgIcon('ic-new', 'send', 'send.db8a38df.svg');
    this.addSvgIcon('ic-new', 'settings', 'settings.2781196d.svg');
    this.addSvgIcon('ic-new', 'shopping-cart', 'shopping-cart.eba12db0.svg');
    this.addSvgIcon('ic-new', 'sliders', 'sliders.7a748701.svg');
    this.addSvgIcon('ic-new', 'smiling-face', 'smiling-face.7445d88a.svg');
    this.addSvgIcon('ic-new', 'sparkle1', 'sparkle1.139630ee.svg');
    this.addSvgIcon('ic-new', 'square', 'square.e2f683df.svg');
    this.addSvgIcon('ic-new', 'stop-circle', 'stop-circle.17ad8cbb.svg');
    this.addSvgIcon('ic-new', 'succeed', 'succeed.9aa10deb.svg');
    this.addSvgIcon('ic-new', 'terminal', 'terminal.3dc2d2f6.svg');
    this.addSvgIcon('ic-new', 'thunder', 'thunder.86666121.svg');
    this.addSvgIcon('ic-new', 'toggle-left', 'toggle-left.117b060b.svg');
    this.addSvgIcon('ic-new', 'toggle-right', 'toggle-right.0995f9c6.svg');
    this.addSvgIcon('ic-new', 'trash-2', 'trash-2.306f2244.svg');
    this.addSvgIcon('ic-new', 'trending-down', 'trending-down.86da5eea.svg');
    this.addSvgIcon('ic-new', 'trending-up', 'trending-up.41823295.svg');
    this.addSvgIcon('ic-new', 'triangle-down', 'triangle-down.b86f5f24.svg');
    this.addSvgIcon('ic-new', 'triangle-up', 'triangle-up.f898c44f.svg');
    this.addSvgIcon('ic-new', 'upload', 'upload.a7edff1e.svg');
    this.addSvgIcon('ic-new', 'user', 'user.d117a0d5.svg');
    this.addSvgIcon('ic-new', 'wait1', 'wait1.f4da1290.svg');
    this.addSvgIcon('ic-new', 'watch', 'watch.f231762a.svg');
    this.addSvgIcon('ic-new', 'weather', 'weather.4a07d916.svg');
    this.addSvgIcon('ic-new', 'work', 'work.9e199a3f.svg');
    this.addSvgIcon('ic-new', 'x-circle', 'x-circle.f86b2462.svg');
    this.addSvgIcon('ic-new', 'x-square', 'x-square.ca09945e.svg');
    this.addSvgIcon('ic-new', 'x', 'x.9f157ae3.svg');
    this.addSvgIcon('ic-new', 'zap', 'zap.a2635c1c.svg');
    this.addSvgIcon('ic-old', 'add', 'add.d7778df5.svg');
    this.addSvgIcon('ic-old', 'alert-error', 'alert-error.c6f8580e.svg');
    this.addSvgIcon('ic-old', 'alert-warning', 'alert-warning.eac887e2.svg');
    this.addSvgIcon('ic-old', 'arrow-down-right', 'arrow-down-right.72ec65e8.svg');
    this.addSvgIcon('ic-old', 'arrow-down', 'arrow-down.3cb6aa37.svg');
    this.addSvgIcon('ic-old', 'arrow-left', 'arrow-left.d8bac7b0.svg');
    this.addSvgIcon('ic-old', 'arrow-right', 'arrow-right.2fde10fb.svg');
    this.addSvgIcon('ic-old', 'arrow-up-right', 'arrow-up-right.faed6d0f.svg');
    this.addSvgIcon('ic-old', 'arrow-up', 'arrow-up.e7098e39.svg');
    this.addSvgIcon('ic-old', 'arrow_back', 'arrow_back.a1ab2b76.svg');
    this.addSvgIcon('ic-old', 'arrow_downward', 'arrow_downward.b19a636b.svg');
    this.addSvgIcon('ic-old', 'arrow_forward', 'arrow_forward.359ede96.svg');
    this.addSvgIcon('ic-old', 'arrow_upward', 'arrow_upward.5a069db8.svg');
    this.addSvgIcon('ic-old', 'availability', 'availability.e452e49f.svg');
    this.addSvgIcon('ic-old', 'bars', 'bars.7920f737.svg');
    this.addSvgIcon('ic-old', 'battery2', 'battery2.42c99bba.svg');
    this.addSvgIcon('ic-old', 'board', 'board.df81a6d1.svg');
    this.addSvgIcon('ic-old', 'calc', 'calc.1b27939b.svg');
    this.addSvgIcon('ic-old', 'cancel-circle-o', 'cancel-circle-o.dd7acbf5.svg');
    this.addSvgIcon('ic-old', 'cancel-square-o', 'cancel-square-o.0dd003c9.svg');
    this.addSvgIcon('ic-old', 'charging', 'charging.2c54fbce.svg');
    this.addSvgIcon('ic-old', 'chat-bubbles', 'chat-bubbles.29ca8592.svg');
    this.addSvgIcon('ic-old', 'check-circle-o', 'check-circle-o.c9613ac6.svg');
    this.addSvgIcon('ic-old', 'check', 'check.b20c46fa.svg');
    this.addSvgIcon('ic-old', 'checkbox-check-o', 'checkbox-check-o.985ea463.svg');
    this.addSvgIcon('ic-old', 'checkbox-empty-o', 'checkbox-empty-o.9f89d2fb.svg');
    this.addSvgIcon('ic-old', 'chevron-down', 'chevron-down.f17792f5.svg');
    this.addSvgIcon('ic-old', 'chevron-left', 'chevron-left.0f93c01c.svg');
    this.addSvgIcon('ic-old', 'chevron-right', 'chevron-right.ff2eb9f9.svg');
    this.addSvgIcon('ic-old', 'chevron-up', 'chevron-up.8ff60f81.svg');
    this.addSvgIcon('ic-old', 'circle-minus-o', 'circle-minus-o.2c39fda2.svg');
    this.addSvgIcon('ic-old', 'clock-o', 'clock-o.f3fd8f23.svg');
    this.addSvgIcon('ic-old', 'close', 'close.312a9e8f.svg');
    this.addSvgIcon('ic-old', 'cogs', 'cogs.fe43d449.svg');
    this.addSvgIcon('ic-old', 'connection', 'connection.84f6f425.svg');
    this.addSvgIcon('ic-old', 'copy', 'copy.3de6c5ff.svg');
    this.addSvgIcon('ic-old', 'correction', 'correction.79fd0ab9.svg');
    this.addSvgIcon('ic-old', 'create', 'create.85f15322.svg');
    this.addSvgIcon('ic-old', 'cube', 'cube.b1818bf7.svg');
    this.addSvgIcon('ic-old', 'dashboard', 'dashboard.c2c98049.svg');
    this.addSvgIcon('ic-old', 'devices', 'devices.137c26b0.svg');
    this.addSvgIcon('ic-old', 'download', 'download.c01fccc8.svg');
    this.addSvgIcon('ic-old', 'earth', 'earth.dfe61ada.svg');
    this.addSvgIcon('ic-old', 'edit-o', 'edit-o.a4cc42c6.svg');
    this.addSvgIcon('ic-old', 'electricity-o', 'electricity-o.623de7b0.svg');
    this.addSvgIcon('ic-old', 'erase', 'erase.8404e2e3.svg');
    this.addSvgIcon('ic-old', 'euro', 'euro.c972ee52.svg');
    this.addSvgIcon('ic-old', 'file-text-o', 'file-text-o.cdb3fc66.svg');
    this.addSvgIcon('ic-old', 'flow-switch', 'flow-switch.6c4a4931.svg');
    this.addSvgIcon('ic-old', 'history', 'history.13824d11.svg');
    this.addSvgIcon('ic-old', 'icon-tennet', 'icon-tennet.b8423f0b.svg');
    this.addSvgIcon('ic-old', 'imbalance', 'imbalance.70a4b2ca.svg');
    this.addSvgIcon('ic-old', 'info_outline', 'info_outline.4e7cfd74.svg');
    this.addSvgIcon('ic-old', 'inline-edit-o', 'inline-edit-o.29e0cfed.svg');
    this.addSvgIcon('ic-old', 'inputs', 'inputs.aa1e4507.svg');
    this.addSvgIcon('ic-old', 'intraday', 'intraday.ee2d778a.svg');
    this.addSvgIcon('ic-old', 'invoice-o', 'invoice-o.44c7625f.svg');
    this.addSvgIcon('ic-old', 'invoice-orders', 'invoice-orders.45f78209.svg');
    this.addSvgIcon('ic-old', 'key', 'key.116bc92a.svg');
    this.addSvgIcon('ic-old', 'list-ul', 'list-ul.51e304f9.svg');
    this.addSvgIcon('ic-old', 'location', 'location.7ca2430a.svg');
    this.addSvgIcon('ic-old', 'lock', 'lock.6bb85b72.svg');
    this.addSvgIcon('ic-old', 'log-out', 'log-out.8fe7ed7b.svg');
    this.addSvgIcon('ic-old', 'mail', 'mail.4aafbda5.svg');
    this.addSvgIcon('ic-old', 'measurement', 'measurement.3823ca40.svg');
    this.addSvgIcon('ic-old', 'meter-o', 'meter-o.620b8d23.svg');
    this.addSvgIcon('ic-old', 'minus-square-o', 'minus-square-o.7b8b3477.svg');
    this.addSvgIcon('ic-old', 'more-menu-v', 'more-menu-v.1b1ffc1e.svg');
    this.addSvgIcon('ic-old', 'noodvermogen', 'noodvermogen.d46fed83.svg');
    this.addSvgIcon('ic-old', 'overview', 'overview.67592613.svg');
    this.addSvgIcon('ic-old', 'paper-plane', 'paper-plane.bb309af6.svg');
    this.addSvgIcon('ic-old', 'paragraph-left', 'paragraph-left.f5f47bc3.svg');
    this.addSvgIcon('ic-old', 'party-o', 'party-o.d4cc489f.svg');
    this.addSvgIcon('ic-old', 'party', 'party.b75da452.svg');
    this.addSvgIcon('ic-old', 'paste', 'paste.c93be2d9.svg');
    this.addSvgIcon('ic-old', 'pause-circle-o', 'pause-circle-o.a677795b.svg');
    this.addSvgIcon('ic-old', 'pause', 'pause.8cbceba1.svg');
    this.addSvgIcon('ic-old', 'play-circle-o', 'play-circle-o.571c4bed.svg');
    this.addSvgIcon('ic-old', 'play', 'play.25065f88.svg');
    this.addSvgIcon('ic-old', 'plug', 'plug.d8463632.svg');
    this.addSvgIcon('ic-old', 'plus-circle-o', 'plus-circle-o.914fa488.svg');
    this.addSvgIcon('ic-old', 'plus-square-o', 'plus-square-o.8239a010.svg');
    this.addSvgIcon('ic-old', 'portfolio', 'portfolio.d9f63ba1.svg');
    this.addSvgIcon('ic-old', 'products', 'products.2e17d5eb.svg');
    this.addSvgIcon('ic-old', 'question-circle-o', 'question-circle-o.dd5f2c9c.svg');
    this.addSvgIcon('ic-old', 'recipient-add', 'recipient-add.9aa1a646.svg');
    this.addSvgIcon('ic-old', 'recipient-remove', 'recipient-remove.2eac5ffd.svg');
    this.addSvgIcon('ic-old', 'recipients', 'recipients.4bef6f07.svg');
    this.addSvgIcon('ic-old', 'refresh', 'refresh.accf8e58.svg');
    this.addSvgIcon('ic-old', 'reset', 'reset.6d611353.svg');
    this.addSvgIcon('ic-old', 'save', 'save.20dce2b7.svg');
    this.addSvgIcon('ic-old', 'schedule-s', 'schedule-s.f63eb9d4.svg');
    this.addSvgIcon('ic-old', 'schedule', 'schedule.a13176ec.svg');
    this.addSvgIcon('ic-old', 'search', 'search.c9fed892.svg');
    this.addSvgIcon('ic-old', 'security', 'security.63c137ff.svg');
    this.addSvgIcon('ic-old', 'settings', 'settings.69ac8bb4.svg');
    this.addSvgIcon('ic-old', 'sign-draw', 'sign-draw.f5628bd1.svg');
    this.addSvgIcon('ic-old', 'sign-o', 'sign-o.a09321db.svg');
    this.addSvgIcon('ic-old', 'source-external', 'source-external.d4001cec.svg');
    this.addSvgIcon('ic-old', 'star-o', 'star-o.60abb64f.svg');
    this.addSvgIcon('ic-old', 'star', 'star.ab3bde86.svg');
    this.addSvgIcon('ic-old', 'stop-circle-o', 'stop-circle-o.245823f6.svg');
    this.addSvgIcon('ic-old', 'stop', 'stop.9aeda76e.svg');
    this.addSvgIcon('ic-old', 'toggle-off', 'toggle-off.3abbccde.svg');
    this.addSvgIcon('ic-old', 'toggle-on', 'toggle-on.e7240cf1.svg');
    this.addSvgIcon('ic-old', 'transfer', 'transfer.3818c12f.svg');
    this.addSvgIcon('ic-old', 'trash-o', 'trash-o.a28d6d45.svg');
    this.addSvgIcon('ic-old', 'trash', 'trash.92117f64.svg');
    this.addSvgIcon('ic-old', 'undo', 'undo.eebe2c41.svg');
    this.addSvgIcon('ic-old', 'upload', 'upload.feb7ca94.svg');
    this.addSvgIcon('ic-old', 'user-add', 'user-add.6b6e273b.svg');
    this.addSvgIcon('ic-old', 'user-circle-o', 'user-circle-o.09b915c7.svg');
    this.addSvgIcon('ic-old', 'user', 'user.c69ad2a4.svg');
    this.addSvgIcon('ic-old', 'users-o', 'users-o.87721ae4.svg');
    this.addSvgIcon('ic-old', 'utils', 'utils.55ff60be.svg');
    this.addSvgIcon('ic-old', 'verify', 'verify.200fcad0.svg');
    this.addSvgIcon('ic-old', 'view', 'view.1c9ae1dc.svg');
    this.addSvgIcon('ic-old', 'volume', 'volume.f3e667a4.svg');
  }

  addSvgIcon(namespace: string, iconName: string, fileName: string): void {
    this.iconRegistry.addSvgIconInNamespace(
      namespace,
      iconName,
      this.sanitizer.bypassSecurityTrustResourceUrl(`${ICON_ASSET_BASE_PATH}/${namespace}/${fileName}`)
    );
  }
}
