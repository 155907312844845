import { Inject, Injectable, InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { map, retry, tap } from 'rxjs/operators';
import { getRetryConfig } from '../common/rxjs-utils';

export interface FrontendAppInfo {
  version: string;
  commitId: string;
}

export const FRONTEND_APP_INFO = new InjectionToken<FrontendAppInfo>('App version and commit id');

export class AppInfo {
  env: string;
  backendVersion: string;
  backendCommitId: string;
  frontendVersion: string;
  frontendCommitId: string;
}

class BackendAppInfo {
  environment: string;
  version: string;
  commitId: string;
  shortCommitId: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {
  private appInfoSubject = new ReplaySubject<AppInfo>(1);

  constructor(private httpClient: HttpClient, @Inject(FRONTEND_APP_INFO) private frontendAppInfo: FrontendAppInfo) {
    this.httpClient
      .get<BackendAppInfo>('/api/v1/app-info')
      .pipe(
        retry(getRetryConfig()),
        map((it) => {
          return {
            env: it.environment,
            backendVersion: it.version,
            backendCommitId: it.commitId,
            frontendVersion: this.frontendAppInfo.version,
            frontendCommitId: this.frontendAppInfo.commitId
          };
        })
      )
      .subscribe((result) => {
        this.appInfoSubject.next(result);
      });
  }

  getAppInfo(): Observable<AppInfo> {
    return this.appInfoSubject.asObservable();
  }
}
