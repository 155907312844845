import { OnChanges } from '@angular/core';
import { distinctUntilChanged, map, Observable, ReplaySubject } from 'rxjs';
import { ConstructorType } from '../common/constructor-type.mixin';
import { OnDestroyProvider } from '../common/on-destroy.mixin';

// Inspired by https://github.com/pauldraper/neo-observable-input
export class ObservableInputs {
  private onChangesSubject = new ReplaySubject<void>(1);

  constructor(onDestroyProvider: OnDestroyProvider) {
    onDestroyProvider.onDestroy$.subscribe(() => this.onChangesSubject.complete());
  }

  onChanges(): void {
    this.onChangesSubject.next();
  }

  observe<T>(value: () => T): Observable<T> {
    return this.onChangesSubject.asObservable().pipe(
      map(() => value()),
      distinctUntilChanged()
    );
  }
}

// eslint-disable-next-line
export function ObservableInputsMixin<TBase extends ConstructorType<OnDestroyProvider>>(Base: TBase) {
  return class ObservableInputsMixinBase extends Base implements OnChanges {
    oi = new ObservableInputs(this);

    ngOnChanges(...args: any): void {
      this.oi.onChanges();
    }
  };
}
