import { SubjectProvider } from '../../../d3-graph/d3-graph/common';
import { DestroyableMixin, OnDestroyMixin, OnDestroyProvider } from '../../common/on-destroy.mixin';
import { MixinBase } from '../../common/constructor-type.mixin';
import { AppInjector, appInjectorProvider, getFromInjector } from '../../common/app-injector';
import { FlexWebsocketService } from './websocket.service';
import { BehaviorSubject, Observable, switchMap, combineLatest, EMPTY } from 'rxjs';
import {} from 'rxjs/operators';

/**
 * Manages a subscription for a given entity.
 *
 * It will subscribe to get an initial dataset, which will be refreshed when the connection is lost.
 * It will also subscribe to updates.
 */
export class WebsocketManagedEntity<T> extends DestroyableMixin(OnDestroyMixin(MixinBase)) {
  protected destinationProvider = new SubjectProvider<string>(this);
  protected isActiveProvider = new SubjectProvider<boolean>(this, new BehaviorSubject(false));
  protected resultProvider = new SubjectProvider<T>(this);
  protected websocketService$: Observable<FlexWebsocketService> = getFromInjector(FlexWebsocketService);

  protected initialized = false;

  result$: Observable<T> = this.resultProvider.value$;

  setDestination(destination: string): void {
    this.destinationProvider.next(destination);
  }

  constructor(onDestroyProvider: OnDestroyProvider, constructorDestination?: string) {
    super();

    if (constructorDestination) {
      this.setDestination(constructorDestination);
    }

    this.registerOnDestroyProvider(onDestroyProvider);
  }

  /**
   * Called when activate is called for the first time.
   */
  protected init(): void {
    this.resultProvider.follow(
      combineLatest([this.destinationProvider.value$, this.isActiveProvider.value$, this.websocketService$]).pipe(
        switchMap(([destination, isActive, webSocketService]) => (isActive ? webSocketService.subscribeToResource<T>(destination) : EMPTY))
      )
    );
  }

  destroy(): void {
    this.ngOnDestroy();
  }

  activate(): void {
    if (!this.initialized) {
      this.initialized = true;
      this.init();
    }

    this.isActiveProvider.next(true);
  }

  deactivate(): void {
    this.isActiveProvider.next(false);
  }
}
