<mat-card *ngIf="(messageService.errors$ | async).length" [class.is-dashboard]="isDashboard$ | async">
  <mat-card-header>
    <mat-card-title>Errors</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-list>
      <mat-list-item *ngFor="let message of messageService.errors$ | async">
        {{ message }}
      </mat-list-item>
    </mat-list>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button color="primary" (click)="messageService.clearErrors()">Clear</button>
  </mat-card-actions>
</mat-card>

<mat-card *ngIf="(messageService.infos$ | async).length">
  <mat-card-header>
    <mat-card-title>Infos</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <mat-list>
      <mat-list-item *ngFor="let message of messageService.infos$ | async">
        {{ message }}
      </mat-list-item>
    </mat-list>
  </mat-card-content>
  <mat-card-actions>
    <button mat-stroked-button color="primary" (click)="messageService.clearInfos()">Clear</button>
  </mat-card-actions>
</mat-card>
