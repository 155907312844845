import { Control, ControlSchedule } from '../../../../api-private/control/control';

export class ResetControlCommand {
  public static readonly type = '[Control] Reset control action';

  constructor(public payload?: Partial<Control>) {}
}

export class LoadControlCommand {
  public static readonly type = '[Control] Load control data';

  constructor(public id: string, public showForm: boolean = false) {}
}

export class ControlLoadedEvent {
  public static readonly type = '[Control] Control data loaded';

  constructor(public payload: Control, public showForm: boolean = false) {}
}

export class SaveControlCommand {
  public static readonly type = '[Control] Save control data';

  constructor(public navigateAfterSave: boolean = true) {}
}

export class SaveControlConfigurationCommand {
  public static readonly type = '[Control] Save control configuration data';

  constructor(public navigateAfterSave: boolean = true) {}
}

export class DeleteControlCommand {
  public static readonly type = '[Control] Delete control';

  constructor(public control: Control) {}
}

export class UpdateFlatDeviceContactsCommand {
  public static readonly type = '[Control] Update flat device contacts';

  constructor(public customerId: string) {}
}

export class SendScheduleCommand {
  public static readonly type = '[Control] Send schedule command';

  constructor(public schedule: ControlSchedule) {}
}
