import { RestEntity } from '../rest-client/rest-entity';
import { Capacity } from '../../core/domain/capacity';
import { AvailabilityType } from '../service-agreement/service-agreement';
import { PeriodDomain, PeriodView } from '../../core/domain/period';
import { TimeSlot } from '../../core/date-time/time-slot';
import { AdjustmentDirection } from '../../core/common/adjustment-direction';

export class OperationView extends RestEntity {
  direction: AdjustmentDirection;
  notificationTime: string;
  period: PeriodView;
  requestedPower: Capacity;

  reference?: number;
  active?: boolean;
  manual?: boolean;
  ended?: boolean;
  cancelled?: boolean;

  initialPoolSentSuccessfully?: boolean;

  operationPool?: OperationPoolGridPointView[];
}

export type Operation = Required<Omit<Omit<OperationView, 'period'>, 'operationPool'>> & {
  period: PeriodDomain;
  operationPool?: OperationPoolGridPoint[];
};

export class OperationPoolGridPointView {
  customerName: string;
  gridPointEan: string;
  gridPointDescription: string;
  period: PeriodView;
  power: Capacity;
  measurementDataStatus: string;

  availabilityType: AvailabilityType;
  measurementCompanyName: string;
}

export class OperationPoolGridPoint {
  customerName: string;
  gridPointEan: string;
  gridPointDescription: string;
  period: PeriodDomain;
  power: Capacity;
  measurementDataStatus: string;

  availabilityType: AvailabilityType;
  measurementCompanyName: string;
}

export class R3Activation {
  operationReference: number;
  period: TimeSlot;
  direction: AdjustmentDirection;
  availabilityType: AvailabilityType;
  target: Capacity | null;
  operationId?: string;
  measurementsAvailable?: boolean;
  cancelled?: boolean;
}

export interface OperationContactPerson {
  /**
   * Contacts to call in non-standard cases
   */
  name: '';
  phone: '';
}
