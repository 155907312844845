<div (click)="!_isDisabled && clickDebounceProvider.next($event)" [ngSwitch]="type">
  <button
    *ngSwitchDefault
    [attr.cdkFocusInitial]="shouldGetInitialFocus || null"
    mat-flat-button
    [color]="_color"
    [disabled]="_isDisabled"
    [matTooltip]="_tooltip"
    [matTooltipDisabled]="!_tooltip"
    [class.spinner-visible]="pending || recentlyClicked"
  >
    <ng-container *ngTemplateOutlet="buttonContents"></ng-container>
  </button>

  <button
    *ngSwitchCase="'stroked'"
    [attr.cdkFocusInitial]="shouldGetInitialFocus || null"
    mat-stroked-button
    [color]="_color"
    [disabled]="_isDisabled"
    [matTooltip]="_tooltip"
    [matTooltipDisabled]="!_tooltip"
    [class.spinner-visible]="pending || recentlyClicked"
  >
    <ng-container *ngTemplateOutlet="buttonContents"></ng-container>
  </button>
</div>

<ng-template #buttonContents>
  <span class="button-contents">
    <span #ngContentContainer><ng-content></ng-content></span>
    <ng-container *ngIf="ngContentContainer.childNodes.length === 0">{{ label }}</ng-container>
  </span>
  <div class="spinner">
    <span class="pending-label">{{ pendingLabel }}</span>
    <mat-spinner diameter="20"></mat-spinner>
  </div>
</ng-template>
