import { ScaleTime, Selection } from 'd3';
import { BaseType } from 'd3-selection';
import { combineLatest, Observable } from 'rxjs';
import { ScaleBand, ScaleContinuousNumeric } from 'd3-scale';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { DestroyableMixin, OnDestroyMixin } from '../../../../core/common/on-destroy.mixin';
import { MixinBase } from '../../../../core/common/constructor-type.mixin';
import { DynamicDataHelper, SubjectProvider } from '../../common';
import { addHours, differenceInHours, getHours, isEqual as dateIsEqual, max, min, startOfHour } from 'date-fns';
import { D3GraphSubmodule } from '../d3-graph-submodule';

interface VerticalBarModel {
  heightPx: number;
  widthPx: number;
  offsetX: number;
  offsetY: number;
}

type SupportedYScale = ScaleContinuousNumeric<number, number> | ScaleTime<number, number> | ScaleBand<any>;

/**
 * Build an SVG representing an odd:even background with dark and light bands.
 *
 * Requires:
 *  - a set of ticks (for X axis only for now)
 *  - a scale to calculate X positions with
 *  - a scale to calculate Y positions with
 */
export class D3GraphTimeSlotBackground extends DestroyableMixin(OnDestroyMixin(MixinBase)) implements D3GraphSubmodule {
  private xScaleProvider = new SubjectProvider<ScaleTime<number, number>>(this);
  private yScaleProvider = new SubjectProvider<SupportedYScale>(this);

  private barWithDataHelper: BarWithDataHelper;

  private verticalBarData$: Observable<VerticalBarModel[]> = combineLatest([this.xScaleProvider.value$, this.yScaleProvider.value$]).pipe(
    map(([xScale, yScale]) => {
      const height = Math.max(...yScale.range()) - Math.min(...yScale.range());
      const chunks = determineHourBackgroundChunks(xScale);

      return chunks.map(([xStart, xEnd]) => {
        const width = Math.max(0, xScale(xEnd) - xScale(xStart));

        return {
          heightPx: height,
          widthPx: width,
          offsetX: xScale(xStart),
          offsetY: yScale.range()[0]
        };
      });
    }),
    distinctUntilChanged(isEqual)
  );

  setXScale(scale: ScaleTime<number, number>): void {
    this.xScaleProvider.next(scale);
  }

  setXScale$(scale$: Observable<ScaleTime<number, number>>): void {
    this.xScaleProvider.follow(scale$);
  }

  setYScale(scale: SupportedYScale): void {
    this.yScaleProvider.next(scale);
  }

  setYScale$(scale$: Observable<SupportedYScale>): void {
    this.yScaleProvider.follow(scale$);
  }

  /**
   * Create the graph in the parent selection, e.g. a <svg> node.
   * It will update automatically based on the other inputs of this class.
   */
  attach(mainSvg: Selection<BaseType, any, any, any>): () => any {
    const node = mainSvg.append('g').attr('class', 'background');

    this.barWithDataHelper = new BarWithDataHelper(node);

    const subscription = this.verticalBarData$.subscribe((data) => {
      this.barWithDataHelper.setData(data);
    });

    return () => subscription.unsubscribe();
  }

  destroy(): void {
    this.ngOnDestroy();
  }
}

class BarWithDataHelper extends DynamicDataHelper<VerticalBarModel> {
  nodeName = 'rect';
  class = 'vertical-bar';

  protected setStatic(selectAllWithData: Selection<BaseType, VerticalBarModel, BaseType, any>): void {
    selectAllWithData.attr('y', 0).attr('fill', '#f6f8fa');
  }

  protected setDynamic(selectAllWithData: Selection<BaseType, VerticalBarModel, BaseType, any>): void {
    selectAllWithData
      .attr('x', (datum: VerticalBarModel) => datum.offsetX)
      .attr('y', (datum: VerticalBarModel) => datum.offsetY)
      .attr('width', (datum: VerticalBarModel) => datum.widthPx)
      .attr('height', (datum: VerticalBarModel) => datum.heightPx);
  }
}

/**
 * Determine hour transitions for the given xScale.
 * Even hours (e.g. 0:00 - 1:00) are generated, odd hours are not (e.g. 1:00 - 2:00;
 */
export function determineHourBackgroundChunks(xScale: ScaleTime<number, number>): [Date, Date][] {
  return [...evenHoursBlockGenerator(xScale)];
}

export function hourIsEven(date: Date): boolean {
  return !(getHours(date) % 2);
}

export function* evenHoursBlockGenerator(scale: ScaleTime<number, number>): Generator<[Date, Date]> {
  const domain = scale.domain();
  const whileEnd = max([domain[0], startOfHour(domain[1])]); // Stop on end of previous hour
  let cursor: Date = domain[0];

  // Check leading edge
  if (!dateIsEqual(cursor, startOfHour(addHours(cursor, 1)))) {
    const newEnd = min([startOfHour(addHours(cursor, 1)), domain[1]]);
    // Starts on partial hour
    // Check if hour should be drawn
    if (hourIsEven(cursor)) {
      // isEven, create block
      yield [cursor, newEnd];
    }

    // isOdd, skip current block
    cursor = newEnd;
  }

  if (differenceInHours(whileEnd, cursor) >= 1) {
    // Main loop
    while (cursor < whileEnd) {
      const newEnd = addHours(cursor, 1);
      if (hourIsEven(cursor)) {
        yield [cursor, newEnd];
      }
      cursor = newEnd;
    }
  }

  // Check trailing edge
  if (!dateIsEqual(whileEnd, domain[1]) && !dateIsEqual(domain[0], whileEnd)) {
    // Ends with partial hour
    if (hourIsEven(whileEnd)) {
      // isEven, create block
      yield [whileEnd, domain[1]];
    }
  }

  return;
}
