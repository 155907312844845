<input
  matInput
  type="text"
  [disabled]="disabled"
  [(ngModel)]="inputValue"
  (ngModelChange)="_handleInput()"
  [placeholder]="placeholder"
  [matAutocomplete]="matAutocomplete"
  (click)="_handleClickInInput()"
/>
<mat-autocomplete
  autoActiveFirstOption
  #matAutocomplete
  [displayWith]="dataSource.displayIdWith.bind(dataSource)"
  (optionSelected)="onOptionSelected($event)"
>
  <mat-option *ngFor="let currentData of filteredData$ | async" [value]="dataSource.identifyWith(currentData)">
    {{ dataSource.displayEntityWith(currentData) }}
  </mat-option>
</mat-autocomplete>
