export enum VolumeUnit {
  kWh = 'kWh',
  MWh = 'MWh'
}

export class Volume {
  value: number;
  unit: VolumeUnit | 'kWh' | 'MWh';

  constructor(value: number, unit: VolumeUnit | 'kWh' | 'MWh') {
    this.value = value;
    this.unit = unit;
  }
}
