import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { DateAndTime } from './date-and-time';
import { LocalDate } from './local-date';
import { Serialized } from '../types/serialized';

// TODO remove
export class Period {
  startDate: Date = new Date();
  endDate: Date = new Date();

  constructor(startDate: Date, endDate: Date) {
    this.startDate = startDate;
    this.endDate = endDate;
  }
}

export interface ZonedPeriod {
  startDate: string;
  endDate: string;
  timeZone: string;
}

// TODO update
export const validPeriodValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const startDate: Date = control.get('startDate').value;
  const endDate: Date = control.get('endDate').value;
  return startDate > endDate ? { periodWrong: true } : null;
};

export type PeriodDomain = Serialized<PeriodView>;

export class PeriodView {
  startDateTime: DateAndTime;
  toDateTime: DateAndTime;

  static deserialize(periodResponse: PeriodDomain): PeriodView {
    if (!periodResponse) {
      return null;
    }

    return {
      startDateTime: DateAndTime.deserialize(periodResponse.startDateTime),
      toDateTime: DateAndTime.deserialize(periodResponse.toDateTime)
    };
  }

  static serialize(periodDomain: PeriodView): PeriodDomain {
    return {
      startDateTime: DateAndTime.serialize(periodDomain.startDateTime),
      toDateTime: DateAndTime.serialize(periodDomain.toDateTime)
    };
  }
}

export type DatePeriod = Serialized<DatePeriodView>;

export class DatePeriodView {
  startDate: LocalDate;
  endDate: LocalDate;

  static deserialize(periodResponse: DatePeriod): DatePeriodView {
    return {
      startDate: LocalDate.deserialize(periodResponse.startDate),
      endDate: LocalDate.deserialize(periodResponse.endDate)
    };
  }

  static serialize(periodDomain: DatePeriodView): DatePeriod {
    return {
      startDate: LocalDate.serialize(periodDomain.startDate),
      endDate: LocalDate.serialize(periodDomain.endDate)
    };
  }
}
