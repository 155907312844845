import * as d3 from 'd3';
import { BaseType, Selection } from 'd3';
import { DynamicDataHelper } from '../common';

export type DataPointCoordinate = { x: number; y: number };
export type DataPointDatum = {
  coordinates: DataPointCoordinate[];
  lineColor: string;
  strokeDasharray?: number[];
};

export class D3GraphLineHelper extends DynamicDataHelper<DataPointDatum> {
  protected nodeName = 'path';
  protected class = 'line';

  protected setDynamic(selectAllWithData: Selection<BaseType, DataPointDatum, BaseType, any>): void {
    const lineGenerator = d3
      .line<DataPointCoordinate>()
      .defined((d) => {
        return d != null;
      })
      .y((c) => c.y)
      .x((c) => c.x);

    selectAllWithData
      .attr('d', (datum) => lineGenerator(datum.coordinates))
      .attr('stroke', (datum) => datum.lineColor)
      .attr('stroke-dasharray', (datum) => (datum.strokeDasharray ? datum.strokeDasharray.join(' ') : undefined));
  }

  protected setStatic(selectAllWithData: Selection<BaseType, DataPointDatum, BaseType, any>): void {
    selectAllWithData.attr('fill', 'transparent');
  }
}
