export enum MomentDisplayFormatters {
  YEAR = 'YYYY',
  MONTH = 'MM-YYYY',
  MONTH_NICE = 'MMMM YYYY',
  DAY = 'DD-MM-YYYY',
  DAY_NICE = 'DD MMMM YYYY'
}

export enum MomentISOFormatters {
  YEAR = 'YYYY',
  MONTH = 'YYYY-MM',
  DAY = 'YYYY-MM-DD'
}
