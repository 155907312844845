import { EnterElement, Selection } from 'd3';
import { BaseType } from 'd3-selection';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SubjectProvider } from '../common';
import { DestroyableMixin, OnDestroyMixin, OnDestroyProvider } from '../../../core/common/on-destroy.mixin';
import { MixinBase } from '../../../core/common/constructor-type.mixin';
import { distinctUntilChanged, filter, first, map, publish, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { D3GraphMultiSeriesDataHelper, MultiSeriesDatum } from '../data-helpers/d3-graph-multi-series-data.helper';
import { differenceBy, intersectionBy, orderBy } from 'lodash-es';
import { D3GraphSeriesSubModule } from '../submodules/d3-graph-submodule';

/**
 * Attach multiple subModules based on a list of data, one submodule for each item in the input data list.
 * Datum is expected to be prepared for multi series use, use TODO converter for convenience if required
 */
export class MultiSeriesAttachHelper<
  Datum extends MultiSeriesDatum<any, any>,
  SubModule extends D3GraphSeriesSubModule<any, any, any, any>,
  SupportedYScale,
  SubModuleDatum
> extends OnDestroyMixin(DestroyableMixin(MixinBase)) {
  protected mainSvg: Selection<BaseType, any, any, any>;
  protected containerNode: Selection<EnterElement, any, BaseType, any>;
  protected dynamicDataHelper: D3GraphMultiSeriesDataHelper<Datum, SupportedYScale, SubModule, SubModuleDatum>;
  protected dynamicDataHelperDataProvider = new SubjectProvider<Datum[]>(this, new BehaviorSubject([]));

  // Should ONLY be read as input for dynamicDataHelperDataProvider, this class should only set new values, never read them.
  // This is because instance managed here will not get enriched with MultiSeriesDatum properties.
  protected dataProvider = new SubjectProvider<Datum[]>(this, new BehaviorSubject([]));

  hasData$ = this.dynamicDataHelperDataProvider.value$.pipe(map((data) => data.some((current) => current?.lineData?.length > 0)));
  canUseData$ = this.dynamicDataHelperDataProvider.value$.pipe(
    switchMap((data) => {
      const observables: Observable<boolean>[] = data
        .map((current) => current.subModuleInstance?.canUseData$)
        .filter((canUseData$) => !!canUseData$);

      return combineLatest(observables);
    }),
    map((results) => results.every((a) => a)),
    startWith(false)
  );

  shownIds$ = this.dynamicDataHelperDataProvider.value$.pipe(map((data) => data.map((current) => this.identifyWith(current))));

  usedYScaleProviders$ = this.dynamicDataHelperDataProvider.value$.pipe(
    switchMap((data) => {
      const observables = data.map((current) => current.show && current.subModuleInstance?.usedYScaleProvider$).filter((a) => !!a);

      return combineLatest(observables).pipe(startWith([]));
    })
  );

  usedYScales$ = this.usedYScaleProviders$.pipe(
    switchMap((results) => combineLatest(results.map((result) => result.scale$))),
    startWith([])
  );

  constructor(onDestroyProvider: OnDestroyProvider) {
    super();
    this.registerOnDestroyProvider(onDestroyProvider);

    // Bind dynamicDataHelper to dynamicDataHelperDataProvider
    this.dynamicDataHelperDataProvider.value$.subscribe((value) => {
      // Note that this will sometimes be called when dynamicDataHelper is not yet set, due to dynamicDataHelperDataProvider being initialized with a behaviorsubject
      this.dynamicDataHelper?.setData(value);
    });
  }

  /**
   * Return the datum belonging to a given id as an observable
   */
  getDatumForId$(id: string): Observable<Datum> {
    return this.dynamicDataHelperDataProvider.value$.pipe(map((data) => data.find((current) => this.identifyWith(current) === id)));
  }

  /**
   * Return the current datum belonging to a given id
   */
  getDatumForId(id: string): Datum {
    return this.dynamicDataHelperDataProvider.value?.find((data) => this.identifyWith(data) === id);
  }

  /**
   * Get the shown attribute for the given id
   */
  isShown$(id: string): Observable<boolean> {
    return this.getDatumForId$(id).pipe(map((datum) => !!datum?.show));
  }

  /**
   * Toggle the show attribute of the datum with the given id, if present
   */
  toggle(id?: string): void {
    const foundDatum = this.getDatumForId(id);

    if (!foundDatum) {
      return;
    }

    this.updateData(
      this.dynamicDataHelperDataProvider.value.map((value) => {
        if (this.identifyWith(value) === id) {
          return {
            ...value,
            show: !value.show
          };
        }
        return value;
      })
    );
  }

  /**
   * Update datums using a static value
   */
  updateData(newData: Datum[]): void {
    this.dataProvider.next(newData);
  }

  /**
   * Update datums using an observable to be followed
   */
  followData(newData$: Observable<Datum[]>): void {
    this.dataProvider.follow(newData$);
  }

  attach(mainSvg: Selection<BaseType, any, any, any>): void {
    this.mainSvg = mainSvg;

    // Create dynamic data helper instance, since it requires mainSvg to be known
    this.dynamicDataHelper = this.getDynamicDataHelper();

    // Link dataProvider output to dynamicDataHelper
    this.dynamicDataHelperDataProvider.follow(
      this.dataProvider.value$.pipe(
        map((newData) => this.enrichNewData(this.dynamicDataHelper.getData(), newData, [['gridPointDescription'], ['asc']]))
      )
    );
  }

  attachLabel(labelSvg: Selection<BaseType, any, any, any>, id: string): void {
    // Wait for subModuleInstance on the datum, and then attach the label using hte subModule instance
    this.getDatumForId$(id)
      .pipe(
        filter((foundDatum) => !!foundDatum?.subModuleInstance),
        first()
      )
      .subscribe((foundDatum) => {
        foundDatum.subModuleInstance?.attachLabel(labelSvg);
      });
  }

  /**
   * Return a string which uniquely identifies a datum.
   * Should be overridden if the id field is not present
   */
  protected identifyWith(datum: Datum): string {
    if (!(datum as any)?.id) {
      throw new Error('identifyWith was called with a datum that is falsy, or that does not contain the id field');
    }

    return (datum as any)?.id;
  }

  protected getDynamicDataHelper(): D3GraphMultiSeriesDataHelper<Datum, SupportedYScale, SubModule, SubModuleDatum> {
    throw new Error('getDynamicDataHelper not implemented');
  }

  /**
   * Create or return the main container which will contain the child containers
   */
  protected getOrCreateContainerNode(): Selection<EnterElement, any, BaseType, any> {
    if (!this.containerNode) {
      this.containerNode = this.mainSvg.append('g');
    }
    return this.containerNode;
  }

  /**
   * Return newData, but retain any added properties that have been set in oldData for any existing datum with the same identifier,
   * as specified by identifyWith.
   *
   * This method is used to store/retain e.g. the submodule instance on the Datum
   */
  protected enrichNewData(oldData: Datum[], newData: Datum[], orderByArgs?: [string[], ('desc' | 'asc')[]]): Datum[] {
    // Determine intersection between old and new data,
    const overlapping: Datum[] = intersectionBy(oldData, newData, (v) => this.identifyWith(v)).map((currentOldData) => {
      const currentNewData = newData.find((current) => this.identifyWith(current) === this.identifyWith(currentOldData));

      return {
        ...currentOldData,
        ...currentNewData
      };
    });

    const toAdd: Datum[] = differenceBy(newData, overlapping, (v) => this.identifyWith(v));

    const unsortedResult = [...overlapping, ...toAdd];
    return orderByArgs ? orderBy(unsortedResult, ...orderByArgs) : unsortedResult;
  }
}
