import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { differenceInMilliseconds } from 'date-fns';
import { TokenService } from './token.service';
import { AzureMetric, CounterProvider } from '../../app-insights/metrics-provider';
import { SINGLETON } from '../../core/common/on-destroy.mixin';

/**
 * Ensure that the idToken from tokenServe remains in sync with whatever oAuthService is doing
 */
@Injectable({
  providedIn: 'root'
})
export class TokenWatchdogService {
  static WATCHDOG_INTERVAL_MS = 5000;
  static ALLOWED_SKEW_MS = 5000;

  private watchdogTriggeredCounter = new CounterProvider(SINGLETON, AzureMetric.TOKEN_WATCHDOG_FIRED);

  lastDate: Date;

  constructor(private oauthService: OAuthService, private tokenService: TokenService) {
    setInterval(() => {
      this.checkContinuousTime();
    }, TokenWatchdogService.WATCHDOG_INTERVAL_MS);
  }

  /**
   * Check if the time between lastDate and now is in line with the specified watchdog interval +- skew
   * @private
   */
  private checkContinuousTime(): void {
    const now = new Date();

    if (this.lastDate) {
      const diff = Math.abs(differenceInMilliseconds(now, this.lastDate));
      if (diff > TokenWatchdogService.WATCHDOG_INTERVAL_MS + TokenWatchdogService.ALLOWED_SKEW_MS) {
        // Out of sync, update tokenService
        this.watchdogTriggeredCounter.increment();
        this.tokenService.updateToken(this.oauthService.getIdToken());
        this.tokenService.refreshTokenIfNotAvailable();
      }
    }

    this.lastDate = now;
  }
}
