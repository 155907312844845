import { State } from '@ngxs/store';
import { ControlState } from './states/control/control.state';
import { ControlsState } from './states/controls/controls.state';
import { Injectable } from '@angular/core';

export const ControlStates = [ControlsState, ControlState];

@State({
  name: 'controlStateModule',
  children: ControlStates
})
@Injectable({
  providedIn: 'root'
})
export class ControlStateModule {}
