import Fuse, { FuseSearchOptions } from 'fuse.js';
import { orderBy } from 'lodash-es';

export class FrontendPaginationHelper<T> {
  private fuse: Fuse;
  private data: T[] = [];
  private lastPagedData: T[] = [];
  private lastFilteredData: T[] = [];

  constructor(
    // Property can be used like sortingDataAccessor on matDataSource to define the property lookup for columns
    private sortingDataAccessor?: (data: T, sortHeaderId: string) => string | number,
    private searchOptions?: FuseSearchOptions
  ) {}

  /**
   * Length property to update paginator with. Represents the total count of elements.
   */
  get length(): number {
    return this.data.length;
  }

  get originalData(): T[] {
    return this.data;
  }

  get pagedData(): T[] {
    return this.lastPagedData;
  }

  get filteredData(): T[] {
    return this.lastFilteredData;
  }

  updateData(data: T[]): void {
    this.data = data || [];
    this.fuse = new Fuse(this.data, this.searchOptions);
  }

  /**
   * Applies the inputs to the known data and updates the page and filtered result
   * Returns the page result.
   */
  handleLoadData(
    pageIndex?: number | undefined,
    pageSize?: number | undefined,
    searchTerm?: string | undefined,
    sort?: string | undefined
  ): T[] {
    let newData = this.data;
    let newFilteredData = this.data;

    // If no data is available, return empty array
    if (this.fuse) {
      let results: T[] = searchTerm ? this.fuse.search(searchTerm).map((value) => value.item) : this.data;
      newFilteredData = results;

      if (sort) {
        // Sort is currently provided as either empty string (no sorting) or `${property} ${direction}`
        // So we split it up if available, and use lodash for sorting
        const [sortProperty, sortDirection] = sort.split(' ');

        if (this.sortingDataAccessor) {
          results = orderBy(results, [(item) => this.sortingDataAccessor(item, sortProperty)], [sortDirection as any]);
        } else {
          results = orderBy(results, [sortProperty], [sortDirection as any]);
        }
      }

      if (pageIndex !== undefined && pageSize !== undefined) {
        results = results.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
      }

      // @ts-ignore
      newData = results.map((result) => result?.item ?? result);
    }

    this.lastPagedData = newData;
    this.lastFilteredData = newFilteredData;

    return newData;
  }
}
