import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorityService } from '../authorization/authority.service';
import { AuthService } from '../authentication/auth.service';
import { ALLOWED_LANDING_PAGES } from '../../core/common/allowed-landing.token';
import { TokenService } from '../authentication/token.service';
import { LoginRedirectService } from './login-redirect.service';

@Injectable({
  providedIn: 'root'
})
export class LandingPageService {
  constructor(
    private authService: AuthService,
    private tokenService: TokenService,
    private authorityService: AuthorityService,
    private router: Router,
    private loginRedirectService: LoginRedirectService,
    @Inject(ALLOWED_LANDING_PAGES) private allowedLandingPages: string[]
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (state.url.indexOf('error=') > -1) {
      const params = new URLSearchParams(state.url);

      if (params.get('error_description')?.indexOf('AADB2C90118') > -1) {
        // User requested a password reset
        this.authService.resetPasswordFlow();
      }
      if (params.get('error_description')?.indexOf('AADB2C90091') > -1) {
        // User cancelled reset password flow
        this.authService.resetImplicitFlow();
      }
    }
    return this.tokenService.validIdToken$.pipe(
      map(() => {
        // Check redirect route
        const redirectUri = this.loginRedirectService.getRedirectUri();

        if (redirectUri && !redirectUri.includes('callback')) {
          this.loginRedirectService.restoreState(redirectUri).catch(() => {
            // If this fails, navigate to landing page
            this.navigateToLandingPage(route);
          });
        } else {
          this.navigateToLandingPage(route);
        }
        return true;
      })
    );
  }

  navigateToLandingPage(route?: ActivatedRouteSnapshot): void {
    this.authorityService.landingPage$.subscribe((landingPage) => {
      const allowedLandingPages = this.allowedLandingPages || [];
      if (allowedLandingPages.includes(landingPage)) {
        this.router.navigate([landingPage]);
      } else if (!route || route.url.length !== 0) {
        // Should not redirect to '/' if the currentRoute is []
        this.router.navigate(['/']);
      }
    });
  }
}
