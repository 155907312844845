import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { RouterStateSnapshot } from '@angular/router';
import { RouterNavigation } from '@ngxs/router-plugin';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GridPoint } from '../../../../api-private/grid-point/grid-point';
import { GridPointService } from '../../../../api-private/grid-point/grid-point.service';
import { findParameterValue } from '../../../../core/common/find-parameter-value';
import { MessageService } from '../../../../core/messages/message.service';
import { EntityState, EntityStateModel, FetchEntity, SaveEntity } from '../../../common/entity.state';
import { FormStatus, NgxsFormState } from '../../../common/ngxs-form-state';
import { RouterInitializer } from '../../../common/router-initializer';

import { LoadGridPointsCommand } from '../grid-points/grid-points.actions';
import {
  DeleteGridPointCommand,
  GridPointLoadedEvent,
  LoadGridPointCommand,
  ResetGridPointCommand,
  RetrieveContractsForGridPointCommand,
  SaveGridPointCommand
} from './grid-point.actions';
import { PulseMeasurementConfigurationState } from './pulse-measurement-configuration/pulse-measurement-configuration.state';

export class GridPointStateModel extends EntityStateModel<GridPoint> implements NgxsFormState<GridPoint> {
  model: GridPoint = {
    id: null,
    measurementProvider: null,
    balanceResponsiblePartyId: null,
    balanceResponsiblePartyName: '',
    customerId: '',
    customerName: '',
    customerLegalName: '',
    description: '',
    consumptionMeasurementChannels: [],
    productionMeasurementChannels: [],
    ean: '',
    measurementMethod: '',
    measurementMethodAutomatic: false,
    fiveMinuteDataAvailable: false,
    preQualified: false,
    gopacsPreQualified: false,
    measurementCompanyId: '',
    measurementCompanyName: ''
  };
  dirty: boolean | null;
  status: FormStatus | null;
}

@State<GridPointStateModel>({
  name: 'gridPointState',
  defaults: new GridPointStateModel(),
  children: [PulseMeasurementConfigurationState]
})
@Injectable({
  providedIn: 'root'
})
export class GridPointState extends EntityState<GridPoint> implements RouterInitializer<GridPointStateModel> {
  constructor(
    private gridPointService: GridPointService,
    private location: Location,
    private messageService: MessageService,
    protected store: Store
  ) {
    super(store);
  }

  @Selector()
  public static getGridPoint(state: GridPointStateModel): GridPoint {
    return {
      ...state.model
    };
  }

  @Selector()
  public static isBusy(state: GridPointStateModel): boolean {
    return state.isBusyReceiving || state.isBusySending;
  }

  @Selector()
  public static isInitialized(state: GridPointStateModel): boolean {
    return state.isInitialized;
  }

  @Selector()
  public static isBusySending(state: GridPointStateModel): boolean {
    return state.isBusySending;
  }

  @Selector()
  public static error(state: GridPointStateModel): string | null {
    return state.sendError;
  }

  @Selector()
  public static isFormDirty(state: GridPointStateModel): boolean {
    return state.dirty;
  }

  @Selector()
  public static isFormValid(state: GridPointStateModel): boolean {
    return state.status === FormStatus.VALID;
  }

  @Selector()
  public static customers(state: GridPointStateModel): void {}

  @Action(RouterNavigation)
  routerInit(
    { getState, dispatch, setState }: StateContext<GridPointStateModel>,
    { routerState }: RouterNavigation<RouterStateSnapshot>
  ): void {
    const gridPointId = findParameterValue(routerState, 'gridPointId');
    const customerId = findParameterValue(routerState, 'customerId');
    const state = getState();

    if (gridPointId && gridPointId !== 'new') {
      dispatch(new LoadGridPointCommand(gridPointId));
    } else if (state.model.id || gridPointId === 'new') {
      dispatch(new ResetGridPointCommand({ customerId }));
    } else if (state.model.customerId !== customerId) {
      setState({ ...state, model: { ...state.model, customerId } });
    }
  }

  @Action(ResetGridPointCommand)
  resetGridPoint({ setState }: StateContext<GridPointStateModel>, { payload }: ResetGridPointCommand): void {
    const newState = new GridPointStateModel();
    setState({ ...newState, model: { ...newState.model, ...payload } });
  }

  @FetchEntity()
  @Action(LoadGridPointCommand)
  initGridPoint({ dispatch }: StateContext<GridPointStateModel>, { id, showForm }: LoadGridPointCommand): Observable<GridPoint> {
    if (id === 'new') {
      return;
    }
    return this.gridPointService.getById(id).pipe(tap((gridPoint) => dispatch(new GridPointLoadedEvent(gridPoint, showForm))));
  }

  @SaveEntity()
  @Action(SaveGridPointCommand)
  saveGridPoint({ getState, dispatch }: StateContext<GridPointStateModel>, action: SaveGridPointCommand): Observable<GridPoint> {
    let gridPoint = getState().model as GridPoint;
    gridPoint = { ...gridPoint };
    const saveObservable =
      gridPoint.id && gridPoint.id !== 'new' ? this.gridPointService.update(gridPoint) : this.gridPointService.add(gridPoint);
    return saveObservable.pipe(
      tap(
        (result) => (action.navigateAfterSave ? this.location.back() : dispatch(new GridPointLoadedEvent(result))),
        (err) => this.messageService.error(err.message)
      )
    );
  }

  @Action(GridPointLoadedEvent)
  setGridPoint({ setState, getState }: StateContext<GridPointStateModel>, { payload }: GridPointLoadedEvent): void {
    const state = getState();
    setState({
      ...state,
      model: {
        ...state.model,
        ...payload
      }
    });
  }

  @Action(DeleteGridPointCommand)
  deleteGridPoint({ dispatch }: StateContext<GridPointStateModel>, { gridPoint }: DeleteGridPointCommand): Observable<any> {
    return this.gridPointService.delete(gridPoint).pipe(tap(() => dispatch(new LoadGridPointsCommand())));
  }

  @Action(RetrieveContractsForGridPointCommand)
  retrieveContracts(
    { getState, dispatch }: StateContext<GridPointStateModel>,
    action: RetrieveContractsForGridPointCommand
  ): Observable<any> {
    let gridPoint = getState().model as GridPoint;
    gridPoint = { ...gridPoint };
    return this.gridPointService.retrieveContracts(gridPoint).pipe(tap(() => dispatch(new LoadGridPointsCommand())));
  }
}
