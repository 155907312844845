import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'ph-flex-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.scss']
})
export class TableFilterComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  isAutocomplete: boolean = false;

  @Input()
  placeholder: string;

  @Input()
  label: string = 'Search';

  // Currently only works when isAutocomplete is false.
  @Input()
  autofocus: boolean = false;

  private readonly ngUnsubscribe = new Subject<void>();

  @Output()
  filterChanged = new EventEmitter<string | undefined>();

  filterTerm: string = '';

  @ViewChild(MatInput, { static: false }) private matInput: MatInput;

  constructor(private fb: UntypedFormBuilder) {}

  filterFormGroup: UntypedFormGroup = this.fb.group({
    textSearch: ['']
  });

  ngOnInit(): void {
    this.filterFormGroup
      .get('textSearch')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.filterTerm = value;
        this.filterChanged.emit(value);
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit(): void {
    if (this.autofocus) {
      this.matInput?.focus();
    }
  }
}
