import { Directive, ElementRef, Inject, Input, OnChanges, Optional, Renderer2, SimpleChanges } from '@angular/core';
import { AuthorityService } from './authority.service';
import { isArray } from 'lodash-es';
import { REQUIRE_AUTHENTICATION } from '../authentication/auth.service';

@Directive({
  selector: '[phFlexAuthShow]'
})
export class AuthShowDirective implements OnChanges {
  @Input()
  phFlexAuthShow: ReadonlyArray<string> = [];

  private currentAuthorities: ReadonlyArray<string> = [];

  constructor(
    private elRef: ElementRef,
    private renderer2: Renderer2,
    private authorityService: AuthorityService,
    @Optional() @Inject(REQUIRE_AUTHENTICATION) private requireAuthentication: boolean = true
  ) {
    this.updateElementStyle();

    this.authorityService.authorities$.subscribe((authorities) => {
      this.currentAuthorities = authorities;
      this.updateElementStyle();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.phFlexAuthShow) {
      this.updateElementStyle();
    }
  }

  private updateElementStyle(): void {
    const requiredAuthorities = isArray(this.phFlexAuthShow) ? this.phFlexAuthShow : [this.phFlexAuthShow];
    let isShown =
      requiredAuthorities.length === 0 ||
      requiredAuthorities.some((requiredAuthority) => this.currentAuthorities.includes(requiredAuthority));

    if (!this.requireAuthentication) {
      isShown = true;
    }

    if (isShown) {
      this.renderer2.removeStyle(this.elRef.nativeElement, 'display');
    } else {
      this.renderer2.setStyle(this.elRef.nativeElement, 'display', 'none');
    }
  }
}
