import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { v4 as uuid } from 'uuid';

export const conversationHeader = 'X-Conversation-Id';

@Injectable()
export class ConversationInterceptorService implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.headers.has(conversationHeader) && !req.url.includes('.well-known')) {
      // Exclude .well-known calls (since the OIDC provider does not allow the x-conversation-id header)
      return next.handle(
        req.clone({
          headers: req.headers.append(conversationHeader, uuid())
        })
      );
    } else {
      return next.handle(req);
    }
  }
}
