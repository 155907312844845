import { BehaviorSubject, ObjectUnsubscribedError, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { Selection } from 'd3';
import { BaseType, EnterElement } from 'd3-selection';
import { ConstructorType, MixinBase } from '../../core/common/constructor-type.mixin';
import { DestroyableMixin, OnDestroyMixin, OnDestroyProvider } from '../../core/common/on-destroy.mixin';
import { ApplicationRef, ComponentFactoryResolver, EventEmitter } from '@angular/core';

/**
 * Margins object based on the Margin Conventions: https://observablehq.com/@d3/margin-convention
 */
export class Margins {
  left: number = 8;
  top: number = 0;
  right: number = 8;
  bottom: number = 0;
}

/**
 * Provides the last static value of an Observable.
 */
export class ObservableProvider<T> extends DestroyableMixin(MixinBase) {
  /**
   * Get the internal ReplaySubject as observable
   */
  value$: Observable<T>;

  /**
   * Last emitted value. Will be undefined by default.
   * If you require a default value, just use BehaviorSubject.
   */
  protected lastValue: T;

  protected lastFollowSubscription: Subscription;
  protected valueSubscription: Subscription;

  constructor(onDestroyProvider: OnDestroyProvider, value$: Observable<T>) {
    super();
    this.registerOnDestroyProvider(onDestroyProvider);
    this.value$ = value$;

    this.valueSubscription = this.value$.subscribe((value) => {
      this.lastValue = value;
    });
  }

  /**
   * Get the current value or throw an error if the subject has an error or is closed as getter.
   */
  get value(): T {
    return this.getValue();
  }

  destroy(): void {
    this.lastFollowSubscription?.unsubscribe();
  }

  /**
   * Get the current value or throw an error if the subject has an error or is closed.
   */
  getValue(): T {
    if (this.valueSubscription.closed) {
      throw new ObjectUnsubscribedError();
    }
    return this.lastValue;
  }
}

/**
 * Provides the last static value of a ReplaySubject and a static setter.
 * Inspired by the BehaviorSubject API.
 */
export class SubjectProvider<T> extends ObservableProvider<T> {
  constructor(
    onDestroyProvider: OnDestroyProvider,
    protected subject: Subject<T> = new ReplaySubject<T>(1)
  ) {
    super(onDestroyProvider, subject);
  }

  /**
   * Emit the provided value to the ReplaySubject.
   * Does not unsubscribe the follow subscription.
   */
  next(value: T): void {
    this.subject.next(value);
  }

  /**
   * Follow the provided observable.
   * Will unsubscribe from the last subscription if called multiple times.
   * @return Unsubscribe function
   */
  follow(observable: Observable<T>): () => void {
    this.lastFollowSubscription?.unsubscribe();
    this.lastFollowSubscription = observable.subscribe((result) => this.next(result));
    return () => this.lastFollowSubscription.unsubscribe();
  }

  /**
   * Internal onDestroy handle
   */
  destroy(): void {
    super.destroy();
    this.subject.complete();
  }

  /**
   * Get the current value or throw an error if the subject has an error or is closed.
   */
  getValue(): T {
    if (this.subject.hasError) {
      throw this.subject.thrownError;
    } else if (this.subject.closed) {
      throw new ObjectUnsubscribedError();
    } else {
      return this.lastValue;
    }
  }

  getEventEmitter(): EventEmitter<T> {
    // Cast as event emitter.
    // This means .emit is not available, but you shouldn't call that anyway, since you'd be using the SubjectProvider for that.
    return this.subject as EventEmitter<T>;
  }
}

/**
 * Abstract base class for dynamically updated lists of d3 managed elements
 */
export abstract class DynamicDataHelper<DatumType> {
  /**
   * Class to be added to the attached children.
   * Example: 'vertical-bar'
   */
  protected abstract class: string;
  /**
   * Node name to be created by the default enter implementation.
   * Example: 'rect'
   */
  protected abstract nodeName: string;

  constructor(protected parent: Selection<EnterElement, any, BaseType, any>) {}

  /**
   * Selector of the children.
   * Example: 'rect.vertical-bar'
   */
  protected get selector(): string {
    return `${this.nodeName}.${this.class}`;
  }

  getData(): DatumType[] {
    return this.parent.selectAll(this.selector).data() as DatumType[];
  }

  setData(data: DatumType[]): void {
    const selectAllWithData = this.parent.selectAll(this.selector).data(data);

    this.exit(selectAllWithData);
    this.enter(selectAllWithData);
    this.update(selectAllWithData);
  }

  /**
   * Update all static properties here, that do not rely on any datum information and do not change during the lifecycle of the node.
   */
  protected abstract setStatic(selectAllWithData: Selection<BaseType, DatumType, BaseType, any>): void;

  /**
   * @this
   * Update all dynamic properties here.
   */
  protected abstract setDynamic(selectAllWithData: Selection<BaseType, DatumType, BaseType, any>): void;

  /**
   * Set exit behavior of exit
   */
  protected exit(selectAllWithData: Selection<BaseType, DatumType, BaseType, any>): void {
    selectAllWithData.exit().remove();
  }

  /**
   * Set enter behavior of enter
   */
  protected enter(selectAllWithData: Selection<BaseType, DatumType, BaseType, any>): void {
    const appended = selectAllWithData.enter().append(this.nodeName).attr('class', this.class);

    this.setStatic(appended);
    this.setDynamic(appended);
  }

  /**
   * Set enter behavior of update
   */
  protected update(selectAllWithData: Selection<BaseType, DatumType, BaseType, any>): void {
    this.setDynamic(selectAllWithData);
  }
}

/**
 * Simplified common interface of D3 scales, like ScaleContinuousNumeric and ScaleTime
 */
export interface D3Scale<TDomain> {
  range(): [number, number];
  range(newRange: [number, number]): void;

  domain(): [TDomain, TDomain];
  domain(newDomain: [TDomain, TDomain]): void;
}

/**
 * Position of x axis around the graph.
 */
export type AxisPosition = 'left' | 'right';

export abstract class PortalDatum<TDatum> {
  cfr: ComponentFactoryResolver;
  appRef: ApplicationRef;

  /**
   * Lazy init Subject for the current Datum.
   * This is used to init the component or template portal.
   */
  datumSubject?: Subject<TDatum>;
}

// eslint-disable-next-line
export function PortalSubModuleMixin<TBase extends ConstructorType>(Base: TBase) {
  return class PortalSubModuleBase extends OnDestroyMixin(Base) {
    /**
     * ComponentFactoryResolver of the parent Angular component to use for creating the PortalOutlet
     */
    readonly cfrProvider = new SubjectProvider<ComponentFactoryResolver>(this, new BehaviorSubject(null));

    /**
     * AppReference of the parent Angular component to use for creating the PortalOutlet
     */
    readonly appRefProvider = new SubjectProvider<ApplicationRef>(this, new BehaviorSubject(null));

    setAppRef(appRef: ApplicationRef): void {
      this.appRefProvider.next(appRef);
    }

    setCfr(cfr: ComponentFactoryResolver): void {
      this.cfrProvider.next(cfr);
    }
  };
}
