import { DestroyableMixin, OnDestroyMixin, OnDestroyProvider } from '../../../core/common/on-destroy.mixin';
import { MixinBase } from '../../../core/common/constructor-type.mixin';
import { D3GraphScaleProvider } from './d3-graph-scale-provider';
import { ScaleBand } from 'd3-scale';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { SubjectProvider } from '../common';
import { map } from 'rxjs/operators';
import * as d3 from 'd3';

export class DumbbellScaleConfig {
  /**
   * Row height (bar + margin x 2) in px
   */
  rowHeight = 64;

  /**
   * Padding outer (between outside bars and range start/end) is 25% of row height (16px)
   */
  paddingOuter = 0.25;

  /**
   * Padding inner (between bars) is 50% of row height (32px)
   */
  paddingInner = 0.5;
}

export class DumbbellScaleProvider
  extends DestroyableMixin(OnDestroyMixin(MixinBase))
  implements D3GraphScaleProvider<ScaleBand<string>, string>
{
  private domainProvider = new SubjectProvider<string[] | string>(this);
  private configProvider = new SubjectProvider(this, new BehaviorSubject(new DumbbellScaleConfig()));

  scale$: Observable<ScaleBand<string>> = combineLatest([this.domainProvider.value$, this.configProvider.value$]).pipe(
    map(([domain, config]) =>
      d3
        .scaleBand()
        .domain(domain)
        .range([0, Math.max(1, domain.length) * config.rowHeight])
        .paddingOuter(config.paddingOuter)
        .paddingInner(config.paddingInner)
    )
  );

  scale: ScaleBand<string>;
  ticks$ = this.domainProvider.value$;

  constructor(onDestroyProvider: OnDestroyProvider) {
    super();
    this.registerOnDestroyProvider(onDestroyProvider);
    this.scale$.subscribe((scale) => (this.scale = scale));
  }

  setDomain$(domain$: Observable<string[]> | Observable<string>): void {
    this.domainProvider.follow(domain$);
  }

  setConfig(config: Partial<DumbbellScaleConfig>): void {
    this.configProvider.next({
      ...this.configProvider.value,
      ...config
    });
  }

  destroy(): void {
    this.ngOnDestroy();
  }
}
